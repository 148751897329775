import { buildQueryParams } from "helpers/utlities"
import { get, post, del, put } from "./AXIOS"
import {
  LABELS,
  GET_LABEL_TYPES,
  CREATE_NEW_LABEL_TYPE,
  FIND_LABELS,
  GET_LABEL_SUGGESTIONS,
  CREATE_NEW_SYSTEM,
  DELETE_SYSTEM,
  EDIT_SYSTEM, GET_POLICY_UPDATE_PICTURE
} from "./CONSTANTS"

export const getIndicators = () => get(`${LABELS}/all`)

export const getSystemTypes = (query, body) => {
  if (query?.searchTerm) {
    return post(
      `${GET_LABEL_TYPES}/${query.searchTerm}?limit=${query.limit}&offset=${query.offset}`,
      body
    )
  } else {
    return post(
      `${GET_LABEL_TYPES}?limit=${query.limit}&offset=${query.offset}`,
      body
    )
  }
}

export const createNewSystemType = body => post(CREATE_NEW_LABEL_TYPE, body)

// export const editSpecificSystem = (id, body) =>
//   put(`${EDIT_SYSTEM}/${id}`, body, {
//     headers: {
//       "Content-Type": "application/json",
//     },
//   }) // Ensure the id is correctly placed and content type is JSON

// export const getSystems = (query, body) => {
//   if (query?.searchTerm) {
//     return post(
//       `${FIND_LABELS}/${query.searchTerm}?limit=${query.limit}&offset=${query.offset}`,
//       body
//     )
//   } else {
//     return post(
//       `${FIND_LABELS}?limit=${query.limit}&offset=${query.offset}`,
//       body
//     )
//   }
// }

export const getSystems = (query) =>{
  const params = buildQueryParams({
    limit: query.limit,
    skip: query.offset,
    name: query.searchField
  });

  return get(`${FIND_LABELS}?${params.toString()}`)
}

export const getSystemSuggestions = input =>
  get(`${GET_LABEL_SUGGESTIONS}/${input}`)

// export const createNewSystem = body => post(CREATE_NEW_SYSTEM, body)

//Create new System
export const createNewSystem = body => post(
  `${CREATE_NEW_SYSTEM}`, body, {"headers": {"Content-Type": "application/json"}})

// Get specific system
export const getSpecificSystem = (system_id) =>
  get(GET_POLICY_UPDATE_PICTURE(system_id));

// Edit system
export const editSpecificSystem = (id, body) =>
  put(EDIT_SYSTEM(id), body, {"headers": {"Content-Type": "application/json"}})

// export const createNewSystem = async (body) => {
//   try {
//     const response = await post(CREATE_NEW_SYSTEM, body, {
//       headers: {
//         'Content-Type': 'application/json',
//       },
//     });
//     return response.data;
//   } catch (error) {
//     console.log('Error creating system:', error.response?.data || error.message);
//   }
// };


//Delete system
export const deleteSystem = (id) =>
  del(DELETE_SYSTEM(id), {"headers": { "accept": "application/json"}}); // Ensure the id is correctly placed
