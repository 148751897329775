import React, { useState, useEffect } from "react"
import { useQuery } from "@tanstack/react-query"
import { useSelector } from "react-redux"
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
} from "reactstrap"
import Select from "react-select"
import {
  createNewOrganization,
  getOrganizations,
  getOrganizationSuggestions,
  // getOrganizationTypes,
  editUserOrganisation,
  deleteUserOrganisation
} from "services/organizationServices"
import { CustomTable } from "./Organization-Management"
import OrganizationFilters from "./Organization-Filters"
import useDebounce from "hooks/useDebounce"
import SearchBox from "components/Common/SearchBox"
import Pagination from "components/Common/Pagination"
import { StyledContainer } from "pages/Labels/Systems"
import LoadingSpinner from "components/Common/Loading-Spinner"
import ExportTable from "../../components/Common/ExportTable";
import { exportTableData } from "services/common"
import './Organizations.css'
import { createNewPartner, deletePartner, editSpecificPartner } from "../../services/partnerServices"

const Organizations = ({alert}) => {
  
  document.title = "Organization Management"

  const initialOrganisationForm = {
    short_name: "",
    long_name: "",
  }

  // Organisation Edit Details
  const [newOrganisation, setNewOrganisation] = useState(initialOrganisationForm)

  //fetch Organizations
  const loadOrganizations = async (query) => {
    try {
      const { data, count } = await getOrganizations(query)
      setTotalEntries(count)
      return data
    } catch (err) {
      console.error(err)
      return { organizations: [] }
    }
  }
  
  //fetch OrgTypes
  // const loadOrganizationTypes = async () => {
  //   try {
  //     const { data } = await getOrganizationTypes({ limit: 0, offset: 0 })
  //     return data
  //   } catch (err) {
  //     console.error(err)
  //     return []
  //   }
  // }
  //fetch Organization Suggestions
  const loadOrganizationSuggestions = async input => {
    try {
      const { data, count } = await getOrganizationSuggestions(input)
      setTotalEntries(count)
      return data
    } catch (err) {
      console.error(err)
      return []
    }
  }

  // const { data: orgTypesData } = useQuery({
  //   queryKey: ["Organization Types"],
  //   queryFn: () => loadOrganizationTypes(),
  //   initialData: { types: [] },
  // })
  
  //pagination
  const [offset, setOffset] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalEntries, setTotalEntries] = useState(0)

  const handlePageChange = pageNum => {
    const offsetVal = pageNum * 50 - 50
    setOffset(offsetVal)
    setCurrentPage(pageNum)
  }
  //search
  const [searchTerm, setSearchTerm] = useState("")
  const debouncedSearchTerm = useDebounce(searchTerm)

  const handleSearchInputChange = evt => {
    setSearchTerm(evt.target.value)
    setOffset(0)
    setCurrentPage(1)
    setTotalEntries(orgData?.count || 0)
  }
  //filters
  const [selectedLocations, setSelectedLocations] = useState([])

  const applyFilters = (locations) => {
    setSelectedLocations(locations)
  }

  //clear Filters
  const areAnyFiltersSelected = () => Boolean(selectedLocations.length);

  const clearFilters = () => {
    setSelectedLocations([])
  }

  const [modalFilter, setModalFilter] = useState(false)
  const toggleModalFilter = () => setModalFilter(prevState => !prevState)

  const {
    data: orgData = { organizations: [] },
    refetch,
    isLoading,
  } = useQuery({
    queryKey: [
      "Organizations",
      {
        debouncedSearchTerm,
        selectedLocations,
        page: currentPage,
      },
    ],
    queryFn: () => loadOrganizations(
            { 
              searchField: debouncedSearchTerm, 
              limit: 50, 
              offset, 
              locations: selectedLocations?.map(location=>location.value),
            },
          )
  });

  //modal

  //userInputs
  const [userInput, setUserInput] = useState("")
  const [hasVisitedInput, setHasVisitedInput] = useState(false)
  const [showSuggestions, setShowSuggestions] = useState(true)
  const [selectedSuggestion, setSelectedSuggestion] = useState(null)
  // const [selectedSuggestionsOrgType, setSelectedSuggestionsOrgType] = useState(
  //   []
  // )
  const [isUserEditing, setIsUserEditing] = useState(false)
  const [editingRowID, setEditingRowID] = useState(null)
  const [orgAlreadyExistsError, setOrgAlreadyExistsError] = useState(false)
  const [selectedCountries, setSelectedCountries] = useState([])
  // const [orgType, setOrgType] = useState({})

  const [showModal, setShowModal] = useState(false)
  // const openModal = () => {
  //   setShowModal(true)
  //   if (!isUserEditing) {
  //     setUserInput("")
  //     setOrgAlreadyExistsError(false)
  //     setHasVisitedInput(false)
  //     // setSelectedCountries([])
  //     // setOrgType({})
  //   }
  // }
  // const closeModal = () => {
  //   setShowModal(false)
  //   setIsUserEditing(false)
  //   setUserInput("")
  //   setOrgAlreadyExistsError(false)
  //   setHasVisitedInput(false)
  // }
  const openModal = () => setShowModal(true)
  const closeModal = () => {
    setShowModal(false)
    setNewOrganisation({
      short_name: "",
      long_name: "",
    })
    setIsUserEditing(false)
    setEditingRowID(null)
  }

  const { data: orgSuggestions } = useQuery({
    queryKey: ["Organization Suggestions", { userInput }],
    queryFn: () => loadOrganizationSuggestions(userInput),
    enabled: userInput.length >= 3 && hasVisitedInput,
    initialData: [],
  })

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target
    setNewOrganisation((prevOrganisation) => ({
      ...prevOrganisation,  [name]: value
    }))
    // setUserInput(evt.target.value)
    // setShowSuggestions(true)
    // setSelectedSuggestion(null)
    // setOrgAlreadyExistsError(false)
  }

  const onDeleteClick = async (id) => {
    try {
      await deleteUserOrganisation(id)
      alert("Organisation deletion", true)
      await refetch() // Refresh the partners list after deletion
    } catch (err) {
      alert("Failed to delete organisation", false)
      console.error("Error deleting organisation:", err)
    }
  }

  const handleSuggestionSelection = suggestion => {
    setSelectedSuggestion(suggestion)
    setUserInput(suggestion.name)
    setShowSuggestions(false)
    // const selectedSuggestionsOrgType = orgTypesData.types.find(
    //   orgType => orgType.id === suggestion.organization_type.id
    // )
    // setSelectedSuggestionsOrgType([selectedSuggestionsOrgType])
  }
  const handleOrgCreate = async () => {
    // const selectedCountryCodes = selectedCountries?.map(country => country.value)

    if (!newOrganisation.long_name) {
      alert("Long name field cannot be empty", false)
      return
    }

    try {
      const requestBody = {
        short_name: newOrganisation.short_name,
        long_name: newOrganisation.long_name,
      }

      if (isUserEditing) {
        await editUserOrganisation(editingRowID, requestBody) // Ensure the correct id is passed
        alert("Organisation updation", true)
      }
      // else {
      //   await createNewPartner(requestBody)
      //   alert("Organisation creation", true)
      // }

      closeModal()
      await refetch()
    } catch (err) {
      console.error("Error response:", err.response?.data)
      alert("Failed to create organisation", false)
    }

    // if (!isUserEditing) {
    //   let body
    //   if (selectedSuggestion && selectedSuggestion.name === userInput) {
    //     body = {
    //       id: selectedSuggestion.id,
    //       organization_type: selectedSuggestion.organization_type.id,
    //       countries: [
    //         ...selectedSuggestion.countries?.map(country => country.id),
    //         ...selectedCountryCodes,
    //       ],
    //       name: userInput,
    //     }
    //   } else {
    //     body = {
    //       name: userInput,
    //       countries: selectedCountryCodes,
    //       organization_type: orgType.value,
    //     }
    //   }
    //   try {
    //     const { data } = await createNewOrganization(body)
    //     closeModal()
    //   } catch (err) {
    //     if (err.response.data.statusCode === 409) setOrgAlreadyExistsError(true)
    //     console.error(err)
    //   }
    // } else {
    //   try {
    //     const { data } = await editSpecificOrganization(editingRowID, {
    //       name: userInput,
    //       countries: selectedCountryCodes,
    //       organization_type: orgType.value,
    //     })
    //     closeModal()
    //   } catch (err) {
    //     console.error(err)
    //   }
    // }
    // refetch()
  }

  const initiateEdit = (id) => {
    // console.log("Editing ID:", id);
    // setShowModal(true)
    // setIsUserEditing(true)
    const selectedRowData = orgData?.find(row => row.id === id)

    setNewOrganisation(
      {
        short_name: selectedRowData.short_name,
        long_name: selectedRowData.long_name,
      }
    )

    if (!selectedRowData) {
      console.error(`Organization with id ${id} not found.`);
      return; // Exit the function early if the row is not found
    }

    setIsUserEditing(true)
    setEditingRowID(selectedRowData.id)
    openModal()

    // setEditingRowID(selectedRowData.id)
    // setUserInput(selectedRowData.name)
    // setSelectedCountries(
    //   selectedRowData.countries?.map(country => ({
    //     label: country.label,
    //     value: country.id,
    //   }))
    // )
  }

  const exportTable = async type => {
    try {
      const query = {
        export_format: type,
        // ...(selectedUser && { user_id: selectedUser }),
        // ...(selectedActivity && { activity: selectedActivity }),
      };

      exportTableData(query, "Organisations");
    } catch (err) {
      console.error(err)
      alert("Data export", false)
    }
  }

  return (
    <div>
      <div className="d-flex justify-content-between">
        <StyledContainer>
          <SearchBox searchChange={handleSearchInputChange} />
          <Button
            style={{
              backgroundColor: "#036F36",
              color: "#FFFDB8",
              margin: "15px 10px",
              border: "none",
              fontSize: "14px",
            }}
            onClick={toggleModalFilter}
          >
            <i className="mdi mdi-filter-variant me-1" /> Filters
          </Button>
          {/* <OrganizationFilters
              // typeOptions={orgTypesData.types?.map(val => ({
              //   label: val.name,
              //   value: val.id,
              // }))}
              countryOptions={countries?.map(country => ({
                label: country.name,
                value: country.code,
              }))}
              applyFilters={applyFilters}
            /> */}
          {/* <Select
            options={sortOptions}
            defaultValue={sortBy}
            className="basic-single"
            classNamePrefix="select"
            placeholder="Sort by"
            onChange={option => setSortBy(option?.value || "ASC")}
          /> */}
          {areAnyFiltersSelected() && (
            <Button
              className="d-flex align-items-center"
              style={{
                backgroundColor: "#f8f9fa",
                color: "black",
                margin: "15px 10px",
                border: "none",
                fontSize: "14px",
              }}
              onClick={clearFilters}
            >
              <i className="ph-x me-1"></i>
              Clear All Filters
            </Button>
          )}
        </StyledContainer>
        {/*<Button*/}
        {/*  className="common-button"*/}
        {/*  onClick={openModal}*/}
        {/*  style={{ marginInline: 0 }}*/}
        {/*>*/}
        {/*  Create New*/}
        {/*</Button>*/}
        <ExportTable loadFilteredData={exportTable}></ExportTable>
        <Modal
          isOpen={modalFilter}
          toggle={toggleModalFilter}
          className="modal-lg"
        >
          <ModalHeader toggle={toggleModalFilter}>
            <i className="mdi mdi-filter-variant me-1"></i>
            Filters
          </ModalHeader>
          <ModalBody>
            <OrganizationFilters
              filteredLocations={selectedLocations}
              applyFilters={applyFilters}
            />
          </ModalBody>
        </Modal>
        {/* <Modal
          isOpen={showModal}
          toggle={showModal ? closeModal : openModal}
          style={{ borderRadius: "0.25rem" }}
        >
          <ModalHeader
            style={{
              alignItems: "flex-start",
              height: "80px",
              paddingBottom: "0px",
            }}
            toggle={showModal ? closeModal : openModal}
          >
            <b>
              <p
                style={{
                  fontSize: "24px",
                  marginBottom: "0px",
                  color: "black",
                }}
              >
                {isUserEditing ? (
                  <>
                    <i className="mdi mdi-plus me-2"></i>
                    Edit Organization
                  </>
                ) : (
                  <>
                    <i className="mdi mdi-plus me-2"></i>
                    Create New Organization
                  </>
                )}
              </p>
            </b>
          </ModalHeader>
          <ModalBody>
            <div style={{ position: "relative" }}>
              <Label className="form-label" htmlFor="name">
                Org Name:
              </Label>
              <Input
                id="name"
                type="text"
                value={userInput}
                required
                onChange={handleInputChange}
                onFocus={() => setHasVisitedInput(true)}
              />
              {orgAlreadyExistsError && (
                <p style={{ color: "red" }}>
                  An organization by this name already exits. Please retype the
                  org name and select it from the suggestions.
                </p>
              )}
              {orgSuggestions.length > 0 && showSuggestions && (
                <ul
                  style={{
                    position: "absolute",
                    top: "100%",
                    left: 0,
                    margin: 0,
                    padding: 0,
                    maxHeight: "100px",
                    overflowY: "auto",
                    zIndex: 999,
                    width: "100%",
                    backgroundColor: "#f2f2f2",
                    listStyle: "none",
                  }}
                >
                  {orgSuggestions?.map(suggestion => (
                    <li
                      key={suggestion.id}
                      style={{ padding: "0.5rem", cursor: "pointer" }}
                      onClick={() => {
                        handleSuggestionSelection(suggestion)
                      }}
                    >
                      {suggestion.name}
                    </li>
                  ))}
                </ul>
              )}
            </div>
            <div>
              <Label className="form-label" htmlFor="name">
                Countries:
              </Label>
              <Select
                name="countries"
                classNamePrefix="select2-selection"
                options={countries?.map(country => ({
                  label: country.long_name,
                  value: country.id,
                }))}
                isMulti
                value={selectedCountries}
                onChange={setSelectedCountries}
              />
            </div>
          </ModalBody>
          <ModalFooter
            style={{
              display: "flex",
              justifyContent: "flex-end",
              borderTop: "none",
              paddingTop: "0px",
            }}
          >
            <div>
              <Button
                style={{ backgroundColor: "#50A050", border: "none" }}
                onClick={handleOrgCreate}
              >
                {isUserEditing ? "Save" : "Create"}
              </Button>
            </div>
          </ModalFooter>
        </Modal> */}
        <Modal
          isOpen={showModal}
          toggle={showModal ? closeModal : openModal}
          className="custom-modal"
        >
          <ModalHeader
            className="modal-header"
            style={{
              display: 'flex',
              alignItems: 'center', // Ensure vertical alignment
              justifyContent: 'flex-start', // Align content to the left
              padding: '10px 15px', // Adjust padding as needed
            }}
            toggle={showModal ? closeModal : openModal}
          >
            <b>
              <p className="modal-title">
                {isUserEditing ? (
                  <>
                    <i className="mdi mdi-plus me-2"></i>
                    Edit Organization
                  </>
                ) : (
                  <>
                    <i className="mdi mdi-plus me-2"></i>
                    Create New Organization
                  </>
                )}
              </p>
            </b>
          </ModalHeader>

          <div className="modal-subtext">
            <small>All fields marked with asterisks (<span className="required">*</span>) are compulsory.</small>
          </div>

          <ModalBody className="modal-body">
            <Label className="form-label" htmlFor="shortName">
              Organisation Short Name
            </Label>
            <Input
              id="shortName"
              name="short_name"
              type="text"
              className="input-field"
              value={newOrganisation.short_name}
              required
              onChange={handleInputChange}
            />

            <Label className="form-label mt-3" htmlFor="longName">
              Organisation Long Name<span className="required">*</span>
            </Label>
            <Input
              id="longName"
              name="long_name"
              type="text"
              className="input-field"
              value={newOrganisation.long_name}
              onChange={handleInputChange}
            />

            {/*<div className="form-group position-relative">*/}
            {/*  <Label className="form-label" htmlFor="shortName">*/}
            {/*    Organization Short Name*/}
            {/*  </Label>*/}
            {/*  <Input*/}
            {/*    id="shortName"*/}
            {/*    type="text"*/}
            {/*    value={userInput}*/}
            {/*    required*/}
            {/*    className="input-field"*/}
            {/*    onChange={handleInputChange}*/}
            {/*    onFocus={() => setHasVisitedInput(true)}*/}
            {/*  />*/}
            {/*  {orgAlreadyExistsError && (*/}
            {/*    <p className="error-text">*/}
            {/*      An organization by this name already exists. Please retype the org name and select it from the*/}
            {/*      suggestions.*/}
            {/*    </p>*/}
            {/*  )}*/}
            {/*  {orgSuggestions.length > 0 && showSuggestions && (*/}
            {/*    <ul className="suggestions-list">*/}
            {/*      {orgSuggestions.map((suggestion) => (*/}
            {/*        <li*/}
            {/*          key={suggestion.id}*/}
            {/*          className="suggestion-item"*/}
            {/*          onClick={() => handleSuggestionSelection(suggestion)}*/}
            {/*        >*/}
            {/*          {suggestion.name}*/}
            {/*        </li>*/}
            {/*      ))}*/}
            {/*    </ul>*/}
            {/*  )}*/}
            {/*</div>*/}

            {/*<div className="form-group position-relative">*/}
            {/*  <Label className="form-label" htmlFor="name">*/}
            {/*    Organization Long Name<span className="required">*</span>*/}
            {/*  </Label>*/}
            {/*  <Input*/}
            {/*    id="name"*/}
            {/*    type="text"*/}
            {/*    value={userInput}*/}
            {/*    required*/}
            {/*    className="input-field"*/}
            {/*    onChange={handleInputChange}*/}
            {/*    onFocus={() => setHasVisitedInput(true)}*/}
            {/*  />*/}
            {/*  {orgAlreadyExistsError && (*/}
            {/*    <p className="error-text">*/}
            {/*      An organization by this name already exists. Please retype the org name and select it from the*/}
            {/*      suggestions.*/}
            {/*    </p>*/}
            {/*  )}*/}
            {/*  {orgSuggestions.length > 0 && showSuggestions && (*/}
            {/*    <ul className="suggestions-list">*/}
            {/*      {orgSuggestions.map((suggestion) => (*/}
            {/*        <li*/}
            {/*          key={suggestion.id}*/}
            {/*          className="suggestion-item"*/}
            {/*          onClick={() => handleSuggestionSelection(suggestion)}*/}
            {/*        >*/}
            {/*          {suggestion.name}*/}
            {/*        </li>*/}
            {/*      ))}*/}
            {/*    </ul>*/}
            {/*  )}*/}
            {/*</div>*/}

            {/*<div className="form-group">*/}
            {/*  <Label className="form-label" htmlFor="countries">*/}
            {/*    Countries<span className="required">*</span>*/}
            {/*  </Label>*/}
            {/*  <Select*/}
            {/*    name="countries"*/}
            {/*    classNamePrefix="select2-selection"*/}
            {/*    options={countries?.map((country) => ({*/}
            {/*      label: country.label,*/}
            {/*      value: country.id,*/}
            {/*    }))}*/}
            {/*    isMulti*/}
            {/*    value={selectedCountries}*/}
            {/*    onChange={setSelectedCountries}*/}
            {/*    styles={{*/}
            {/*      container: (provided) => ({*/}
            {/*        ...provided,*/}
            {/*        width: '100%',  // Ensure the dropdown takes full width*/}
            {/*      }),*/}
            {/*      control: (provided) => ({*/}
            {/*        ...provided,*/}
            {/*        borderRadius: '8px',*/}
            {/*        backgroundColor: '#F3F4F6',*/}
            {/*        borderColor: '#ccc',*/}
            {/*        padding: '8px',*/}
            {/*      }),*/}
            {/*    }}*/}
            {/*  />*/}
            {/*</div>*/}
          </ModalBody>

          <ModalFooter className="modal-footer">
            <Button
              // className="btn-primary"
              style={{
                backgroundColor: '#036F36',
                color: '#FFF605',
                borderRadius: '2.25rem',
                width: '100%',
              }}
              onClick={handleOrgCreate}
            >
              {isUserEditing ? "Save" : "Create"}
            </Button>
          </ModalFooter>
        </Modal>

      </div>
      {isLoading && <LoadingSpinner />}
      {Array.isArray(orgData) && orgData.length > 0 && !isLoading ? (
        <>
          <CustomTable
            data={orgData || []}
            caption="Organizations"
            initiateEdit={initiateEdit}
            onDeleteClick={onDeleteClick}
          />
          <Pagination
            currentPage={currentPage}
            goToPage={handlePageChange}
            totalEntries={totalEntries}
          />
        </>
      ) : (
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          {Array.isArray(orgData) && orgData.length === 0 && !isLoading ? (
            <p>No organizations available.</p>
          ) : (
            <p>Loading organizations data...</p>
          )}
        </div>
      )}
    </div>
  )
}

export default Organizations
