import React from 'react';
import PropTypes from 'prop-types'
import { withRouter } from "react-router-dom"
import LandingPageHeader from 'pages/LandingPage/Header';
import Footer from './VerticalLayout/Footer';

const HeaderLayout = (props) => {
    return (
        <React.Fragment>
            <LandingPageHeader />
            <div className="pb-4">{props.children}</div>
            <Footer/>
        </React.Fragment>
    );
}

HeaderLayout.propTypes = {
    children: PropTypes.any,
    location: PropTypes.object
}
  
export default withRouter(HeaderLayout)