import React, { useState } from 'react';
import styles from './styles/mile_stones.module.css'
const MileStonePopUp = ({hoverConent,mainContent}) => {
  // State to control the visibility of the pop-up
  const [isHovered, setIsHovered] = useState(false);

  // Handle mouse enter and leave
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div className={styles.container}>
      {/* Main Div where hover will occur */}
      <div
        className={styles.hoverTarget}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {hoverConent}
      </div>

      {/* Popup div that shows on hover */}
      {isHovered && (
        <div className={`${styles.popup} ${styles.above}`}>
          {mainContent}
        </div>
      )}
    </div>
  );
};

export default MileStonePopUp;
