import React from "react"
import CustomTabs from "components/Common/Custom-Tabs"
import Visualisation from "./Visualisation"
import CountryLandscaping from "./CountryLandscaping"

export default function LandscapingTabs({alert}) {
  const triggers = [
    { name: "Visualisation", value: "tab1" },
    { name: "Data", value: "tab2" },
  ]
  const content = [
    { value: "tab1", component: <Visualisation alert={alert} /> },
    { value: "tab2", component: <CountryLandscaping alert={alert}/> },
  ]

  return (
    <div className="page-content">
      <CustomTabs
        ariaLabel="Landscaping"
        defaultValue="tab1"
        triggers={triggers}
        content={content}
      />
    </div>
  )
}
