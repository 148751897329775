import CountriesDropdown from 'components/Common/countries-dropdown';
import React, { useEffect, useState } from 'react';
import LoadingSpinner from "components/Common/Loading-Spinner";
import { Button } from 'reactstrap';
import TabularFormsTable from "./LandscapingDataAnalyticsTable";
import { getLandscapingAnalytics } from 'services/milestoneServices';
import Chart from './Chart';
import * as XLSX from 'xlsx';
import { Icon } from '@iconify/react';
import downloadChart from 'components/Common/ImageToPdf';

function Visualisation({alert}) {

    const [isLoading, setIsLoading] = useState(true);
    const [tab, setTab] = useState('chart');
    const [countryID, setCountryID] = useState();
    const [data, setData] = useState();
    const [isDownloading, setIsDownloading] = useState(false);

    const getData = async () => {
        try {
            setIsLoading(true);
            let query = {
                view_type: tab,
                ...(countryID && { country_id: countryID }),
            };
            
            const response = await getLandscapingAnalytics(query);
            setData(response); // Set the data to state
        } catch (err) {
            setData(null); // Clear data on error
            console.error("Error fetching data:", err); // Log any error
            alert("Error fetching data", false);
        } finally {
            setIsLoading(false);
        }
    };
    
    const generatePDF = () => {
        if (tab === 'chart') {
            downloadChart('analytics-chart', setIsDownloading, `landscaping-chart-${new Date().toISOString().split('T')[0]}.pdf`);
        } else {
            downloadTable();
        }
    }

    const downloadTable = () => {
        const ws = XLSX.utils.aoa_to_sheet(TabularFormsTable.exportData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Policy Analysis');

        const filename = `policy-analysis-${new Date().toISOString().split('T')[0]}.xlsx`;
        XLSX.writeFile(wb, filename);
    };

    const handleTabChange = (tabValue) => {
        setTab(tabValue);
    };

    useEffect(()=>{
        getData();
    },[tab, countryID])

    return (
        <div>
            <div className="d-flex justify-content-between">
                <div className="country-selection">
                <span className="mr-2 text-nowrap">Showing data for </span>
                    <CountriesDropdown setCountryID={setCountryID} setDataLoading={setIsLoading} hasRAC={true}/>
                </div>
                <Button className="common-button" onClick={generatePDF} disabled={isLoading || isDownloading}>
                    {isDownloading ? <Icon icon="codex:loader"/> : <i className="bx bx-download"/>}
                </Button>
            </div>
            <div className='d-flex justify-center'>
                <div className={`sub-tab mr-1 ${tab === 'chart' ? 'sub-tab-active' : ''}`} onClick={()=>handleTabChange('chart')}>Chart</div>
                <div className={`sub-tab ml-1 ${tab === 'tabular' ? 'sub-tab-active' : ''}`} onClick={()=> handleTabChange('tabular')}>Table</div>
            </div>
            {isLoading ? (
                <LoadingSpinner />
            ) : (
            data ? 
                (tab === "chart" ? (
                    <div id="analytics-chart">
                        <Chart data={data}/>
                    </div>
                    ) : (
                    <TabularFormsTable forms={data} countryID={countryID} />
                )) 
            : <p>No data found.</p>
            )}
        </div>
    );
}

export default Visualisation;