import React, { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
} from 'chart.js';
import { Radar } from 'react-chartjs-2';
import { Card, CardBody } from 'reactstrap';

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

const RadarChart = ({ data }) => {
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: '',
        data: [],
        backgroundColor: 'rgba(34, 197, 94, 0.2)',
        borderColor: 'rgb(34, 197, 94)',
        borderWidth: 2,
        pointBackgroundColor: 'rgb(34, 197, 94)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgb(34, 197, 94)',
      },
    ],
  });

  const wrapLabel = (str, maxCharsPerLine = 35) => {
    if (!str) return '';
    const words = str.split(' ');
    let lines = [];
    let currentLine = '';

    words.forEach(word => {
      if ((currentLine + ' ' + word).length <= maxCharsPerLine) {
        currentLine += (currentLine.length ? ' ' : '') + word;
      } else {
        if (currentLine) lines.push(currentLine);
        currentLine = word;
      }
    });
    if (currentLine) lines.push(currentLine);
    return lines;
  };

  useEffect(() => {
    if (data?.benchmarking_milestone_questions?.length > 0) {
      setChartData({
        labels: data.benchmarking_milestone_questions.map(
          item => item?.benchmarking_milestone_question?.question || ''
        ),
        datasets: [
          {
            label: data?.benchmarking_milestone || '',
            data: data.benchmarking_milestone_questions.map(
              item => ((item?.question_response_score || 0) / 3) * 100
            ),
            backgroundColor: 'rgba(34, 197, 94, 0.2)',
            borderColor: 'rgb(34, 197, 94)',
            borderWidth: 2,
            pointBackgroundColor: 'rgb(34, 197, 94)',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgb(34, 197, 94)',
          },
        ],
      });
    }
  }, [data]);

  const options = {
    responsive: true,
    scales: {
      r: {
        angleLines: {
          display: true,
          color: 'rgba(0, 0, 0, 0.1)',
        },
        grid: {
          color: 'rgba(0, 0, 0, 0.1)',
        },
        suggestedMin: 0,
        suggestedMax: 100,
        ticks: {
          stepSize: 20,
          color: 'rgb(75, 85, 99)',
          font: {
            size: 12,
          },
        },
        pointLabels: {
          color: 'rgb(55, 65, 81)',
          font: {
            size: 13,
            weight: '500',
            family: 'Inter, system-ui, sans-serif'
          },
          padding: 25,
          centerPointLabels: false,
          callback: function(value) {
            return wrapLabel(value);
          }
        },
      },
    },
    plugins: {
      legend: {
        position: 'top',
        align: 'center',
        labels: {
          boxWidth: 16,
          boxHeight: 16,
          padding: 16,
          font: {
            size: 14,
            weight: '600',
            family: 'Inter, system-ui, sans-serif'
          },
        },
      },
      tooltip: {
        callbacks: {
          label: (context) => `Score: ${context.formattedValue}%`,
        },
        padding: 12,
        bodyFont: {
          size: 14,
          family: 'Inter, system-ui, sans-serif'
        },
      },
    },
    elements: {
      line: {
        tension: 0.2,
      },
    },
    maintainAspectRatio: false,
  };

  if (!data?.benchmarking_milestone_questions?.length) {
    return (
      <div className="w-full h-[600px] flex items-center justify-center text-gray-500">
        No data available
      </div>
    );
  }

  return (
    <Card className="w-full">
        <CardBody className="p-8">
          <div className="flex-1 w-full min-h-[600px]">
            <Radar data={chartData} options={options} />
          </div>
        </CardBody>
    </Card>
  );
};

export default RadarChart;