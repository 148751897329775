import * as moment from "moment"

export const formateDate = (date, format) => {
  const dateFormat = format ? format : "Y-MM-DD H:m:s"
  //const date1 = moment(date).format(dateFormat) // For local timezone
  const date1 = moment.utc(date).format(dateFormat) // UTC Timezone format
  return date1
}

export const formateOnlyDate = (date, format) => {
  const dateFormat = format ? format : "Y-MM-DD"
  //const date1 = moment(date).format(dateFormat) // For local timezone
  const date1 = moment.utc(date).format(dateFormat) // UTC Timezone format
  return date1
}

export const capitalizeFirstLetter = str => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

const formatter = new Intl.RelativeTimeFormat("en-us", {
  numeric: "always",
})

const DIVISIONS = [
  { amount: 60, name: "seconds" },
  { amount: 60, name: "minutes" },
  { amount: 24, name: "hours" },
  { amount: 7, name: "days" },
  { amount: 4.34524, name: "weeks" },
  { amount: 12, name: "months" },
  { amount: Number.POSITIVE_INFINITY, name: "years" },
]
export function formatTimeAgo(date) {
  let duration = (new Date(date) - new Date()) / 1000

  for (let i = 0; i < DIVISIONS.length; i++) {
    const division = DIVISIONS[i]
    if (Math.abs(duration) < division.amount) {
      return formatter.format(Math.round(duration), division.name)
    }
    duration /= division.amount
  }
}

export function formatDateUTC(dt) {
  const date = new Date(dt)
  const year = date.getUTCFullYear()
  const month = (date.getUTCMonth() + 1).toString().padStart(2, "0")
  const day = date.getUTCDate().toString().padStart(2, "0")
  const hours = date.getUTCHours().toString().padStart(2, "0")
  const minutes = date.getUTCMinutes().toString().padStart(2, "0")
  const seconds = date.getUTCSeconds().toString().padStart(2, "0")

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds} UTC`
}

export const welcomeMessages = [
  {
    id: 0,
    message:
      "Effortlessly navigate through a comprehensive range of agricultural policies, making it simple to find, analyze, and utilize key information for informed decision-making",
  },
  {
    id: 1,
    message:
      "Unlock detailed and comprehensive gap analysis of agricultural policies to better understand their strengths, weaknesses, and areas for impactful reform",
  },
  {
    id: 2,
    message:
      "Benchmark agricultural policies with precision and leverage insights to drive significant policy transformation, fostering improvements in agricultural systems",
  },
  {
    id: 3,
    message:
      "Effortlessly navigate through a comprehensive range of agricultural policies, making it simple to find, analyze, and utilize key information for informed decision-making",
  },
]
export const transformationResults = [
  {
    title: 'Real-Time Policy Tracking',
    message:
      "Instant updates on the latest policy developments, ensuring you're always informed and prepared.",
  },
  {
    title: 'Customizable Dashboards',
    message:
      "Organize your policy information exactly how you want it, focusing on the areas that matter most to you.",
  },
  {
    title: 'Benchmarking and Analytics',
    message:
      "Track key metrics and gain valuable insights to optimize your policy strategy.",
  },
  {
    title: 'Policy News and Updates',
    message:
      "Timely information on policy developments, ensuring you're always up-to-date on the issues that matter most.",
  },
]

export function areObjectsEqual(obj1, obj2) {
  const obj1Keys = Object.keys(obj1);
  const obj2Keys = Object.keys(obj2);

  // Check if both objects have the same number of keys
  if (obj1Keys.length !== obj2Keys.length) {
    return false;
  }

  // Check each key-value pair
  for (const key of obj1Keys) {
    const value1 = obj1[key];
    const value2 = obj2[key];

    // Handle arrays specially
    if (Array.isArray(value1) && Array.isArray(value2)) {
      if (value1.length !== value2.length) {
        return false;
      }
      // Compare array contents
      if (!value1.every((item, index) => item === value2[index])) {
        return false;
      }
    }
    // Handle non-array values
    else if (value1 !== value2) {
      return false;
    }
  }

  return true;
}

// Helper function to handle both single and multiple values
const appendParam = (params, key, value) => {
  if (Array.isArray(value)) {
    value.forEach(v => params.append(key, v)); // Append each item in array
  } else if (value !== undefined && value !== null && value !== "") {
    params.append(key, value); // Append single value
  }
};

// Function to build query parameters for API calls
export const buildQueryParams = (paramMap) => {
  const params = new URLSearchParams();
  for (const [key, value] of Object.entries(paramMap)) {
    appendParam(params, key, value);
  }
  return params;
};