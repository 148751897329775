import React, { useState, useEffect } from "react"

export const OnboardSlider = ({ slides, child }) => {
  const [currentIndex, setCurrentIndex] = useState(0)

  // Function to move to the next slide
  const nextSlide = () => {
    setCurrentIndex(prevIndex => (prevIndex + 1) % slides.length)
  }

  // Automatically move to the next slide every 3 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide()
    }, 10000) // Change slide every 5 seconds

    return () => clearInterval(interval) // Cleanup interval on unmount
  }, [currentIndex])

  return (
    <div className="overflow-hidden">
      <div
        className="flex"
        style={{
          transform: `translateX(-${currentIndex * 100}%)`,
          transition: "transform 0.5s ease-in-out",
        }}
      >
        {slides && slides.length > 0
          ? slides.map((slide, index) => {
              return (
                <div key={index} className="min-w-full">
                  <div className="font-medium text-base lg:text-lg text-[#FEFE01]">
                    {slide?.message}
                  </div>
                </div>
              )
            })
          : null}
      </div>
      <div>{child}</div>

      {/* <div className="flex flex-row mt-32 w-[20rem]">
        {slides && slides.length > 0
          ? slides.map((_, index) => {
              return (
                <div
                  key={index}
                  className={`w-[4.875rem] h-[0.218rem] mr-2 ${
                    currentIndex === index ? "bg-[#EAE200]" : "bg-[#D9D9D9]"
                  }`}
                ></div>
              )
            })
          : null}
      </div> */}
    </div>
  )
}
