import React from "react";
import { Table } from "reactstrap";
import { Link } from "react-router-dom"; // Import Link for routing
import "./FormsTable.scss";
import styled from "@emotion/styled";

// Styling for the button group
const StyledButtonGroup = styled.div`
  display: flex;
  gap: 1em;
  border-collapse: separate;

  & button {
    all: unset;
    cursor: pointer;
  }

  & .mdi-pencil {
    color: #4caf50; /* Green for edit */
    transition: transform 0.2s;
  }

  & .mdi-delete {
    color: #f44336; /* Red for delete */
    transition: transform 0.2s;
  }

  & button:hover .mdi-pencil,
  & button:hover .mdi-delete {
    transform: scale(1.1);
  }
`;

const StyledTableContainer = styled.div`
  width: 100%;
  padding: 1em;
  overflow-x: auto; /* Allows horizontal scroll if necessary */
`;

const StyledTable = styled(Table)`
  width: 100%;
  table-layout: fixed;
  background-color: transparent;
  border-radius: 16px 16px 0px 0px;
  overflow: hidden;

  & thead {
    background-color: #f8f9fa;
  }

  & td {
    padding: 1em;
    text-align: left;
    font-size: 14px;
    font-weight: 500;
    border: none; /* Remove all borders */
    border-bottom: 1px solid #dee2e6; /* Only keep the bottom border */
    vertical-align: middle;
    background-color: white; /* Ensure the background is white */
  }

  & tbody tr td:nth-of-type(2) {
    .name-cell {
      padding: 0.2em 0.4em;
      color: #28a745; /* Green color for the text */
      text-decoration: none; /* Prevent underline for links */
      background-color: transparent; /* Remove blue highlight */
    }

    .name-cell:hover {
      text-decoration: underline; /* Add underline on hover for better UX */
    }
  }
`;

function FormsTable({ forms }) {
  return (
    <StyledTableContainer>
      <StyledTable className="custom-table">
        <colgroup>
          <col style={{ width: "20%" }} />
          <col style={{ width: "25%" }} />
          <col style={{ width: "30%" }} />
          <col style={{ width: "13%" }} />
          <col style={{ width: "13%" }} />
          <col style={{ width: "15%" }} />
          {/*<col style={{ width: "15%" }} />*/}
        </colgroup>
        <thead>
          <tr>
            <th scope="col">Full Name</th>
            <th scope="col">Email</th>
            <th scope="col">Location</th>
            <th scope="col">Status</th>
            <th scope="col">Role</th>
            <th scope="col">Created At</th>
            {/*<th scope="col">Action</th>*/}
          </tr>
        </thead>
        <tbody>
          {forms && forms.length > 0 ? (
            forms.map((row, i) => {
              return (
                <tr key={i}>
                  <td className="col-w-lg`" style={{ backgroundColor: "white" }}>
                    <a href={`/user-management/${row.id}`} rel="noopener noreferrer" className="flex items-start">
                      <span className="arrow-icon">↗</span>
                      <p className="text-green-700">{row?.first_name} {row?.last_name}</p>
                    </a>
                  </td>
                  <td>
                    <p
                      style={{
                        whiteSpace: "pre-wrap",
                        wordWrap: "break-word",
                        overflowWrap: "break-word",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        maxWidth: "300px",
                      }}
                    >
                      {row?.email}
                    </p>
                  </td>
                  <td>
                    <p>
                      {row?.user_country
                        ?.map((country) => country.country.long_name)
                        .join(", ")}
                    </p>
                  </td>
                  <td>{row?.is_active ? "Active" : "Inactive"}</td>
                  <td>{row?.is_admin ? "Admin" : "User"}</td>
                  <td>{row?.created_at}</td>
                  {/*<td>*/}
                  {/*  <StyledButtonGroup>*/}
                  {/*    <button onClick={() => initiateEdit(row.id)}>*/}
                  {/*      <i className="mdi mdi-pencil font-size-18 hover-event text-success" />*/}
                  {/*    </button>*/}
                  {/*    <button*/}
                  {/*      onClick={() => {*/}
                  {/*        setDeleteModal(true);*/}
                  {/*        setDeletionObjectId(row.id);*/}
                  {/*      }}*/}
                  {/*    >*/}
                  {/*      <i className="mdi mdi-delete font-size-18 del-icon text-danger" />*/}
                  {/*    </button>*/}
                  {/*  </StyledButtonGroup>*/}
                  {/*</td>*/}
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan="7"> No data available</td>
            </tr>
          )}
        </tbody>
      </StyledTable>
    </StyledTableContainer>
  );
}

export default FormsTable;
