// common/Filters/stageOptions.js
import { listStages } from "services"; // Import the service for fetching countries

let cachedStageOptions = null;

const fetchStageOptions = async () => {
  if (!cachedStageOptions) {
    try {
      const stages = await listStages();
      cachedStageOptions = stages.map((stage) => ({
        label: stage.name,
        value: stage.id,
      }));
    } catch (error) {
      console.error("Failed to fetch systems:", error);
      cachedStageOptions = []; // Default to an empty array on failure
    }
  }
  return cachedStageOptions;
};

export const getStageOptions = fetchStageOptions;