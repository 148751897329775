import React from 'react';
import SunburstChart from "./Charts/SunburstChart";

function Chart({data}) {
    return (
        <div className='flex flex-wrap m-2'>
            <div className='w-full md:w-1/2 p-6'>
                <>
                    <h3 className="section-title">About Landscaping</h3>
                    <p>Policy landscaping involves reviewing and organising the existing policies and frameworks that support agriculture and food systems in AGRA-focus countries. It helps identify, list, and classify these policies into three main categories: macro, sector, and micro. This classification helps to better understand how different policies shape agricultural development.</p>
                </>
                <>
                    <h3 className="section-title">Macro Policies</h3>
                    <p>Macro policies for agrifood systems are high-level government policies aimed at enhancing the productivity, sustainability, and resilience of agricultural and food systems. These policies often address economic, social, environmental, and food security objectives at a national or even global level.</p>
                </>
                <>
                    <h3 className="section-title">Meso (Sector) Policies</h3>
                    <p>Meso policies for agrifood systems are policies and initiatives that operate at a regional, state, or local level, focusing on the coordination, support, and implementation of macro-level policies while responding to specific needs within regions or communities. These policies bridge the gap between national-level directives and on-the-ground realities, aligning resources, institutions, and communities to foster sustainable, resilient, and inclusive agrifood systems.</p>
                </>
                <>
                    <h3 className="section-title">Micro Policies</h3>
                    <p>Micro policies for agrifood systems focus on initiatives and actions at the local, community, or farm level. These policies are highly specific and tailored to individual farmers, households, or small businesses within the agrifood sector. Micro policies support the implementation of broader macro and meso policies by directly targeting the needs and challenges of producers, consumers, and small agrifood enterprises on the ground.</p>
                </>
            </div>
            <div className="border rounded w-full md:w-1/2 p-6 flex flex-col items-center justify-start">
                <h3 className="section-title">Policy Landscape Chart</h3>
                <SunburstChart data={data}/>
            </div>
        </div>
    );
}

export default Chart;