import React, { useState, useContext, createContext, useEffect } from "react"
import * as RadixDialog from "@radix-ui/react-dialog"
import styled from "@emotion/styled"
import { AnimatePresence, motion, useAnimationControls } from "framer-motion"

const DialogContext = createContext({
  open: false,
  setOpen: () => {},
})
export default function Dialog({ children }) {
  const [open, setOpen] = useState(false)

  return (
    <DialogContext.Provider value={{ open, setOpen }}>
      <RadixDialog.Root open={open} onOpenChange={setOpen}>
        {children}
      </RadixDialog.Root>
    </DialogContext.Provider>
  )
}

const StyledButton = styled.button`
  all: unset;
  padding: 0.4em 0.8em;
  background-color: white;
  color: ${props => (props.color ? props.color : "#000")};
  display: grid !important;
  grid-auto-flow: column;
  place-items: center !important;
  min-width: 30px;
  min-height: 30px;

  & i {
    font-size: ${props => props.isCloseSVG && "20px"};
  }
`

const StyledCloseButton = styled.button`
  all: unset;
  position: absolute;
  top: 15px;
  right: 15px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  & i {
    width: 100%;
    height: 100%;
  }
`

function DialogTrigger({ children, leading, color }) {
  return (
    <RadixDialog.Trigger asChild>
      <StyledButton color={color}>
        {leading && <i className={leading} />}
        <span>{children}</span>
      </StyledButton>
    </RadixDialog.Trigger>
  )
}

Dialog.Trigger = DialogTrigger

const StyledOverlay = styled(RadixDialog.DialogOverlay)`
  position: fixed;
  inset: 0;
  background-color: hsl(0 0% 0%/ 0.5);
  z-index: 998;
`

const StyledContent = styled(RadixDialog.Content)`
  background-color: white;
  /* border-radius: 1em; */
  box-shadow: var(--shadow-elevation-medium);
  position: fixed;
  z-index: 999;
  inset: 0 0 0 auto;
  width: 60vw;
  max-width: 1100px;
  overflow-x: auto;
  max-height: 100vh;
  padding: 2rem;
  font-size: 14px;
`

const variants = {
  closed: {
    x: "100vw",
    opacity: 0,
    transition: {
      duration: 5,
      ease: "easeIn",
    },
  },
  open: {
    x: 0,
    opacity: 1,
    transition: {
      duration: 5,
      ease: "easeOut",
    },
  },
}

const DialogPortal = ({ children }) => {
  return (
    <RadixDialog.Portal>
      <StyledOverlay />
      {children}
    </RadixDialog.Portal>
  )
}

Dialog.Portal = DialogPortal

const DialogContentContext = createContext({ closeDialog: () => {} })

const DialogContent = React.forwardRef(({ children }, ref) => {
  const { open, setOpen } = useContext(DialogContext)
  const controls = useAnimationControls()

  async function closeDialog() {
    await controls.start("closed")
    setOpen(false)
  }

  useEffect(() => {
    if (open) {
      controls.start("open")
    }
  }, [controls, open])
  return (
    <DialogContentContext.Provider value={{ closeDialog }}>
      <AnimatePresence>
        {open && (
          <StyledContent
            asChild
            forceMount
            ref={ref}
            onInteractOutside={async evt => {
              evt.preventDefault()
              await closeDialog()
            }}
          >
            <motion.div
              variants={{
                closed: {
                  x: "100vw",
                  opacity: 0,
                  transition: { ease: "easeIn", duration: 0.2 },
                },
                open: {
                  x: 0,
                  opacity: 1,
                  transition: { ease: "easeOut", duration: 0.2 },
                },
              }}
              initial="closed"
              animate={controls}
              exit="closed"
            >
              {children}
            </motion.div>
          </StyledContent>
        )}
      </AnimatePresence>
    </DialogContentContext.Provider>
  )
})
DialogContent.displayName = "DialogContent"
Dialog.Content = DialogContent

function DialogTitle({ children }) {
  return <RadixDialog.Title>{children}</RadixDialog.Title>
}

Dialog.Title = DialogTitle

function DialogDescription({ children }) {
  return <RadixDialog.Description>{children}</RadixDialog.Description>
}

Dialog.Description = DialogDescription

function DialogClose({
  children,
  leading,
  isCloseSVG,
  handleClick = () => {},
}) {
  const { closeDialog } = useContext(DialogContentContext)

  const handleDialogClose = async evt => {
    evt.preventDefault()
    handleClick()
    await closeDialog()
  }

  return (
    <RadixDialog.Close asChild onClick={handleDialogClose}>
      <StyledButton isCloseSVG>
        {leading && <i className={leading} />}
        {children && <span>{children}</span>}
      </StyledButton>
    </RadixDialog.Close>
  )
}

Dialog.Close = DialogClose
