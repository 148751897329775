import Select from "react-select"
import React, { useState } from "react"

const LandscapingFilters = ({
    filteredLandscapingCategory,
    applyFilters
}) => {
    const landscapingCategories = [
        {
            label: "Macro",
            value: "MACRO"
        },
        {
            label: "Micro",
            value: "MICRO"
        },
        {
            label: "Meso",
            value: "MESO"
        }
    ];

    const [selectedLandscapingCategory, setSelectedLandscapingCategory] = useState(filteredLandscapingCategory);

  return (
    <div>
      {/* Filter by Location */}
      <div className="d-flex my-2">
        <div className="d-flex align-items-center">
          <i className="mdi mdi-map-marker-outline me-1" />
          <h6 className="mb-0">Landscaping Category:</h6>
        </div>
        <div className="ms-2" style={{ width: "100%" }}>
          <Select
            isMulti
            options={landscapingCategories}
            defaultValue={selectedLandscapingCategory}
            className="basic-single"
            classNamePrefix="select"
            placeholder="Select landscaping categories"
            onChange={options => setSelectedLandscapingCategory(options)}
          />
        </div>
      </div>

      {/* Apply Filters Button */}
      <div className="d-flex" style={{ justifyContent: "center" }}>
        <button
          className="btn btn-success common-button"
          style={{ width: "90%" }}
          onClick={()=>
            applyFilters(
              selectedLandscapingCategory
            )}
        >
          Apply
        </button>
      </div>
    </div>
  )
}

export default LandscapingFilters
