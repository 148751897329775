import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { LOGIN_USER, LOGOUT_USER} from "./actionTypes"
import {
  apiError,
  loginSuccess,
  logoutUserSuccess,
  setAccessToken,
} from "./actions"

import { postLogin } from "../../../services/authServices"
import { listCountries, listStages } from "../../list/actions"
import { userSession } from "../profile/actions"
import { useSessionStore } from "services/sessionStore"

function* loginUser({ payload: { user } }) {
  try {
    const response = yield call(postLogin, {
      username: user.email,
      password: user.password,
      recaptchaValue: user.recaptchaValue,
    })
    localStorage.setItem("token", response.access_token)
    const returnPath = localStorage.getItem("returnPath");
    localStorage.removeItem("returnPath");
    
    if (returnPath) {
      window.location = returnPath;
    } else {
      window.location = "/";
    }
    // localStorage.setItem(
    //   "countries",
    //   JSON.stringify(response.data.user.countries)
    // )
    // localStorage.setItem(
    //   "countries_ids",
    //   JSON.stringify(response.data.user.countries_id)
    // )
    // localStorage.setItem("stages", JSON.stringify(response.data.user.stages))
    // localStorage.setItem("role", response.data.user.role)
    yield put(setAccessToken(response.access_token)) //remove?
    yield put(loginSuccess(response)) 
    yield put(userSession())
    yield put(listCountries()) //remove?
    yield put(listStages()) //remove?
  } catch (error) {
    yield put(apiError(error));
  }
}

function* logoutUser() {
  try {
    const { isSessionExpired } = yield call(() => useSessionStore.getState());
    if(isSessionExpired){
      // Save current path for return after login
      const currentPath = window.location.pathname + window.location.search;
      localStorage.setItem("returnPath", currentPath);
      useSessionStore.setState({ isSessionExpired: false });
      window.location = `/login`;
    }
    // yield put(logoutUserSuccess(response))

    // Clear all local storage EXCEPT returnPath
    const returnPath = localStorage.getItem("returnPath");
    localStorage.clear();
    if (returnPath) {
      localStorage.setItem("returnPath", returnPath);
    }
  } catch (error) {
    yield put(apiError(error));
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga;
