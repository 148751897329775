import React from "react"
import { Table } from "reactstrap"
import { Link } from "react-router-dom"
import "./FormsTable.css"
import styled from "@emotion/styled"

const StyledTableContainer = styled.div`
    width: 100%;
    padding: 1em;
    overflow-x: auto; /* Allows horizontal scroll if necessary */
`

const StyledTable = styled(Table)`
    width: 100%;
    table-layout: fixed;
    background-color: transparent;
    border-radius: 16px 16px 0px 0px;
    overflow: hidden;

    & thead {
        background-color: #f8f9fa;
        // border-radius: 16px 16px 0px 0px;
    }

    // & th {
    //   padding-block: 1.5em;
    //   background-color: ##F0F0F0;
    //   text-align: left;
    //   font-size: 1rem;
    //   color: #495057;
    //   border-bottom: 2px solid #dee2e6;
    // }

    & td {
        padding: 1em;
        text-align: left;
        font-size: 14px;
        font-weight: 500;
        border: none; /* Remove all borders */
        border-bottom: 1px solid #dee2e6; /* Only keep the bottom border */
        vertical-align: middle;
        background-color: white; /* Ensure the background is white */
    }

    // & td:first-child {
    //   border-top: none; /* Ensure no top border on the first row */
    //   color: #036F36; /* Set the text color for the first column */
    // }

    & tbody {
        & tr {
            background-color: white !important; /* Ensure all rows have a white background */
            cursor: default;

            &:hover {
                background-color: #f8f9fa; /* Add a hover effect for rows */
            }

            & .description_cell {
                white-space: pre-wrap;
                word-wrap: break-word;
                text-overflow: ellipsis;
                overflow-wrap: break-word;
                max-width: 200px;
            }
        }

    }


    & tbody tr td:nth-of-type(2) {
    .name-cell {
      padding: 0.2em 0.4em;
      color: #28a745; /* Green color for the text */
      text-decoration: none; /* Prevent underline for links */
      background-color: transparent; /* Remove blue highlight */
    }

    .name-cell:hover {
      text-decoration: underline; /* Add underline on hover for better UX */
    }
  }
`;


function ETrackerTable({ forms }) {
  return (
    <StyledTableContainer>
      <StyledTable className="custom-table">
        <colgroup>
          <col style={{ width: "22.22%" }} />
          <col style={{ width: "33.33%" }} />
          <col style={{ width: "16.67%" }} />
          <col style={{ width: "16.67%" }} />
          <col style={{ width: "11.11%" }} />
        </colgroup>
        <thead className="table-header">
        <tr>
          <th scope="col">Location</th>
          <th scope="col">Policy Name</th>
          <th scope="col">Mapped To</th>
          <th scope="col">Stage</th>
          <th scope="col">Average Score</th>
        </tr>
        </thead>
        <tbody>
        {forms && forms.length > 0 ? (
          forms?.map(row => (
            <tr key={row.id}>
              {/* <td>{row?.name || ""}</td> */}
              <td style={{ color: "#4D4D4D" }}>
                {row?.country.long_name || ""}
              </td>
              <td>
                <a href={`/etracker-forms/${row.id}/${row.stage_id}`} rel="noopener noreferrer" className="flex items-start">
                      <span className="arrow-icon">↗</span>
                      <p className="text-green-700">{row?.name || ""}</p>
                    </a>
              </td>
              {/*<td>*/}
              {/*  <ul style={{ padding: 0, margin: 0, listStyleType: "none" }}>*/}
              {/*    {row?.policy_systems.map((system) => (*/}
              {/*      <li key={system?.system_id}>{system?.system.name}</li>*/}
              {/*    ))}*/}
              {/*  </ul>*/}
              {/*</td>*/}
              <td>
                {row?.policy_systems?.map((system, index) => (
                  <span key={index}>{system.system.name}
                    {index < row.policy_systems.length - 1 && "; "}
                  </span>
                ))}
              </td>
              <td>{row?.stage.name || ""}</td>
              <td>{row?.average_score || ""}</td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="7"> No data available</td>
          </tr>
        )
        }
        </tbody>
      </StyledTable>
    </StyledTableContainer>

  )
}

export default ETrackerTable
