import { Icon } from '@iconify/react';
import React, { useState } from 'react';

function Step2({setPolicyFile, handleInputChange, setCurrentStep, formData, policyFile}) {
    // Local state for validation errors
    const [validationErrors, setValidationErrors] = useState({});

    const validateForm = () => {
      const newErrors = {};
      
      // Validate Policy Details
      if (!formData.policy_request_body?.details?.trim()) {
        newErrors.details = 'Policy details are required';
      } else if (formData.policy_request_body.details.length > 2000) {
        newErrors.details = 'Policy details must be less than 2000 characters';
      }

      // Validate Emerging Outcomes
      if (!formData.policy_request_body?.updates_and_emerging_outcomes?.trim()) {
        newErrors.emerging_outcomes = 'Emerging outcomes are required';
      } else if (formData.policy_request_body.updates_and_emerging_outcomes.length > 2000) {
        newErrors.emerging_outcomes = 'Emerging outcomes must be less than 2000 characters';
      }

      // Validate File (if present)
      if (policyFile) {
        const maxSize = 10 * 1024 * 1024; // 10MB
        const allowedTypes = ['application/pdf', 
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
        
        if (policyFile.size > maxSize) {
          newErrors.file = 'File size must be less than 10MB';
        }
        
        if (!allowedTypes.includes(policyFile.type)) {
          newErrors.file = 'Only PDF and DOCX documents are allowed';
        }
      }

      setValidationErrors(newErrors);
      return Object.keys(newErrors).length === 0;
    };

    const handleFileChange = (e) => {
      const file = e.target.files[0];
      if (file) {
        const maxSize = 10 * 1024 * 1024; // 10MB
        const allowedTypes = ['application/pdf',
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
        
        if (file.size > maxSize) {
          setValidationErrors(prev => ({
            ...prev,
            file: 'File size must be less than 10MB'
          }));
          return;
        }
        
        if (!allowedTypes.includes(file.type)) {
          setValidationErrors(prev => ({
            ...prev,
            file: 'Only PDF and DOCX files are allowed'
          }));
          return;
        }

        setValidationErrors(prev => ({ ...prev, file: undefined }));
        setPolicyFile(file);
      }
    };

    const removeFile = () => {
      setPolicyFile(null);
      setValidationErrors(prev => ({ ...prev, file: undefined }));
    };

    const handleTextChange = (name, value) => {
      handleInputChange(name, value);
      // Clear validation error for the field being changed
      setValidationErrors(prev => ({ ...prev, [name === 'details' ? 'details' : 'emerging_outcomes']: undefined }));
    };

    const handleNext = () => {
      const isValid = validateForm();
      if (isValid) {
        setCurrentStep(3);
      }
    };

    return (
        <div className="space-y-6">
          <div>
            <label className="block mb-1 text-sm">Policy Details*</label>
            <textarea
              name="details"
              value={formData.policy_request_body.details}
              onChange={(e) => handleTextChange(e.target.name, e.target.value)}
              className={`w-full p-2 border rounded h-32`}
              placeholder="Please type here"
            />
            {validationErrors.details && (
              <span className="text-red-500 text-sm mt-1">{validationErrors.details}</span>
            )}
            <div className="text-right text-sm text-gray-500 mt-1">
              {(formData.policy_request_body.details?.length || 0)}/2000
            </div>
          </div>

          <div>
            <label className="block mb-1 text-sm">Emerging Outcomes*</label>
            <textarea
              name="updates_and_emerging_outcomes"
              value={formData.policy_request_body.updates_and_emerging_outcomes}
              onChange={(e) => handleTextChange(e.target.name, e.target.value)}
              className={`w-full p-2 border rounded h-32`}
              placeholder="Please type here"
            />
            {validationErrors.emerging_outcomes && (
              <span className="text-red-500 text-sm mt-1">{validationErrors.emerging_outcomes}</span>
            )}
            <div className="text-right text-sm text-gray-500 mt-1">
              {(formData.policy_request_body.updates_and_emerging_outcomes?.length || 0)}/2000
            </div>
          </div>

          <div>
            <label className="block mb-1 text-sm">Policy File</label>
            {policyFile ? (
              <div className="mt-2 flex items-center space-x-4">
                <div className="flex items-center p-2 bg-gray-50 rounded">
                  <Icon icon="mdi-light:file" className="w-5 h-5 text-gray-400"/>
                  <span className="ml-2 text-sm text-gray-600">{policyFile.name}</span>
                </div>
                <button
                  onClick={removeFile}
                  className="text-red-500 hover:text-red-600"
                  type="button"
                >
                  <Icon icon="material-symbols-light:delete-outline" className="w-5 h-5"/>
                </button>
              </div>
            ) : (
              <div>
                <input
                  type="file"
                  onChange={handleFileChange}
                  className={`mt-1`}
                  accept=".pdf,.docx"
                />
                <div className="text-sm text-gray-500 mt-1">
                  Accepted formats: PDF, DOCX (Max size: 10MB)
                </div>
              </div>
            )}
            {validationErrors.file && (
              <span className="text-red-500 text-sm mt-1 block">{validationErrors.file}</span>
            )}
          </div>

          <div className="flex justify-between">
            <button
              onClick={() => setCurrentStep(1)}
              className="px-6 py-2 border rounded text-gray-600 hover:bg-gray-50"
            >
              Previous
            </button>
            <button
              onClick={handleNext}
              className="px-6 py-2 bg-green-100 text-green-800 rounded hover:bg-green-200"
            >
              Next
            </button>
          </div>
        </div>
    );
}

export default Step2;