// common/Filters/countryOptions.js
import { listCountries } from "services"; // Import the service for fetching countries

let cachedCountryOptions = null;

const fetchCountryOptions = async () => {
  if (!cachedCountryOptions) {
    try {
      const countries = await listCountries();
      cachedCountryOptions = countries.map((country) => ({
        label: country.long_name,
        value: country.id,
      }));
    } catch (error) {
      console.error("Failed to fetch countries:", error);
      cachedCountryOptions = []; // Default to an empty array on failure
    }
  }
  return cachedCountryOptions;
};

export const getCountryOptions = fetchCountryOptions;