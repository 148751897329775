import React, { useEffect, useState, useRef, useMemo } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  InputGroup,
  Form,
  Table,
} from "reactstrap"
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { map } from "lodash"
import SimpleBar from "simplebar-react"
import { useFormik } from "formik"
import LoadingOverlay from "react-loading-overlay"

import Select from "react-select"
import { useSelector } from "react-redux"

import { formateOnlyDate } from "helpers/utlities"
import DefaultTableContainer from "../../components/Common/DefaultTableContainer"
import { Name, Tags } from "./tableCol"
import verifiedTweet from "../../assets/images/icons/tweet-icon.png"


import CountryMap from "./CountryMap.js";
import NewMap from "./DrillDownMap2";
import BarChart from "./barchart"
import Legend from "./Legend"

import {
  getIntelligenceSentimentScores,
  getIntelligenceTweets,
  getIntelligencePoliciesAffected,
  getIndicators,
} from "services"

const Dashboard = () => {
  document.title = "News Insights | AGRA"

  LoadingOverlay.propTypes = undefined

  let newDate = new Date()

  const [tweets, setTweets] = useState([])
  const [policiesAffected, setPoliciesAffected] = useState([])
  const [sentimentScores, setSentimentScores] = useState({})
  const [indicators, setIndicators] = useState([])
  const [startPickDate, setStartPickDate] = useState(
    newDate.getTime() - 24 * 60 * 60 * 1000 * 7
  )
  const [endPickDate, setEndPickDate] = useState(
    newDate.getTime() - 24 * 60 * 60 * 1000 * 1
  )
  const [viewType, setViewType] = useState("map_view")
  const [dropdownLocation, setDropdownLocation] = useState("all")
  const [dropdownLocationName, setDropdownLocationName] = useState("")
  const [sentimentParams, setSentimentParams] = useState({})
  const [loading, setLoading] = useState(true)

  const { country, user } = useSelector(state => state.Profile)

  const { countries, stages, landscaping_stages, tracker_stages } = useSelector(
    state => state.list
  )

  useEffect(function () {
    getIndicators()
      .then(res => setIndicators(res))
      .catch(err => setIndicators([]))
  }, [])

  useEffect(function () {
    let params = {
      start_date: formateOnlyDate(startPickDate),
      end_date: formateOnlyDate(endPickDate),
      indicator: ["all"],
    }
    setSentimentParams(params)
    getIntelligenceSentimentScores(params)
      .then(res => {
        setSentimentScores(res.data)
        setLoading(false)
      })
      .catch(err => {
        setSentimentScores({})
        setLoading(false)
      })

    getIntelligenceTweets(params)
      .then(res => {
        setTweets(res.data)
      })
      .catch(err => {
        setTweets({})
      })
  }, [])

  const validation = useFormik({
    initialValues: {
      indicator: "all",
      country: "all",
      start_date: formateOnlyDate(startPickDate),
      end_date: formateOnlyDate(endPickDate),
      view_map: "map_view",
    },
    onSubmit: values => {
      setLoading(true)
      const params = {
        indicator: [values.indicator],
        start_date: values.start_date,
        end_date: values.end_date,
      }

      setViewType(values.view_map)
      setDropdownLocation(values.country)

      if (values.country != "all") {
        params.country = values.country
      }

      setSentimentParams(params)

      getIntelligenceSentimentScores(params)
        .then(res => {
          setSentimentScores(res.data)
          setLoading(false)
        })
        .catch(err => {
          setSentimentScores({})
          setLoading(false)
        })

      getIntelligenceTweets(params)
        .then(res => {
          setTweets(res.data)
        })
        .catch(err => {
          setTweets({})
        })

      if (values.country != "all") {
        // params.country = values.country;
        let formatPoliciesAffected = []
        getIntelligencePoliciesAffected(params)
          .then(res => {
            Object.keys(res.data).length > 0 &&
              map(res.data, (policy, index) =>
                formatPoliciesAffected.push(policy)
              )
            setPoliciesAffected(formatPoliciesAffected)
          })
          .catch(err => {
            setPoliciesAffected({})
          })
      }
    },
  })

  const columns = useMemo(
    () => [
      {
        Header: "Policy Name",
        accessor: "policy_name",
        disableFilters: true,
        Cell: cellProps => {
          return <Name {...cellProps} />
        },
      },
      {
        Header: "Negatively Affected Systems",
        accessor: "negative_indicator",
        disableSortBy: true,
        disableFilters: true,
        Cell: cellProps => {
          return <Tags {...cellProps} bg_color="#d75c5c" />
        },
      },
      {
        Header: "Neutral Systems",
        accessor: "neutral_indicator",
        disableSortBy: true,
        disableFilters: true,
        Cell: cellProps => {
          return <Tags {...cellProps} bg_color="#0c92f7" />
        },
      },
      {
        Header: "Positively Affected Systems",
        accessor: "positive_indicator",
        disableSortBy: true,
        disableFilters: true,
        Cell: cellProps => {
          return <Tags {...cellProps} bg_color="#01a824" />
        },
      },
      {
        Header: "Current Progress",
        accessor: "policy_stage",
        disableSortBy: true,
        disableGlobalFilter: true,
        Cell: cellProps => {
          return <Name {...cellProps} />
        },
      },
    ],
    [policiesAffected]
  )

  // const onLocationChange = (location) => {
  //   console.log(location)
  // }

  const onViewTypeChange = view_type => {
    setViewType(view_type.value)
  }

  const dropdownStyle = {
    control: (base, state) => ({
      ...base,
      border: 0,
      outline: 0,
    }),
  }

  return (
    <React.Fragment>
      <div className="page-content">
        {/* <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner> */}
        <LoadingOverlay active={loading} spinner text="Generating data...">
          <Container fluid>
            <Row>
              <Col md={8}>
                <Row>
                  <Col md={12}>
                    <Card>
                      <CardBody>
                        <h4 className="card-title mb-4">
                          <i className="ph-stack-simple"></i> Showing news
                          insights for AGRA systems
                        </h4>
                        <div className="crypto-buy-sell-nav">
                          <Form
                            onSubmit={e => {
                              e.preventDefault()
                              validation.handleSubmit()
                              return "false"
                            }}
                          >
                            <Row className="m-0 pb-4">
                              <h4 className="mb-3 mt-4">
                                <i className="ph-funnel-simple"></i> Filters
                              </h4>
                              <Col sm={3}>
                                <label className="col-form-label color-light-sky-blue">
                                  <i className="ph-bookmark-simple"></i>{" "}
                                  System
                                </label>
                                <Select
                                  defaultValue={{
                                    value: "all",
                                    label: "All Systems",
                                  }}
                                  name="indicator"
                                  options={[
                                    { label: "All Systems", value: "all" },
                                  ].concat(
                                    indicators.map(val => ({
                                      label: val.label_name,
                                      value: val.label_name,
                                    }))
                                  )}
                                  className="select-light-sky-blue"
                                  styles={dropdownStyle}
                                  onChange={selectedOption =>
                                    validation.setFieldValue(
                                      "indicator",
                                      selectedOption.value
                                    )
                                  }
                                />
                              </Col>
                              <Col sm={3}>
                                <label className="col-form-label color-light-orange">
                                  <i className="ph-globe-hemisphere-east"></i>{" "}
                                  Location
                                </label>
                                <Select
                                  defaultValue={{
                                    value: "all",
                                    label: "All Locations",
                                  }}
                                  name="country"
                                  options={[
                                    { label: "All Locations", value: "all" },
                                  ].concat(
                                    countries.map(val => ({
                                      label: val.label,
                                      value: val.title,
                                    }))
                                  )}
                                  className="select-light-orange"
                                  styles={dropdownStyle}
                                  onChange={function (selectedOption) {
                                    validation.setFieldValue(
                                      "country",
                                      selectedOption.value
                                    )
                                    setDropdownLocationName(
                                      selectedOption.label
                                    )
                                    if (selectedOption.value != "all") {
                                      //validation.setFieldValue('view_map', 'graph_view');
                                    } else {
                                      //validation.setFieldValue('view_map', 'map_view');
                                    }
                                  }}
                                />
                              </Col>
                              <Col sm={3}>
                                <label className="col-form-label color-light-purple">
                                  <i className="ph-calendar-blank"></i> Start
                                  Period
                                </label>
                                <InputGroup>
                                  <Flatpickr
                                    name="start_date"
                                    className="form-control d-block select-light-purple"
                                    placeholder="Start Period"
                                    options={{
                                      altInput: true,
                                      altFormat: "Y-m-d",
                                      dateFormat: "Y-m-d",
                                      defaultDate: startPickDate,
                                    }}
                                    onChange={function (value) {
                                      validation.setFieldValue(
                                        "start_date",
                                        formateOnlyDate(value[0])
                                      )
                                    }}
                                  />
                                  <div className="input-group-append">
                                    <button
                                      type="button"
                                      className="btn btn-outline-secondary docs-datepicker-trigger "
                                      disabled
                                    >
                                      <i
                                        className="fa fa-calendar"
                                        aria-hidden="true"
                                      />
                                    </button>
                                  </div>
                                </InputGroup>
                              </Col>
                              <Col sm={3}>
                                <label className="col-form-label color-light-blue">
                                  <i className="ph-calendar"></i> End Period
                                </label>
                                <InputGroup>
                                  <Flatpickr
                                    name="end_date"
                                    className="form-control d-block select-light-purple"
                                    placeholder="End Period"
                                    options={{
                                      altInput: true,
                                      altFormat: "Y-m-d",
                                      dateFormat: "Y-m-d",
                                      defaultDate: endPickDate,
                                    }}
                                    onChange={function (value) {
                                      validation.setFieldValue(
                                        "end_date",
                                        formateOnlyDate(value[0])
                                      )
                                    }}
                                  />
                                  <div className="input-group-append">
                                    <button
                                      type="button"
                                      className="btn btn-outline-secondary docs-datepicker-trigger "
                                      disabled
                                    >
                                      <i
                                        className="fa fa-calendar"
                                        aria-hidden="true"
                                      />
                                    </button>
                                  </div>
                                </InputGroup>
                              </Col>
                            </Row>
                            <Row className="m-0 pb-4">
                              <Col sm={3}>
                                <div className="mt-1 d-grid">
                                  <label className="col-form-label color-light-orange">
                                    &nbsp;
                                  </label>
                                  <button
                                    type="submit"
                                    className="btn font-white btn-primary"
                                  >
                                    Generate
                                  </button>
                                </div>
                              </Col>
                            </Row>
                          </Form>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>

                  <Col md={12}>
                    <Card>
                      <CardBody>
                        <CardTitle>
                          <Row>
                            <Col sm={8}>
                              <h4>
                                <i className="ph-globe-hemisphere-east"></i> Map
                                View
                              </h4>
                            </Col>
                          </Row>
                        </CardTitle>
                        <Row>
                          <Col md={12}>
                            <div className="crypto-buy-sell-nav">
                            {sentimentScores.message ? sentimentScores.message : sentimentParams.country? <CountryMap sentimentScores={sentimentScores} params={sentimentParams}/>: <NewMap sentimentScores={sentimentScores}/>}
                            </div>
                          </Col>
                          <Legend />
                        </Row>
                      </CardBody>
                    </Card>

                    <Card>
                      <CardBody>
                        <CardTitle>
                          <Row>
                            <Col sm={8}>
                              <h4>
                                <i className="ph-chart-bar-horizontal"></i>{" "}
                                Graph View
                              </h4>
                            </Col>
                          </Row>
                        </CardTitle>
                        <Row>
                          <Col md={12}>
                            <div className="crypto-buy-sell-nav">
                              <Row className="mb-3">
                                {(dropdownLocation != "all" ||
                                  validation.values.indicator != "all") &&
                                  sentimentScores &&
                                  Object.keys(sentimentScores).length > 0 && (
                                    <BarChart
                                      data={sentimentScores}
                                      country={dropdownLocation}
                                    />
                                  )}
                                {dropdownLocation == "all" &&
                                  validation.values.indicator == "all" && (
                                    <p>
                                      Please Select at least 1 location or
                                      system
                                    </p>
                                  )}
                              </Row>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
              <Col md={4}>
                <Card>
                  <CardBody>
                    <Row>
                      <Col sm={12}>
                        <h4 className="card-title mb-4">
                          <i className="ph-twitter-logo me-1"></i> Trending
                          Tweets
                        </h4>
                      </Col>

                      <>
                        <Col sm={12}>
                          <Card>
                            <CardBody className="bg-v-light-green">
                              <h4 className="card-title mb-4">
                                <i className="ph-thumbs-up"></i> Positive Tweets
                              </h4>
                              <SimpleBar style={{ maxHeight: "390px" }}>
                                {(tweets?.positive?.length > 0 &&
                                  tweets.positive.map((data, key) => (
                                    <Card key={key}>
                                      <CardBody>
                                        <div className="d-flex">
                                          <div className="avatar-xs me-3">
                                            <span className="avatar-title rounded-circle bg-light">
                                              <img
                                                src={data["profile_image_url"]}
                                                alt=""
                                                height="18"
                                              />
                                            </span>
                                          </div>
                                          <div className="flex-grow-1">
                                            <h5 className="font-size-14">
                                              {data["name"]}{" "}
                                              <img
                                                src={verifiedTweet}
                                                alt=""
                                                height="18"
                                              />
                                            </h5>
                                            <p className="text-muted">
                                              @{data["username"]}
                                            </p>
                                            <p className="text-muted">
                                              {data["text"]}
                                            </p>
                                            <p className="text-muted mb-0">
                                              <span className="me-5">
                                                <i className="ph-heart color-red me-1"></i>{" "}
                                                {data["like_count"]}
                                              </span>
                                              <span className="me-5">
                                                <i className="ph-chat me-1"></i>{" "}
                                                {data["reply_count"]}
                                              </span>
                                              <span className="">
                                                <i className="ph-arrows-clockwise me-1"></i>{" "}
                                                {data["retweet_count"]}
                                              </span>
                                            </p>
                                          </div>
                                        </div>
                                      </CardBody>
                                    </Card>
                                  ))) || (
                                  <Card>
                                    <CardBody>No tweet available</CardBody>
                                  </Card>
                                )}
                              </SimpleBar>
                            </CardBody>
                          </Card>
                        </Col>

                        <Col sm={12}>
                          <Card>
                            <CardBody className="bg-v-light-red">
                              <h4 className="card-title mb-4">
                                <i className="ph-thumbs-down"></i> Negative
                                Tweets{" "}
                              </h4>
                              <SimpleBar style={{ maxHeight: "390px" }}>
                                {(tweets?.positive?.length > 0 &&
                                  tweets.negative.map((data, key) => (
                                    <Card key={key}>
                                      <CardBody>
                                        <div className="d-flex">
                                          <div className="avatar-xs me-3">
                                            <span className="avatar-title rounded-circle bg-light">
                                              <img
                                                src={data["profile_image_url"]}
                                                alt=""
                                                height="18"
                                              />
                                            </span>
                                          </div>
                                          <div className="flex-grow-1">
                                            <h5 className="font-size-14">
                                              {data["name"]}{" "}
                                              <img
                                                src={verifiedTweet}
                                                alt=""
                                                height="18"
                                              />
                                            </h5>
                                            <p className="text-muted">
                                              @{data["username"]}
                                            </p>
                                            <p className="text-muted">
                                              {data["text"]}
                                            </p>
                                            <p className="text-muted mb-0">
                                              <span className="me-5">
                                                <i className="ph-heart color-red me-1"></i>{" "}
                                                {data["like_count"]}
                                              </span>
                                              <span className="me-5">
                                                <i className="ph-chat me-1"></i>{" "}
                                                {data["reply_count"]}
                                              </span>
                                              <span className="">
                                                <i className="ph-arrows-clockwise me-1"></i>{" "}
                                                {data["retweet_count"]}
                                              </span>
                                            </p>
                                          </div>
                                        </div>
                                      </CardBody>
                                    </Card>
                                  ))) || (
                                  <Card>
                                    <CardBody>No tweet available</CardBody>
                                  </Card>
                                )}
                              </SimpleBar>
                            </CardBody>
                          </Card>
                        </Col>
                      </>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col sm={12}>
                <Card>
                  <CardBody>
                    <div className="clearfix">
                      <h4 className="card-title mb-4">
                        <i className="bx bx-grid-alt me-1"></i>Policies Affected{" "}
                        {dropdownLocation != "all"
                          ? " for " + dropdownLocationName
                          : ""}
                      </h4>
                    </div>
                    {dropdownLocation != "all" &&
                      policiesAffected.length > 0 && (
                        <DefaultTableContainer
                          columns={columns}
                          data={policiesAffected}
                          isGlobalFilter={true}
                          isExport={"policies_affected"}
                          customPageSize={5}
                          className="custom-header-css"
                        />
                      )}
                    {dropdownLocation == "all" && (
                      <p>
                        Please Select at least 1 location for policies affected
                      </p>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </LoadingOverlay>
      </div>
    </React.Fragment>
  )
}

export default Dashboard
