import React, { useEffect, useState } from "react"
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap"
import { get, map } from "lodash"
import { useSelector, useDispatch } from "react-redux"

import countriesFlag from "common/countriesFlag"
import { useQuery } from "@tanstack/react-query"
import { getUserData } from "services/authServices"
import { listCountries } from "../../../services"


const LanguageDropdown = ({ title }) => {
  const [countries, setCountries] = useState([])
  const { country, user } = useSelector(state => state.Profile)
  const [selectedCountry, setSelectedCountry] = useState("")
  const [countriesDropdown, setCountriesDropdown] = useState([])
  const [countryName, setCountryName] = useState("")
  const [menu, setMenu] = useState(false)

  const dispatch = useDispatch()

  // useEffect to fetch countries from the API
  useEffect(() => {
    const fetchCountries = async () => {
      try {
        let countryList = await listCountries()
        if (countryList && countryList.length > 0) {
          // Add "Africa" as the first option
          countryList = [
            { short_name: "all", long_name: "Africa", id: 0 },
            ...countryList,
          ]
          setCountries(countryList)
        }
      } catch (error) {
        console.error("Failed to fetch countries:", error)
        // TODO: Add alert or error handling logic
      }
    }
    fetchCountries()
  }, [])

  // useEffect to update countriesDropdown based on user admin status
  useEffect(() => {
    if (countries && countries.length > 0) {
      if (
        user.is_admin &&
        title !== "Policy Landscaping" &&
        title !== "Tasks Management" &&
        title !== "Benchmarking"
      ) {
        setCountriesDropdown([
          { id: 0, short_name: "all", long_name: "Africa" },
          ...countries,
        ])
      } else {
        setCountriesDropdown(countries)
      }
    }
  }, [countries, user, title])

  // useEffect to set selected country from localStorage or default
  useEffect(() => {
    if (countriesDropdown.length > 0) {
      const storedCountry = localStorage.getItem("COUNTRY")
      if (
        (title === "Policy Landscaping" ||
          title === "Tasks Management" ||
          title === "Benchmarking") &&
        storedCountry === "all"
      ) {
        localStorage.setItem("COUNTRY", countriesDropdown[0].short_name)
      }

      if (storedCountry) {
        const selected = countriesDropdown.find(
          c => c.short_name === storedCountry
        )
        if (selected) {
          setCountryName(selected.long_name)
          setSelectedCountry(storedCountry)
          // dispatch(setCountryFlag(storedCountry))
          // dispatch(setCountryID(selected.id))
        }
      } else {
        const defaultCountry = countriesDropdown[0]
        localStorage.setItem("COUNTRY", defaultCountry.short_name)
        setCountryName(defaultCountry.long_name)
        setSelectedCountry(defaultCountry.short_name)
        // dispatch(setCountryFlag(defaultCountry.short_name))
        // dispatch(setCountryID(defaultCountry.id))
      }
    }
  }, [countriesDropdown, dispatch, title])

  const changeCountryAction = country => {
    localStorage.setItem("COUNTRY", country.short_name)
    setSelectedCountry(country.short_name)
    setCountryName(country.long_name)
    const countryDetail = countries.find(c => c.short_name === country.short_name)
    // if (countryDetail) {
    //   dispatch(setCountryID(countryDetail.id))
    // }
    // dispatch(setCountryFlag(country.short_name))
  }

  const toggle = () => {
    setMenu(!menu)
  }

  return (
    countriesDropdown &&
    countriesDropdown.length > 0 && (
      <>
        <Dropdown isOpen={menu} toggle={toggle} className="d-inline-block">
          <DropdownToggle className="d-flex align-items-center btn bg-white" tag="button">
            <img
              src={countriesFlag[selectedCountry.short_name]}
              alt={selectedCountry.short_name}
              height={16}
              width={16}
              className="mx-2"
            />
            {selectedCountry.long_name}
            <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
          </DropdownToggle>
          <DropdownMenu>
            {countries.map(country => (
              <DropdownItem
                key={country.id}
                onClick={() => changeCountryAction(country)}
                className={`d-flex align-items-center ${
                  selectedCountry.short_name === country.short_name ? "bg-slate-100 justify-content-between" : ""
                }`}
              >
                <img
                  src={countriesFlag[country.short_name]}
                  alt={country.short_name}
                  className="me-2"
                  height={16}
                  width={16}
                />
                <span>{country.long_name}</span>
                { selectedCountry.short_name === country.short_name && <i className="mdi mdi-check ms-auto" />}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </Dropdown>
      </>
    )
  )
}

export default LanguageDropdown
