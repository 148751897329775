import React from 'react'
import styles from './styles/policy_tracking_table_cell.module.css'


const PolicyTrackingTableCell = ({ currently_at, coverage_percentage }) => {

    const getRandomPositionPercentage = () => {
        let percent = Math.floor(Math.random() * 100) 
        if (percent>95){
            return 95
        }
        return percent
    }

    const getActualLeftPosition = () => {
        let left = 0
        if (currently_at === 0){
            left = 0
        }
        if (currently_at > 0){
            left = ((6-currently_at)/6)*100
            // if the percentage is greater than zero add it to the cell
            if (coverage_percentage > 0){
                left = left + coverage_percentage
            }
        }

        return left
    }
    return (
        <div className={`${styles.policyTrackingTableCell}`}>
            <div className={`${styles.policyProgressIndicatorOver}`} style={{left: `${getActualLeftPosition()}%`}}>

            </div>
        </div>
    )
}


export default PolicyTrackingTableCell
