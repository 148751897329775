import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

// Register ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const PolicyStageChart = ({chartData, tab,title}) => {
  const systems = chartData?.map(system => system.system_name)

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        title: {
          display: true,
          text: 'Systems',
          font: {
            size: 14,
          },
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: tab === 'number_of_policies' ? 'No. of policies' : 'Avg. policy score',
          font: {
            size: 14,
          },
        },
        ticks: {
          stepSize: 1,
        },
      },
    },
    plugins: {
      legend: {
        position: 'right',
        labels: {
          usePointStyle: true,
          padding: 20,
        },
      },
      tooltip: {
        padding: 12,
      },
    },
    barPercentage: 0.8,
  };

  const getData = (stageIndex) => {
    const key = tab === 'number_of_policies' ? 'number_of_policies' : 'average_score';
    return chartData?.map((system) => system?.data?.[key]?.[stageIndex]);
  }

  const data = {
    labels: systems,
    datasets: [
      {
        label: '1 - Initiation',
        data: getData(0),
        backgroundColor: '#04B2D9',
      },
      {
        label: '2 - Development',
        data: getData(1),
        backgroundColor: '#80B918',
      },
      {
        label: '3 - Validation',
        data: getData(2),
        backgroundColor: '#A68B03',
      },
      {
        label: '4 - Approval',
        data: getData(3),
        backgroundColor: '#E69A06',
      },
      {
        label: '5 - Legislation',
        data: getData(4),
        backgroundColor: '#B53031',
      },
      {
        label: '6 - Implementation',
        data: getData(5),
        backgroundColor: '#D2D63B',
      },
      {
        label: '7 - Policy Win',
        data: getData(6),
        backgroundColor: '#2ECC71',
      },
    ],
  };

  return (
    <div className="chart-container w-full">
        <h3 className="section-title">{title?title:`Policies across Systems and Stages`}</h3>
        <div className="h-[360px] w-full">
            <Bar options={options} data={data} />
        </div>
    </div>
  );
};

export default PolicyStageChart;