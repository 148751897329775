export const downloadFile = (fileName, name, file) => {
    const ext = fileName.split(".")[1];
    const fileURL = window.URL.createObjectURL(new Blob([file]));
    const fileLink = document.createElement("a");
    fileLink.href = fileURL;
    const downloadName = `${name}.${ext}`;
    fileLink.setAttribute("download", downloadName);
    fileLink.setAttribute("target", "_blank");
    fileLink.click();
    URL.revokeObjectURL(fileURL);
}

export const downloadTable = (file, name, type) => {
    const formatMapper = {
        pdf: 'pdf',
        excel: 'xlsx',
        stata: 'dta',
        spss: 'sav'
    };

    const filename = `${name}.${formatMapper[type]}`;

    // Trigger file download
    if(file instanceof Blob){
        const url = URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = url;
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    }else{
        console.error('Response is not a blob.');
    }
}