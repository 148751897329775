import React, { useState, useEffect } from "react"
import { Button, Card, CardBody, CardHeader, ModalHeader, Modal, ModalBody } from "reactstrap"
import SearchBox from "components/Common/SearchBox"
import Pagination from "components/Common/Pagination"
import LoadingSpinner from "components/Common/Loading-Spinner"
import useDebounce from "hooks/useDebounce"
import { StyledContainer } from "pages/Labels/Systems"
import ExportTable from "../../../components/Common/ExportTable"
import { exportTableData } from "services/common"
import ETrackerTable from "../ETrackerTasksTable"
import { getETrackerTasks } from "services/milestoneServices"
import ETrackerTasksFilters from "./Filters"
import '../TasksManagement.css';
// import Stages from "./cards/TasksCard"

const ETrackerTasks = ({ alert }) => {
  // const title = formType === "ETracker" ? "E-Tracker Milestones" : "Benchmarking Milestones";
  // document.title = "Milestone Management | AGRA";

  const [searchField, setSearchField] = useState("")
  const debouncedSearchTerm = useDebounce(searchField);
  const [selectedLocations, setSelectedLocations] = useState([])
  const [selectedSystems, setSelectedSystems] = useState([])
  const [selectedCategories, setSelectedCategories] = useState([])
  const [selectedStages, setSelectedStages] = useState([])
  const [offTrack, setOffTrack] = useState(null);
  const [delayed, setDelayed] = useState(null);

  const [currentPage, setCurrentPage] = useState(1)
  const [totalEntries, setTotalEntries] = useState(0)
  const [offset, setOffset] = useState(0)

  // Manage loading, error, and milestonesData states
  const [tasksData, setTasksData] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  const [modalFilter, setModalFilter] = useState(false)
  const toggleFilter = () => setModalFilter(prevState => !prevState)

  const searchChange = e => {
    setSearchField(e.target.value.toLowerCase())
    setOffset(0)
    setCurrentPage(1)
  }
  
  const fetchTasks = async () => {
    const filters = {
      searchField: debouncedSearchTerm,
      locations: selectedLocations?.map(location=>location.value),
      systems: selectedSystems?.map(system=>system.value),
      categories: selectedCategories?.map(category=>category.value),
      stages: selectedStages?.map(stage=>stage.value),
      offTrack: offTrack?.value,
      delayed: delayed?.value,
      limit: 50,
      offset
    };
    setIsLoading(true);
    try {
      const {data, count} = await getETrackerTasks(filters);

      setTasksData(data)
      setTotalEntries(count)
    } catch (error) {
      console.error(error)
      alert("Fetching data", false)
    } finally {
      setIsLoading(false)
    }
  }

  const applyFilters = (locations, systems, stages, categories, offTrack, delayed) => {
    setSelectedLocations(locations);
    setSelectedSystems(systems);
    setSelectedStages(stages);
    setSelectedCategories(categories);
    setOffTrack(offTrack);
    setDelayed(delayed);
    toggleFilter();
  }

  const goToPage = page => {
    if (page > 0) {
      const offsetParam = page * 50 - 50
      setOffset(offsetParam)
      setCurrentPage(page)
    } else {
      console.error("Page number should be greater than 0")
    }
  }

  const checkForFilters = () => {
    return (
      selectedStages.length || selectedCategories.length || selectedLocations.length || selectedSystems.length || offTrack || delayed
    )
  }

  const clearFilters = () => {
    setSelectedStages([]);
    setSelectedCategories([]);
    setSelectedLocations([]);
    setSelectedSystems([]);
    setOffTrack(null);
    setDelayed(null);
  }  

  useEffect(() => {
    fetchTasks();
  }, [
    selectedCategories, 
    selectedLocations, 
    selectedSystems, 
    selectedStages, 
    offTrack, 
    delayed, 
    debouncedSearchTerm, 
    currentPage, 
  ])

  const exportTable = async type => {
    try {
      const query = {
        export_format: type
        // ...(selectedUser && { user_id: selectedUser }),
        // ...(selectedActivity && { activity: selectedActivity }),
      }

      // const exportEntity = formType === "ETracker" ? "ETracker_Tasks": "Benchmarking_Tasks"
      const exportEntity = "ETracker_Tasks"

      exportTableData(query, exportEntity)

    } catch (err) {
      console.error(err)
      alert("Data export", false)
    }
  }

  return (
    <div>
      <Card>
        <CardHeader
         className="bg-white"
         style={{ paddingBottom: '8px', paddingTop: '8px' }}
         >

          
          {/*<h4><i className="bx bx-file"></i> {title}</h4>*/}
          <div className="d-flex justify-content-between">
            <StyledContainer>
              <SearchBox searchChange={searchChange} />
              <Button
                // style={{
                //   backgroundColor: "#f8f9fa",
                //   color: "black",
                //   margin: "15px 10px",
                //   border: "none",
                //   fontSize: "14px",
                // }}
                className="common-button"
                onClick={toggleFilter}
              >
                <i className="mdi mdi-filter-variant me-1" /> Filters
              </Button>

              {checkForFilters() ? 
                <Button
                  className="common-button"
                  // style={{
                  //   backgroundColor: "#f8f9fa",
                  //   color: "black",
                  //   margin: "15px 10px",
                  //   border: "none",
                  //   fontSize: "14px"
                  // }}
                  onClick={clearFilters}
                >
                  <i className="ph-x me-1"></i>
                  Clear All Filters
                </Button> : null
              }
            </StyledContainer>
            <ExportTable loadFilteredData={exportTable}></ExportTable>
          </div>
          <Modal isOpen={modalFilter} toggle={toggleFilter} className="modal-lg">
            <ModalHeader toggle={toggleFilter}>
              <i className="mdi mdi-filter-variant me-1"></i>
              Filters
            </ModalHeader>
            <ModalBody>
              <ETrackerTasksFilters
                filteredLocations={selectedLocations}
                filteredSystems={selectedSystems}
                filteredCategories={selectedCategories}
                filteredStages={selectedStages}
                filteredOffTrack={offTrack}
                filteredDelayed={delayed}
                applyFilters={applyFilters}
              />
            </ModalBody>
          </Modal>
          {/* <div>
          <Stages />
          </div> */}
        </CardHeader>
        {(isLoading) && <LoadingSpinner />}
        <CardBody>
          {tasksData?.length > 0 ? (
            <>
                <ETrackerTable
                    forms={tasksData}
                />
                <Pagination
                    currentPage={currentPage}
                    goToPage={goToPage}
                    totalEntries={totalEntries}
                />
            </>
          ) : null}
        </CardBody>
      </Card>
    </div>
  )
}

export default ETrackerTasks
