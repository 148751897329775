import Select from "react-select"
import React, { useEffect, useState } from "react"
import { getCountryOptions } from "common/SelectOptions/countryOptions"
import { getSystemOptions } from "common/SelectOptions/systemOptions"
import { getStageOptions } from "common/SelectOptions/stageOptions"
import { landscapingCategoryOptions } from "common/SelectOptions/landscapingCategoryOptions"
import { BooleanOptions } from "common/SelectOptions/BooleanOptions"
import policyTypeOptions from "common/SelectOptions/policyTypeOptions"
import AGRASupportedDropdown from "components/Common/AGRASupportedDropdown"
import { useSelector } from "react-redux"

const PolicyDirectoryFilters = ({
  appliedFilters, 
  applyFilters,
}) => {
  const [locationOptions, setLocationOptions] = useState([]);
  const [systemOptions, setSystemOptions] = useState([]);
  const [stageOptions, setStageOptions] = useState([]);
  const [filters, setFilters] = useState(appliedFilters);
  const [isAGRAUser] = useState(
    Boolean(
      useSelector(
        (state) => state.Profile)
        .user
        ?.user_organisation
        ?.organisation
        ?.short_name === 'AGRA'
      )
    );

  const handleChange = (options, field) => {
    setFilters((prev)=>({
      ...prev,
      [field]: options
    }))
  }

  useEffect(() => {
    const fetchOptions = async () => {
      setLocationOptions(await getCountryOptions());
      setSystemOptions(await getSystemOptions());
      setStageOptions(await getStageOptions());
    };
    fetchOptions();
  }, []);

  return (
    <div>
      {/* Filter by Location */}
      <div className="d-flex my-2">
        <div className="d-flex align-items-center">
          <i className="mdi mdi-map-marker-outline me-1" />
          <h6 className="mb-0">Locations:</h6>
        </div>
        <div className="ms-2" style={{ width: "100%" }}>
          <Select
            isMulti
            options={locationOptions}
            defaultValue={filters.locations}
            className="basic-single"
            classNamePrefix="select"
            placeholder="Select locations"
            onChange={options => handleChange(options, 'locations')}
          />
        </div>
      </div>

      {/* Filter by Systems */}
      <div className="d-flex my-2">
        <div className="d-flex align-items-center">
          <i className="mdi mdi-link-variant me-1" />
          <h6 className="mb-0">Systems:</h6>
        </div>
        <div className="ms-2" style={{ width: "100%" }}>
          <Select
            isMulti
            options={systemOptions}
            defaultValue={filters.systems}
            className="basic-single"
            classNamePrefix="select"
            placeholder="Select systems"
            onChange={options => handleChange(options, 'systems')}
          />
        </div>
      </div>

      {/* Filter by Stage */}
      <div className="d-flex my-2">
        <div className="d-flex align-items-center">
          <i className="mdi mdi-progress-check me-1" />
          <h6 className="mb-0">Stages:</h6>
        </div>
        <div className="ms-2" style={{ width: "100%" }}>
          <Select
            isMulti
            options={stageOptions}
            defaultValue={filters.stages}
            className="basic-single"
            classNamePrefix="select"
            placeholder="Select stages"
            onChange={options => handleChange(options, 'stages')}
          />
        </div>
      </div>

      {/* Filter by Categories */}
      <div className="d-flex my-2">
        <div className="d-flex align-items-center">
          <i className="mdi mdi-chart-bar me-1" />
          <h6 className="mb-0">Landscaping Categories:</h6>
        </div>
        <div className="ms-2" style={{ width: "100%" }}>
          <Select
            isMulti
            options={landscapingCategoryOptions}
            defaultValue={filters.categories}
            className="basic-single"
            classNamePrefix="select"
            placeholder="Select categories"
            onChange={options => handleChange(options, 'categories')}
          />
        </div>
      </div>

      <div className="d-flex my-2">
        <div className="d-flex align-items-center">
          <i className="mdi mdi-chart-bar me-1" />
          <h6 className="mb-0">Policy Types:</h6>
        </div>
        <div className="ms-2" style={{ width: "100%" }}>
          <Select
            isMulti
            options={policyTypeOptions}
            defaultValue={filters.policyTypes}
            className="basic-single"
            classNamePrefix="select"
            placeholder="Select categories"
            onChange={options => handleChange(options, 'policyTypes')}
          />
        </div>
      </div>
      
      <div className="d-flex my-2">
        <div className="d-flex align-items-center">
          <i className="mdi mdi-chart-bar me-1" />
          <h6 className="mb-0">Off Track:</h6>
        </div>
        <div className="ms-2" style={{ width: "100%" }}>
          <Select
            isClearable
            options={BooleanOptions}
            defaultValue={filters.isOffTrack}
            className="basic-single"
            classNamePrefix="select"
            placeholder="Select"
            onChange={options => handleChange(options, 'isOffTrack')}
         />
        </div>
      </div>

      <div className="d-flex my-2">
        <div className="d-flex align-items-center">
          <i className="mdi mdi-chart-bar me-1" />
          <h6 className="mb-0">Delayed:</h6>
        </div>
        <div className="ms-2" style={{ width: "100%" }}>
          <Select
            isClearable
            options={BooleanOptions}
            defaultValue={filters.isDelayed}
            className="basic-single"
            classNamePrefix="select"
            placeholder="Select"
            onChange={options => handleChange(options, 'isDelayed')}
          />
        </div>
      </div>

      <div className="d-flex my-2">
        <div className="d-flex align-items-center">
          <i className="mdi mdi-chart-bar me-1" />
          <h6 className="mb-0">Complete:</h6>
        </div>
        <div className="ms-2" style={{ width: "100%" }}>
          <Select
            isClearable
            options={BooleanOptions}
            defaultValue={filters.isComplete}
            className="basic-single"
            classNamePrefix="select"
            placeholder="Select"
            onChange={options => handleChange(options, 'isComplete')}
          />
        </div>
      </div>

      <div className="d-flex my-2">
        <div className="d-flex align-items-center">
          <i className="mdi mdi-chart-bar me-1" />
          <h6 className="mb-0">Policy Win:</h6>
        </div>
        <div className="ms-2" style={{ width: "100%" }}>
          <Select
            isClearable
            options={BooleanOptions}
            defaultValue={filters.isPolicyWin}
            className="basic-single"
            classNamePrefix="select"
            placeholder="Select"
            onChange={options => handleChange(options, 'isPolicyWin')}
          />
        </div>
      </div>

      {
        isAGRAUser ? 
          <AGRASupportedDropdown 
            selectedOption={filters.AGRASupported} 
            setSelectedOption={(option)=>handleChange(option, 'AGRASupported')}
          />
          : null
      }
    
      {/* Apply Filters Button */}
      <div className="d-flex" style={{ justifyContent: "center" }}>
        <button
          className="btn btn-success common-button"
          style={{ width: "90%" }}
          onClick={()=>applyFilters(filters)}
        >
          Apply
        </button>
      </div>
    </div>
  )
}

export default PolicyDirectoryFilters
