import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import { Card, CardBody, Col, Container, Row, Button, Input } from "reactstrap"
import "./user-details.css"
import { getUserProfile, getUserPicture } from "../../services/userProfileService"

import profile1 from "assets/images/profile-img.png"


const UserProfileDetail = () => {

  // const [userProfileForm, setUserProfileForm] = useState({initialUserProfileForm})

  const { id: userId } = useParams();
  const [userProfile, setUserProfile] = useState(null)
  const [userPhoto, setUserPhoto] = useState(profile1) // Set default photo as fallback
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    if (userId) {
      loadUserProfile(userId);
    }
  }, [userId])

    const loadUserProfile = async (id) => {
    setLoading(true)
    try {
      const profileData = await getUserProfile(id);
      setUserProfile(profileData);

      if (profileData.id) {
        try {
          const photoBlob = await getUserPicture(profileData.id);
          const photoUrl = URL.createObjectURL(photoBlob);
          setUserPhoto(photoUrl);
        } catch (err) {
          console.error("Error fetching profile picture:", err);
        }
      }

      setLoading(false);
    } catch (error) {
      console.error("Error fetching user profile:", error);
      setError("Failed to load user profile.");
      setLoading(false);
    }
  };

  const updateUserProfile = (updatedData) => {
    setUserProfile((prevProfile) => ({
      ...prevProfile,
      ...updatedData
    }))
  }

  if (loading) {
    return <p>Loading user data...</p>
  }

  if (error) {
    return <p>{error}</p>
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row className="justify-content-center">
            <Col lg="10" md="12">
              <Card className="user-detail-card bg-white">
                <CardBody>
                  <Row className="align-items-center">
                    <Col lg="4" md="6" sm="12" className="text-center mb-4">
                      <div className="profile-card">
                        <img
                          src={userPhoto}
                          alt="Profile"
                          className="img-fluid rounded-circle profile-image"
                        />
                        <h4 className="mt-4">{`${userProfile.first_name} ${userProfile.last_name}`}</h4>
                        <div className="status-badge">
                          <span className={`dot ${userProfile.is_active ? "bg-success" : "bg-danger"}`}></span>
                          <span className="ml-3">{userProfile.is_active ? "Active" : "Inactive"}</span>
                        </div>
                      </div>
                    </Col>

                    <Col lg="8" md="12">
                      <div className="user-info-table bg-white">
                        {/* Email */}
                        <div className="user-info-row">
                        <span className="info-icon">
                          <i className="fas fa-envelope"></i>
                        </span>
                          <span className="info-label">Email</span>
                          <span className="info-value">{userProfile.email}</span>
                        </div>

                        {/* Username */}
                        <div className="user-info-row">
                        <span className="info-icon">
                          <i className="fas fa-user"></i>
                        </span>
                          <span className="info-label">Username</span>
                          <span className="info-value">{`${userProfile.first_name} ${userProfile.last_name}`}</span>
                        </div>

                        {/* Location */}
                        <div className="user-info-row">
                        <span className="info-icon">
                          <i className="fas fa-map-marker-alt"></i>
                        </span>
                          <span className="info-label">Location</span>
                          <span className="info-value">
                          {userProfile.user_country && userProfile.user_country.length > 0 ? (
                            userProfile.user_country
                              .map((countryObj) => countryObj.country.long_name)
                              .join(", ")
                          ) : (
                            "N/A"
                          )}
                        </span>
                        </div>

                        {/* Organization */}
                        <div className="user-info-row">
                        <span className="info-icon">
                          <i className="fas fa-building"></i>
                        </span>
                          <span className="info-label">Organization</span>
                          <span className="info-value">
                          {userProfile.user_organisation?.organisation?.long_name || "N/A"}
                        </span>
                        </div>

                        {/* Access Level */}
                        <div className="user-info-row">
                        <span className="info-icon">
                          <i className="fas fa-user-shield"></i>
                        </span>
                          <span className="info-label">Access Level</span>
                          <span className="info-value">
                          {userProfile.is_admin ? "Administrator" : "User"}
                        </span>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )

}

export default UserProfileDetail