// common/Filters/stageMilestoneOptions.js
import { getStageMileStones } from "services/milestoneServices";

const fetchMilestoneOptions = async (stageID) => {
  try {
    let milestones = await getStageMileStones(stageID);
    milestones = milestones.milestones.data.map((milestone) => ({
      label: milestone.name,
      value: milestone.id,
    }));
    return milestones;
  } catch (error) {
    console.error("Failed to fetch milestones:", error);
    return []; // Default to an empty array on failure
  }
};

export const getMilestoneOptions = fetchMilestoneOptions;