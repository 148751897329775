import React, { useState, useEffect, useRef } from 'react';
import Step3 from './Step3';
import { createPolicyService, getPolicy, updatePolicy } from 'services/policyServices';
import Step1 from './Step1';
import { getCountryOptions } from 'common/SelectOptions/countryOptions';
import { getStageOptions } from 'common/SelectOptions/stageOptions';
import { getSystemOptions } from 'common/SelectOptions/systemOptions';
import Step2 from './Step2';
import PolicyPreview from './Preview';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import policyTypeOptions from 'common/SelectOptions/policyTypeOptions';
import { getMilestoneOptions } from 'common/SelectOptions/milestoneOptions';

const PolicyForm = ({alert}) => {

  const { policyID } = useParams();
  const isEditMode = Boolean(policyID);

  const [currentStep, setCurrentStep] = useState(1);
  const [policyFile, setPolicyFile] = useState(null);
  const [formData, setFormData] = useState({
    policy_request_body: {
      name: '',
      details: '',
      country_id: '',
      stage_id: '',
      is_off_track: false,
      is_delayed: false,
      updates_and_emerging_outcomes: '',
      // is_complete: false
    },
    system_ids: [],
    partner_request_bodies: []
  });

  const [countryOptions, setCountryOptions] = useState([]);
  const [stageOptions, setStageOptions] = useState([]);
  const [systemOptions, setSystemOptions] = useState([]);
  const [milestoneOptions, setMilestoneOptions] = useState([]);

  const [isLoading, setIsLoading] = useState({
    countryOptions: false,
    stageOptions: false,
    systemOptions: false,
    milestoneOptions: false,
    policy: true
  });

  const [errors, setErrors] = useState({
    countryOptions: null,
    stageOptions: null,
    systemOptions: null,
    milestoneOptions: null,
    policy: null
  });

  const prevCountryId = useRef(formData.policy_request_body?.country_id);

  useEffect(() => {
    const currentCountryId = formData.policy_request_body?.country_id;
    
    if (prevCountryId.current && prevCountryId.current !== currentCountryId) {
        setFormData(prev => ({
            ...prev,
            partner_request_bodies: []
        }));
    }
    
    prevCountryId.current = currentCountryId;
  }, [formData.policy_request_body?.country_id]);

  const fetchOptions = async (
    fetchFn,
    setterFn,
    optionType,
    errorTypeText
  ) => {
    try {
      setIsLoading(prev => ({ ...prev, [optionType]: true }));
      setErrors(prev => ({ ...prev, [optionType]: null }));
      const options = await fetchFn();
      setterFn(options);
    } catch (error) {
      setErrors(prev => ({
        ...prev,
        [optionType]: `Failed to load ${errorTypeText}. Please try again later.`
      }));
      console.error(`Error loading ${errorTypeText}:`, error);
    } finally {
      setIsLoading(prev => ({ ...prev, [optionType]: false }));
    }
  };

  // Fetch options on component mount
  useEffect(() => {
    const loadOptions = async () => {
      // Fetch all options in parallel
      await Promise.all([
        fetchOptions(getCountryOptions, setCountryOptions, 'countryOptions', 'countries'),
        fetchOptions(getStageOptions, setStageOptions, 'stageOptions', 'stages'),
        fetchOptions(getSystemOptions, setSystemOptions, 'systemOptions', 'systems'),
      ]);
    };

    loadOptions();

    // If in edit mode, fetch policy data
    if (isEditMode) {
      const fetchPolicy = async () => {
        try {
          setIsLoading(prev => ({ ...prev, policy: true }));
          const data = await getPolicy(policyID);
          const policyData = data.policy_data;
          // Transform policy data to match form structure
          setFormData({
            policy_request_body: { //remove create in name
              name: policyData.name,
              details: policyData.details,
              ...(policyData.country_id == 2 && {policy_type: policyData.policy_type}),
              country_id: policyData.country_id,
              stage_id: policyData.stage_id,
              is_off_track: policyData.is_off_track,
              is_delayed: policyData.is_delayed,
              updates_and_emerging_outcomes: policyData.updates_and_emerging_outcomes,
            },
            system_ids: policyData.policy_systems.map(system => ({ id: system.system_id })),
            partner_request_bodies: policyData.policy_partners.map(partner => ({
              id: partner.partner_id,
              short_name: partner.partner.short_name,
              long_name: partner.partner.long_name,
              is_technical_expert: partner.partner.is_technical_expert
            }))
          });
          
          if (policyData.policy_file) {
            setPolicyFile({name: policyData.policy_file});
          }
          
        } catch (error) {
          setErrors(prev => ({
            ...prev,
            policy: 'Failed to load policy data. Please try again later.'
          }));
          console.error('Error loading policy:', error);
        } finally {
          setIsLoading(prev => ({ ...prev, policy: false }));
        }
      }
      fetchPolicy();
    }
  }, []);

  useEffect(() => {
    const fetchMilestoneOptions = async () => {
      try {
        setIsLoading(prev => ({ ...prev, milestoneOptions: true }));
        setErrors(prev => ({ ...prev, milestoneOptions: null }));

        if (!formData.policy_request_body.stage_id) {
          setMilestoneOptions([]); // Reset if stage_id is missing
          return;
        }
        
        const milestones = await getMilestoneOptions(formData.policy_request_body.stage_id);
        setMilestoneOptions(milestones);
      } catch (error) {
        setErrors(prev => ({
          ...prev,
          milestoneOptions: `Failed to load milestones. Please try again later.`,
        }));
        console.error("Error loading milestones:", error);
      } finally {
        setIsLoading(prev => ({ ...prev, milestoneOptions: false }));
      }
    };

    fetchMilestoneOptions();

  }, [formData.policy_request_body.stage_id]);

  const handleSubmit = async () => {
    try {
      if (isEditMode) {
        await updatePolicy(policyID, formData, policyFile);
      } else {
        await createPolicyService(formData, policyFile);
      }

      alert(`Policy ${isEditMode ? 'updated' : 'created'}`, true);
      setTimeout(()=>window.open(`/policy-directory`), [1000]);
    } catch (error) {
      console.error(`Error ${isEditMode ? 'updating' : 'creating'} policy:`, error);
      alert(`Error ${isEditMode ? 'updating' : 'creating'} policy`, false);
    }
  };
  
  const handleInputChange = (field, value) => {
    setFormData(prev => ({
      ...prev,
      policy_request_body: {
        ...prev.policy_request_body,
        [field]: value
      }
    }));
  };

  if (isEditMode && isLoading.policy) {
    return <div>Loading policy data...</div>;
  }

  if (errors.policy) {
    return <div className="text-red-500">{errors.policy}</div>;
  }

  return (
    <div className='page-content'>
      <div className="max-w-5xl mx-auto">
        {currentStep >= 1 && currentStep <= 3 &&
        <>
          <div className="flex space-x-2 mb-9">
            <div 
              className={`flex-1 text-center py-3 px-4 rounded ${currentStep === 1 ? 'bg-green-100 text-green-800' : 'bg-gray-100'}`}
            >
              1. Policy Details
            </div>
            <div 
              className={`flex-1 text-center py-3 px-4 rounded ${currentStep === 2 ? 'bg-green-100 text-green-800' : 'bg-gray-100'}`}
            >
              2. Updates & Emerging Outcomes
            </div>
            <div 
              className={`flex-1 text-center py-3 px-4 rounded ${currentStep === 3 ? 'bg-green-100 text-green-800' : 'bg-gray-100'}`}
            >
              3. Add Partners
            </div>
          </div>
        </>
        }

        {currentStep === 1 && (
          <Step1
            isEditMode={isEditMode} 
            formData={formData} 
            handleInputChange={handleInputChange} 
            setFormData={setFormData} 
            setCurrentStep={setCurrentStep}
            policyTypeOptions={policyTypeOptions}
            countryOptions={countryOptions}
            stageOptions={stageOptions}
            systemOptions={systemOptions}
            milestoneOptions={milestoneOptions}
            isLoading={isLoading}
            errors={errors}
          />
        )}

        {currentStep === 2 && 
          <Step2 
            setPolicyFile={setPolicyFile} 
            handleInputChange={handleInputChange} 
            setCurrentStep={setCurrentStep} 
            formData={formData}
            policyFile={policyFile}
          />
        }

        {currentStep === 3 && (
          <Step3 
            formData={formData} 
            setFormData={setFormData} 
            setCurrentStep={setCurrentStep}
          />
        )}

        {currentStep === 4 && (
          <PolicyPreview
            formData={formData} 
            policyFile={policyFile}
            createPolicy={handleSubmit} 
            setCurrentStep={setCurrentStep}
            countryOptions={countryOptions}
            systemOptions={systemOptions}
          />
        )}
      </div>
    </div>
  );
};

export default PolicyForm;