// common/Filters/landscapingCategoryOptions.js

export const landscapingCategoryOptions = [
    {
        label: "Macro",
        value: "MACRO"
    },
    {
        label: "Micro",
        value: "MICRO"
    },
    {
        label: "Meso",
        value: "MESO"
    }
];