import React from "react";
import { Table } from "reactstrap";
import styled from "@emotion/styled";
import "./FormsTable.css";

const StyledTableContainer = styled.div`
  width: 100%;
  padding: 1em;
  overflow-x: auto; /* Allows horizontal scroll if necessary */
`;

const StyledTable = styled(Table)`
  width: 100%;
  table-layout: fixed;
  background-color: transparent;
  border-radius: 16px 16px 0px 0px;
  overflow: hidden;

  & thead {
    background-color: #f8f9fa;
  }

  & td {
    padding: 1em;
    text-align: left;
    font-size: 14px;
    font-weight: 500;
    border: none; /* Remove all borders */
    border-bottom: 1px solid #dee2e6; /* Only keep the bottom border */
    vertical-align: middle;
    background-color: white; /* Ensure the background is white */
  }

  & tbody tr {
    background-color: white !important; /* Ensure all rows have a white background */
    cursor: default;

    &:hover {
      background-color: #f8f9fa; /* Add a hover effect for rows */
    }
  }
`;

function LandscapingTable({ forms }) {
  return (
    <StyledTableContainer>
      <StyledTable className="custom-table">
        <colgroup>
          <col style={{ width: "14%" }} />
          <col style={{ width: "18%" }} />
          <col style={{ width: "14%" }} />
          <col style={{ width: "14%" }} />
          <col style={{ width: "14%" }} />
          <col style={{ width: "13%" }} />
          <col style={{ width: "13%" }} />
        </colgroup>
        <thead className="table-header">
          <tr>
            <th scope="col">Location</th>
            <th scope="col">Focal Area</th>
            <th scope="col">Focal Area Category</th>
            <th scope="col">Landscaping Category</th>
            <th scope="col">Average Score</th>
            <th scope="col">Owner</th>
            <th scope="col">Supervisor</th>
          </tr>
        </thead>
        <tbody>
          {forms && forms.length > 0 ? (
            forms.map((row, i) => {
              const owner = row.owner_and_supervisor?.owner
                ? `${row.owner_and_supervisor.owner.first_name} ${row.owner_and_supervisor.owner.last_name}`
                : "";

              const supervisor = row.owner_and_supervisor?.supervisor
                ? `${row.owner_and_supervisor.supervisor.first_name} ${row.owner_and_supervisor.supervisor.last_name}`
                : "";

              return (
                <tr key={i}>
                  <td style={{ color: "#4D4D4D" }}>{row?.country?.long_name || ""}</td>
                  <td>
                    <a
                      href={`/landscaping-forms/${row.country_id}/${row.focal_area_id}`}
                      rel="noopener noreferrer"
                      className="flex items-start text-green-700"
                    >
                      <span className="arrow-icon">↗</span> {row?.landscaping_focal_area?.name || ""}
                    </a>
                  </td>
                  <td>{row?.landscaping_focal_area?.focal_area_category || ""}</td>
                  <td>{row?.landscaping_focal_area?.landscaping_category || ""}</td>
                  <td>{row?.landscaping_focal_area?.average_score || ""}</td>
                  <td>{owner}</td>
                  <td>{supervisor}</td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan="7">No data available</td>
            </tr>
          )}
        </tbody>
      </StyledTable>
    </StyledTableContainer>
  );
}

export default LandscapingTable;