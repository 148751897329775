import React, { useState, useEffect, useCallback } from "react"
import { FiArrowDownCircle, FiArrowUpCircle } from "react-icons/fi"
import { get } from "lodash"
import countriesFlag from "common/countriesFlag"
import { getKeyMetrics } from "services/dashboardService"

export const KeyMetricsView = () => {

  const [data, setData] = useState([]);
  const content = ['policies', 'Agri-Food Systems', 'policy wins'];
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(()=>{
    getKeyMetrics().then(data=>{
      setData(Object.values(data));
    })
  }, []);

  // Function to move to the next slide
  const nextSlide = () => {
    setCurrentIndex(prevIndex => (prevIndex + 1) % data.length)
  }

  const prevSlide = useCallback(() => {
    setCurrentIndex(prevIndex => (prevIndex > 0 ? prevIndex - 1 : data.length - 1));
  }, [data.length]);

  useEffect(() => {
    const interval = setInterval(nextSlide, 10000);
    return () => clearInterval(interval);
  }, [nextSlide])

  if (!data.length) return null;

  return (
    <div className="flex flex-row justify-between">
      <div className="flex flex-col mr-5 justify-center">
      {console.log(currentIndex)}
        <div className="w-[23.93rem] h-[23.93rem] rounded-full border-2 border-dashed border-[#EAE200] border-spacing-4 flex justify-center items-center">
          <div className="w-[11.18rem] rounded-2xl bg-white flex justify-center items-center text-[#036F36] font-light text-4xl py-3 px-3">
            {currentIndex === 0
              ? "Policies"
              : currentIndex === 1
              ? "Agri-Food Systems"
              : "Policy Wins"}
          </div>
        </div>
      </div>
      <div className="flex flex-col overflow-hidden h-[46rem] py-5">
        <div className="flex flex-col items-center">
          {data && data.length > 0 ? (
            <div className="flex flex-col max-w-[22.63rem]">
              <div className="font-normal text-[#EAE200] text-[2rem]">
                Total number of {content[currentIndex]}
              </div>
              <div className="font-normal text-6xl text-[#ffffff] mb-3">
                {data[currentIndex]?.total}
              </div>
              <div className="w-auto bg-[#0B4805] h-[1px] my-3"></div>
              <div className="font-normal text-[#EAE200] text-[2rem] mb-2">
                Country with the highest number of {content[currentIndex]}
              </div>
              <div className="flex flex-row">
                <div className="flex flex-col mr-2">
                  <div className="rounded-2xl w-10 bg-white flex justify-center items-center mb-2">
                    <img
                      src={get(
                        countriesFlag,
                        data[currentIndex]?.highest.short_name
                      )}
                      alt=""
                      className="w-full h-auto"
                    />
                  </div>
                  <div className="text-white font-normal text-2xl">
                    {data[currentIndex]?.highest.long_name}
                  </div>
                </div>
                <div className="font-normal text-6xl text-[#ffffff] mb-3">
                  {data[currentIndex]?.highest.number_of_policies ||
                    data[currentIndex]?.highest.number_of_systems}
                </div>
              </div>
              <div className="w-auto bg-[#0B4805] h-[1px] my-3"></div>
              <div className="font-normal text-[#EAE200] text-[2rem] mb-2">
                Country with the lowest number of {content[currentIndex]}
              </div>
              <div className="flex flex-row">
                <div className="flex flex-col mr-2">
                  <div className="rounded-2xl w-10 bg-white flex justify-center items-center mb-2">
                    <img
                      src={get(
                        countriesFlag,
                        data[currentIndex]?.lowest.short_name
                      )}
                      alt=""
                      className="w-full h-auto"
                    />
                  </div>
                  <div className="text-white font-normal text-2xl">
                    {data[currentIndex]?.lowest.long_name}
                  </div>
                </div>
                <div className="font-normal text-6xl text-[#ffffff] mb-3">
                  {data[currentIndex]?.lowest.number_of_policies ||
                    data[currentIndex]?.lowest.number_of_systems}
                </div>
              </div>
              <div className="w-auto bg-[#0B4805] h-[1px]"></div>
            </div>
          ) : null}
        </div>
      </div>
      <div className="flex flex-col justify-center ml-5">
        <div
          className="w-[3.93rem] h-[4.25rem] rounded-2xl border-2 border-[#EAE200] flex justify-center items-center"
          onClick={prevSlide}
        >
          <FiArrowUpCircle color="#EAE200" size="3rem" />
        </div>

        <div className="flex flex-col mt-3 items-center">
          {data && data.length > 0
            ? data.map((_, index) => {
                return (
                  <div
                    key={index}
                    className={`h-[4.875rem] w-[0.218rem] mb-2 ${
                      currentIndex === index ? "bg-[#EAE200]" : "bg-[#D9D9D9]"
                    }`}
                  ></div>
                )
              })
            : null}
        </div>
        <div
          className="w-[3.93rem] h-[4.25rem] rounded-2xl border-2 border-[#EAE200] flex justify-center items-center mt-2"
          onClick={nextSlide}
        >
          <FiArrowDownCircle color="#EAE200" size="3rem" />
        </div>
      </div>
    </div>
  )
}
