import React, { useEffect, useState, useRef, useMemo } from "react"
import { withRouter, Link } from "react-router-dom"
import DefaultTableContainer from "../../../components/Common/DefaultTableContainer"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
  Alert,
} from "reactstrap"
import Select from "react-select"
import * as Yup from "yup"
import { useFormik } from "formik"
import { motion, AnimatePresence } from "framer-motion"

import { Name, Date, Boolean, Countries, Tags } from "./accesscodelistCol"

import { useSelector } from "react-redux"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal"
import ErrorModal from "components/Common/ErrorModal"
import SuccessModal from "components/Common/SuccessModal"

import { Country, Role, Permission } from "constants/global"

import {
  getAccessCodes,
  addNewAccessCode,
  deleteAccessCode,
  updateAccessCode,
  listOrganizationTypes,
  listOrganizations,
  getUserOrganizations,
} from "services"
import { isValid } from "redux-form"
import { useQuery } from "@tanstack/react-query"

const variants = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
}
const AccessCodesList = props => {
  //meta title
  document.title = "Access Code Management | AGRA"

  const [accessCode, setAccessCode] = useState()
  const [code, setCode] = useState()
  const [accessCodeList, setAccessCodeList] = useState([])
  const [modal, setModal] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [errorModal, setErrorModal] = useState("")
  const [successModal, setSuccessModal] = useState("")
  const [filterCountries, setFilterCountries] = useState([])

  const {
    countries,
    stages,
    landscaping_stages,
    tracker_stages,
    benchmarking_stages,
  } = useSelector(state => state.list)

  const { country } = useSelector(state => state.Profile)

  //email limit state management
  const [numOfEmailsLeft, setNumOfEmailsLeft] = useState(20)

  const stageTypeOptions = [
    { label: "E-Tracker", value: "tracker" },
    { label: "Landscaping", value: "landscaping" },
    { label: "Benchmarking", value: "benchmarking" },
  ]

  const validate = values => {
    const errors = {}

    if (!values.countries.length) {
      errors.countries = "Please select at least 1 country"
    }

    if (!values.emails.length) {
      errors.emails = "Please enter emails"
    }

    if (!values.role.hasOwnProperty("value")) {
      errors.role = "Please select role"
    } else if (values.role.value == "user" && !values.stages.length) {
      errors.stages = "Please select at least 1 stage"
    }

    if (!values.orgType.hasOwnProperty("value")) {
      errors.orgType = "Please select an Org type."
    }
    if (!values.org.hasOwnProperty("value")) {
      errors.org = "Please select an Organization."
    }
    return errors
  }

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      countries: (accessCode && accessCode.countries) || [],
      // role: (accessCode && accessCode.role) || "",
      emails: (accessCode && accessCode.emails) || "",
      // stageTypes: (accessCode && accessCode.stageTypes) || [],
      // stages: (accessCode && accessCode.stages) || [],
      // orgType: (accessCode && accessCode.orgType) || "",
      org: (accessCode && accessCode.org) || "",
    },
    validate,
    // validationSchema: Yup.object({
    //   countries: Yup.array()
    //     .min(1, "Pick at least 1 country")
    //     .required("Please Select Country"),
    //   role: Yup.object().required("Please Select Role"),
    //   emails: Yup.string().required("Please Enter Email"),
    //   stages: Yup.array().when("role", {
    //     is: role => role && "value" in role && role.value == "user",
    //     then: Yup.array()
    //       .min(1, "Please select at least one stage")
    //       .required("Please Select Stages"),
    //     otherwise: Yup.array().required("Please Select First Role"),
    //   }),
    // }),
    onSubmit: values => {
      if (isEdit) {
        const accessCodeData = {
          countries: values["countries"].map(country => country.value),
          users: values["emails"].replace(/\s/g, "").split(","),
          role: values["role"].value,
          stages: values["stages"].map(stage => stage.value),
          orgType: values["orgType"].value,
          org: values["org"].value,
        }
        updateAccessCode(accessCode.id, accessCodeData)
          .then(res => {
            //setCode(res.data.code)
            setRefresh(!refresh)
            validation.resetForm()
            toggle()
            //setSuccessModal(res.message)
            props.alert(res.message, true)
          })
          .catch(err => {
            //setErrorModal(err.response.data.message)
            props.alert(err.response.data.message, false)
          })
        setIsEdit(false)
      } else {
        const newAccessCode = {
          countries: values["countries"].map(country => country.value),
          users: values["emails"].replace(/\s/g, "").split(","),
          role: values["role"].value,
          stages: values["stages"].map(stage => stage.value),
          organization_id: values["org"].value,
        }
        addNewAccessCode(newAccessCode)
          .then(res => {
            setCode(res.data.code)
            validation.resetForm()
            toggle()
            //setSuccessModal(`${res.message} \n Access Code: ${res.data.code}`)
            props.alert(`${res.message} \n Access Code: ${res.data.code}`, true)
          })
          .catch(err => {
            // setErrorModal(err.response.data.message)
            props.alert(err.response.data.message, false)
          })
      }
    },
  })

  useEffect(() => {
    if (validation.values.emails === "") {
      setNumOfEmailsLeft(20)
    } else {
      const numOfEmailsAdded = validation.values.emails.split(",").length - 1
      setNumOfEmailsLeft(20 - numOfEmailsAdded)
    }
  }, [validation.values.emails])
  const stageTypesMap = new Map([
    ["tracker", tracker_stages],
    ["landscaping", landscaping_stages.slice(0, 3)],
    ["benchmarking", benchmarking_stages],
  ])
  const [stagesOptions, setStagesOptions] = useState([])

  useEffect(() => {
    const selectedStageTypes = validation.values.stageTypes.map(
      type => type.value
    )
    const tempStageOptions = selectedStageTypes.map(type => [
      ...stageTypesMap.get(type),
    ])

    setStagesOptions(tempStageOptions.flat())
  }, [validation.values.stageTypes])
  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        disableGlobalFilter: true,
        disableFilters: true,
      },
      {
        Header: "Access Code",
        accessor: "code",
        disableSortBy: true,
        disableFilters: true,
        Cell: cellProps => {
          return <Name {...cellProps} />
        },
      },
      {
        Header: "Email",
        accessor: "email",
        disableSortBy: true,
        disableFilters: true,
        Cell: cellProps => {
          return <Name {...cellProps} />
        },
      },
      {
        Header: "Role",
        accessor: "role",
        disableGlobalFilter: true,
        disableSortBy: true,
        Cell: cellProps => {
          return <Name {...cellProps} />
        },
      },
      {
        Header: "Location",
        accessor: "countries",
        disableSortBy: true,
        disableGlobalFilter: true,
        disableFilters: true,
        Cell: cellProps => {
          return <Tags {...cellProps} />
        },
      },
      {
        Header: "Created At",
        accessor: "created_at",
        disableGlobalFilter: true,
        disableFilters: true,
        Cell: cellProps => {
          return <Date {...cellProps} />
        },
      },
      {
        Header: "Joined At",
        accessor: "joined_at",
        disableGlobalFilter: true,
        disableFilters: true,
        Cell: cellProps => {
          return <Date {...cellProps} />
        },
      },
      {
        Header: "Action",
        disableSortBy: true,
        disableFilters: true,
        disableGlobalFilter: true,
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to={`/access-code-detail/${cellProps.row.original.id}`}
                className="text-success"
              >
                <i
                  className="mdi mdi-open-in-app font-size-18"
                  id="viewtooltip"
                />
                <UncontrolledTooltip placement="top" target="viewtooltip">
                  View
                </UncontrolledTooltip>
              </Link>
              {!cellProps.row.original.joined_at && (
                <Link
                  to="#"
                  className="text-success"
                  onClick={() => {
                    const accessCodeData = cellProps.row.original
                    handleAccessCodeClick(accessCodeData)
                  }}
                >
                  <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                  <UncontrolledTooltip placement="top" target="edittooltip">
                    Edit
                  </UncontrolledTooltip>
                </Link>
              )}
              {!cellProps.row.original.joined_at && (
                <Link
                  to="#"
                  className="text-danger"
                  onClick={() => {
                    const accessCodeData = cellProps.row.original
                    onClickDelete(accessCodeData)
                  }}
                >
                  <i
                    className="mdi mdi-delete font-size-18"
                    id="deletetooltip"
                  />
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    Delete
                  </UncontrolledTooltip>
                </Link>
              )}
            </div>
          )
        },
      },
    ],
    []
  )

  // useEffect(
  //   function () {
  //     if (country) {
  //       let params = {}
  //       params = { country: country }
  //       getAccessCodes(params)
  //         .then(res => {
  //           setAccessCodeList(res.data)
  //         })
  //         .catch(err => setAccessCodeList([]))
  //     }
  //   },
  //   [country, code, refresh]
  // )

  useEffect(
    function () {
      let params = {}
      params = { country: [] }
      getAccessCodes(params)
        .then(res => {
          setAccessCodeList(res.data)
        })
        .catch(err => setAccessCodeList([]))
    },
    [country, code, refresh]
  )

  useEffect(
    function () {
      if (filterCountries.length > 0) {
        let params = {}
        params = { country: filterCountries.map(data => data.value) }
        getAccessCodes(params)
          .then(res => {
            setAccessCodeList(res.data)
          })
          .catch(err => setAccessCodeList([]))
      }
    },
    [filterCountries]
  )

  const toggle = () => {
    setModal(!modal)
  }

  const handleAccessCodeClick = arg => {
    const access_code = arg
    setAccessCode({
      id: access_code.id,
      countries: access_code.countries.map(data => ({
        label: data.label,
        value: data.id,
      })),
      role: {
        label: Role[access_code.role],
        value: access_code.role,
      },
      emails: access_code.email,
      stages: access_code.stages.map(data => ({
        label: data.label,
        value: data.id,
      })),
    })
    setIsEdit(true)

    toggle()
  }

  const handleAccessCodeClicks = () => {
    setAccessCode("")
    setIsEdit(false)
    toggle()
  }

  //delete access code
  const [deleteModal, setDeleteModal] = useState(false)

  const onClickDelete = access_code => {
    setAccessCode(access_code)
    setDeleteModal(true)
  }

  const handleDeleteAccessCode = () => {
    deleteAccessCode(accessCode.id)
      .then(res => {
        setRefresh(!refresh)
        props.alert(`Access Code Deleted`, true)
      })
      .catch(err => {
        props.alert("Access Code Not Deleted", false)
      })
    //  setDeleteModal(false)
  }

  const onRoleChange = selectedOption => {
    if (selectedOption.value != "user") validation.values.stages = []
  }

  const keyField = "id"

  //fetching orgTypes and orgs
  const [orgTypes, setOrgTypes] = useState([])
  const [orgs, setOrgs] = useState([])

  useEffect(() => {
    const fetchOrgTypes = async () => {
      // const countryCodes = validation.values.countries.map(
      //   country => country.value
      // )
      try {
        const { data } = await listOrganizationTypes({})
        setOrgTypes(data)
      } catch (err) {
        console.error(err)
      }
    }

    fetchOrgTypes()
  }, [])

  // useEffect(() => {
  //   const fetchOrganizations = async () => {
  //     const countryCodes = validation.values.countries.map(
  //       country => country.value
  //     )
  //     try {
  //       const { data } = await listOrganizations({
  //         countries: countryCodes,
  //         organization_type: validation.values.orgType.value,
  //       })
  //       setOrgs(data)
  //     } catch (err) {
  //       console.error(err)
  //     }
  //   }
  //   if (
  //     validation.values.countries.length > 0 &&
  //     validation.values.orgType.value
  //   ) {
  //     fetchOrganizations()
  //   } else {
  //     validation.values.org = ""
  //     setOrgs([])
  //   }
  // }, [validation.values.orgType.value])

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteAccessCode}
        onCloseClick={() => setDeleteModal(false)}
      />
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle} tag="h4">
          {!!isEdit ? "Edit Access Code" : "Add Access Code"}
        </ModalHeader>
        <ModalBody>
          <Form
            onSubmit={e => {
              e.preventDefault()
              validation.handleSubmit()
              return "false"
            }}
          >
            <Row>
              <Col xs={12}>
                {validation.errors &&
                  Object.keys(validation.errors).length > 0 && (
                    <Alert color="danger">
                      {validation.errors.emails ||
                        validation.errors.role ||
                        validation.errors.countries ||
                        validation.errors.stages}
                    </Alert>
                  )}

                <div className="mb-3">
                  <Label className="form-label">Location</Label>
                  <Select
                    name="countries"
                    options={countries.map(val => ({
                      label: val.label,
                      value: val.id,
                    }))}
                    isMulti={true}
                    classNamePrefix="select2-selection"
                    value={validation.values.countries || []}
                    onChange={selectedOption => {
                      let event = {
                        target: {
                          name: "countries",
                          value: selectedOption,
                        },
                      }
                      validation.handleChange(event)
                    }}
                  />
                </div>

                <div className="mb-3">
                  <Label className="form-label">Emails</Label>
                  <Input
                    name="emails"
                    label="Emails"
                    placeholder="Please enter email addresses separated by commas(,)"
                    type="textarea"
                    disabled={!!isEdit ? true : false}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.emails || ""}
                    invalid={
                      validation.touched.emails && validation.errors.emails
                        ? true
                        : false
                    }
                  />
                  <p
                    style={{
                      fontSize: "12px",
                      textAlign: "end",
                      marginBlockEnd: 0,
                    }}
                  >
                    You can add {numOfEmailsLeft} more emails.
                  </p>
                  {validation.touched.emails && validation.errors.emails ? (
                    <FormFeedback type="invalid">
                      {validation.errors.emails}
                    </FormFeedback>
                  ) : null}
                </div>

                <div className="mb-3">
                  <Label className="form-label">First name</Label>
                  <Input
                    name="first_name"
                    type="text"
                    placeholder="Enter First Name"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.first_name || ""}
                    invalid={
                      validation.touched.first_name &&
                      validation.errors.first_name
                        ? true
                        : false
                    }
                  />
                </div>
                <div className="mb-3">
                  <Label className="form-label">Last Name</Label>
                  <Input
                    name="last_name"
                    type="text"
                    placeholder="Enter Last Name"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.last_name || ""}
                    invalid={
                      validation.touched.last_name &&
                      validation.errors.last_name
                        ? true
                        : false
                    }
                  />
                  {validation.touched.last_name &&
                  validation.errors.last_name ? (
                    <FormFeedback type="invalid">
                      {validation.errors.last_name}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="mb-3">
                  <Label className="form-label">Organization:</Label>
                  <Select
                    name="org"
                    options={orgs.map(val => ({
                      label: val.name,
                      value: val.id,
                    }))}
                    classNamePrefix="select2-selection"
                    value={validation.values.org || ""}
                    onChange={selectedOption => {
                      const event = {
                        target: {
                          name: "org",
                          value: selectedOption,
                        },
                      }
                      validation.handleChange(event)
                    }}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-end">
                  <button type="submit" className="btn btn-success save-user">
                    Save
                  </button>
                </div>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
      {/*
      <ErrorModal show={errorModal} onCloseClick={() => setErrorModal("")} />
      <SuccessModal
        show={successModal}
        onCloseClick={() => setSuccessModal("")}
      /> */}
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          {/* <Breadcrumbs title="Access Codes" breadcrumbItem="Access Code List" /> */}
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <DefaultTableContainer
                    columns={columns}
                    data={accessCodeList}
                    isGlobalFilter={true}
                    isAddAccessCodeList={true}
                    isExport={"access-code"}
                    handleAccessCodeClick={handleAccessCodeClicks}
                    customPageSize={10}
                    setFilterCountries={setFilterCountries}
                    customSort={{ id: "created_at", desc: true }}
                    className="custom-header-css"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(AccessCodesList)
