import React, { useState } from "react"
import { useQuery } from "@tanstack/react-query"
import { useSelector } from "react-redux"
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
} from "reactstrap"
import Select from "react-select"
import {
  // createNewOrganization,
  // getOrganizations,
  getPolicySuggestions,
  // getOrganizationTypes,
  editSpecificPolicy,
  getPolicies,
} from "services/policyDirectoryServices"
import { CustomTable } from "./PolicyDirectory-management"
import useDebounce from "hooks/useDebounce"
import SearchBox from "components/Common/SearchBox"
import Pagination from "components/Common/Pagination"
import { StyledContainer } from "pages/Labels/Systems"
import LoadingSpinner from "components/Common/Loading-Spinner"
import ExportTable from "../../components/Common/ExportTable";
import { exportTableData } from "services/common"
import { Link } from "react-router-dom/cjs/react-router-dom.min"
import PolicyDirectoryFilters from "./PolicyDirectoryFilters"
import { areObjectsEqual } from "helpers/utlities"

const Policies = ({alert}) => {
  document.title = "Policy Directory"
  const { countries } = useSelector(state => state.list); 

  //fetch OrgTypes
  // const loadOrganizationTypes = async () => {
  //   try {
  //     const { data } = await getOrganizationTypes({ limit: 0, offset: 0 })
  //     return data
  //   } catch (err) {
  //     console.error(err)
  //     return []
  //   }
  // }
  //fetch Organization Suggestions

  const loadPolicySuggestions = async input => {
    try {
      const { data, count } = await getPolicySuggestions(input)
      setTotalEntries(count)
      return data
    } catch (err) {
      console.error(err)
      return []
    }
  }

  // const { data: orgTypesData } = useQuery({
  //   queryKey: ["Organization Types"],
  //   queryFn: () => loadOrganizationTypes(),
  //   initialData: { types: [] },
  // })
  
  //pagination
  const [offset, setOffset] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalEntries, setTotalEntries] = useState(0)
  const handlePageChange = pageNum => {
    const offsetVal = pageNum * 50 - 50
    setOffset(offsetVal)
    setCurrentPage(pageNum)
  }
  //search
  const [searchTerm, setSearchTerm] = useState("")
  const debouncedSearchTerm = useDebounce(searchTerm)

  const handleSearchInputChange = evt => {
    setSearchTerm(evt.target.value)
    setOffset(0)
    setCurrentPage(1)
  }

  //filters
  const initialFiltersState = {
    locations: [],
    systems: [],
    stages: [],
    categories: [],
    policyTypes: [],
    isOffTrack: null,
    isPolicyWin: null,
    isComplete: null,
    isDelayed: null,
    AGRASupported: null,
  }
  const [appliedFilters, setAppliedFilters] = useState(initialFiltersState);

  const applyFilters = (filters) => {
    setAppliedFilters(filters);
    toggleModalFilter();
  }

  const areAnyFiltersSelected = () => !areObjectsEqual(appliedFilters, initialFiltersState);

  const clearFilters = () => setAppliedFilters(initialFiltersState);

  const [modalFilter, setModalFilter] = useState(false)
  const toggleModalFilter = () => setModalFilter(prevState => !prevState)

  const {
    data: policyData = { policies: [] },
    refetch,
    isLoading,
  } = useQuery({
    queryKey: [
      "Policies",
      {
        debouncedSearchTerm,
        appliedFilters,
        page: currentPage,
      },
    ],
    queryFn: async() => {
      const { data, count } = await getPolicies({ searchTerm: debouncedSearchTerm, limit: 50, offset, ...appliedFilters });
      setTotalEntries(count);
      return data;
    }
  });

  //userInputs
  const [userInput, setUserInput] = useState("")
  const [hasVisitedInput, setHasVisitedInput] = useState(false)
  const [showSuggestions, setShowSuggestions] = useState(true)
  const [selectedSuggestion, setSelectedSuggestion] = useState(null)
  // const [selectedSuggestionsOrgType, setSelectedSuggestionsOrgType] = useState(
  //   []
  // )
  const [isUserEditing, setIsUserEditing] = useState(false)
  const [editingRowID, setEditingRowID] = useState(null)
  const [partnerAlreadyExistsError, setPartnerAlreadyExistsError] = useState(false)
  const [selectedCountries, setSelectedCountries] = useState([])
  const [policyType, setOrgType] = useState({})

  const [showModal, setShowModal] = useState(false)
  const openModal = () => {
    setShowModal(true)
    if (!isUserEditing) {
      setUserInput("")
      setPartnerAlreadyExistsError(false)
      setHasVisitedInput(false)
      setSelectedCountries([])
      setOrgType({})
    }
  }
  const closeModal = () => {
    setShowModal(false)
    setIsUserEditing(false)
    setUserInput("")
    setPartnerAlreadyExistsError(false)
    setHasVisitedInput(false)
  }
  const { data: orgSuggestions } = useQuery({
    queryKey: ["Organization Suggestions", { userInput }],
    queryFn: () => loadPolicySuggestions(userInput),
    enabled: userInput.length >= 3 && hasVisitedInput,
    initialData: [],
  })

  const handleInputChange = evt => {
    setUserInput(evt.target.value)
    setShowSuggestions(true)
    setSelectedSuggestion(null)
    setPartnerAlreadyExistsError(false)
  }
  const handleSuggestionSelection = suggestion => {
    setSelectedSuggestion(suggestion)
    setUserInput(suggestion.name)
    setShowSuggestions(false)
    // const selectedSuggestionsOrgType = orgTypesData.types.find(
    //   orgType => orgType.id === suggestion.organization_type.id
    // )
    // setSelectedSuggestionsOrgType([selectedSuggestionsOrgType])
  }
  const handlePartnerCreate = async () => {
    const selectedCountryCodes = selectedCountries?.map(country => country.value)
    if (!isUserEditing) {
      let body
      if (selectedSuggestion && selectedSuggestion.name === userInput) {
        body = {
          id: selectedSuggestion.id,
          organization_type: selectedSuggestion.organization_type.id,
          countries: [
            ...selectedSuggestion.countries?.map(country => country.id),
            ...selectedCountryCodes,
          ],
          name: userInput,
        }
      } else {
        body = {
          name: userInput,
          countries: selectedCountryCodes,
          organization_type: policyType.value,
        }
      }
      try {
        const { data } = await createNewOrganization(body)
        closeModal()
      } catch (err) {
        if (err.response.data.statusCode === 409) setOrgAlreadyExistsError(true)
        console.error(err)
      }
    } else {
      try {
        const { data } = await editSpecificPolicy(editingRowID, {
          name: userInput,
          countries: selectedCountryCodes,
          organization_type: policyType.value,
        })
        closeModal()
      } catch (err) {
        console.error(err)
      }
    }
    refetch()
  }
  const initiateEdit = id => {
    setShowModal(true)
    setIsUserEditing(true)
    const selectedRowData = policyData?.policies?.find(row => row.id === id)
    setEditingRowID(selectedRowData.id)
    setUserInput(selectedRowData.name)
    setSelectedCountries(
      selectedRowData.countries?.map(country => ({
        label: country.label,
        value: country.id,
      }))
    )
  }

  const exportTable = async (type) => {
    try{ 
      const query = {
        export_format: type,
        // ...(selectedUser && { user_id: selectedUser }),
        // ...(selectedActivity && { activity: selectedActivity }),
      };

      exportTableData(query, "Policies");
    } catch(err){
      console.error(err);
      alert("Data export", false)
    }
  }

  return (
    <div>
      <div className="d-flex justify-content-between">
        <StyledContainer>
          <SearchBox searchChange={handleSearchInputChange} />
          <Button
            className="common-button"
            onClick={toggleModalFilter}
          >
            <i className="mdi mdi-filter-variant me-1" /> Filters
          </Button>
          {areAnyFiltersSelected() && (
            <Button
              className="d-flex align-items-center"
              style={{
                backgroundColor: "#f8f9fa",
                color: "black",
                margin: "15px 10px",
                border: "none",
                fontSize: "14px",
              }}
              onClick={clearFilters}
            >
              <i className="ph-x me-1"></i>
              Clear All Filters
            </Button>
          )}
        </StyledContainer>
        <Link to='/create-policy'>
          <button className="common-button">Create Policy</button>
        </Link>
        <ExportTable loadFilteredData={exportTable}></ExportTable>
        <Modal
          isOpen={modalFilter}
          toggle={toggleModalFilter}
          className="modal-lg"
        >
          <ModalHeader toggle={toggleModalFilter}>
            <i className="mdi mdi-filter-variant me-1"></i>
            Filters
          </ModalHeader>
          <ModalBody>
            <PolicyDirectoryFilters
              appliedFilters={appliedFilters}
              applyFilters={applyFilters}
            />
          </ModalBody>
        </Modal>
        <Modal
          isOpen={showModal}
          toggle={showModal ? closeModal : openModal}
          style={{ borderRadius: "0.25rem" }}
        >
          <ModalHeader
            style={{
              alignItems: "flex-start",
              height: "80px",
              paddingBottom: "0px",
            }}
            toggle={showModal ? closeModal : openModal}
          >
            <b>
              <p
                style={{
                  fontSize: "24px",
                  marginBottom: "0px",
                  color: "black",
                }}
              >
                {isUserEditing ? (
                  <>
                    <i className="mdi mdi-plus me-2"></i>
                    Edit Organization
                  </>
                ) : (
                  <>
                    <i className="mdi mdi-plus me-2"></i>
                    Create New Organization
                  </>
                )}
              </p>
            </b>
          </ModalHeader>
          <ModalBody>
            <div style={{ position: "relative" }}>
              <Label className="form-label" htmlFor="name">
                Org Name:
              </Label>
              <Input
                id="name"
                type="text"
                value={userInput}
                required
                onChange={handleInputChange}
                onFocus={() => setHasVisitedInput(true)}
              />
              {partnerAlreadyExistsError && (
                <p style={{ color: "red" }}>
                  An organization by this name already exits. Please retype the
                  org name and select it from the suggestions.
                </p>
              )}
              {orgSuggestions.length > 0 && showSuggestions && (
                <ul
                  style={{
                    position: "absolute",
                    top: "100%",
                    left: 0,
                    margin: 0,
                    padding: 0,
                    maxHeight: "100px",
                    overflowY: "auto",
                    zIndex: 999,
                    width: "100%",
                    backgroundColor: "#f2f2f2",
                    listStyle: "none",
                  }}
                >
                  {orgSuggestions?.map(suggestion => (
                    <li
                      key={suggestion.id}
                      style={{ padding: "0.5rem", cursor: "pointer" }}
                      onClick={() => {
                        handleSuggestionSelection(suggestion)
                      }}
                    >
                      {suggestion.name}
                    </li>
                  ))}
                </ul>
              )}
            </div>
            <div>
              <Label className="form-label" htmlFor="name">
                Countries:
              </Label>
              <Select
                name="countries"
                classNamePrefix="select2-selection"
                options={countries?.map(country => ({
                  label: country.label,
                  value: country.id,
                }))}
                isMulti
                value={selectedCountries}
                onChange={setSelectedCountries}
              />
            </div>
          </ModalBody>
          <ModalFooter
            style={{
              display: "flex",
              justifyContent: "flex-end",
              borderTop: "none",
              paddingTop: "0px",
            }}
          >
            <div>
              <Button
                style={{ backgroundColor: "#50A050", border: "none" }}
                onClick={handlePartnerCreate}
              >
                {isUserEditing ? "Save" : "Create"}
              </Button>
            </div>
          </ModalFooter>
        </Modal>
      </div>
      {isLoading ? <LoadingSpinner /> :
        Array.isArray(policyData) && policyData.length > 0 ? (
        <>
          <CustomTable
            data={policyData || []}
            caption="Organizations"
            initiateEdit={initiateEdit}
          />
          <Pagination
            currentPage={currentPage}
            goToPage={handlePageChange}
            totalEntries={totalEntries}
          />
        </>
        ) : 
        Array.isArray(policyData) && policyData.length === 0 ? 
          <div style={{ textAlign: 'center', marginTop: '20px' }}>
            <p>No policies available.</p>
          </div> 
        : null
      }
    </div>
  )
}

export default Policies;