import React, { useState, useEffect } from "react";
import {
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  FormGroup,
} from "reactstrap";
import "./AddUpdateOrEventButton.css";
import { createEvent, createPolicyUpdate, getCountries } from "services/policyUpdatesAndEventsService";

const AddUpdateOrEventButton = ({ alert, refreshEvents, refreshPolicyUpdates }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [modalType, setModalType] = useState(null); // 'event' or 'update'
  const [modalOpen, setModalOpen] = useState(false);
  const [hoveredItem, setHoveredItem] = useState(null); // Track hovered item

  const handleMouseEnter = (item) => setHoveredItem(item);
  const handleMouseLeave = () => setHoveredItem(null);

  const initialEventForm = {
    name: "",
    country_id: "",
    venue: "",
    start_date: "",
    end_date: "",
    event_type: "",
  }

  const [eventForm, setEventForm] = useState(initialEventForm);

  const initialPolicyUpdateForm = {
    name: "",
    country_id: "",
    description: "",
    source: "",
    photo: null,
  }

  const [policyForm, setPolicyForm] = useState(initialPolicyUpdateForm);

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [countries, setCountries] = useState([]);
  const [eventTypes] = useState(["Conference", "Workshop", "Webinar", "Training", "Seminar", "Trade Show", "Summit"]);

  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const countriesResponse = await getCountries();
        setCountries(countriesResponse);
      } catch (err) {
        console.error("Error fetching countries:", err);
      }
    };

    fetchCountries();
  }, []);

  const openModal = (type) => {
    setModalType(type);
    setModalOpen(true);
    setSuccessMessage("");
    setErrorMessage("");
  };

  const closeModal = () => setModalOpen(false);

  const handleEventChange = (e) => {
    setEventForm({ ...eventForm, [e.target.name]: e.target.value });
  };

  const handleCountryChange = (e) => {
    setEventForm({ ...eventForm, country_id: e.target.value });
  };

  const handleEventTypeChange = (e) => {
    setEventForm({ ...eventForm, event_type: e.target.value });
  };

  const handlePolicyChange = (e) => {
    if (e.target.name === "photo") {
      setPolicyForm({ ...policyForm, photo: e.target.files[0] });
    } else {
      setPolicyForm({ ...policyForm, [e.target.name]: e.target.value });
    }
  };

  const handleEventSubmit = async () => {
    try {
      await createEvent(eventForm);
      alert("Country Event Creation", true)
      setEventForm(initialEventForm)
      // setSuccessMessage("Event created successfully!");
      closeModal();
      // Refresh events after creating one
      refreshEvents();
    } catch (err) {
      setErrorMessage(`Error creating event: ${err.message}`);
      console.error("Error creating event:", err);
    }
  };

  const handlePolicySubmit = async () => {
    if (!policyForm.name || !policyForm.country_id || !policyForm.description || !policyForm.source) {
      setErrorMessage("All fields are required.");
      return;
    }

    try {
      const formData = new FormData();

      const policyUpdateBody = {
        name: policyForm.name,
        country_id: parseInt(policyForm.country_id),
        description: policyForm.description,
        source: policyForm.source,
      };

      formData.append("policy_update_create_request_body", JSON.stringify(policyUpdateBody));

      if (policyForm.photo) {
        formData.append("photo", policyForm.photo);
      }

      await createPolicyUpdate(formData);
      alert("Policy Update Creation", true)
      setPolicyForm(initialPolicyUpdateForm)
      // setSuccessMessage("Policy update added successfully!");
      closeModal();

      // Refresh policy updates after creating one
      refreshPolicyUpdates();
    } catch (err) {
      setErrorMessage(`Error adding policy update: ${err.message}`);
      console.error("Error adding policy update:", err);
    }
  };

  useEffect(() => {
    if (successMessage || errorMessage) {
      const timer = setTimeout(() => {
        setSuccessMessage("");
        setErrorMessage("");
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [successMessage, errorMessage]);

  return (
    <div className="add-update-event-button">
      <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} direction="down" className="policy-updates-dropdown">
        <DropdownToggle
          style={{
            backgroundColor: "#036F36",
            color: "#FFFDB8",
            margin: "15px 10px",
            border: "none",
            fontSize: "14px",
          }}
        >
          Add Update/Event
        </DropdownToggle>
        <DropdownMenu className="px-2">
          <DropdownItem
            className="rounded"
            onClick={() => openModal("event")}
            onMouseEnter={() => handleMouseEnter("event")}
            onMouseLeave={handleMouseLeave}
            // className="rounded"
          >
            Create Event
          </DropdownItem>
          <DropdownItem
            className="rounded"
            onClick={() => openModal("update")}
            onMouseEnter={() => handleMouseEnter("update")}
            onMouseLeave={handleMouseLeave}
            // className="rounded"
          >
            Add Update
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
      {/* Pop-up Success/Error Messages */}
      {successMessage && <div className="popup-message success">{successMessage}</div>}
      {errorMessage && <div className="popup-message error">{errorMessage}</div>}

      {/* Modal for creating an event */}
      {/* <Modal isOpen={modalOpen && modalType === "event"} toggle={closeModal}>
        <ModalHeader className="modal-header" toggle={closeModal}>
          Create Event
        </ModalHeader>
        <ModalBody className="modal-body">
          <Label for="name">Event Name</Label>
          <Input
            type="text"
            name="name"
            className="input-field"
            placeholder="Enter event name"
            value={eventForm.name}
            onChange={handleEventChange}
          />

          <Label for="venue" className="mt-3">Venue</Label>
          <Input
            type="text"
            name="venue"
            className="input-field"
            placeholder="Enter venue"
            value={eventForm.venue}
            onChange={handleEventChange}
          />

          <Label for="country_id" className="mt-3">Country</Label>
          <Input
            type="select"
            name="country_id"
            className="input-field"
            value={eventForm.country_id}
            onChange={handleCountryChange}
          >
            <option value="">Select a country</option>
            {countries.map((country) => (
              <option key={country.id} value={country.id}>{country.long_name}</option>
            ))}
          </Input>

          <Label for="start_date" className="mt-3">Start Date</Label>
          <Input
            type="date"
            name="start_date"
            className="input-field"
            value={eventForm.start_date}
            onChange={handleEventChange}
          />

          <Label for="end_date" className="mt-3">End Date</Label>
          <Input
            type="date"
            name="end_date"
            className="input-field"
            value={eventForm.end_date}
            onChange={handleEventChange}
          />

          <Label for="event_type" className="mt-3">Event Type</Label>
          <Input
            type="select"
            name="event_type"
            className="input-field"
            value={eventForm.event_type}
            onChange={handleEventTypeChange}
          >
            <option value="">Select an event type</option>
            {eventTypes.map((type) => (
              <option key={type} value={type}>{type}</option>
            ))}
          </Input>
        </ModalBody>
        <ModalFooter className="modal-footer">
          <Button color="primary" onClick={handleEventSubmit}>Create Event</Button>
          <Button color="secondary" onClick={closeModal}>Cancel</Button>
        </ModalFooter>
      </Modal> */}
      <Modal isOpen={modalOpen && modalType === "event"} toggle={closeModal} className="custom-modal">
        <ModalHeader className="modal-header" toggle={closeModal}>
          Create Event
        </ModalHeader>
        <ModalBody className="modal-body">
          <Label for="name">Event Name<span className="required">*</span></Label>
          <Input
            type="text"
            name="name"
            className="input-field"
            placeholder="Enter event name"
            value={eventForm.name}
            onChange={handleEventChange}
          />

          <Label for="venue" className="mt-3">Venue<span className="required">*</span></Label>
          <Input
            type="text"
            name="venue"
            className="input-field"
            placeholder="Enter venue"
            value={eventForm.venue}
            onChange={handleEventChange}
          />

          <Label for="country_id" className="mt-3">Country<span className="required">*</span></Label>
          <Input
            type="select"
            name="country_id"
            className="input-field"
            value={eventForm.country_id}
            onChange={handleCountryChange}
          >
            <option value="">Select a country<span className="required">*</span></option>
            {countries.map((country) => (
              <option key={country.id} value={country.id}>
                {country.long_name}
              </option>
            ))}
          </Input>

          <Label for="start_date" className="mt-3">Start Date<span className="required">*</span></Label>
          <Input
            type="date"
            name="start_date"
            className="input-field"
            value={eventForm.start_date}
            onChange={handleEventChange}
          />

          <Label for="end_date" className="mt-3">End Date<span className="required">*</span></Label>
          <Input
            type="date"
            name="end_date"
            className="input-field"
            value={eventForm.end_date}
            onChange={handleEventChange}
          />

          <Label for="event_type" className="mt-3">Event Type<span className="required">*</span></Label>
          <Input
            type="select"
            name="event_type"
            className="input-field"
            value={eventForm.event_type}
            onChange={handleEventTypeChange}
          >
            <option value="">Select an event type</option>
            {eventTypes.map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </Input>
        </ModalBody>
        <ModalFooter className="modal-footer">
          <Button
            // className="btn-primary"
            style={{
              backgroundColor: '#036F36',
              color: '#FFF605',
              borderRadius: '2.25rem',
              width: '100%',
            }}
            onClick={handleEventSubmit}>
            Create Event
          </Button>
          {/* <Button className="btn-secondary" onClick={closeModal}>
            Cancel
          </Button> */}
        </ModalFooter>
      </Modal>

      {/* Modal for adding a policy update */}
      {/* <Modal isOpen={modalOpen && modalType === "update"} toggle={closeModal}>
        <ModalHeader className="modal-header" toggle={closeModal}>
          Add Policy Update
        </ModalHeader>
        <ModalBody className="modal-body">
          <Label for="name">Update Title</Label>
          <Input
            type="text"
            name="name"
            className="input-field"
            placeholder="Enter update title"
            value={policyForm.name}
            onChange={handlePolicyChange}
          />

          <Label for="description" className="mt-3">Description</Label>
          <Input
            type="textarea"
            name="description"
            className="input-field"
            placeholder="Enter description"
            value={policyForm.description}
            onChange={handlePolicyChange}
          />

          <Label for="country_id" className="mt-3">Country</Label>
          <Input
            type="select"
            name="country_id"
            className="input-field"
            value={policyForm.country_id}
            onChange={handlePolicyChange}
          >
            <option value="">Select a country</option>
            {countries.map((country) => (
              <option key={country.id} value={country.id}>{country.long_name}</option>
            ))}
          </Input>

          <Label for="source" className="mt-3">Source</Label>
          <Input
            type="url"
            name="source"
            className="input-field"
            placeholder="Enter source URL"
            value={policyForm.source}
            onChange={handlePolicyChange}
          />

          <FormGroup className="mt-3">
            <Label for="photo">Upload Photo</Label>
            <Input
              type="file"
              name="photo"
              className="input-field"
              id="photo"
              onChange={handlePolicyChange}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter className="modal-footer">
          <Button color="primary" onClick={handlePolicySubmit}>Add Update</Button>
          <Button color="secondary" onClick={closeModal}>Cancel</Button>
        </ModalFooter>
      </Modal> */}
      <Modal isOpen={modalOpen && modalType === "update"} toggle={closeModal} className="custom-modal">
        <ModalHeader className="modal-header" toggle={closeModal}>
          Add Policy Update
        </ModalHeader>

        <div className="modal-subtext">
          <small>All fields marked with asterisks (<span className="required">*</span>) are compulsory.</small>
        </div>

        <ModalBody className="modal-body">
          <Label for="name">Update Title<span className="required">*</span></Label>
          <Input
            type="text"
            name="name"
            className="input-field"
            placeholder="Enter update title"
            value={policyForm.name}
            onChange={handlePolicyChange}
          />

          <Label for="description" className="mt-3">Description<span className="required">*</span></Label>
          <Input
            type="textarea"
            name="description"
            className="input-field"
            placeholder="Enter description"
            value={policyForm.description}
            onChange={handlePolicyChange}
          />

          <Label for="country_id" className="mt-3">Country<span className="required">*</span></Label>
          <Input
            type="select"
            name="country_id"
            className="input-field"
            value={policyForm.country_id}
            onChange={handlePolicyChange}
          >
            <option value="">Select a country<span className="required">*</span></option>
            {countries.map((country) => (
              <option key={country.id} value={country.id}>
                {country.long_name}
              </option>
            ))}
          </Input>

          <Label for="source" className="mt-3">Source<span className="required">*</span></Label>
          <Input
            type="url"
            name="source"
            className="input-field"
            placeholder="Enter source URL"
            value={policyForm.source}
            onChange={handlePolicyChange}
          />

          <FormGroup className="mt-3">
            <Label for="photo">Upload Photo</Label>
            <Input
              type="file"
              name="photo"
              className="input-field"
              id="photo"
              onChange={handlePolicyChange}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter className="modal-footer">
          <Button
            // className="btn-primary" 
            style={{
              backgroundColor: '#036F36',
              color: '#FFF605',
              borderRadius: '2.25rem',
              width: '100%',
            }}
            onClick={handlePolicySubmit}>
            Add Update
          </Button>
          {/* <Button className="btn-secondary" onClick={closeModal}>
            Cancel
          </Button> */}
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default AddUpdateOrEventButton;
