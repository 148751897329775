import React from 'react';

const PreviewField = ({ label, value }) => (
  <div className="border-b border-gray-100 py-3">
    <div className="grid grid-cols-2">
      <div className="text-gray-600">{label}</div>
      <div className="font-normal text-gray-900">{label === 'Systems' ? value?.join(', ') : (value || '-')}</div>
    </div>
  </div>
);

const PolicyPreview = ({ 
    countryOptions,
    systemOptions,
    formData, 
    policyFile,
    setCurrentStep,
    createPolicy
}) => {
  return (
    <div className="max-w-4xl mx-auto bg-white rounded-lg shadow-sm p-6 mt-5">
      <h1 className="text-2xl font-medium mb-8">Preview</h1>
      
      {/* Policy Details Section */}
      <div className="mb-8">
        <h2 className="text-lg font-medium text-green-800 mb-4">1. Policy Details</h2>
        <div className="space-y-0">
          <PreviewField 
            label="Policy name" 
            value={formData.policy_request_body.name}
          />
          <PreviewField 
            label="Country" 
            value={countryOptions.find(country => country.value == formData.policy_request_body.country_id)?.label} 
          />
          <PreviewField 
            label="Stage" 
            value={formData.policy_request_body?.stage_id} 
          />
          <PreviewField 
            label="Systems" 
            value={formData.system_ids?.map(systemID => systemOptions.find(system=>system.value == systemID.id)?.label)} 
          />
          <PreviewField 
            label="Policy on track" 
            value={formData.policy_request_body.is_off_track ? 'No' : 'Yes'} 
          />
          <PreviewField 
            label="Policy delayed" 
            value={formData.policy_request_body.is_delayed ? 'Yes' : 'No'} 
          />
        </div>
      </div>

      {/* Updates and Emerging Outcomes Section */}
      <div className="mb-8">
        <h2 className="text-lg font-medium text-green-800 mb-4">2. Updates and Emerging Outcomes</h2>
        <div className="space-y-4">
          <div className='flex items-center'>
            <div className="text-gray-600 mb-2 text-nowrap mr-1">Policy details</div>
            <div className="bg-gray-50 p-4 rounded w-100">
              {formData.policy_request_body.details}
            </div>
          </div>

          <div className='flex items-center'>
            <div className="text-gray-600 mb-2 text-nowrap mr-1">Emerging Outcomes</div>
            <div className="bg-gray-50 p-4 rounded whitespace-pre-wrap w-100">
              {formData.policy_request_body.updates_and_emerging_outcomes || '-'}
            </div>
          </div>

          <div className='flex items-center'>
            <div className="text-gray-600 mb-2 text-nowrap mr-1">Policy File</div>
            <div className="bg-gray-50 p-4 rounded w-100">
              {policyFile ? policyFile.name : '-'}
            </div>
          </div>
        </div>
      </div>

      {/* Partner Detail Section */}
      <div className="mb-8">
        <h2 className="text-lg font-medium text-green-800 mb-4">3. Partner Detail</h2>
        <div className="overflow-x-auto">
          <table className="min-w-full border-separate border-spacing-0">
            <thead>
              <tr>
                <th className="text-left py-3 text-gray-600 font-medium border-b border-gray-200 pr-4">Short name</th>
                <th className="text-left py-3 text-gray-600 font-medium border-b border-gray-200 pr-4">Long name</th>
                <th className="text-left py-3 text-gray-600 font-medium border-b border-gray-200">Technical partner</th>
              </tr>
            </thead>
            <tbody>
              {formData.partner_request_bodies.map((partner, index) => (
                <tr key={index} className="border-b border-gray-100">
                  <td className="py-3 pr-4">{partner.short_name || '-'}</td>
                  <td className="py-3 pr-4">{partner.long_name}</td>
                  <td className="py-3">{partner.is_technical_expert ? 'Yes' : 'No'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Action Buttons */}
      <div className="flex justify-end space-x-3">
        <button
          onClick={()=>setCurrentStep(1)}
          className="px-6 py-2 border rounded text-green-800 hover:bg-gray-50 flex items-center"
        >
          Edit
        </button>
        <button
          onClick={createPolicy}
          className="px-6 py-2 bg-green-100 text-green-800 rounded hover:bg-green-200"
        >
          Save Policy
        </button>
      </div>
    </div>
  );
};

export default PolicyPreview;