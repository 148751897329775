import { call, put, takeEvery } from "redux-saga/effects"
import { LIST_COUNTRIES, LIST_STAGES } from "./actionTypes"
import { listCountries, listStages } from "../../services"
import {
  listCountriesSuccess,
  listCountriesFail,
  listStagesSuccess,
  listStagesFail,
} from "./actions"

function* fetchListCountries() {
  try {
    const response = yield call(listCountries)
    yield put(listCountriesSuccess(response))
  } catch (error) {
    yield put(listCountriesFail(error))
  }
}

function* fetchListStages() {
  try {
    const response = yield call(listStages)
    yield put(listStagesSuccess(response))
  } catch (error) {
    yield put(listStagesFail(error))
  }
}

function* listSaga() {
  yield takeEvery(LIST_COUNTRIES, fetchListCountries)
  yield takeEvery(LIST_STAGES, fetchListStages)
}

export default listSaga
