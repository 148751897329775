import {
  USER_SESSION,
  USER_SESSION_SUCCESS,
  USER_SESSION_ERROR,
  RESET_PROFILE_FLAG,
  COUNTRY_FLAG,
  PROFILE_ERROR,
  PROFILE_SUCCESS,
  EDIT_PROFILE,
  COUNTRY_ID
} from "./actionTypes"

export const userSession = () => {
  return {
    type: USER_SESSION,
  }
}

export const userSessionSuccess = user => {
  return {
    type: USER_SESSION_SUCCESS,
    payload: user,
  }
}

export const userSessionError = error => {
  return {
    type: USER_SESSION_ERROR,
    payload: error,
  }
}

export const resetProfileFlag = error => {
  return {
    type: RESET_PROFILE_FLAG,
  }
}

export const setCountryFlag = country => {
  return {
    type: COUNTRY_FLAG,
    payload: country,
  }
}

export const setCountryID = country => {
  return {
    type: COUNTRY_ID,
    payload: country,
  }
}

export const editProfile = user => {
  return {
    type: EDIT_PROFILE,
    payload: { user },
  }
}

export const profileSuccess = msg => {
  return {
    type: PROFILE_SUCCESS,
    payload: msg,
  }
}

export const profileError = error => {
  return {
    type: PROFILE_ERROR,
    payload: error,
  }
}
