import '@fortawesome/fontawesome-free/css/all.min.css';
import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { Button, Input } from "reactstrap";
import Select from "react-select";
import { BiX, BiUpload, BiTrash, BiCheckCircle } from "react-icons/bi";
import "./UploadEvidence.css";
import { getLandscapingFocalAreaOptions } from "../../../common/SelectOptions/landscapingFocalAreaOptions"

// Styled Components
const SidebarContainer = styled.div`
  position: fixed;
  top: 0;
  right: ${({ isOpen }) => (isOpen ? "0" : "-100%")};
  width: 500px;
  height: 100vh;
  background-color: white;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  transition: right 0.3s ease-in-out;
  padding: 20px;
  z-index: 2000;
  overflow-y: auto;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const CloseButton = styled(BiX)`
  cursor: pointer;
  font-size: 24px;
`;

const FieldRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
`;

const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const Label = styled.label`
  font-weight: 600;
  color: #049046;
  padding-bottom: 5px;
  font-size: 14px;
`;

const Label1 = styled.label`
  font-weight: 400;
  color: #049046;
  padding-bottom: 5px;
  font-size: 16px;
`;

const FileTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 15px;
`;

const TableHeader = styled.th`
  text-align: left;
  color: #5C5C5C;
  font-size: 12px;
  font-weight: 400;
  padding: 8px;
  border-bottom: 1px solid #ddd;
`;

const TableRow = styled.tr`
  border-bottom: 1px solid #ddd;
`;

const TableData = styled.td`
  padding: 8px;
`;

const FileItemActions = styled(Button)`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 120px;
  margin-top: 10px;
  font-weight: bold;
  font-size: 12px;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
`;

function Sidebar({
  isOpen,
  onClose,
  evidenceData = [],
  onAddFile,
  onFileRemove,
  onSave,
  formType,
}) {
  const [nameInput, setNameInput] = useState(""); // Local state for either policy name or name

  // Clear inputs whenever the sidebar opens for a new question
  useEffect(() => {
    if (isOpen) {
      setNameInput("");
    }
  }, [isOpen]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      console.log("File Selected:", file);
      console.log("Policy Name Input:", nameInput);

      onAddFile({
        name: file.name,
        policyName: nameInput || "Unnamed Policy",
      });
    }
  };

  return (
    <SidebarContainer isOpen={isOpen}>
      <div className="sidebar-title-container">
        <div className="sidebar-title">
          <h3>Add Evidence</h3>
          <button className="close-icon" onClick={onClose}>
            <i className="fas fa-times-circle"></i>
          </button>
        </div>
      </div>

      <FieldContainer>
        <Label1>Please provide details below</Label1>
      </FieldContainer>

      {/* Dynamic Label based on formType */}
      <FieldContainer>
        <Label>
          {(formType === "landscaping" || formType === "benchmarking") ? "Policy Name" : "Name"}
          <span className="required">*</span>
        </Label>
        <Input
          type="text"
          value={nameInput}
          onChange={(e) => setNameInput(e.target.value)}
          placeholder={`Enter ${(formType === "landscaping" || formType === "benchmarking") ? "policy name" : "name"}`}
        />
        <div className="modal-subtext">
          <small>This is the description area</small>
        </div>
      </FieldContainer>

      {/* Upload Files section */}
      <FieldContainer>
        <Label>Upload Files<span className="required">*</span></Label>
        <input
          type="file"
          id="fileInput"
          style={{ display: "none" }}
          onChange={handleFileChange}
        />
      </FieldContainer>

      {/* Dynamic Table Headers based on formType */}
      <FileTable>
        <thead>
          <tr>
            <TableHeader>
              {(formType === "landscaping" || formType === "benchmarking") ? "Policy Name" : "Name"}
            </TableHeader>
            <TableHeader>Actions</TableHeader>
          </tr>
        </thead>
        <tbody>
          {evidenceData.map((file, index) => (
            <TableRow key={index}>
              <TableData>{file.policyName}</TableData>
              <TableData>
                <FileItemActions
                  color="danger"
                  size="sm"
                  outline
                  onClick={() => onFileRemove(index)}
                >
                  <BiTrash /> Remove
                </FileItemActions>
              </TableData>
            </TableRow>
          ))}
        </tbody>
      </FileTable>
      <StyledButton
        color="primary"
        onClick={() => document.getElementById("fileInput").click()}
      >
        <BiUpload size={20} /> Add Files
      </StyledButton>

      <ButtonGroup>
        <StyledButton color="primary" outline onClick={onClose}>
          <BiX size={20} /> Cancel
        </StyledButton>
        <StyledButton color="primary" onClick={onSave}>
          <BiCheckCircle size={20} /> Save
        </StyledButton>
      </ButtonGroup>
    </SidebarContainer>
  );
}

export default Sidebar;