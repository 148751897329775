import React from "react"
import Systems from "./Systems"

export default function SystemsManagement({ alert }) {
  document.title = "Systems Management"

  return (
    <div className="page-content">
      <Systems alert={alert} />
    </div>
  )
}
