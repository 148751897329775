import React from 'react';

function ToggleSwitch({ label, defaultChecked, onChange }) {
    return (
        <div className="flex items-center">
            <span className="mr-4">{label}</span>
            <div className="relative inline-block w-12 align-middle select-none">
                <input
                    id="toggle-switch"
                    type="checkbox"
                    defaultChecked={defaultChecked}
                    onChange={onChange}
                    className={`peer absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer 
                            transition-transform duration-200 ease-in-out translate-x-0 peer-checked:translate-x-6
                            ${defaultChecked ? 'translate-x-5' : 'translate-x-0'}`}
                    style={{
                        top: '2px',
                        left: '2px',
                        borderColor: defaultChecked ? '#22C55E' : '#D1D5DB'
                }}
                />
                <label
                    htmlFor = "toggle-switch"
                    className={`block overflow-hidden h-7 rounded-full cursor-pointer transition-colors duration-200 ease-in-out
                            ${defaultChecked ? 'bg-green-500' : 'bg-gray-200'}`}
                />
            </div>
        </div>
    );
}

export default ToggleSwitch;