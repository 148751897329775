import React, { useEffect, useMemo, useState } from "react"
import * as Tabs from "@radix-ui/react-tabs"
import styled from "@emotion/styled"
import StepTwo from "./StepTwo/Step-Two"
import StepThree from "./StepThree/Step-Three"
import StepAddOwners from "./AddOwnersTab/AddOwners"
import Questionnaire from "../Questionnaire/Questionnaire"
import "./CreateMilestone.css"
import StepOne from "./Step-One"
import {
  createBenchmarkingMilestone,
  createETrackerMilestone,
  createLandscapingFocalArea
} from "../../../services/milestoneServices"
import SuccessModal from "../../../components/Common/SuccessModal"
import ErrorModal from "../../../components/Common/ErrorModal"
import { useSelector } from "react-redux"

const TabsRoot = styled(Tabs.Root)`
    display: flex;
    flex-direction: column;
`

const TabsList = styled(Tabs.List)`
    display: flex;
    justify-content: space-between;
    background-color: white;
    padding: 10px 0;
    border-radius: 0.5rem;
    border: 1px solid #ddd;
    margin-bottom: 20px;
    width: 100%;
    border: none;
`

const TabsTrigger = styled(Tabs.Trigger)`
    all: unset;
    font-size: 1rem;
    padding: 12px 20px;
    text-align: center;
    // width: 33.33%;
    width: calc(33.33% - 10px);
    color: #6c757d;
    background-color: #f8f9fa;
    transition: all 250ms ease;
    border-radius: 0.5rem;
    margin: 0 5px;

    &[data-state="active"] {
        background-color: #198754;
        color: white;
        font-weight: bold;
    }

    &:hover {
        background-color: #47a87b;
        color: white;
    }

    &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }
`

const TabsContent = styled(Tabs.Content)`
    flex-grow: 1;
    padding: 20px;
    background-color: white;
    border-radius: 0.5rem;
    border: 1px solid #ddd;
    margin-top: 1.5rem;
    margin-left: 2rem;
    margin-right: 2rem;
`

const CreateMilestone = () => {
  if (localStorage.getItem("milestoneForm")) {
    localStorage.removeItem("milestoneForm")
  }

  if (localStorage.getItem("questions")) {
  localStorage.removeItem("questions");
}

  const formKey = "milestoneForm" // Key for localStorage
  const questionsKey = "questions" // Key for saving questions in localStorage

  const { user } = useSelector(state => state.Profile)
  const [activeTab, setActiveTab] = useState("step1")
  const [successModal, setSuccessModal] = useState("")
  const [errorModal, setErrorModal] = useState("")

  // Retrieve formType from localStorage
  const formTypeFromStorage = localStorage.getItem("formType")
  const [questions, setQuestions] = useState(() => {
    const savedQuestions = localStorage.getItem(questionsKey)
    return savedQuestions ? JSON.parse(savedQuestions) : []
  })
  const [questionnaireCreated, setQuestionnaireCreated] = useState(false)
  const [createdQuestionnaire, setCreatedQuestionnaire] = useState(null)

  // Automatically determine form type based on the active tab
  // Use useMemo to determine the final formType
  const formType = useMemo(() => {
    if (formTypeFromStorage === "ETracker") return "tracker"
    if (formTypeFromStorage === "Benchmarking") return "benchmarking"
    if (formTypeFromStorage === "Landscaping") return "landscaping"
    return null
  }, [formTypeFromStorage]) // Dependency array to watch changes to formTypeFromStorage


  const [form, setForm] = useState(() => {
    const savedData = localStorage.getItem(formKey)
    return savedData
      ? JSON.parse(savedData)
      : {
        name: "",
        type: "",
        stage_id: "",
        system_id: "",
        landscaping_category: "",
        description: ""
      }
  })

  useEffect(() => {
    localStorage.setItem(formKey, JSON.stringify(form))
  }, [form])

  useEffect(() => {
    localStorage.setItem(questionsKey, JSON.stringify(questions))
  }, [questions])

  // Handle form input changes for Step 1
  const handleInputChange = (field, value) => {
    setForm((prevForm) => ({
      ...prevForm,
      [field]: value
    }))
  }

  // Save details in Step 1
  const saveDetails = (step) => {
    console.log(`Details saved in step ${step}`, form)
    // Any additional logic you need to persist form details
  }

  // Save questions in Step 2
  const saveQuestions = (step) => {
    console.log(`Questions saved in step ${step}`, questions)
    // Questions are saved in localStorage as well using useEffect
  }

  const createQuestionnaire = (questions) => {
    const newQuestionnaire = {
      id: 1, // Placeholder ID for now
      questions: [...questions]
    }
    setCreatedQuestionnaire(newQuestionnaire)
    setQuestionnaireCreated(true)

    // Once saved, clear localStorage
    localStorage.removeItem(formKey)
    localStorage.removeItem(questionsKey)
  }

  const changeTab = (step) => {
    setActiveTab(step)
  }

  const handleSubmit = async () => {
    try {
      let body = {}

      if (formType === "tracker") {
        body = {
          e_tracker_milestone_create_request_body: {
            name: form.name,
            stage_id: parseInt(form.stage_id)
          },
          ...(user?.user_country?.length === 1 && {
          e_tracker_milestone_country_create_request_body: {
            owner_id: parseInt(form.owners.value),
            supervisor_id: parseInt(form.supervisors.value),
          },
        }),
          e_tracker_milestone_question_create_request_bodies: questions.map(
            question => ({
              question: question.question_text,
              is_required: question.required
            })
          )
        }

        const response = await createETrackerMilestone(body)

        if (response) {
          setSuccessModal("Form submitted successfully!")
          // Clear localStorage after successful submission
          localStorage.removeItem(formKey)
          localStorage.removeItem(questionsKey)
        } else {
          // Handle unexpected status codes
          setErrorModal("Something went wrong. Please try again.")
        }

      } else if (formType === "benchmarking") {
        body = {
          benchmarking_milestone_create_request_body: {
            name: form.name,
            system_id: parseInt(form.system_id)
          },
          ...(user?.user_country?.length === 1 && {
          benchmarking_milestone_country_create_request_body: {
            owner_id: parseInt(form.owners.value),
            supervisor_id: parseInt(form.supervisors.value),
          },
        }),
          benchmarking_milestone_question_create_request_bodies: questions.map(
            question => ({
              question: question.question_text,
              is_required: question.required,
              is_evidence_required: question.evidenceRequired
            })
          )
        }

        const response = await createBenchmarkingMilestone(body)

        if (response) {
          setSuccessModal("Form submitted successfully!")
          // Clear localStorage after successful submission
          localStorage.removeItem(formKey)
          localStorage.removeItem(questionsKey)
        } else {
          // Handle unexpected status codes
          setErrorModal("Something went wrong. Please try again.")
        }

      } else if (formType === "landscaping") {
        body = {
          landscaping_focal_area_create_request_body: {
            landscaping_category: form.landscaping_category,
            name: form.name,
            description: form.description
          },
          ...(user?.user_country?.length === 1 && {
          landscaping_focal_area_country_create_request_body: {
            owner_id: parseInt(form.owners.value),
            supervisor_id: parseInt(form.supervisors.value),
          },
        }),
          landscaping_focal_area_question_create_request_bodies: questions.map(
            question => ({
              question: question.question_text,
              is_required: question.required,
              is_evidence_required: question.evidenceRequired
            })
          )
        }

        const response = await createLandscapingFocalArea(body)
        if (response) {
          setSuccessModal("Form submitted successfully!")
          // Clear localStorage after successful submission
          localStorage.removeItem(formKey)
          localStorage.removeItem(questionsKey)
        } else {
          // Handle unexpected status codes
          setErrorModal("Something went wrong. Please try again.")
        }
      }
    } catch
      (e) {
      setErrorModal("Error submitting form. Please try again.")
      console.error("Error submitting form:", e)
    }
  }

  return (
    <React.Fragment>
      <ErrorModal show={errorModal} onCloseClick={() => setErrorModal("")} />
      <SuccessModal
        show={successModal}
        onCloseClick={() => setSuccessModal("")}
      />
      <div className="page-content">
        <h2 className="header-title">Create Questionnaire</h2>
        <TabsRoot defaultValue="step1" value={activeTab}>
          <TabsList>
            <TabsTrigger value="step1" onClick={() => setActiveTab("step1")}>
              1. Enter Details
            </TabsTrigger>

            {user?.user_country?.length === 1 && (
              <TabsTrigger value="stepAddOwners" onClick={() => setActiveTab("stepAddOwners")}>
                2. Add Owners
              </TabsTrigger>
            )}

            <TabsTrigger value="step2" onClick={() => setActiveTab("step2")}>
              {user?.user_country?.length === 1 ? "3. Add Questions" : "2. Add Questions"}
            </TabsTrigger>

            <TabsTrigger value="step3" onClick={() => setActiveTab("step3")}>
              {user?.user_country?.length === 1 ? "4. Preview" : "3. Preview"}
            </TabsTrigger>

            {/*<TabsTrigger value="stepAddOwners" onClick={() => setActiveTab("stepAddOwners")}>*/}
            {/*  2. Add Owners*/}
            {/*</TabsTrigger>*/}
            {/*<TabsTrigger value="step2" onClick={() => setActiveTab("step2")}>*/}
            {/*  3. Add Questions*/}
            {/*</TabsTrigger>*/}
            {/*<TabsTrigger value="step3" onClick={() => setActiveTab("step3")}>*/}
            {/*  4. Preview*/}
            {/*</TabsTrigger>*/}
          </TabsList>

          {/* Step 1: Enter Details */}
          <TabsContent value="step1">
            <StepOne
              form={form}
              formType={formType}
              handleInputChange={handleInputChange}
              changeTab={changeTab}
              save={saveDetails}
              activeTab={activeTab}
            />
          </TabsContent>

          {/* Step 2: Add Owners */}
          {/*<TabsContent value="stepAddOwners">*/}
          {/*  <StepAddOwners*/}
          {/*    form={form}*/}
          {/*    handleInputChange={handleInputChange}*/}
          {/*    changeTab={changeTab}*/}
          {/*  />*/}
          {/*</TabsContent>*/}
          {user?.user_country?.length === 1 && (
            <TabsContent value="stepAddOwners">
              <StepAddOwners
                form={form}
                handleInputChange={handleInputChange}
                changeTab={changeTab}
              />
            </TabsContent>
          )}

          {/* Step 3: Add Questions */}
          <TabsContent value="step2">
            <StepTwo
              formType={formType}
              questions={questions}
              setQuestions={setQuestions}
              save={saveQuestions} // Call saveQuestions on step change
              changeTab={changeTab}
            />
          </TabsContent>

          {/* Step 3: Preview */}
          <TabsContent value="step3">
            {questionnaireCreated ? (
              // Render the created Questionnaire once saved
              <Questionnaire getQuestionnaire={() => createdQuestionnaire} />
            ) : (
              <StepThree
                questions={questions}
                setQuestions={setQuestions}
                save={handleSubmit} // Call handleSubmit on form submission
                createQuestionnaire={createQuestionnaire}
              />
            )}
          </TabsContent>
        </TabsRoot>

        {/* Render FormsTable with links to questionnaires
        <FormsTable forms={[createdQuestionnaire]} /> */}
      </div>
    </React.Fragment>
  )
}

export default CreateMilestone
