// common/SelectOptions/landscapingFocalAreaOptions.js
import { landscapingFocalAreas } from "services/listServices";

let cachedOptions = {};

const fetchlandscapingFocalAreaOptions = async (landscapingCategory) => {
  if (!cachedOptions[landscapingCategory]) {
    try {
      const landscapingFocalAreaOptions = await landscapingFocalAreas(landscapingCategory);
      cachedOptions[landscapingCategory] = landscapingFocalAreaOptions.map((landscapingFocalArea) => ({
        label: landscapingFocalArea.name,
        value: landscapingFocalArea.id,
      }));
    } catch (error) {
      console.error("Failed to fetch landscaping focal areas:", error);
      cachedOptions[landscapingCategory] = []; // Default to an empty array on failure
    }
  }
  return cachedOptions[landscapingCategory];
};

export const getLandscapingFocalAreaOptions = fetchlandscapingFocalAreaOptions;