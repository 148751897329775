import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom"; // To get the id from the URL
import "./Questionnaire.css";

const Questionnaire = ({ getQuestionnaire }) => {
  const { id } = useParams(); // Extract the id from the URL
  const questionnaire = getQuestionnaire(id); // Fetch the questionnaire based on id

  const [answers, setAnswers] = useState({}); // Track answers to the questions
  const [progress, setProgress] = useState(0); // Track the progress percentage
  const [progressColor, setProgressColor] = useState("#ff4e4e"); // Initial red color for progress bar

  // Update progress whenever the answers change
  useEffect(() => {
    if (questionnaire?.questions?.length > 0) {
      const totalQuestions = questionnaire.questions.length;
      const answeredQuestions = Object.keys(answers).length;
      const progressPercentage = Math.floor((answeredQuestions / totalQuestions) * 100);
      setProgress(progressPercentage);

      // Set color based on progress percentage
      if (progressPercentage === 100) {
        setProgressColor("#198754"); // Green when fully done
      } else if (progressPercentage >= 75) {
        setProgressColor("#ff9900"); // Orange when almost done
      } else {
        setProgressColor("#ff4e4e"); // Red for initial progress
      }
    }
  }, [answers, questionnaire]);

  const handleAnswerChange = (index, value) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [index]: value,
    }));
  };

  return (
    <div className="questionnaire-container">
      <div className="progress-bar-container">
        <div
          className="progress-bar-fill"
          style={{ width: `${progress}%`, backgroundColor: progressColor }} 
        ></div>
      </div>

      <div className="header">
        <h2>Milestone Questionnaire</h2>
        <p>Answer the following questions for Milestone {id}</p>
      </div>

      <div className="questions-list">
        {questionnaire?.questions?.length > 0 ? (
          questionnaire.questions.map((question, index) => (
            <div className="question-card" key={index}>
              <h4 className="question-title">{index + 1}. {question.question_text}</h4>
              <div className="answer-options">
                <label className="radio-label">
                  <input
                    type="radio"
                    name={`question-${index}`}
                    value="yes"
                    checked={answers[index] === "yes"}
                    onChange={() => handleAnswerChange(index, "yes")}
                  />
                  <span className="radio-custom"></span> Yes
                </label>
                <label className="radio-label">
                  <input
                    type="radio"
                    name={`question-${index}`}
                    value="no"
                    checked={answers[index] === "no"}
                    onChange={() => handleAnswerChange(index, "no")}
                  />
                  <span className="radio-custom"></span> No
                </label>
                {/*<label className="radio-label">*/}
                {/*  <input*/}
                {/*    type="radio"*/}
                {/*    name={`question-${index}`}*/}
                {/*    value="unknown"*/}
                {/*    checked={answers[index] === "unknown"}*/}
                {/*    onChange={() => handleAnswerChange(index, "unknown")}*/}
                {/*  />*/}
                {/*  <span className="radio-custom"></span> I don't know*/}
                {/*</label>*/}
              </div>

              {/* For Benchmarking and Landscaping: Evidence file upload */}
              {question.evidenceRequired && (
                <div className="file-upload">
                  <label className="required-label">Upload Evidence:</label>
                  <input type="file" className="file-upload-input" />
                </div>
              )}
            </div>
          ))
        ) : (
          <p>No questions available for this milestone.</p>
        )}
      </div>

      <div className="buttons">
        <button className="prev-button">Previous milestone</button>
        <button className="next-button">Next milestone</button>
      </div>
    </div>
  );
};

export default Questionnaire;
