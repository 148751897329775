import React, { Fragment } from "react"
import * as Tabs from "@radix-ui/react-tabs"
import styled from "@emotion/styled"

const TabsRoot = styled(Tabs.Root)`
  display: flex;
  flex-direction: column;
`

const TabsList = styled(Tabs.List)`
  flex-shrink: 0;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
  margin-block-end: 1.5rem;
  border-bottom: 1px solid hsla(0deg 0% 0%/0.3);
`

const TabsTrigger = styled(Tabs.Trigger)`
  all: unset;
  cursor: pointer;
  padding: 0 0.8em;
  height: 45px;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  user-select: none;
  transition: all 250ms;
  background-color: transparent; /* Ensure the background is transparent by default */

  /* Rounded corners */
  border-top-left-radius: 6px;
  border-top-right-radius: 6px; /* Top right rounded corner */
  border-bottom-right-radius: 6px; /* Bottom right rounded corner */

  &:hover {
    color: green; /* Optional: Adjust hover color as needed */
  }

  &[data-state="active"] {
    background-color: #FFFDB8; /* Highlight color for the active tab */
    box-shadow: 0 0 0 0px #FFFDB8; /* Remove border shadow effect */
    color: #036F36; /* Keep the text color visible */
  }

  &:focus-visible {
    position: relative;
    box-shadow: 0 0 0 2px black;
  }
`



const TabsContent = styled(Tabs.Content)`
  flex-grow: 1;
  padding: 20px;
  background-color: white;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  outline: none;
  &:focus-visible {
    box-shadow: 0 0 0 2px black;
  }
`

const CustomTabs = ({
  triggers,
  content,
  ariaLabel,
  defaultValue = "tab1",
}) => {
  return (
    <TabsRoot defaultValue={defaultValue}>
      <TabsList aria-label={ariaLabel}>
        {triggers.map(trigger => (
          <Fragment key={trigger.value}>
            <TabsTrigger value={trigger.value}>{trigger.name}</TabsTrigger>
          </Fragment>
        ))}
      </TabsList>
      {content.map((tabContent, idx) => (
        <TabsContent key={idx} value={tabContent.value}>
          {tabContent.component}
        </TabsContent>
      ))}
    </TabsRoot>
  )
}

export default CustomTabs
