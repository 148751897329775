import React from 'react';

function NoData() {
    return (
        <div className="h-[30em] w-full flex items-center justify-center border rounded-lg bg-gray-50">
            <div className="text-center">
                <p className="text-xl text-gray-500 font-medium">No data available</p>
                <p className="text-sm text-gray-400 mt-2">Please try adjusting your filters or selection</p>
            </div>
        </div>
    );
}

export default NoData;