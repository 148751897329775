import React from "react"
import { Table, UncontrolledTooltip } from "reactstrap"
import { Link } from "react-router-dom"
import "./FormsTable.css"
import styled from "@emotion/styled"
import DeleteModal from "../../components/Common/DeleteModal"

const StyledTableContainer = styled.div`
    width: 100%;
    padding: 1em;
    overflow-x: auto; /* Allows horizontal scroll if necessary */
`

const StyledTable = styled(Table)`
    width: 100%;
    table-layout: fixed;
    background-color: transparent;
    border-radius: 16px 16px 0px 0px;
    overflow: hidden;

    & thead {
        background-color: #f8f9fa;
        // border-radius: 16px 16px 0px 0px;
    }

    // & th {
    //   padding-block: 1.5em;
    //   background-color: ##F0F0F0;
    //   text-align: left;
    //   font-size: 1rem;
    //   color: #495057;
    //   border-bottom: 2px solid #dee2e6;
    // }

    & td {
        padding: 1em;
        text-align: left;
        font-size: 14px;
        font-weight: 500;
        border: none; /* Remove all borders */
        border-bottom: 1px solid #dee2e6; /* Only keep the bottom border */
        vertical-align: middle;
        background-color: white; /* Ensure the background is white */
    }

    // & td:first-child {
    //   border-top: none; /* Ensure no top border on the first row */
    //   color: #036F36; /* Set the text color for the first column */
    // }

    & tbody {
        & tr {
            background-color: white !important; /* Ensure all rows have a white background */
            cursor: default;

            &:hover {
                background-color: #f8f9fa; /* Add a hover effect for rows */
            }

            & .description_cell {
                white-space: pre-wrap;
                word-wrap: break-word;
                text-overflow: ellipsis;
                overflow-wrap: break-word;
                max-width: 200px;
            }
        }

    }


    & tbody tr td:first-of-type {
        .name-cell {
            padding: 0.2em 0.4em;
            border-radius: 0.8em;
            background-color: rgba(80, 165, 241, 0.1);
            color: rgba(80, 165, 241);
        }
    }
`

const ETrackerBenchmarkingTable = ({
                                     forms,
                                     userRole,
                                     formType,
                                     initiateEdit,
                                     onDeleteClick,
                                     caption,
                                     adminCountryLength
                                   }) => {
  const [deleteModal, setDeleteModal] = React.useState(false)
  const [deletionObjectId, setDeletionObjectId] = React.useState()

  return (
    <StyledTableContainer>
      <StyledTable className="custom-table">
        <colgroup>
          <col style={{ width: "30%" }} />
          <col style={{ width: "20%" }} />
          <col style={{ width: "15%" }} />
          <col style={{ width: "15%" }} />
          <col style={{ width: "15%" }} />
          <col style={{ width: "15%" }} />
          <col style={{ width: "90px" }} />
        </colgroup>
        <thead className="table-header">
        <tr>
          <th scope="col">Milestone Name</th>
          <th scope="col">{formType === "ETracker" ? "Stage" : "System"}</th>
          <th scope="col">Created By</th>
          <th scope="col">Created At</th>
          <th scope="col">Updated By</th>
          <th scope="col">Updated At</th>
          <th className="actions-column">Actions</th>
        </tr>
        </thead>
        <tbody>
        {forms && forms.length > 0 ? (
          forms?.map(row => (
            <tr key={row.id}>
              <td style={{ color: "#495057" }}>{row?.name || ""}</td>
              <td>
                {formType === "ETracker" ? row?.stage.name || "" : row?.system.name || ""}
              </td>
              {/*<td>{row?.system.name || "-"}</td>*/}
              <td>{`${row.created_by?.first_name || ""} ${row.created_by?.last_name || ""}`.trim() || ""}</td>
              <td>{row?.created_at || "N/A"}</td>
              <td>{`${row.updated_by?.first_name || ""} ${row.updated_by?.last_name || ""}`.trim() || ""}</td>
              <td>{row?.updated_at || ""}</td>
              <td className="actions-column">
                {((row?.is_locked && adminCountryLength === 1) || (!row?.is_locked && adminCountryLength > 1))  ? (
                  <div className="d-flex justify-center">
                    <i className="mdi mdi-lock font-size-18 text-muted" id={`locktooltip-${row.id}`} />
                    <UncontrolledTooltip placement="top" target={`locktooltip-${row.id}`}>
                      Locked
                    </UncontrolledTooltip>
                  </div>
                ) : (
                  <div className="d-flex justify-content-between">
                    {/* Edit button with Link and initiateEdit */}
                    <Link
                      to={{
                        pathname: "/edit-form",
                        state: {
                          userRole: userRole
                        }
                      }}
                      className="text-success"
                      onClick={() => initiateEdit(row.id)}
                      //   (e) => {
                      //   // e.preventDefault() // prevent default navigation if needed
                      //   initiateEdit(system.id) // call your edit function
                      // }}
                      style={{ cursor: "pointer" }}
                    >
                      <i className="mdi mdi-pencil font-size-18" id={`edittooltip-${row.id}`} />
                      <UncontrolledTooltip placement="top" target={`edittooltip-${row.id}`}>
                        Edit
                      </UncontrolledTooltip>
                    </Link>

                    {/* Delete button with tooltip */}
                    <p
                      style={{ cursor: "pointer" }}
                      className="text-danger m-0"
                      onClick={() => {
                        setDeleteModal({ show: true })
                        setDeletionObjectId(row.id)
                      }}
                    >
                      <i className="mdi mdi-delete font-size-18" id={`deletetooltip-${row.id}`} />
                      <UncontrolledTooltip placement="top" target={`deletetooltip-${row.id}`}>
                        Delete
                      </UncontrolledTooltip>
                    </p>
                  </div>
                )
                }

              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="7"> No data available</td>
          </tr>
        )
        }
        </tbody>
        <DeleteModal
          show={deleteModal.show}
          onCloseClick={() => setDeleteModal(false)}
          onDeleteClick={() => onDeleteClick(deletionObjectId)}
          item={caption}
        />
      </StyledTable>
    </StyledTableContainer>
  )
}


const LandscapingTable = ({
                            forms,
                            userRole,
                            initiateEdit,
                            onDeleteClick,
                            caption,
                            adminCountryLength,
                          }) => {
  const [deleteModal, setDeleteModal] = React.useState(false)
  const [deletionObjectId, setDeletionObjectId] = React.useState()

  return (
    <StyledTableContainer>
      <StyledTable className="custom-table">
        <colgroup>
          <col style={{ width: "30%" }} />
          <col style={{ width: "20%" }} />
          <col style={{ width: "35%" }} />
          <col style={{ width: "20%" }} />
          <col style={{ width: "20%" }} />
          <col style={{ width: "20%" }} />
          <col style={{ width: "20%" }} />
          <col style={{ width: "90px" }} />
        </colgroup>

        <thead className="table-header">
        <tr>
          <th scope="col">Focal Area</th>
          <th scope="col"> Landscaping Category</th>
          <th scope="col"> Description</th>
          <th scope="col">Created By</th>
          <th scope="col">Created At</th>
          <th scope="col">Updated By</th>
          <th scope="col">Updated At</th>
          <th className="actions-column">Actions</th>
        </tr>
        </thead>

        <tbody>
        {forms && forms.length > 0 ? (
          forms.map((row) => (
            <tr key={row.id}>
              <td style={{ color: "#495057" }}>{row?.name || ""}</td>
              <td>{row?.landscaping_category || ""}</td>
              <td>{row?.description || ""}</td>
              <td>{`${row.created_by?.first_name || ""} ${row.created_by?.last_name || ""}`.trim() || ""}</td>
              <td>{row?.created_at || "N/A"}</td>
              <td>{`${row.updated_by?.first_name || ""} ${row.updated_by?.last_name || ""}`.trim() || ""}</td>
              <td>{row?.updated_at || ""}</td>
              <td className="actions-column">
                {((row?.is_locked && adminCountryLength === 1) || (!row?.is_locked && adminCountryLength > 1)) ? (
                  <div className="d-flex justify-content-center">
                    <i className="mdi mdi-lock font-size-18 text-muted" id={`locktooltip-${row.id}`} />
                    <UncontrolledTooltip placement="top" target={`locktooltip-${row.id}`}>
                      Locked
                    </UncontrolledTooltip>
                  </div>
                ):
                (<div className="d-flex justify-content-between">
                  {/* Edit button with Link and initiateEdit */}
                  <Link
                    to={{
                      pathname: "/edit-form",
                      state: {
                        userRole: userRole
                      }
                    }}
                    className="text-success"
                    onClick={() => initiateEdit(row.id)}
                    // (e) => {
                    //   // e.preventDefault() // prevent default navigation if needed
                    //   initiateEdit(system.id) // call your edit function
                    // }}
                    style={{ cursor: "pointer" }}
                  >
                    <i className="mdi mdi-pencil font-size-18" id={`edittooltip-${row.id}`} />
                    <UncontrolledTooltip placement="top" target={`edittooltip-${row.id}`}>
                      Edit
                    </UncontrolledTooltip>
                  </Link>

                  {/* Delete button with tooltip */}
                  <p
                    style={{ cursor: "pointer" }}
                    className="text-danger m-0"
                    onClick={() => {
                      setDeleteModal({ show: true })
                      setDeletionObjectId(row.id)
                    }}
                  >
                    <i className="mdi mdi-delete font-size-18" id={`deletetooltip-${row.id}`} />
                    <UncontrolledTooltip placement="top" target={`deletetooltip-${row.id}`}>
                      Delete
                    </UncontrolledTooltip>
                  </p>
                </div>
                )}
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="7">No data available</td>
          </tr>
        )}
        </tbody>
        <DeleteModal
          show={deleteModal}
          onCloseClick={() => setDeleteModal(false)}
          onDeleteClick={() => onDeleteClick(deletionObjectId)}
          item={caption}
        />
      </StyledTable>
    </StyledTableContainer>
  )
}


const FormsTable = ({ formType, caption, forms, userRole, initiateEdit, onDeleteClick, adminCountryLength }) => {
  const renderTable = () => {
    switch (formType) {
      case "ETracker":
        return <ETrackerBenchmarkingTable
          forms={forms}
          userRole={userRole}
          formType={formType}
          initiateEdit={initiateEdit}
          onDeleteClick={onDeleteClick}
          caption={caption}
          adminCountryLength={adminCountryLength}
        />
      case "Benchmarking":
        return <ETrackerBenchmarkingTable
          forms={forms}
          userRole={userRole}
          formType={formType}
          initiateEdit={initiateEdit}
          onDeleteClick={onDeleteClick}
           caption={caption}
          adminCountryLength={adminCountryLength}
        />
      case "Landscaping":
        return <LandscapingTable
          forms={forms}
          userRole={userRole}
          initiateEdit={initiateEdit}
          onDeleteClick={onDeleteClick}
          caption={caption}
          adminCountryLength={adminCountryLength}
        />
      default:
        return <p>No table available for this form type</p>
    }
  }

  return <div>{renderTable()}</div>
}

export default FormsTable