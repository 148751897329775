import React, { useState } from "react"
import { FormGroup, Input, Label } from "reactstrap"
import "./sidebar.css"
import { FiArrowRight } from "react-icons/fi"
import { changeMeProfile } from "../../../services/userProfileService"


const EditProfileSidebar = ({ alert, userProfile, isOpen, toggleSidebar, updateUserProfile }) => {

  const initialUserUpdateForm = {
    first_name: userProfile.first_name,
    last_name: userProfile.last_name,
    photo: userProfile.photo
  }

  const [userProfileForm, setUserProfileForm] = useState(initialUserUpdateForm)
  const [loading, setLoading] = useState(false)

  const handleProfilePictureChange = (e) => {
    if (e.target.name === "photo") {
      setUserProfileForm({ ...userProfileForm, photo: e.target.files[0] })
    } else {
      setUserProfileForm({ ...userProfileForm, [e.target.name]: e.target.value })
    }
  }

  const handleUpdateProfile = async () => {

    setLoading(true)

    try {
      const formData = new FormData()

      const userUpdateBody = {
        first_name: userProfileForm.first_name,
        last_name: userProfileForm.last_name
      }

      formData.append("user_update_me_request_body", JSON.stringify(userUpdateBody))

      const photo = userProfileForm.photo
      if (photo && photo instanceof File) {
        formData.append("photo", photo)
      }

      const updatedUser = await changeMeProfile(formData)
      alert("User Profile Updation", true)

      updateUserProfile({
        first_name: updatedUser.first_name,
        last_name: updatedUser.last_name,
        photo: updatedUser.photo,
      });

      setUserProfileForm({
        first_name: updatedUser.first_name,
        last_name: updatedUser.last_name,
        photo: updatedUser.photo
      })

    } catch (err) {
      alert("User Profile Updation", false)
      console.error("Error updating profile:", err)
    } finally {
      setLoading(false); // Ensure loading state is reset
    }
  }

  return (
    <div className={`sidebar ${isOpen ? "open" : ""}`}>
      <div className="sidebar-title-container">
        <div className="sidebar-title">
          <h3>Change Profile</h3>
          <button className="close-icon" onClick={toggleSidebar}>
            <i className="fas fa-times-circle"></i>
          </button>
        </div>
      </div>
      <div className="sidebar-body">
        {/* First Name Input */}
        <div className="form-group">
          <label htmlFor="firstName" className="label">First Name <span
            className="required">*</span></label>
          <div className="password-wrapper">
            <Input
              type="text"
              id="userFirstName"
              value={userProfileForm.first_name}
              onChange={(e) => setUserProfileForm({ ...userProfileForm, first_name: e.target.value })}
              placeholder="Enter first name"
              className="input-field"
            />
          </div>
        </div>

        {/* Last Name Input */}
        <div className="form-group">
          <label htmlFor="lastName" className="label">Last Name <span className="required">*</span></label>
          <div className="password-wrapper">
            <Input
              type={"text"}
              id="userLastName"
              value={userProfileForm.last_name}
              onChange={(e) => setUserProfileForm({ ...userProfileForm, last_name: e.target.value })}
              placeholder="Enter last name"
              className="input-field"
            />
          </div>
        </div>

        {/*Profile picture*/}
        <FormGroup className="mt-3">
          <Label for="photo">Upload Photo</Label>
          <Input
            type="file"
            name="photo"
            className="input-field"
            id="photo"
            onChange={handleProfilePictureChange}
          />
        </FormGroup>

        <div className="d-grid">
          <button
            className="btn btn-primary btn-block rounded-[2.25rem] text-[#FFF605] flex flex-row items-center justify-center"
            onClick={handleUpdateProfile}
            disabled={loading}
          >
            Update Profile
            <div className="rounded-full flex justify-center items-center w-4 h-4 bg-[#EAE200] ml-1">
              <FiArrowRight color="#036F36" />
            </div>
          </button>
        </div>
      </div>
    </div>
  )
}


export default EditProfileSidebar
