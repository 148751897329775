import { takeEvery, fork, put, all, call } from "redux-saga/effects"

//Account Redux states
import { REGISTER_USER } from "./actionTypes"
import { registerUserSuccessful, registerUserFailed } from "./actions"

import { postRegister } from "../../../services/authServices"

// Is user register successfull then direct plot user in redux.
export function* registerUser({ payload: { user } }) {
  try {
    const response = yield call(postRegister, user)
    localStorage.setItem("token", response.data.access_token)
    yield put(setAccessToken(response.data.access_token))
    yield put(registerUserSuccessful(response.data))
    // yield put(loginSuccess(response))
    yield put(userSession())
    yield put(listCountries())
    yield put(listStages())
    history.push("/dashboard")
  } catch (error) {
    yield put(registerUserFailed(error))
  }
}

export function* watchUserRegister() {
  yield takeEvery(REGISTER_USER, registerUser)
}

function* accountSaga() {
  yield all([fork(watchUserRegister)])
}

export default accountSaga
