import React from "react";
import Partners from "./Partners";
import { Table } from "reactstrap";
import styled from "@emotion/styled";
import "./Partners.css";
import LongNameDropdown from "./PartnerLongName";
import DeleteModal from "../../components/Common/DeleteModal"

const StyledTable = styled(Table)`
   width: 100%;
  table-layout: fixed;
  background-color: transparent;
  border-radius: 16px 16px 0px 0px;
  overflow: hidden;

  & thead {
    background-color: #f8f9fa;
  }

  // & th {
  //   // padding-block: 1.5em;
  //   // background-color: ##F0F0F0;
  //   text-align: left;
  //   font-size: 1rem;
  //   color: #495057;
  //   border-bottom: 2px solid #dee2e6;
  // }

  & td {
    padding: 1em;
    text-align: left;
    font-size: 14px;
    font-weight: 500;
    border: none; /* Remove all borders */
    border-bottom: 1px solid #dee2e6; /* Only keep the bottom border */
    vertical-align: middle;
    background-color: white; /* Ensure the background is white */
  }

  // & td:first-child {
  //   border-top: none; /* Ensure no top border on the first row */
  //   color: #036F36; /* Set the text color for the first column */
  // }

  & tbody {
    & tr {
      background-color: white !important; /* Ensure all rows have a white background */
      cursor: default;

      &:hover {
        background-color: #f8f9fa; /* Add a hover effect for rows */
      }
    }

  }

  & tbody tr td:first-of-type {
    .name-cell {
      padding: 0.2em 0.4em;
      border-radius: 0.8em;
      background-color: rgba(80, 165, 241, 0.1);
      color: rgba(80, 165, 241);
    }
  }
`;


const StyledTableContainer = styled.div`
  width: 100%;
  padding: 1em;
  overflow-x: auto; /* Allows horizontal scroll if necessary */
`;

const StyledButtonGroup = styled.div`
  display: flex;
  gap: 1em;
  border-collapse: separate;

  & button {
    all: unset;
    cursor: pointer;
  }

  & .mdi-pencil {
    color: #4caf50; /* Green for edit */
    transition: transform 0.2s;
  }

  & .mdi-delete {
    color: #f44336; /* Red for delete */
    transition: transform 0.2s;
  }

  & button:hover .mdi-pencil,
  & button:hover .mdi-delete {
    transform: scale(1.1);
  }
`;

export const CustomTable = ({ data, caption, initiateEdit, onDeleteClick }) => {
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [deletionObjectId, setDeletionObjectId] = React.useState();

  return (
    <StyledTableContainer>
      <StyledTable className="custom-table">
        <colgroup>
          <col style={{ width: "18%" }} />
          <col style={{ width: "25%" }} />
          <col style={{ width: "20%" }} />
          <col style={{ width: "30%" }} />
          <col style={{ width: "25%" }} />
          <col style={{ width: "25%" }} />
          <col style={{ width: "25%" }} />
          <col style={{ width: "25%" }} />
          <col style={{ width: "20%" }} />
        </colgroup>
        <thead>
          <tr>
            <th>Name</th>
            <th>Short Name</th>
            <th>Country</th>
            <th>Technical Expert</th>
            <th scope="col">Created By</th>
            <th scope="col">Created At</th>
            <th scope="col">Updated By</th>
            <th scope="col">Updated At</th>
            <th >Actions</th>
          </tr>
        </thead>
        <tbody>
          {data?.map(partnerMgt => (
            <tr key={partnerMgt.id}>
              <td className="first-column">
                <LongNameDropdown name={partnerMgt.long_name} />
              </td>
              <td style={{textAlign: "center"}}>{partnerMgt.short_name}</td>
              <td>
                <ol
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    listStyle: "none",
                    gap: ".5em",
                    padding: 0,
                  }}
                >
                  {partnerMgt.country?.map(country => (
                    <li
                      key={country.country_id}
                      style={{
                        backgroundColor: "#FEDCDC",
                        // color: "white",
                        alignContent: "center",
                        padding: ".2em .4em",
                        borderRadius: ".8em",
                      }}
                    >
                      {country.country.long_name}
                    </li>
                  ))}
                </ol>
              </td>
              <td style={{textAlign: "center"}}>{partnerMgt.is_technical_expert ? "Yes" : "No"}</td>
              <td style={{textAlign: "center", fontWeight: "400"}}>{`${partnerMgt.created_by?.first_name || ""} ${partnerMgt.created_by?.last_name || ""}`.trim() || ""}</td>
              <td>{partnerMgt.created_at}</td>
              <td>{`${partnerMgt.updated_by?.first_name || ""} ${partnerMgt.updated_by?.last_name || ""}`.trim() || ""}</td>
              <td>{partnerMgt.updated_at}</td>
              <td>
                <StyledButtonGroup>
                  <button onClick={() => initiateEdit(partnerMgt.id)}>
                    <i className="mdi mdi-pencil font-size-18 hover-event text-success" />
                  </button>
                  <button
                    onClick={() => {
                      setDeleteModal(true);
                      setDeletionObjectId(partnerMgt.id);
                    }}
                  >
                    <i className="mdi mdi-delete font-size-18 del-icon text-danger" />
                  </button>
                </StyledButtonGroup>
              </td>
            </tr>
          ))}
        </tbody>
        <DeleteModal
          show={deleteModal}
          onCloseClick={() => setDeleteModal(false)}
          onDeleteClick={() => onDeleteClick(deletionObjectId)}
          item={caption}
        />
      </StyledTable>
    </StyledTableContainer>

  );
};

export default function PartnerManagement( {alert}) {
  
  document.title = "Partners Management";

  return (
    <div className="page-content">
      <Partners alert={alert} />
    </div>
  );
}
