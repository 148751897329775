import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu"
import logo from "../../assets/images/agra_logo2_cropped.png"
import logoLightSvg from "../../assets/images/collapsed.png"
import { useSelector } from "react-redux"
import { withTranslation } from "react-i18next"
import {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
} from "../../store/actions"
import StagesExplainer from "components/Common/Explainers/StagesExplainer";

const Header = props => {

  const { country } = useSelector(state => state.Profile)
  const { countries } = useSelector(state => state.list)
  const [countryName, setCountryName] = useState("")

  useEffect(() => {
    if (country == localStorage.getItem("COUNTRY") && props.title == "Home") {
      let country_name = countries
        .filter(c => c.title == country)
        .map(cn => cn.label)
      setCountryName(country_name[0] ? "- " + country_name[0] : "")
    }
  }, [countries, country])

  // function toggleFullscreen() {
  //   if (
  //     !document.fullscreenElement &&
  //     /* alternative standard method */ !document.mozFullScreenElement &&
  //     !document.webkitFullscreenElement
  //   ) {
  //     // current working methods
  //     if (document.documentElement.requestFullscreen) {
  //       document.documentElement.requestFullscreen()
  //     } else if (document.documentElement.mozRequestFullScreen) {
  //       document.documentElement.mozRequestFullScreen()
  //     } else if (document.documentElement.webkitRequestFullscreen) {
  //       document.documentElement.webkitRequestFullscreen(
  //         Element.ALLOW_KEYBOARD_INPUT
  //       )
  //     }
  //   } else {
  //     if (document.cancelFullScreen) {
  //       document.cancelFullScreen()
  //     } else if (document.mozCancelFullScreen) {
  //       document.mozCancelFullScreen()
  //     } else if (document.webkitCancelFullScreen) {
  //       document.webkitCancelFullScreen()
  //     }
  //   }
  // }
  
  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex align-items-center">
            <div className="navbar-brand-box d-lg-none d-md-block">
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logo} alt="" height="22" />
                </span>
              </Link>

              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img src={logoLightSvg} alt="" height="22" />
                </span>
              </Link>
            </div>

            {props.iconClass && (
                  <span className="me-2">
                    {typeof props.iconClass === "string" ? (
                      <i className={`bx ${props.iconClass}`} />
                    ) : (
                      props.iconClass // Render Iconify JSX icon
                    )}
                  </span>
                )}

            <div className="app-search page-title-box d-sm-flex align-items-center items-center gap-2 justify-content-between">
              <h4 className="mb-0 text-muted mt-2">
                {/* <i className={`bx ${props.iconClass}`}></i>{" "} */}
                {props.title.toUpperCase()} {countryName}
              </h4>
              <StagesExplainer stage={"all"} />
            </div>
          </div>
          <ProfileMenu />
        </div>
      </header>
    </React.Fragment>
  )
}

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
}

const mapStatetoProps = state => {
  const { layoutType, showRightSidebar, leftMenu, leftSideBarType } =
    state.Layout
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType }
}

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
})(withTranslation()(Header))
