import React, { useEffect, useState } from "react"
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap"
import countriesFlag from "common/countriesFlag"
import { listCountries } from "services"

const CountriesDropdown = ({ countryID, setCountryID, hasRAC = false, setCountry=()=>{}, setDataLoading }) => {
  const [countries, setCountries] = useState([]);
  const [menu, setMenu] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(()=>{
    const fetchCountries = async () => {
      try{
        setIsLoading(true);
        const result = await listCountries();
        if (result?.length) {
          if(result.length > 1){
            setCountries(hasRAC 
              ? [{ short_name: "all", long_name: "Africa", id: null }, ...result]
              : [{ short_name: "all", long_name: "Africa", id: null }, ...result.slice(0, -1)]);
          } else { //result.length === 1
            setCountries(result);
            setSelectedCountry(result[0]);
            setCountryID(result[0]?.id);
            setCountry(result[0]); //remove later
          }
        } 
      }catch(error){
        console.error("Failed to fetch countries:", error);
      }finally{
        setIsLoading(false);
      }
    }
    fetchCountries()
  },[])

  useEffect(()=>{
    //set selected country based on parent component's countryID
    if(countries?.length > 1){
      if(countryID){
        setSelectedCountry(countries?.find((country)=>country.id === countryID))
        //if countryID not found?
      }else{
        setSelectedCountry({ short_name: "all", long_name: "Africa", id: null });
      }
    }
  }, [countries, countryID])

  const changeCountryAction = country => {
    if (setDataLoading) setDataLoading(true);
    setSelectedCountry(country);
    setCountryID(country.id);
    setCountry(country);
    toggle();
  }

  const toggle = () => {
    setMenu(!menu)
  }

  return (
    <>
      {countries.length === 1 ? <div className="d-flex align-items-center p-2 rounded bg-white">
        <img
            src={countriesFlag[countries[0].short_name]}
            alt={countries[0].short_name}
            height={16}
            width={16}
            className="me-2"
          />
          {countries[0].long_name} 
      </div> :
      <Dropdown isOpen={menu} toggle={toggle} className="d-inline-block">
        <DropdownToggle className="d-flex align-items-center btn bg-white" tag="button">
          <img
            src={countriesFlag[selectedCountry?.short_name]}
            alt={selectedCountry?.short_name}
            height={16}
            width={16}
            className="mx-2"
          />
          {isLoading ? 'Loading...' : selectedCountry?.long_name}
          <i className="mdi mdi-chevron-down mx-2" />
        </DropdownToggle>
        <DropdownMenu>
          {countries?.map((country, i) => (
            <DropdownItem
              key={i}
              onClick={() => changeCountryAction(country)}
              className={`d-flex align-items-center ${
                selectedCountry?.short_name === country?.short_name ? "bg-slate-100 justify-content-between" : ""
              }`}
            >
              <img
                src={countriesFlag[country?.short_name]}
                alt={country?.short_name}
                className="me-2"
                height={16}
                width={16}
              />
              <span>{country?.long_name}</span>
              { selectedCountry?.short_name === country?.short_name && <i className="mdi mdi-check ms-auto" />}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
      }
    </>
  )
}

export default CountriesDropdown
