import { get } from "./AXIOS"
import {
  ALL_LABELS, ALL_PUBLIC_SYSTEMS, ALL_SYSTEMS,
  LABEL_SPECIFIC_COUNTRIES,
  LIST_COUNTRIES,
  LIST_LABELS_FOR_BENCHMARKING,
  LIST_LANDSCAPING_FOCAL_AREAS,
  LIST_PUBLIC_COUNTRIES,
  LIST_STAGES,LIST_PUBLIC_STAGES
} from "./CONSTANTS"

// list Countries
export const listCountries = () => {
  return get(localStorage.getItem('token') ? LIST_COUNTRIES : LIST_PUBLIC_COUNTRIES)
}

// list Stages
export const listStages = () => get(localStorage.getItem('token') ? LIST_STAGES : LIST_PUBLIC_STAGES)

// list systems
export const listAllSystems = () => get(localStorage.getItem('token') ? ALL_SYSTEMS : ALL_PUBLIC_SYSTEMS)

export const listAllPublicSystems = () => get(ALL_PUBLIC_SYSTEMS)

//list systems for benchmarking form creation

export const listSystems = () => get(LIST_LABELS_FOR_BENCHMARKING)

//list countries to which the user selected system is mapped to

export const listSystemSpecificCountries = id =>
  get(`${LABEL_SPECIFIC_COUNTRIES}/${id}`)

// export const listAllSystems = () => get(ALL_LABELS)

export const landscapingFocalAreas = (landscapingCategory) => get(LIST_LANDSCAPING_FOCAL_AREAS(landscapingCategory));