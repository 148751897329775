import React, { useState, useEffect, useMemo } from 'react';
import { Icon } from '@iconify/react';
import { getPartners } from 'services/partnerServices';

const PartnerSelection = ({ countryID, setFormData, selectedPartners, onSelect }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [partners, setPartners] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const selectedPartnersMap = useMemo(() => {
    return new Map(selectedPartners?.map(partner => [partner.id, true]));
  }, [selectedPartners]);

  const isPartnerSelected = (partnerId) => {
    return selectedPartnersMap.has(partnerId);
  };

  const fetchPartners = async () => {
    if (!countryID) return;
    
    setIsLoading(true);
    setError(null);
    
    try {
      const { data } = await getPartners({
        locations: countryID,
        searchField: searchTerm
      });
      setPartners(data);
    } catch (err) {
      setError('Failed to load partners. Please try again.');
      console.error('Error fetching partners:', err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetchPartners();
    }, 300); // Debounce search by 300ms

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm, countryID]);

  const handlePartnerSelect = (partner) => {
    setFormData(prev => ({
      ...prev,
      partner_request_bodies: [
        ...prev.partner_request_bodies,
        {
          short_name: partner.short_name || '',
          long_name: partner.long_name || '',
          is_technical_expert: partner.is_technical_expert || false,
          id: partner.id
        }
      ]
    }));
    
    // Clear search and validation states
    setSearchTerm('');
    onSelect?.(); // Trigger parent component validation reset
  };

  return (
    <div className="relative w-full">
      {/* Main dropdown button */}
      <button
        onClick={() => setIsOpen(!isOpen)}
        className={'w-full p-2 text-left rounded border flex items-center justify-between bg-gray-100 cursor-pointer hover:bg-gray-200'}
      >
        <span className={'text-gray-600'}>
          Select option
        </span>
        <Icon 
          icon={isOpen ? "mdi:chevron-up" : "mdi:chevron-down"} 
          className="w-5 h-5 text-gray-500"
        />
      </button>

      {/* Dropdown panel */}
      {isOpen && (
        <div className="absolute z-10 w-full mt-1 bg-white border rounded-md shadow-lg max-h-96 overflow-hidden">
          {/* Search field at top */}
          <div className="sticky top-0 bg-white border-b p-2">
            <div className="relative">
              <input
                type="text"
                placeholder="Search partners"
                value={searchTerm}
                onChange={(e)=>setSearchTerm(e.target.value)}
                className={'w-full p-2 border rounded border-gray-300'}
                aria-label="Search partners"
              />
              <Icon 
                icon="material-symbols:search" 
                className="absolute right-2 top-2.5 h-5 w-5 text-gray-400"
              />
            </div>
          </div>

          {/* Partners list */}
          <div className="overflow-auto max-h-72">
            {isLoading ? (
              <div className="p-3 flex justify-center">
                <Icon 
                  icon="line-md:loading-loop" 
                  className="h-5 w-5 text-gray-400"
                />
              </div>
            ) : error ? (
              <div className="p-3 text-red-500 text-sm flex items-center">
                <Icon icon="mdi:alert" className="w-4 h-4 mr-1" />
                {error}
              </div>
            ) : partners.length === 0 ? (
              <div className="p-3 text-gray-500 text-sm text-center">
                {searchTerm ? 'No partners found matching your search' : 'No partners available'}
              </div>
            ) : (
              partners.map(partner => (
                <button
                  key={partner.id}
                  onClick={() => {
                    handlePartnerSelect(partner);
                    setIsOpen(false);
                  }}
                  disabled={isPartnerSelected(partner.id)}
                  className={`w-full text-left px-4 py-2 flex items-center justify-between
                    ${isPartnerSelected(partner.id) ? 'bg-gray-50 cursor-not-allowed' : 'hover:bg-gray-100'}
                  `}
                  aria-label={`Select partner ${partner.short_name}`}
                >
                  <div className="flex items-center space-x-3">
                    <div className="w-5">
                      {isPartnerSelected(partner.id) && (
                        <Icon 
                          icon="mdi:check" 
                          className="w-5 h-5 text-green-600"
                        />
                      )}
                    </div>
                    <div>
                      <div className="font-medium">{partner.short_name}</div>
                      {partner.long_name && (
                        <div className="text-sm text-gray-500">{partner.long_name}</div>
                      )}
                    </div>
                  </div>
                  {partner.is_technical_expert && (
                    <span className="ml-2 inline-block px-2 py-1 bg-green-100 text-green-800 text-xs rounded">
                      Technical
                    </span>
                  )}
                </button>
              ))
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default PartnerSelection;