import React from 'react';
import './count-display.css';
import { Icon } from '@iconify/react';

const CountDisplay = ({ icon, title, count }) => {
  return (
    <div className="count-display">
      <div className="count-info">
        <div className="icon-container">
          {
            icon === "milestone" ? 
              <Icon icon="mdi:milestone" />
              :
              <Icon icon="mdi-light:file-multiple" />
          }
        </div>
        <p className="title">{title}</p>
      </div>
      <div className="divider" />
      <span className="count">{count || 0}</span>
    </div>
  );
};

export default CountDisplay;