import { get, post, postFormData, put, del, putFormData } from "./AXIOS"
import {
  GET_COUNTRY_EVENTS,
  GET_COUNTRY_EVENTS_PUBLIC,
  GET_POLICY_UPDATES,
  GET_POLICY_UPDATES_PUBLIC,
  CREATE_EVENT,
  CREATE_POLICY_UPDATE,
  GET_COUNTRIES,
  GET_POLICY_UPDATE_PICTURE,
  USER_PROFILE_PHOTO,
  EDIT_POLICY_UPDATE,
  EDIT_COUNTRY_EVENT,
  DELETE_COUNTRY_EVENT,
  DELETE_UPDATE, EDIT_PARTNER
} from "./CONSTANTS"

// Get country events
export const getCountryEvents = (query) =>
  get(`${query.usePublic?GET_COUNTRY_EVENTS_PUBLIC:GET_COUNTRY_EVENTS}?limit=${query.limit}&skip=${query.offset}${query.countryId?`&country_id=${query.countryId}`:``}`);

// Get countries
export const getCountries = () => get(GET_COUNTRIES);

// Get policy updates
export const getPolicyUpdates = (query) => {
  // Start with the base URL including limit and offset
  let url = `${query.usePublic?GET_POLICY_UPDATES_PUBLIC:GET_POLICY_UPDATES}?limit=${query.limit}&skip=${query.offset}${query.countryId?`&country_id=${query.countryId}`:``}`;

  // Add the 'name' parameter only if searchTerm is provided
  if (query.searchTerm) {
    url += `&name=${query.searchTerm}`;
  }

  return get(url);
};

export const getPolicyUpdatePicture = (policy_update_id) =>
  get(GET_POLICY_UPDATE_PICTURE(policy_update_id), { responseType: 'blob' });

export const getPolicyUpdateUserPicture = (user_id) =>
  get(USER_PROFILE_PHOTO(user_id), { responseType: 'blob' });

// Create an event (JSON)
export const createEvent = async (eventData) => {
  try {
    const response = await post(CREATE_EVENT, eventData, {
      "headers": {
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    throw new Error('Error creating event: ' + error.message);
  }
};


export const createPolicyUpdate = formData => postFormData(
  `${CREATE_POLICY_UPDATE}`, formData, { "headers": { "accept": "application/json" } }
)

// Create a policy update (multipart/form-data)
// export const createPolicyUpdate = async (formData) => {
//   try {
//     const response = await postFormData(CREATE_POLICY_UPDATE, formData);
//     return response.data;
//   } catch (error) {
//     // Log the full error response for better debugging
//     if (error.response && error.response.data) {
//       console.error("Error response data:", error.response.data);
//     }
//     throw new Error("Error creating policy update: " + error.message);
//   }
// };

// Edit an existing policy update (PUT request)
// Edit an existing policy update (PUT request)

export const editPolicyUpdateForm = (updateId, formData) =>
  putFormData(EDIT_POLICY_UPDATE(updateId), formData, { "headers": { "accept": "application/json" }})


// export const editPolicyUpdateForm = async (updateId, updateData) => {
//   try {
//     // Replace {policy_update_id} with the actual updateId in the URL
//     const url = EDIT_POLICY_UPDATE(updateId);
//
//     const formData = new FormData();
//
//     // Create a policy_update_edit_request_body
//     const policyUpdateBody = {
//       name: updateData.name,
//       // country_id: parseInt(updateData.country_id), // Ensure country_id is a number
//       description: updateData.description,
//       source: updateData.source,
//     };
//
//     // Append JSON body to the form data
//     formData.append("policy_update_update_request_body", JSON.stringify(policyUpdateBody));
//
//     // If a new photo is provided, append it to the form data
//     console.log("Photo", photo);
//     if (photo && photo instanceof File) { // Check if it's a File object
//       formData.append("photo", photo);
//     }
//
//     // Send the PUT request with the formData
//     const response = await putFormData(url, formData, {
//         "headers": { "accept": "application/json" }
//       }
//     );
//
//     return response.data;
//   } catch (error) {
//     console.error("Error editing policy update:", error.response ? error.response.data : error.message);
//     throw new Error('Error editing policy update: ' + (error.response ? JSON.stringify(error.response.data) : error.message));
//   }
// };


// Edit an existing country event (PUT request)
export const editCountryEvent = async (eventId, eventData) => {
  try {
    // Replace {country_event_id} with the actual eventId in the URL
    const url = EDIT_COUNTRY_EVENT.replace("{country_event_id}", eventId);

    // Ensure the data is sent as JSON
    const response = await put(url, eventData, {
      headers: {
        'Content-Type': 'application/json',  // Ensure JSON format
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error editing event:", error.response ? error.response.data : error.message);
    throw new Error('Error editing event: ' + (error.response ? JSON.stringify(error.response.data) : error.message));
  }
};

// (DELETE request)
export const deletePolicyUpdate = async (policyUpdateId) => {
  try {
    const url = DELETE_UPDATE.replace("{policy_update_id}", policyUpdateId);
    const response = await del(url);
    return response.data;
  } catch (error) {
    console.error(
      "Error deleting policy update:",
      error.response ? error.response.data : error.message
    );
    throw new Error(
      'Error deleting policy update: ' +
      (error.response ? JSON.stringify(error.response.data) : error.message)
    );
  }
};

// Delete a country event by ID
export const deleteCountryEvent = async (countryEventId) => {
  try {
    const url = DELETE_COUNTRY_EVENT.replace("{country_event_id}", countryEventId);
    const response = await del(url);
    return response.data;
  } catch (error) {
    console.error(
      "Error deleting country event:",
      error.response ? error.response.data : error.message
    );
    throw new Error(
      'Error deleting country event: ' +
      (error.response ? JSON.stringify(error.response.data) : error.message)
    );
  }
};

