import React from 'react';
import Select from "react-select"
import { BooleanOptions } from 'common/SelectOptions/BooleanOptions';
import { theme } from 'common/selectCustomStyles';

function AGRASupportedDropdown({selectedOption, setSelectedOption}) {

    return (
        <div className="flex items-center">
            <h6 className="text-nowrap font-medium">AGRA supported:</h6>
            <div className="ms-2" style={{ width: "7rem" }}>
                <Select
                    isClearable
                    options={BooleanOptions}
                    defaultValue={selectedOption}
                    className="basic-single"
                    classNamePrefix="select"
                    placeholder="Select"
                    theme={theme}
                    onChange={option => setSelectedOption(option)}
                />
            </div>
        </div>
    );
}

export default AGRASupportedDropdown;