import logo from "../../assets/images/agra_logo2_cropped.png"
import { Icon } from '@iconify/react';
import { Link, useLocation } from 'react-router-dom/cjs/react-router-dom';
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
  } from 'reactstrap';
import React, { useState } from 'react';

function LandingPageHeader() {

    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggle = () => setDropdownOpen((prevState) => !prevState);

    const location = useLocation();

    // Function to determine active link
    const isActive = (paths) => paths.includes(location.pathname);

    return (
        <header className="bg-white px-4 py-2 shadow-md">
            <div className="container mx-auto flex justify-between items-center">
                <img src={logo} alt="AGRA Logo" className="h-12"/>
                <nav>
                    <ul className="flex space-x-6">
                        <li>
                            <Link 
                                to="/" 
                                className={
                                    `text-green-600 flex items-center gap-1 p-1 rounded hover:bg-yellow-100
                                    ${isActive(['/']) ? 'bg-yellow-200' : ''}`
                                }
                            >
                                <Icon icon="material-symbols:home-outline" />
                                Home
                            </Link>
                        </li>
                        <li>
                            <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                                <DropdownToggle className="bg-white border-none p-0" caret>
                                    <div 
                                        className={
                                            `text-green-600 flex items-center gap-1 rounded hover:bg-yellow-100 p-1
                                            ${isActive(['/home-tracker', '/home-policies']) ? 'bg-yellow-200' : ''}`
                                        }
                                    >
                                        <Icon icon="hugeicons:chart-breakout-square" />
                                        E-tracker
                                        <Icon icon="hugeicons:arrow-down-01" />
                                    </div>
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem header>Policies tracker</DropdownItem>
                                    <DropdownItem>
                                        <Link 
                                            to="/home-tracker" 
                                            className={
                                                `text-green-600 flex items-center gap-1 p-1 rounded hover:bg-yellow-100 
                                                ${isActive(['/home-tracker']) ? 'bg-yellow-200' : ''}`
                                            }
                                        >
                                            <Icon icon="hugeicons:chart-scatter" />
                                            Dashboard
                                        </Link>
                                    </DropdownItem>
                                    <DropdownItem divider />
                                    <DropdownItem>
                                        <Link 
                                            to="/home-policies" 
                                            className={
                                                `text-green-600 flex items-center gap-1 p-1 rounded hover:bg-yellow-100 
                                                ${isActive(['/home-policies']) ? 'bg-yellow-200' : ''}`
                                            }
                                        >
                                            <Icon icon="hugeicons:folder-01" />
                                            Policies Directory
                                        </Link>
                                    </DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </li>
                        <li>
                            <Link 
                                to="/home-benchmarking" 
                                className={
                                    `text-green-600 flex items-center gap-1 p-1 rounded hover:bg-yellow-100
                                    ${isActive(['/home-benchmarking']) ? 'bg-yellow-200' : ''}`
                                }
                            >
                                <Icon icon="hugeicons:chart-line-data-02" />
                                Benchmarking
                            </Link>
                        </li>
                        <li>
                            <Link 
                                to="/home-landscape" 
                                className={
                                    `text-green-600 flex items-center gap-1 p-1 rounded hover:bg-yellow-100
                                    ${isActive(['/home-landscape']) ? 'bg-yellow-200' : ''}`
                                }
                            >
                                <Icon icon="ep:files" />
                                Policy Landscaping
                            </Link>
                        </li>
                        <li>
                            <Link 
                                to="/home-policy-updates" 
                                className={
                                    `text-green-600 flex items-center gap-1 p-1 rounded hover:bg-yellow-100 
                                    ${isActive(['/home-policy-updates']) ? 'bg-yellow-200' : ''}`
                                }>
                                    <Icon icon="hugeicons:news-01" />
                                    Policies Updates        
                            </Link>
                        </li>
                        <li>
                            <Link 
                                to="/home-frequently-asked-questions" 
                                className={
                                    `text-green-600 flex items-center gap-1 p-1 rounded hover:bg-yellow-100
                                    ${isActive(['/home-frequently-asked-questions']) ? 'bg-yellow-200' : ''}`
                                }
                            >
                                <Icon icon="akar-icons:question" />
                                FAQ
                            </Link>
                        </li>
                    </ul>
                </nav>
                <Link to="/login" className="common-button m-0">Login<Icon icon="material-symbols:login" className='ms-2' /></Link>
            </div>
        </header>
    );
}

export default LandingPageHeader;