import React, { useEffect, useMemo, useState } from "react";
import * as Tabs from "@radix-ui/react-tabs";
import StepOne from "./Step-One";
import StepTwo from "./Step-Two";
import StepThree from "./Step-Three";
import StepAddOwners from "./AddOwnersTab/EditOwners"; // 
import Preview from "./Step-Four";
import {
  getLockedETrackerMilestone,
  getUnlockedETrackerMilestone,
  getLockedBenchmarkingMilestone,
  getLockedLandscapingFocalArea,
  editETrackerMilestone,
  editBenchmarkingMilestone,
  editLandscapingFocalArea,
  getUnlockedBenchmarkingMilestone,
  getUnlockedLandscapingFocalArea
} from "services/milestoneServices"
import styled from "@emotion/styled";
import { useSelector } from "react-redux";
import ErrorModal from "../../../components/Common/ErrorModal";
import SuccessModal from "../../../components/Common/SuccessModal";
import Questionnaire from "../Questionnaire/Questionnaire";

const TabsRoot = styled(Tabs.Root)`
  display: flex;
  flex-direction: column;
`;

const TabsList = styled(Tabs.List)`
  display: flex;
  justify-content: space-between;
  background-color: white;
  padding: 10px 0;
  border-radius: 0.5rem;
  border: 1px solid #ddd;
  margin-bottom: 20px;
  width: 100%;
  border: none;
`;

const TabsTrigger = styled(Tabs.Trigger)`
  all: unset;
  font-size: 1rem;
  padding: 12px 20px;
  text-align: center;
  width: calc(25% - 10px); /* Four tabs: Adjust width */
  color: #6c757d;
  background-color: #f8f9fa;
  transition: all 250ms ease;
  border-radius: 0.5rem;
  margin: 0 5px;

  &[data-state="active"] {
    background-color: #198754;
    color: white;
    font-weight: bold;
  }

  &:hover {
    background-color: #47a87b;
    color: white;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

const TabsContent = styled(Tabs.Content)`
  flex-grow: 1;
  padding: 20px;
  background-color: white;
  border-radius: 0.5rem;
  border: 1px solid #ddd;
  margin-top: 1.5rem;
  margin-left: 2rem;
  margin-right: 2rem;
`;

const EditMilestone = (props) => {
  const formKey = "milestoneForm";
  const questionsKey = "questions";

  const { user } = useSelector(state => state.Profile)
  const [activeTab, setActiveTab] = useState("step1");
  const [successModal, setSuccessModal] = useState("");
  const [errorModal, setErrorModal] = useState("");
  const [editingRowID, setEditingRowID] = useState(null)

  // Retrieve MilestoneRecordData from localStorage
  const milestoneRecordData = useMemo(() => {
    const savedMilestoneRecord = localStorage.getItem("MilestoneRecordData")
    return savedMilestoneRecord ? JSON.parse(savedMilestoneRecord) : null
  }, [])

  const [form, setForm] = useState(() => {
    const savedData = localStorage.getItem(formKey);
    return savedData
      ? JSON.parse(savedData)
      : {
          id: "",
          name: "",
          type: "",
          stage_id: "",
          country_id: "",
          system_id: "",
          landscaping_category: "",
          description: "",
          owners: "",
          supervisors: "",
        };
  });

  const [questions, setQuestions] = useState(() => {
    const savedQuestions = localStorage.getItem(questionsKey);
    return savedQuestions ? JSON.parse(savedQuestions) : [];
  });

  const formTypeFromStorage = useMemo(() => {
    return localStorage.getItem("formType");
  }, []);

  const formType = useMemo(() => {
    if (formTypeFromStorage === "ETracker") return "tracker";
    if (formTypeFromStorage === "Benchmarking") return "benchmarking";
    if (formTypeFromStorage === "Landscaping") return "landscaping";
    return null;
  }, [formTypeFromStorage]);

  // Function to load questions based on formType and id
  const loadQuestions = async (id) => {
    try {
      let data

      if (formType === "tracker") {
        data = user?.user_country?.length === 1
          ? await getUnlockedETrackerMilestone(id)
          : await getLockedETrackerMilestone(id)


        // Check if questions exist and map over them if necessary
        if (data?.e_tracker_milestone_questions) {
          const transformedQuestions = data.e_tracker_milestone_questions.map((question) => ({
            id: question.id,
            question_text: question.question,
            required: question.is_required
          }))

          // Set transformed questions in state
          setQuestions(transformedQuestions)
          localStorage.setItem(questionsKey, JSON.stringify(transformedQuestions))
        }

      } else if (formType === "benchmarking") {
        data = user?.user_country?.length === 1
          ? await getUnlockedBenchmarkingMilestone(id)
          : await getLockedBenchmarkingMilestone(id)

        if (data?.benchmarking_milestone_questions) {
          const transformedQuestions = data.benchmarking_milestone_questions.map((question) => ({
            id: question.id,
            question_text: question.question,
            required: question.is_required,
            evidenceRequired: question.is_evidence_required
          }))

          setQuestions(transformedQuestions)
          localStorage.setItem(questionsKey, JSON.stringify(transformedQuestions))
        }

      } else if (formType === "landscaping") {
        data = user?.user_country?.length === 1
          ? await getUnlockedLandscapingFocalArea(id)
          : await getLockedLandscapingFocalArea(id)
        if (data?.landscaping_focal_area_questions) {
          const transformedQuestions = data.landscaping_focal_area_questions.map((question) => ({
            id: question.id,
            question_text: question.question,
            required: question.is_required,
            evidenceRequired: question.is_evidence_required
          }))

          setQuestions(transformedQuestions)
          localStorage.setItem(questionsKey, JSON.stringify(transformedQuestions))
        }
      }

      // Store form data to localStorage after fetching from API
      localStorage.setItem(formKey, JSON.stringify(data))

      // Update form state with API data
      setForm({
        id: data?.id || "",
        name: data?.name || "",
        type: data?.type || "",
        stage_id: data?.stage_id || "",
        system_id: data?.system_id || "",
        landscaping_category: data?.landscaping_category || "",
        description: data?.description || "",
        owners: {
              value: data?.owner_and_supervisor?.owner_id,
              label: `${data?.owner_and_supervisor?.owner?.first_name} ${data?.owner_and_supervisor?.owner?.last_name}`,
          },
        supervisors: {
            value: data?.owner_and_supervisor?.owner_id,
            label: `${data?.owner_and_supervisor?.supervisor?.first_name} ${data?.owner_and_supervisor?.supervisor?.last_name}`,
            }
      })

    } catch (error) {
      console.error("Error loading questions:", error)
    }
  }

  useEffect(() => {
    localStorage.setItem(formKey, JSON.stringify(form));
  }, [form]);

  useEffect(() => {
    localStorage.setItem(questionsKey, JSON.stringify(questions));
  }, [questions]);

  useEffect(() => {
    if (milestoneRecordData?.id) {
      setEditingRowID(milestoneRecordData?.id)
      loadQuestions(milestoneRecordData?.id)
    }
  }, [milestoneRecordData])

  const handleInputChange = (field, value) => {
    setForm((prevForm) => ({
      ...prevForm,
      [field]: value,
    }));
  };

  const saveDetails = (step) => {
    console.log(`Details saved in step ${step}`, form);
  };

  const saveQuestions = (step) => {
    console.log(`Questions saved in step ${step}`, questions);
  };

  const handleSubmit = async () => {
    try {
      let body = {};

      if (formType === "tracker") {
        body = {
          e_tracker_milestone_update_request_body: {
            name: form.name,
            stage_id: parseInt(form.stage_id),
          },
          e_tracker_milestone_question_update_request_bodies: questions.map(
            (question) => ({
              id: question.id,
              question: question.question_text,
              is_required: question.required,
            })
          ),
        };

        const response = await editETrackerMilestone(editingRowID, body)

        response
          ? setSuccessModal("Form submitted successfully!")
          : setErrorModal("Something went wrong. Please try again.");
      } else if (formType === "benchmarking") {
        body = {
          benchmarking_milestone_update_request_body: {
            name: form.name,
            system_id: parseInt(form.system_id),
          },
          benchmarking_milestone_question_update_request_bodies: questions.map(
            (question) => ({
              id: question.id,
              question: question.question_text,
              is_required: question.required,
              is_evidence_required: question.evidenceRequired,
            })
          ),
        };

        const response = await editBenchmarkingMilestone(editingRowID, body);
        response
          ? setSuccessModal("Form submitted successfully!")
          : setErrorModal("Something went wrong. Please try again.");
      } else if (formType === "landscaping") {
        body = {
          landscaping_focal_area_update_request_body: {
            landscaping_category: form.landscaping_category,
            focal_area_category: form.focal_area_category,
            name: form.name,
            description: form.description,
          },
          landscaping_focal_area_question_update_request_bodies: questions.map(
            (question) => ({
              id: question.id,
              question: question.question_text,
              is_required: question.required,
              is_evidence_required: question.evidenceRequired,
            })
          ),
        };

        const response = await editLandscapingFocalArea(editingRowID, body);
        response
          ? setSuccessModal("Form submitted successfully!")
          : setErrorModal("Something went wrong. Please try again.");
      }
    } catch (e) {
      setErrorModal("Error submitting form. Please try again.");
      console.error("Error submitting form:", e);
    }
  };

  return (
    <React.Fragment>
      <ErrorModal show={errorModal} onCloseClick={() => setErrorModal("")} />
      <SuccessModal
        show={successModal}
        onCloseClick={() => setSuccessModal("")}
      />
      <div className="page-content">
        <h2 className="header-title">Edit Questionnaire</h2>
        <TabsRoot defaultValue="step1" value={activeTab}>
          <TabsList>
            <TabsTrigger value="step1" onClick={() => setActiveTab("step1")}>
              1. Enter Details
            </TabsTrigger>

            {user?.user_country?.length === 1 && (
              <TabsTrigger value="stepAddOwners" onClick={() => setActiveTab("stepAddOwners")}>
                2. Add Owners
              </TabsTrigger>
            )}

            <TabsTrigger value="step2" onClick={() => setActiveTab("step2")}>
              {user?.user_country?.length === 1 ? "3. Add Questions" : "2. Add Questions"}
            </TabsTrigger>

            <TabsTrigger value="step3" onClick={() => setActiveTab("step3")}>
              {user?.user_country?.length === 1 ? "4. Preview" : "3. Preview"}
            </TabsTrigger>

            {/*<TabsTrigger*/}
            {/*  value="stepAddOwners"*/}
            {/*  onClick={() => setActiveTab("stepAddOwners")}*/}
            {/*>*/}
            {/*  2. Add Owners*/}
            {/*</TabsTrigger>*/}
            {/*<TabsTrigger value="step2" onClick={() => setActiveTab("step2")}>*/}
            {/*  3. Add Questions*/}
            {/*</TabsTrigger>*/}
            {/*<TabsTrigger value="step3" onClick={() => setActiveTab("step3")}>*/}
            {/*  4. Preview*/}
            {/*</TabsTrigger>*/}
          </TabsList>

          {/* Step 1: Enter Details */}
          <TabsContent value="step1">
            <StepOne
              form={form}
              formType={formType}
              handleInputChange={handleInputChange}
              changeTab={setActiveTab}
              save={saveDetails}
            />
          </TabsContent>

          {/* Step 2: Add Owners */}
          {/*<TabsContent value="stepAddOwners">*/}
          {/*  <StepAddOwners*/}
          {/*    form={form}*/}
          {/*    handleInputChange={handleInputChange}*/}
          {/*    changeTab={setActiveTab}*/}
          {/*  />*/}
          {/*</TabsContent>*/}
          {user?.user_country?.length === 1 && (
            <TabsContent value="stepAddOwners">
              <StepAddOwners
                form={form}
                handleInputChange={handleInputChange}
                changeTab={setActiveTab}
              />
            </TabsContent>
          )}

          {/* Step 3: Add Questions */}
          <TabsContent value="step2">
            <StepTwo
              formType={formType}
              questions={questions}
              setQuestions={setQuestions}
              save={saveQuestions}
              changeTab={setActiveTab}
            />
          </TabsContent>

          {/* Step 4: Preview */}
          <TabsContent value="step3">
            <StepThree
              questions={questions}
              setQuestions={setQuestions}
              save={handleSubmit}
            />
          </TabsContent>
        </TabsRoot>
      </div>
    </React.Fragment>
  );
};

export default EditMilestone;