import React from "react"
import { Container, Row, Col } from "reactstrap"

const Footer = () => {
  return (
    <React.Fragment>
      <footer>
        <div className="fixed bottom-0 left-0 right-0 w-full h-[2.18rem] flex flex-row justify-center items-center bg-primary z-10">
          <div className="text-[#fff71f] text-base">
            Copyright © {new Date().getFullYear()} APRITS
          </div>
        </div>
      </footer>
    </React.Fragment>
  )
}

export default Footer
