import React, { useState } from "react"
import "./Partners.css"
import { useQuery } from "@tanstack/react-query"
import {
  Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, Label
} from "reactstrap"
import {
  // createNewOrganization,
  createNewPartner, // getOrganizations,
  getPartners, // getOrganizationSuggestions,
  // getPartnerSuggestions,
  // getOrganizationTypes,
  // editSpecificOrganization,
  editSpecificPartner, deletePartner

} from "services/partnerServices"
import { CustomTable } from "./Partner-Management"
import useDebounce from "hooks/useDebounce"
import SearchBox from "components/Common/SearchBox"
import Pagination from "components/Common/Pagination"
import LoadingSpinner from "components/Common/Loading-Spinner"
import ExportTable from "../../components/Common/ExportTable"
import { exportTableData } from "services/common"
import styled from "@emotion/styled"
import PartnerFilters from "./Filters"

export const StyledContainer = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    gap: 0.5em;

    & .basic-single {
        flex-basis: 150px;
    }
`

const Partners = ({ alert }) => {
  document.title = "Partner Management"

  // Partner Edit details
  const [newPartner, setNewPartner] = useState({
    short_name: "", long_name: "", is_technical_expert: false
  })

  //pagination
  const [offset, setOffset] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalEntries, setTotalEntries] = useState(0)

  const handlePageChange = pageNum => {
    const offsetVal = pageNum * 50 - 50
    setOffset(offsetVal)
    setCurrentPage(pageNum)
  }

  //filters
  const [selectedLocations, setSelectedLocations] = useState([])
  const [modalFilter, setModalFilter] = useState(false)
  const toggleModalFilter = () => setModalFilter(prevState => !prevState)

  const applyFilters = (locations) => {
    setSelectedLocations(locations);
    toggleModalFilter();
  }

  const areAnyFiltersSelected = () => Boolean(selectedLocations.length);

  const clearFilters = () => {
    setSelectedLocations([]);
  }  

  //search
  const [searchTerm, setSearchTerm] = useState("")
  const debouncedSearchTerm = useDebounce(searchTerm)

  // State for editing functionality
  const [isUserEditing, setIsUserEditing] = useState(false)
  const [editingRowID, setEditingRowID] = useState(null)

  const loadPartners = async (query) => {
    try {
      const { data, count } = await getPartners(query)
      setTotalEntries(count)
      return data
    } catch (err) {
      console.error(err)
      return { partners: [] }
    }
  }

  const {
    data: partnerData = { partners: [] }, refetch, isLoading
  } = useQuery({
    queryKey: ["Partners", {
      debouncedSearchTerm, page: currentPage, selectedLocations
    }],
    queryFn: () => loadPartners({
      searchField: debouncedSearchTerm,
      limit: 50,
      offset,
      locations: selectedLocations?.map(location=>location.value),
    })
  })

  const handleSearchInputChange = (evt) => {
    setSearchTerm(evt.target.value.toLowerCase())
    setOffset(0)
    setCurrentPage(1)
  }

  // Modal management
  const [showModal, setShowModal] = useState(false)
  const openModal = () => setShowModal(true)
  const closeModal = () => {
    setShowModal(false)
    setNewPartner({
      short_name: "",
      long_name: "",
      is_technical_expert: false
    })
    setIsUserEditing(false)
    setEditingRowID(null)
  }

  // Initiate edit function
  const initiateEdit = (id) => {
    const selectedRowData = partnerData?.find((row) => row.id === id)
    setNewPartner({
      short_name: selectedRowData.short_name,
      long_name: selectedRowData.long_name,
      is_technical_expert: selectedRowData.is_technical_expert
    })
    setIsUserEditing(true)
    setEditingRowID(selectedRowData.id)
    openModal()
  }

  const handlePartnerCreate = async () => {
    if (!newPartner.long_name) {
      alert("Long name field cannot be empty", false)
      return
    }

    try {
      const requestBody = {
        short_name: newPartner.short_name,
        long_name: newPartner.long_name,
        is_technical_expert: newPartner.is_technical_expert
      }

      if (isUserEditing) {
        await editSpecificPartner(editingRowID, requestBody) // Ensure the correct id is passed
        alert("Partner updation", true)
      }
      // else {
      //   await createNewPartner(requestBody)
      //   alert("Partner creation", true)
      // }

      closeModal()
      await refetch()
    } catch (err) {
      console.error("Error response:", err.response?.data)
      alert("Failed to create partner", false)
    }
  }
  //
  // const handleInputChange = (e) => {
  //   const { name, value } = e.target
  //   setNewPartner((prevPartner) => ({ ...prevPartner, [name]: value }))
  // }

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target

    // Check if the field is for the Technical Expert checkbox
    if (name === "TechnicalExpert") {
      setNewPartner((prevPartner) => ({
        ...prevPartner,
        is_technical_expert: checked // Set to true/false based on checkbox state
      }))
    } else {
      setNewPartner((prevPartner) => ({
        ...prevPartner,
        [name]: value
      }))
    }
  }

  const onDeleteClick = async (id) => {
    try {
      await deletePartner(id) // Use id instead of policyId
      alert("Partner deletion", true)
      refetch() // Refresh the partners list after deletion
    } catch (err) {
      alert("Failed to delete partner", false)
      console.error("Error deleting partner:", err)
    }
  }

  const exportTable = async (type) => {
    try {
      const query = {
        export_format: type
        // ...(selectedUser && { user_id: selectedUser }),
        // ...(selectedActivity && { activity: selectedActivity }),
      }

      exportTableData(query, "Partners")
    } catch (err) {
      console.log(err)
      alert("Data export", false)
    }
  }

  return (
    <div>
      <div className="d-flex justify-content-between">
        <StyledContainer>
          <SearchBox searchChange={handleSearchInputChange} />
          <Button
            className="common-button"
            onClick={toggleModalFilter}
          >
            <i className="mdi mdi-filter-variant me-1" /> Filters
          </Button>
          {areAnyFiltersSelected() && (
            <Button
              className="common-button"
              onClick={clearFilters}
            >
              <i className="ph-x me-1"></i>
              Clear All Filters
            </Button>
          )}

        </StyledContainer>
        {/*<Button*/}
        {/*  className="common-button"*/}
        {/*  onClick={openModal}*/}
        {/*  style={{ marginInline: 0 }}*/}
        {/*>*/}
        {/*  Create New*/}
        {/*</Button>*/}
        <ExportTable loadFilteredData={exportTable}></ExportTable>
        <Modal
          isOpen={modalFilter}
          toggle={toggleModalFilter}
          className="modal-lg"
        >
          <ModalHeader toggle={toggleModalFilter}>
            <i className="mdi mdi-filter-variant me-1"></i>
            Filters
          </ModalHeader>
          <ModalBody>
            <PartnerFilters
              filteredLocations={selectedLocations}
              applyFilters={applyFilters}
            />
          </ModalBody>
        </Modal>

        {/* <Modal isOpen={showModal} toggle={closeModal}>
          <ModalHeader toggle={closeModal}>
            <b>{isUserEditing ? "Edit Partner" : "Create New Partner"}</b>
          </ModalHeader>
          <ModalBody>
            <Label className="form-label" htmlFor="shortName">
              Partner Short Name:
            </Label>
            <Input
              id="shortName"
              name="short_name"
              type="text"
              value={newPartner.short_name}
              required
              onChange={handleInputChange}
            />
            <Label className="form-label" htmlFor="longName">
              Partner Long Name:
            </Label>
            <Input
              id="longName"
              name="long_name"
              type="text"
              value={newPartner.long_name}
              onChange={handleInputChange}
            />
            <Label className="form-label" htmlFor="TechnicalExpert">
              Technical Expert:
            </Label>
            <div className="form-check form-switch">
              <Input
                id="TechnicalExpert"
                name="TechnicalExpert"
                type="checkbox"
                className="form-check-input"
                checked={newPartner.is_technical_expert}
                onChange={handleInputChange}
              />
              <Label className="form-check-label" htmlFor="TechnicalExpert">
                {newPartner.is_technical_expert ? "Yes" : "No"}
              </Label>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={handlePartnerCreate}>
              {isUserEditing ? "Save" : "Create"}
            </Button>
          </ModalFooter>
        </Modal> */}

        <Modal isOpen={showModal} toggle={closeModal} className="custom-modal">
          <ModalHeader className="modal-header"
            style={{
              display: 'flex',
              alignItems: 'center', // Ensure vertical alignment
              justifyContent: 'flex-start', // Align content to the left
              padding: '10px 15px', // Adjust padding as needed
            }}
            toggle={closeModal}>
            <b>{isUserEditing ? "Edit Partner" : "Create New Partner"}</b>
          </ModalHeader>

          <div className="modal-subtext">
            <small>All fields marked with asterisks (<span className="required">*</span>) are compulsory.</small>
          </div>

          <ModalBody className="modal-body">
            <Label className="form-label" htmlFor="shortName">
              Partner Short Name
            </Label>
            <Input
              id="shortName"
              name="short_name"
              type="text"
              className="input-field"
              value={newPartner.short_name}
              required
              onChange={handleInputChange}
            />

            <Label className="form-label mt-3" htmlFor="longName">
              Partner Long Name<span className="required">*</span>
            </Label>
            <Input
              id="longName"
              name="long_name"
              type="text"
              className="input-field"
              value={newPartner.long_name}
              onChange={handleInputChange}
            />

            <div className="d-flex align-items-center mt-3">
              <Label className="form-label mb-0 me-3" htmlFor="TechnicalExpert">
                Technical Expert<span className="required">*</span>
              </Label>
              <div className="form-check form-switch">
                <Input
                  id="TechnicalExpert"
                  name="TechnicalExpert"
                  type="checkbox"
                  className="form-check-input"
                  checked={newPartner.is_technical_expert}
                  onChange={handleInputChange}
                />
                <Label className="form-check-label" htmlFor="TechnicalExpert">
                  {newPartner.is_technical_expert ? "Yes" : "No"}
                </Label>
              </div>
            </div>
          </ModalBody>

          <ModalFooter className="modal-footer">
            <Button
              // className="btn-primary" 
              style={{
                backgroundColor: '#036F36',
                color: '#FFF605',
                borderRadius: '2.25rem',
                width: '100%',
              }}
              onClick={handlePartnerCreate}>
              {isUserEditing ? "Save" : "Create"}
            </Button>
            {/* <Button className="btn-secondary" onClick={closeModal}>
              Cancel
            </Button> */}
          </ModalFooter>
        </Modal>

      </div>

      {isLoading && <LoadingSpinner />}
      {!isLoading && partnerData?.length > 0 ? (
        <>
          <CustomTable
            data={partnerData}
            initiateEdit={initiateEdit}
            onDeleteClick={onDeleteClick} // Pass delete function to table
          />
          <Pagination
            currentPage={currentPage}
            goToPage={handlePageChange}
            totalEntries={totalEntries}
          />
        </>
      ) : null}
    </div>
  )

}

export default Partners


// const Partners = message => {
//   document.title = "Partner Management"
//   const { countries } = useSelector(state => state.list)
//   //fetch Organizations
//   const loadPartners = async (query) => {
//     try {
//       const { data, count } = await getPartners(query)
//       console.log("API partner Response:", data)
//       setTotalEntries(count)
//       return data
//     } catch (err) {
//       console.error(err)
//       return { partners: [] }
//     }
//   }
//
//
//   //fetch OrgTypes
//   // const loadOrganizationTypes = async () => {
//   //   try {
//   //     const { data } = await getOrganizationTypes({ limit: 0, offset: 0 })
//   //     return data
//   //   } catch (err) {
//   //     console.error(err)
//   //     return []
//   //   }
//   // }
//   //fetch Organization Suggestions
//   const loadPartnerSuggestions = async input => {
//     try {
//       const { data, count } = await getPartnerSuggestions(input)
//       setTotalEntries(count)
//       return data
//     } catch (err) {
//       console.error(err)
//       return []
//     }
//   }
//
//   // const { data: orgTypesData } = useQuery({
//   //   queryKey: ["Organization Types"],
//   //   queryFn: () => loadOrganizationTypes(),
//   //   initialData: { types: [] },
//   // })
//
//   // Partner Edit details
//   const [newPartner, setNewPartner] = useState({
//     short_name: "", long_name: "", is_technical_expert: false
//   })
//
//   //pagination
//   const [offset, setOffset] = useState(0)
//   const [currentPage, setCurrentPage] = useState(1)
//   const [totalEntries, setTotalEntries] = useState(0)
//   const handlePageChange = pageNum => {
//     const offsetVal = pageNum * 50 - 50
//     setOffset(offsetVal)
//     setCurrentPage(pageNum)
//   }
//   //search
//   const [searchTerm, setSearchTerm] = useState("")
//   const debouncedSearchTerm = useDebounce(searchTerm)
//
//   const handleSearchInputChange = evt => {
//     setSearchTerm(evt.target.value)
//     setOffset(0)
//     setCurrentPage(1)
//     setTotalEntries(partnerData?.count || 0)
//   }
//   //filters
//   const [selectedCountryFilters, setSelectedCountryFilters] = useState([])
//   const [selectedTypeFilters, setSelectedTypeFilters] = useState([])
//
//   const applyFilters = (selectedCountries, selectedTypes) => {
//     setSelectedCountryFilters(selectedCountries)
//     setSelectedTypeFilters(selectedTypes)
//   }
//
//   //clear Filters
//   const areAnyFiltersSelected = () => {
//     if (selectedCountryFilters.length > 0 || selectedTypeFilters.length > 0) {
//       return true
//     } else {
//       return false
//     }
//   }
//   const clearFilters = () => {
//     setSelectedCountryFilters([])
//     setSelectedTypeFilters([])
//   }
//   const [modalFilter, setModalFilter] = useState(false)
//   const toggleModalFilter = () => setModalFilter(prevState => !prevState)
//
//   //sort
//   const [sortBy, setSortBy] = useState("updatedAtDESC")
//   const sortOptions = [{
//     value: "updatedAtDESC", label: "Newest"
//   }, {
//     value: "updatedAtASC", label: "Oldest"
//   }, {
//     value: "nameASC", label: "A-Z"
//   }, {
//     value: "nameDESC", label: "Z-A"
//   }]
//   const sortMap = new Map([["updatedAtDESC", { updated_at: "DESC" }], ["updatedAtASC", { updated_at: "ASC" }], ["nameDESC", { name: "DESC" }], ["nameASC", { name: "ASC" }]])
//
//   const {
//     data: partnerData = { partners: [] }, refetch, isLoading
//   } = useQuery({
//     queryKey: ["Partners", {
//       debouncedSearchTerm, selectedCountryFilters, selectedTypeFilters, sortBy, page: currentPage
//     }],
//     queryFn: () => debouncedSearchTerm.length > 0 ? loadPartners({
//       searchTerm: debouncedSearchTerm,
//       limit: 50,
//       offset
//     }, {
//       types: selectedTypeFilters, countries: selectedCountryFilters, sortForm: sortMap.get(sortBy)
//     }) : loadPartners({ limit: 50, offset }, {
//       types: selectedTypeFilters, countries: selectedCountryFilters, sortForm: sortMap.get(sortBy)
//     })
//   })
//
//   //modal
//
//   //userInputs
//   const [userInput, setUserInput] = useState("")
//   const [hasVisitedInput, setHasVisitedInput] = useState(false)
//   const [showSuggestions, setShowSuggestions] = useState(true)
//   const [selectedSuggestion, setSelectedSuggestion] = useState(null)
//   // const [selectedSuggestionsPartnerType, setSelectedSuggestionsPartnerType] = useState(
//   //   []
//   // )
//   const [isUserEditing, setIsUserEditing] = useState(false)
//   const [editingRowID, setEditingRowID] = useState(null)
//   const [partnerAlreadyExistsError, setPartnerAlreadyExistsError] = useState(false)
//   const [selectedCountries, setSelectedCountries] = useState([])
//   const [partnerType, setPartnerType] = useState({})
//
//   const [showModal, setShowModal] = useState(false)
//   const openModal = () => {
//     setShowModal(true)
//     if (!isUserEditing) {
//       setUserInput("")
//       setPartnerAlreadyExistsError(false)
//       setHasVisitedInput(false)
//       setSelectedCountries([])
//       setPartnerType({})
//     }
//   }
//   const closeModal = () => {
//     setShowModal(false)
//     setIsUserEditing(false)
//     setUserInput("")
//     setPartnerAlreadyExistsError(false)
//     setHasVisitedInput(false)
//   }
//   const { data: partnerSuggestions } = useQuery({
//     queryKey: ["Parnter Suggestions", { userInput }],
//     queryFn: () => loadPartnerSuggestions(userInput),
//     enabled: userInput.length >= 3 && hasVisitedInput,
//     initialData: []
//   })
//
//   const handleInputChange = (evt) => {
//     const { name, value } = evt.target
//     setNewPartner(prevPartner => ({
//       ...prevPartner,
//       [name]: value
//     }))
//     setUserInput(value)
//     setShowSuggestions(true)
//     setSelectedSuggestion(null)
//     setPartnerAlreadyExistsError(false)
//   }
//
//   const handleSuggestionSelection = suggestion => {
//     setSelectedSuggestion(suggestion)
//     setUserInput(suggestion.name)
//     setShowSuggestions(false)
//     // const selectedSuggestionsOrgType = orgTypesData.types.find(
//     //   orgType => orgType.id === suggestion.organization_type.id
//     // )
//     // setSelectedSuggestionsOrgType([selectedSuggestionsOrgType])
//   }
//
//   // Initiate edit function
//   const initiateEdit = (id) => {
//     const selectedRowData = partnerData?.find((row) => row.id === id)
//     setNewPartner({
//       short_name: selectedRowData.short_name,
//       long_name: selectedRowData.long_name,
//       is_technical_expert: selectedRowData.is_technical_expert
//     })
//     setIsUserEditing(true)
//     setEditingRowID(selectedRowData.id)
//     openModal()
//   }
//
//   const handlePartnerEdit = async () => {
//     try {
//       const requestBody = {
//         short_name: newPartner.short_name,
//         long_name: newPartner.long_name,
//         is_technical_expert: newPartner.is_technical_expert
//       }
//
//       if (isUserEditing) {
//         await editSpecificPartner(editingRowID, requestBody) // Ensure the correct id is passed
//         alert("Partner updation", true)
//       }
//
//       closeModal()
//       refetch()
//     } catch (err) {
//       console.error("Error response:", err.response?.data)
//       alert("Failed to create system", false)
//     }
//   }
//
//
//   // const handlePartnerCreate = async () => {
//   //   const selectedCountryCodes = selectedCountries?.map(country => country.value)
//   //   if (!isUserEditing) {
//   //     let body
//   //     if (selectedSuggestion && selectedSuggestion.name === userInput) {
//   //       body = {
//   //         id: selectedSuggestion.id,
//   //         organization_type: selectedSuggestion.organization_type.id,
//   //         countries: [
//   //           ...selectedSuggestion.countries?.map(country => country.id),
//   //           ...selectedCountryCodes,
//   //         ],
//   //         name: userInput,
//   //       }
//   //     } else {
//   //       body = {
//   //         name: userInput,
//   //         countries: selectedCountryCodes,
//   //         organization_type: partnerType.value,
//   //       }
//   //     }
//   //     try {
//   //       const { data } = await createNewPartner(body)
//   //       closeModal()
//   //     } catch (err) {
//   //       if (err.response.data.statusCode === 409) setPartnerAlreadyExistsError(true)
//   //       console.error(err)
//   //     }
//   //   } else {
//   //     try {
//   //       const { data } = await editSpecificPartner(editingRowID, {
//   //         name: userInput,
//   //         countries: selectedCountryCodes,
//   //         organization_type: partnerType.value,
//   //       })
//   //       closeModal()
//   //     } catch (err) {
//   //       console.error(err)
//   //     }
//   //   }
//   //   refetch()
//   // }
//
//
//   // const initiateEdit = (id) => {
//   //   setShowModal(true)
//   //   setIsUserEditing(true)
//   //   const selectedRowData = partnerData?.find(row => row.id === id)
//   //   setEditingRowID(selectedRowData.id)
//   //   setUserInput(selectedRowData.name)
//   //   console.log("selectedRowData", selectedRowData)
//   //   setSelectedCountries(
//   //     selectedRowData.country?.map(c => ({
//   //       label: c.country.long_name,
//   //       value: c.country_id,
//   //     }))
//   //   )
//   // setOrgType({
//   //   label: selectedRowData.organization_type.name,
//   //   value: selectedRowData.organization_type.id,
//   // })
//
//   const onDeleteClick = async (id) => {
//     try {
//       await deleteSystem(id) // Use id instead of policyId
//       alert("Partner deletion", true)
//       refetch() // Refresh the partner list after deletion
//     } catch (err) {
//       alert("Failed to delete partner", false)
//       console.error("Error deleting partner:", err)
//     }
//
//   }
//
//
//   const exportTable = async (type) => {
//     try {
//       const query = {
//         export_format: type
//         // ...(selectedUser && { user_id: selectedUser }),
//         // ...(selectedActivity && { activity: selectedActivity }),
//       }
//
//       exportTableData(query, "Partners")
//     } catch (err) {
//       console.log(err)
//       alert("Data export", false)
//     }
//   }
//
//   return (<div>
//     <div className="d-flex justify-content-between">
//       <StyledContainer>
//         <SearchBox searchChange={handleSearchInputChange} />
//         {/* <Button
//             style={{
//               backgroundColor: "#036F36",
//               color: "#FFFDB8",
//               margin: "15px 10px",
//               border: "none",
//               fontSize: "14px",
//             }}
//             onClick={toggleModalFilter}
//           >
//             <i className="mdi mdi-filter-variant me-1" /> Filters
//           </Button> */}
//         <PartnerFilters
//           // typeOptions={orgTypesData.types?.map(val => ({
//           //   label: val.name,
//           //   value: val.id,
//           // }))}
//           countryOptions={countries?.map(country => ({
//             label: country.name, value: country.code
//           }))}
//           applyFilters={applyFilters}
//         />
//         {/* <Select
//             options={sortOptions}
//             defaultValue={sortBy}
//             className="basic-single"
//             classNamePrefix="select"
//             placeholder="Sort by"
//             onChange={option => setSortBy(option?.value || "ASC")}
//           /> */}
//         {areAnyFiltersSelected() && (<Button
//           className="d-flex align-items-center"
//           style={{
//             backgroundColor: "#f8f9fa", color: "black", margin: "15px 10px", border: "none", fontSize: "14px"
//           }}
//           onClick={clearFilters}
//         >
//           <i className="ph-x me-1"></i>
//           Clear All Filters
//         </Button>)}
//       </StyledContainer>
//       {/*<Button*/}
//       {/*  className="common-button"*/}
//       {/*  onClick={openModal}*/}
//       {/*  style={{ marginInline: 0 }}*/}
//       {/*>*/}
//       {/*  Create New*/}
//       {/*</Button>*/}
//       <ExportTable loadFilteredData={exportTable}></ExportTable>
//
//       {/* <Modal
//           isOpen={modalFilter}
//           toggle={toggleModalFilter}
//           className="modal-lg"
//         >
//           <ModalHeader toggle={toggleModalFilter}>
//             <i className="mdi mdi-filter-variant me-1"></i>
//             Filters
//           </ModalHeader>
//           <ModalBody>
//             <PartnerFilters
//               // typeOptions={orgTypesData.types?.map(val => ({
//               //   label: val.name,
//               //   value: val.id,
//               // }))}
//               countryOptions={countries?.map(country => ({
//                 label: country.name,
//                 value: country.code,
//               }))}
//               applyFilters={applyFilters}
//             />
//           </ModalBody>
//         </Modal> */}
//       <Modal
//         isOpen={showModal}
//         toggle={showModal ? closeModal : openModal}
//         style={{ borderRadius: "0.25rem" }}
//       >
//         <ModalHeader
//           style={{
//             alignItems: "flex-start", height: "80px", paddingBottom: "0px"
//           }}
//           toggle={showModal ? closeModal : openModal}
//         >
//           <b>
//             <p
//               style={{
//                 fontSize: "24px", marginBottom: "0px", color: "black"
//               }}
//             >
//               {isUserEditing ? (<>
//                 <i className="mdi mdi-plus me-2"></i>
//                 Edit Partner
//               </>) : (<>
//                 <i className="mdi mdi-plus me-2"></i>
//                 Create New Partner
//               </>)}
//             </p>
//           </b>
//         </ModalHeader>
//         <ModalBody>
//           <Label className="form-label" htmlFor="shortName">
//             Partner Short Name:
//           </Label>
//           <Input
//             id="shortName"
//             name="shortName"
//             type="text"
//             value={newPartner.short_name}
//             required
//             onChange={handleInputChange}
//           />
//           <Label className="form-label" htmlFor="longName">
//             Partner Long Name:
//           </Label>
//           <Input
//             id="longName"
//             name="longName"
//             type="text"
//             value={newPartner.long_name}
//             onChange={handleInputChange}
//           />
//           <Label className="form-label" htmlFor="TechnicalExpert">
//             Technical Expert:
//           </Label>
//           <div className="form-check form-switch">
//             <Input
//               id="TechnicalExpert"
//               name="TechnicalExpert"
//               type="checkbox"
//               className="form-check-input"
//               checked={newPartner.is_technical_expert}
//               onChange={handleInputChange}
//             />
//             <Label className="form-check-label" htmlFor="TechnicalExpert">
//               {newPartner.is_technical_expert ? "Yes" : "No"}
//             </Label>
//           </div>
//           {/*<Input*/}
//           {/*  id="TechnicalExpert"*/}
//           {/*  name="TechnicalExpert"*/}
//           {/*  className="toggle-switch"*/}
//           {/*  checked={newPartner.is_technical_partner}*/}
//           {/*  onChange={handleInputChange}*/}
//           {/*/>*/}
//         </ModalBody>
//         {/*<ModalBody>*/}
//         {/*  <div style={{ position: "relative" }}>*/}
//         {/*    <Label className="form-label" htmlFor="name">*/}
//         {/*      Partner Name:*/}
//         {/*    </Label>*/}
//         {/*    <Input*/}
//         {/*      id="name"*/}
//         {/*      type="text"*/}
//         {/*      value={userInput}*/}
//         {/*      required*/}
//         {/*      onChange={handleInputChange}*/}
//         {/*      onFocus={() => setHasVisitedInput(true)}*/}
//         {/*    />*/}
//         {/*    {partnerAlreadyExistsError && (<p style={{ color: "red" }}>*/}
//         {/*      An partner by this name already exits. Please retype the*/}
//         {/*      partner name and select it from the suggestions.*/}
//         {/*    </p>)}*/}
//         {/*    {partnerSuggestions.length > 0 && showSuggestions && (<ul*/}
//         {/*      style={{*/}
//         {/*        position: "absolute",*/}
//         {/*        top: "100%",*/}
//         {/*        left: 0,*/}
//         {/*        margin: 0,*/}
//         {/*        padding: 0,*/}
//         {/*        maxHeight: "100px",*/}
//         {/*        overflowY: "auto",*/}
//         {/*        zIndex: 999,*/}
//         {/*        width: "100%",*/}
//         {/*        backgroundColor: "#f2f2f2",*/}
//         {/*        listStyle: "none"*/}
//         {/*      }}*/}
//         {/*    >*/}
//         {/*      {partnerSuggestions?.map(suggestion => (<li*/}
//         {/*        key={suggestion.id}*/}
//         {/*        style={{ padding: "0.5rem", cursor: "pointer" }}*/}
//         {/*        onClick={() => {*/}
//         {/*          handleSuggestionSelection(suggestion)*/}
//         {/*        }}*/}
//         {/*      >*/}
//         {/*        {suggestion.name}*/}
//         {/*      </li>))}*/}
//         {/*    </ul>)}*/}
//         {/*  </div>*/}
//         {/*  <div>*/}
//         {/*    <Label className="form-label" htmlFor="name">*/}
//         {/*      Countries:*/}
//         {/*    </Label>*/}
//         {/*    <Select*/}
//         {/*      name="countries"*/}
//         {/*      classNamePrefix="select2-selection"*/}
//         {/*      options={countries?.map(country => ({*/}
//         {/*        label: country.label, value: country.id*/}
//         {/*      }))}*/}
//         {/*      isMulti*/}
//         {/*      value={selectedCountries}*/}
//         {/*      onChange={setSelectedCountries}*/}
//         {/*    />*/}
//         {/*  </div>*/}
//         {/*  /!* <div className="mb-3">*/}
//         {/*      <Label className="form-label">Organization Type:</Label>*/}
//         {/*      <Select*/}
//         {/*        name="orgType"*/}
//         {/*        options={*/}
//         {/*          selectedSuggestion*/}
//         {/*            ? selectedSuggestionsOrgType?.map(val => ({*/}
//         {/*                label: val.name,*/}
//         {/*                value: val.id,*/}
//         {/*              }))*/}
//         {/*            : orgTypesData.types?.map(val => ({*/}
//         {/*                label: val.name,*/}
//         {/*                value: val.id,*/}
//         {/*              }))*/}
//         {/*        }*/}
//         {/*        classNamePrefix="select2-selection"*/}
//         {/*        value={orgType || ""}*/}
//         {/*        onChange={setOrgType}*/}
//         {/*      />*/}
//         {/*    </div> *!/*/}
//         {/*</ModalBody>*/}
//         <ModalFooter
//           style={{
//             display: "flex", justifyContent: "flex-end", borderTop: "none", paddingTop: "0px"
//           }}
//         >
//           <div>
//             <Button
//               style={{ backgroundColor: "#50A050", border: "none" }}
//               onClick={handlePartnerEdit}
//             >
//               {isUserEditing ? "Save" : "Create"}
//             </Button>
//           </div>
//         </ModalFooter>
//       </Modal>
//     </div>
//     {isLoading && <LoadingSpinner />}
//     {!isLoading && partnerData?.length > 0 ? (
//       <>
//       <CustomTable
//         data={partnerData}
//         caption="Partners"
//         initiateEdit={initiateEdit}
//         onDeleteClick={onDeleteClick}
//       />
//       <Pagination
//         currentPage={currentPage}
//         goToPage={handlePageChange}
//         totalEntries={totalEntries}
//       />
//     </>
//     ) : null}
//     </div>
//   )
// }
//
// export default Partners
