import React, { useState, useEffect } from "react";
import { Button, Card, CardBody, CardHeader, ModalHeader, Modal, ModalBody } from "reactstrap";
import { Link } from "react-router-dom";
import SearchBox from "components/Common/SearchBox";
import Pagination from "components/Common/Pagination";
import FormsTable from "../FormsTable";
import LoadingSpinner from "components/Common/Loading-Spinner";
import { useSelector } from "react-redux";
import useDebounce from "hooks/useDebounce";
import { StyledContainer } from "pages/Labels/Systems";
import {
  deleteLandscapingFocalArea, getLandscapingFocalAreas
} from "../../../services/milestoneServices"
import ExportTable from "../../../components/Common/ExportTable";
import { exportTableData } from "services/common";
import LandscapingFilters from "./Filters";

const LandscapingFocalArea = ({alert}) => {
  // document.title = "Milestone Management | AGRA";

  const { user } = useSelector(state => state.Profile);

  const [searchField, setSearchField] = useState("");
  const debouncedSearchTerm = useDebounce(searchField);
  const [selectedLandscapingCategory, setSelectedLandscapingCategory] = useState([]);

  // const [deletingRowID, setDeletingRowID] = useState(null)

  const searchChange = e => {
    setSearchField(e.target.value.toLowerCase());
    setOffset(0);
    setCurrentPage(1);
  };

  const [modalFilter, setModalFilter] = useState(false);
  const toggleFilter = () => setModalFilter(prevState => !prevState);
  const applyFilters = (landscapingCategory) => {
    setSelectedLandscapingCategory(landscapingCategory);
    toggleFilter();
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [totalEntries, setTotalEntries] = useState(0);
  const [offset, setOffset] = useState(0);

  const goToPage = page => {
    if (page > 0) {
      const offsetParam = page * 50 - 50;
      setOffset(offsetParam);
      setCurrentPage(page);
    } else {
      console.error("Page number should be greater than 0");
    }
  };

  const checkForFilters = () => Boolean(selectedLandscapingCategory.length);

  const clearFilters = () => {
    setSelectedLandscapingCategory([]);
  };

  // Manage loading, error, and milestonesData states
  const [milestonesData, setMilestonesData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    localStorage.setItem("formType", "Landscaping");
  }, []);

  const fetchMilestones = async () => {
    setIsLoading(true);
    try {
      const filters = {
        searchField: debouncedSearchTerm,
        landscapingCategories: selectedLandscapingCategory?.map(category=>category.value),
        limit: 50,
        offset
      }
      const {data, count} = await getLandscapingFocalAreas(filters);
      setMilestonesData(data);
      setTotalEntries(count);
    } catch (error) {
      console.error(error);
      alert("Fetching data", false);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchMilestones();
  }, [debouncedSearchTerm, currentPage, selectedLandscapingCategory]);

    const [deleteModal, setDeleteModal] = useState({
        show: false,
        form: {},
    });

  const handleDeleteMilestone = async (id) => {

    // if (deletingRowID === null) return; // Ensure we have a valid ID

    const selectedRowData = milestonesData?.find((row) => row.id === id);

    try {
      let response;

      response = await deleteLandscapingFocalArea(selectedRowData.id)

      if (response && response.message) {
        alert(response.message, true);
      } else {
        alert("Milestone Deletion Failed", false);
      }
    } catch (err) {
      console.error(err);
      alert("Milestone Deletion Failed", false);
    } finally {
      setDeleteModal({ show: false });
      // Refetch the milestones after deletion
        fetchMilestones();
    }
  };

  const exportTable = async type => {
    try {
      const query = {
        export_format: type,
        // ...(selectedUser && { user_id: selectedUser }),
        // ...(selectedActivity && { activity: selectedActivity }),
      };

      const exportEntity = "Landscaping_FocalAreas"

      exportTableData(query, exportEntity)

      // exportTableData(query, formType+'_Milestones');

    } catch (err) {
      console.error(err)
      alert("Data export", false)
    }
  }

  const initiateEdit = (id) => {
    const selectedRowData = milestonesData?.find((row) => row.id === id);
    if (selectedRowData) {
      localStorage.setItem("MilestoneRecordData", JSON.stringify(selectedRowData));
    }
  };

  // Function to open the delete modal
  // const openDeleteModal = (id) => {
  //   setDeletingRowID(id); // Set the ID of the milestone to delete
  //   setDeleteModal({ show: true }); // Show the delete modal
  // };

  return (
    <div>
      <Card>
        <CardHeader className="bg-white">
          {/*<h4><i className="bx bx-file"></i> {title}</h4>*/}
          <div className="d-flex justify-content-between">
            <StyledContainer>
              <SearchBox searchChange={searchChange} />
              <Button
                className="common-button"
                onClick={toggleFilter}
              >
                <i className="mdi mdi-filter-variant me-1"></i>
                Filter
              </Button>

              {/* <MilestoneFilters /> */}

              <Modal isOpen={modalFilter} toggle={toggleFilter} className="modal-lg">
                <ModalHeader toggle={toggleFilter}>
                  <i className="mdi mdi-filter-variant me-1"></i>
                  Filters
                </ModalHeader>
                <ModalBody>
                  <LandscapingFilters
                    filteredLandscapingCategory = {selectedLandscapingCategory}
                    applyFilters={applyFilters}
                  />
                </ModalBody>
              </Modal>
              {/* <Select
                  options={sortOptions}
                  defaultValue={sortForm}
                  className="basic-single"
                  classNamePrefix="select"
                  placeholder="Sort by"
                  onChange={option => setSortForm(option?.value || "ASC")}
                /> */}
              {checkForFilters() && (
                <Button
                  className="common-button"
                  onClick={clearFilters}
                >
                  <i className="ph-x me-1"></i>
                  Clear All Filters
                </Button>
              )}
            </StyledContainer>
            {user.is_admin && (
              <div>
                <Link to={{
                  pathname: "/create-form",
                  state: { formType: "Landscaping" } // Pass the formType in state
                }}>
                  <Button className="common-button">+ Create New</Button>
                </Link>
              </div>
            )}
            <ExportTable loadFilteredData={exportTable}></ExportTable>
          </div>
        </CardHeader>
        {isLoading && <LoadingSpinner />}
        <CardBody>
          {milestonesData?.length > 0 ? (
            <>
              <FormsTable
                forms={milestonesData || []}
                userRole={user.role}
                formType={"Landscaping"}
                initiateEdit={initiateEdit}
                onDeleteClick={handleDeleteMilestone}
                caption="Landscaping"
                adminCountryLength={user?.user_country?.length}
              />
              <Pagination
                currentPage={currentPage}
                goToPage={goToPage}
                totalEntries={totalEntries}
              />
            </>
          ) : null}
        </CardBody>
      </Card>
    </div>
  );
};

export default LandscapingFocalArea