import React, { useState, useEffect } from "react";
import { Button, Card, CardBody } from "reactstrap";
import styled from "@emotion/styled";
import { fetchActiveUsers, fetchSupervisors } from "services/userServices";

const StyledButton = styled(Button)`
  width: 200px;
  margin: 30px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

const dropdownStyles = {
  border: "2px solid #ccc",
  borderRadius: "8px",
  padding: "5px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  cursor: "pointer",
};

const dropdownMenuStyles = {
  border: "1px solid #ccc",
  borderRadius: "8px",
  marginTop: "5px",
  padding: "10px",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  backgroundColor: "#fff",
};

const StepAddOwners = ({ form, handleInputChange, changeTab }) => {
  const [ownerOptions, setOwnerOptions] = useState([]);
  const [supervisorOptions, setSupervisorOptions] = useState([]);
  const [owners, setOwners] = useState(form?.owners || null);
  const [supervisors, setSupervisors] = useState(form?.supervisors || null);
  const [loadingOwners, setLoadingOwners] = useState(false);
  const [loadingSupervisors, setLoadingSupervisors] = useState(false);
  const [ownerDropdownOpen, setOwnerDropdownOpen] = useState(false);
  const [supervisorDropdownOpen, setSupervisorDropdownOpen] = useState(false);
  const [ownerSearch, setOwnerSearch] = useState("");
  const [supervisorSearch, setSupervisorSearch] = useState("");

  useEffect(() => {
    const fetchOwners = async () => {
      setLoadingOwners(true);
      try {
        const activeUsers = await fetchActiveUsers();
        const mappedUsers = activeUsers.map((user) => ({
          value: user.id,
          label: `${user.first_name} ${user.last_name} (${user.user_country[0]?.country?.short_name || "N/A"})`,
        }));
        setOwnerOptions(mappedUsers);
      } catch (error) {
        console.error("Error fetching owners:", error);
      } finally {
        setLoadingOwners(false);
      }
    };

    // const fetchSupervisors = async () => {
    //   setLoadingSupervisors(true);
    //   try {
    //     const admins = await fetchSupervisors();
    //     console.log("Supervisors fetched:", admins); 
    //     const mappedAdmins = admins.map((user) => ({
    //       value: user.id,
    //       label: `${user.first_name} ${user.last_name} (${user.user_country[0]?.country?.short_name || "N/A"})`,
    //     }));
    //     console.log("Mapped Supervisors:", mappedAdmins);
    //     setSupervisorOptions(mappedAdmins);
    //   } catch (error) {
    //     console.error("Error fetching supervisors:", error);
    //   } finally {
    //     setLoadingSupervisors(false);
    //   }
    // };
    const fetchSupervisorsData = async () => {
      setLoadingSupervisors(true);
      try {
        const admins = await fetchSupervisors();
        console.log("Supervisors fetched:", admins);
        const mappedAdmins = admins.map((user) => ({
          value: user.id,
          label: `${user.first_name} ${user.last_name} (${user.user_country[0]?.country?.short_name || "N/A"})`,
        }));
        console.log("Mapped Supervisors:", mappedAdmins);
        setSupervisorOptions(mappedAdmins);
      } catch (error) {
        console.error("Error fetching supervisors:", error);
      } finally {
        setLoadingSupervisors(false);
      }
    };

    fetchOwners();
    fetchSupervisorsData();
  }, []);

  const filteredOwnerOptions = ownerOptions.filter((option) =>
    option.label.toLowerCase().includes(ownerSearch.toLowerCase())
  );

  const filteredSupervisorOptions = supervisorOptions.filter((option) =>
    option.label.toLowerCase().includes(supervisorSearch.toLowerCase())
  );

  const handleOwnerSelect = (selectedOwner) => {
    setOwners(selectedOwner);
    handleInputChange("owners", selectedOwner);
    setOwnerDropdownOpen(false);
  };

  const handleSupervisorSelect = (selectedSupervisor) => {
    setSupervisors(selectedSupervisor);
    handleInputChange("supervisors", selectedSupervisor);
    setSupervisorDropdownOpen(false);
  };

  const clearOwnerSelection = () => {
    setOwners(null);
    handleInputChange("owners", null);
  };

  const clearSupervisorSelection = () => {
    setSupervisors(null);
    handleInputChange("supervisors", null);
  };

  const proceed = () => {
    handleInputChange("owners", owners);
    handleInputChange("supervisors", supervisors);
    changeTab("step2");
  };

  return (
    <Card className="mt-5" style={{ border: "none" }}>
      <CardBody style={{ border: "2px solid #f4dac6", borderRadius: "5px" }}>
        <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "1em" }}>
          {/* Owner Dropdown */}
          <div>
            <label className="block mb-1 text-sm">Select Owner*</label>
            <div
              style={dropdownStyles}
              onClick={() => setOwnerDropdownOpen(!ownerDropdownOpen)}
            >
              <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                <span>{owners?.label || "Search and select owner"}</span>
                {owners && (
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      clearOwnerSelection();
                    }}
                    className="text-red-500 hover:text-red-600"
                  >
                    <i className="ph-x-circle" />
                  </button>
                )}
              </div>
              <i className={`ph-caret-${ownerDropdownOpen ? "up" : "down"}`} />
            </div>
            {ownerDropdownOpen && (
              <div style={dropdownMenuStyles}>
                <input
                  type="text"
                  placeholder="Search owners..."
                  value={ownerSearch}
                  onChange={(e) => setOwnerSearch(e.target.value)}
                  className="w-full p-2 border border-gray-300 rounded mb-2 focus:outline-none"
                />
                <div className="space-y-1">
                  {filteredOwnerOptions.map((option) => (
                    <div
                      key={option.value}
                      className="p-2 hover:bg-gray-100 cursor-pointer rounded"
                      onClick={() => handleOwnerSelect(option)}
                    >
                      {option.label}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>

          {/* Supervisor Dropdown */}
          <div>
            <label className="block mb-1 text-sm">Select Supervisor*</label>
            <div
              style={dropdownStyles}
              onClick={() => setSupervisorDropdownOpen(!supervisorDropdownOpen)}
            >
              <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                <span>{supervisors?.label || "Search and select supervisor"}</span>
                {supervisors && (
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      clearSupervisorSelection();
                    }}
                    className="text-red-500 hover:text-red-600"
                  >
                    <i className="ph-x-circle" />
                  </button>
                )}
              </div>
              <i className={`ph-caret-${supervisorDropdownOpen ? "up" : "down"}`} />
            </div>
            {supervisorDropdownOpen && (
              <div style={dropdownMenuStyles}>
                <input
                  type="text"
                  placeholder="Search supervisors..."
                  value={supervisorSearch}
                  onChange={(e) => setSupervisorSearch(e.target.value)}
                  className="w-full p-2 border border-gray-300 rounded mb-2 focus:outline-none"
                />
                <div className="space-y-1">
                  {filteredSupervisorOptions.map((option) => (
                    <div
                      key={option.value}
                      className="p-2 hover:bg-gray-100 cursor-pointer rounded"
                      onClick={() => handleSupervisorSelect(option)}
                    >
                      {option.label}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </CardBody>

      <div className="d-flex justify-content-end mt-4">
        <StyledButton color="primary" onClick={proceed}>
          Next{" "}
          <i className="ph-caret-right" style={{ verticalAlign: "middle" }} />
        </StyledButton>
      </div>
    </Card>
  );
};

export default StepAddOwners;