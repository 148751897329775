import React from "react"
import { useSelector } from "react-redux"
import { useQuery } from "@tanstack/react-query"
import styled from "@emotion/styled"
import { getDashboardAdminTasks } from "services/reportServices"
import { Country } from "constants/global"
import { Table } from "reactstrap"
import { formateOnlyDate } from "helpers/utlities"
import { Link } from "react-router-dom"
import LoadingSpinner from "components/Common/Loading-Spinner"

const CenterContent = styled.div`
  min-height: 40vh;
  display: grid;
  place-items: center;
`

const AdminTasks = () => {
  const user = useSelector(state => state.Profile.user)
  const { country_id, country } = useSelector(state => state.Profile)

  const loadAdminTasks = async body => {
    try {
      const { data } = await getDashboardAdminTasks(body)
      return data
    } catch (err) {
      console.error(err)
      return err
    }
  }

  const {
    data: adminTasks,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["Admin Tasks", { country_id }],
    queryFn: () => loadAdminTasks({ country_id }),
    // enabled: !!country_id,
    staleTime: 60 * 1000,
  })
  // if (!country_id) {
  //   return (
  //     <CenterContent>
  //       <p>
  //         Please select a country from the above dropdown to see country
  //         specific Admin Tasks
  //       </p>
  //     </CenterContent>
  //   )
  // }

  if (isLoading) return <LoadingSpinner />

  if (!adminTasks?.length & !isError) {
    return (
      <CenterContent>
        <p>
          There are no policies in {Country[country]} that are currently pending
          for approval.
        </p>
      </CenterContent>
    )
  }
  return (
    <Table className="custom-table table-striped">
      <colgroup>
        <col style={{ width: "35%" }} />
        <col style={{ width: "25%" }} />
        <col style={{ width: "20%" }} />
        <col style={{ width: "20%" }} />
      </colgroup>
      <thead>
        <tr>
          <th>Policy Name</th>
          <th>Current Stage</th>
          <th>Deadline</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        {adminTasks.map(task => (
          <tr key={task.task_id}>
            <td>
              <Link to={`policy/${task.task_policy_id}`} target="_blank">
                <i className="mdi mdi-arrow-top-right" />
                <span className="font-size-14">{task.policy_policy_name}</span>
              </Link>
            </td>
            <td>
              <span
                className="badge badge-break-word"
                style={{
                  color: task.stage_color,
                  background: task.stage_backgroundColor,
                  fontSize: "13px",
                }}
              >
                {task.stage_id}: {task.stage_label}
              </span>
            </td>
            <td>
              <span>
                <i className="bx bx-bell me-1"></i>
                {task.policy_deadline
                  ? formateOnlyDate(task.policy_deadline)
                  : "NA"}
              </span>
            </td>
            <td className="status_cell">{task.task_status}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

export default AdminTasks
