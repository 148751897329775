import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import styled from "@emotion/styled";
import { BiArrowBack, BiCheckCircle, BiPlus, BiMinus } from "react-icons/bi";
import { Button, Input } from "reactstrap";
import { useMutation } from "@tanstack/react-query";
import {
  getEtrackerQuestionnaire,
  editPolicyETrackerMilestoneQuestion,
} from "services/milestoneServices";
import MilestoneStageDropdown from "./E-TrackerStageDropdown";
import ScoringGuide from "./ScoringGuide";
import Sidebar from "components/Common/UploadEvidence/UploadEvidenceSidebar";

const PageContent = styled.div`
  background-color: #f8f9fa;
  min-height: 100vh;
  padding: 2em;
  padding-top: 5em;
`;

const ProgressBarContainer = styled.div`
  width: 80%;
  height: 15px;
  background-color: #e0e0e0;
  border-radius: 10px;
  margin: 20px auto;
  overflow: hidden;
`;

const ProgressBarFill = styled.div`
  height: 100%;
  background-color: ${({ progress }) =>
    progress <= 50 ? "#ff4e4e" : progress <= 75 ? "#ff9900" : "#198754"};
  width: ${({ progress }) => `${progress}%`};
  transition: width 0.4s ease, background-color 0.4s ease;
`;

const MilestoneContainer = styled.div`
  margin-bottom: 1.5em;
`;

const RequiredTag = styled.span`
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: #f0f0f0;
  color: #888;
  font-size: 0.8rem;
  padding: 2px 8px;
  border-radius: 4px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const MilestoneHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 1em;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  cursor: pointer;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  h3 {
    margin: 0;
    font-size: 1.2em;
    font-weight: bold;
  }
  p {
    margin: 0.5em 0 0;
    font-size: 0.9em;
    color: #6c757d;
  }
`;

const QuestionBlock = styled.div`
  background-color: white;
  padding: 1.5em;
  margin: 10px 0;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
`;

const AnswerButton = styled.button`
  background-color: ${(props) => (props.selected ? "#d4f8d4" : "#f0f0f0")};
  color: ${(props) => (props.selected ? "#198754" : "#333")};
  border: 1px solid ${(props) => (props.selected ? "#198754" : "#e0e0e0")};
  padding: 10px 20px;
  border-radius: 8px;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  font-size: 1rem;
  display: flex;
  align-items: center;
  gap: 5px;
  width: 100%;
  opacity: ${(props) => (props.disabled ? 0.6 : 1)};
`;

const StyledButton = styled(Button)`
  width: 200px;
  margin: 30px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

function FillFormEtracker({ alert }) {
  const { policyId, stageId } = useParams();
  const { user } = useSelector((state) => state.Profile);
  const currentUserId = user?.id;

  const [formData, setFormData] = useState(null);
  const [responses, setResponses] = useState([]);
  const [progress, setProgress] = useState(0);
  const [selectedStageID, setSelectedStageID] = useState(parseInt(stageId));
  const [expandedMilestone, setExpandedMilestone] = useState(null);
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [selectedQuestionId, setSelectedQuestionId] = useState(null);
  const [evidenceData, setEvidenceData] = useState({});
  const [milestoneDetails, setMilestoneDetails] = useState({});
  const [updateForbidden, setUpdateForbidden] = useState(false);
  const [ownerCanUpdate, setOwnerCanUpdate] = useState(false);
  const [supervisorCanUpdate, setSupervisorCanUpdate] = useState(false);

  const history = useHistory();

  useEffect(() => {
    const loadFormDetails = async () => {
      try {
        const data = await getEtrackerQuestionnaire(policyId, selectedStageID);
        setFormData(data);

        const questions = data.policy_e_tracker_milestones.flatMap((milestone) =>
          milestone.milestone_questions.map((question) => ({
            milestone_id: milestone.milestone_id,
            question_id: question.question_id,
            question_response: question.question_response || "",
            question_note: question.question_note || "",
          }))
        );

        setResponses(questions);

        if (data.policy_e_tracker_milestones.length > 0) {
          const milestone = data.policy_e_tracker_milestones[0];
          const ownerId = milestone.owner_and_supervisor?.owner_id;
          const supervisorId = milestone.owner_and_supervisor?.supervisor_id;

          setMilestoneDetails({
            ownerId,
            supervisorId,
            isComplete: milestone.is_complete,
            approvedBySupervisor: milestone.approved_by_supervisor,
          });
        }
      } catch (error) {
        console.error("Error fetching form data:", error);
      }
    };

    loadFormDetails();
  }, [policyId, selectedStageID]);

  useEffect(() => {
    const { ownerId, supervisorId, isComplete, approvedBySupervisor } = milestoneDetails;

    if (ownerId && supervisorId) {
      const isOwnerAndSupervisorSame = ownerId === supervisorId;

      const canOwnerUpdate = currentUserId === ownerId && !approvedBySupervisor;
      const canSupervisorUpdate = currentUserId === supervisorId && isComplete;
      const forbiddenUpdate =
        (approvedBySupervisor && currentUserId === ownerId && !isOwnerAndSupervisorSame) ||
        (!isComplete && currentUserId !== ownerId && currentUserId !== supervisorId);

      setOwnerCanUpdate(canOwnerUpdate);
      setSupervisorCanUpdate(canSupervisorUpdate);
      setUpdateForbidden(forbiddenUpdate);
    }
  }, [milestoneDetails, currentUserId]);

  useEffect(() => {
    const totalQuestions = responses.length;
    const answeredQuestions = responses.filter((res) => res.question_response).length;
    setProgress(Math.floor((answeredQuestions / totalQuestions) * 100));
  }, [responses]);

  const toggleSidebar = (questionId) => {
    setSelectedQuestionId(questionId);
    setSidebarOpen((prev) => !prev);
  };

  const handleResponseChange = (question_id, answer) => {
    setResponses((prev) =>
      prev.map((res) =>
        res.question_id === question_id ? { ...res, question_response: answer } : res
      )
    );
  };

  const handleSave = () => {
    if (updateForbidden) {
      alert("You do not have permission to save this milestone.", false);
      return;
    }
    saveResponses();
  };

  const { mutate: saveResponses } = useMutation(
    async () => {
      for (const response of responses) {
        if (response.question_response) {
          const body = {
            milestone_update_request_body: {
              question_update_request_body: {
                question_response: response.question_response,
                question_response_score: response.question_response_score || 0,
              },
              question_evidence_update_request_bodies: [],
            },
          };

          if (response.question_note) {
            body.milestone_update_request_body.question_update_request_body.question_note =
              response.question_note;
          }

          const formData = new FormData();

          if (evidenceData[response.question_id]) {
            evidenceData[response.question_id].forEach((entry) => {
              const evidenceEntry = { evidence_name: entry.policyName };

              body.milestone_update_request_body.question_evidence_update_request_bodies.push(
                evidenceEntry
              );

              if (entry.file instanceof File) {
                formData.append("evidence_files", entry.file);
              }
            });
          }

          formData.append(
            "milestone_update_request_body",
            JSON.stringify(body.milestone_update_request_body)
          );

          await editPolicyETrackerMilestoneQuestion(
            policyId,
            selectedStageID,
            response.milestone_id,
            response.question_id,
            formData
          );
        }
      }
    },
    {
      onSuccess: () => {
        alert("All responses saved successfully!", true);
      },
      onError: (error) => {
        alert("Failed to save responses. Please try again.", false);
        console.error("Error saving responses:", error);
      },
    }
  );

  return (
    <PageContent>
      {updateForbidden && (
        <div className="alert alert-danger" role="alert" style={{ textAlign: "center", marginTop: "40px" }}>
          You do not have permission to edit this milestone.
        </div>
      )}

      <ProgressBarContainer>
        <ProgressBarFill progress={progress} />
      </ProgressBarContainer>
      <ScoringGuide />

      <div className="country-selection space-x-5">
        <span className="mr-2 text-nowrap">Select Stage: </span>
        <MilestoneStageDropdown
          selectedStageID={selectedStageID}
          setMilestoneStage={setSelectedStageID}
        />
      </div>

      <h2 style={{ fontSize: "26px", color: "#049046", paddingBottom: "10px", paddingTop: "10px" }}>
        {formData?.policy_name || "e-Tracker Form"}
      </h2>

      {formData?.policy_e_tracker_milestones?.map((milestone) => (
        <MilestoneContainer key={milestone.milestone_id}>
          <MilestoneHeader
            onClick={() => setExpandedMilestone(milestone.milestone_id)}
            style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "10px", borderBottom: "1px solid #ddd" }}
          >
            <div>
              <h3 style={{ marginBottom: "4px", fontSize: "18px", fontWeight: "bold" }}>
                {milestone.milestone_name}
              </h3>
              <p style={{ margin: "2px 0", fontSize: "14px", color: "#555" }}>
                <strong>Owner:</strong> {milestone.owner_and_supervisor.owner.first_name}{" "}
                {milestone.owner_and_supervisor.owner.last_name}
              </p>
              <p style={{ margin: "2px 0", fontSize: "14px", color: "#555" }}>
                <strong>Supervisor:</strong> {milestone.owner_and_supervisor.supervisor.first_name}{" "}
                {milestone.owner_and_supervisor.supervisor.last_name}
              </p>
            </div>
            <span style={{ fontSize: "20px", cursor: "pointer", color: "#333" }}>
              {expandedMilestone === milestone.milestone_id ? <BiMinus /> : <BiPlus />}
            </span>
          </MilestoneHeader>

          {expandedMilestone === milestone.milestone_id &&
            milestone.milestone_questions.map((question) => (
              <QuestionBlock key={question.question_id}>
                <RequiredTag>Required</RequiredTag>
                <p>{question.e_tracker_milestone_question.question}</p>

                {[
                  { display: "[1] Yes", value: "Yes" },
                  { display: "[0] No", value: "No" },
                ].map((option) => (
                  <AnswerButton
                    key={option.value}
                    selected={
                      responses.find((res) => res.question_id === question.question_id)
                        ?.question_response === option.value
                    }
                    onClick={() => handleResponseChange(question.question_id, option.value)}
                    disabled={updateForbidden}
                    style={{
                      width: "50%",
                      textAlign: "center",
                      padding: "12px 10px",
                      fontSize: "16px",
                      margin: "8px 0",
                      cursor: updateForbidden ? "not-allowed" : "pointer",
                      opacity: updateForbidden ? 0.6 : 1,
                    }}
                  >
                    <BiCheckCircle /> {option.display}
                  </AnswerButton>
                ))}

                <Button
                  color="primary"
                  onClick={() => toggleSidebar(question.question_id)}
                  style={{ marginTop: "15px" }}
                  disabled={updateForbidden}
                >
                  Upload Evidence
                </Button>
                <Input
                  type="textarea"
                  placeholder="Add a note..."
                  value={question.question_note || ""}
                  onChange={(e) =>
                    setResponses((prevResponses) =>
                      prevResponses.map((res) =>
                        res.question_id === question.question_id
                          ? { ...res, question_note: e.target.value }
                          : res
                      )
                    )
                  }
                  disabled={updateForbidden}
                  style={{
                    marginTop: "10px",
                    cursor: updateForbidden ? "not-allowed" : "pointer",
                    opacity: updateForbidden ? 0.6 : 1,
                  }}
                />
              </QuestionBlock>
            ))}
        </MilestoneContainer>
      ))}

      <div className="button-container">
        <StyledButton className="left-button" color="primary" onClick={() => history.goBack()}>
          <BiArrowBack style={{ marginRight: "4px" }} /> Go Back
        </StyledButton>

        {ownerCanUpdate || supervisorCanUpdate ? (
          <StyledButton
            className="right-button"
            color="primary"
            onClick={handleSave}
          >
            {ownerCanUpdate ? "Submit for Supervisor Approval" : "Approve Responses"}
          </StyledButton>
        ) : null}
      </div>



      <Sidebar isOpen={isSidebarOpen} onClose={() => setSidebarOpen(false)} />
    </PageContent>
  );
}

export default FillFormEtracker;