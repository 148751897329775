import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';

const downloadChart = async (id, setIsDownloading, filename) => {
    try {
        setIsDownloading(true);
        const chartElement = document.getElementById(id);
        if (!chartElement) {
            throw new Error('Chart element not found');
        }

        const canvas = await html2canvas(chartElement, {
            scale: 2,
            backgroundColor: '#ffffff',
            logging: false
        });

        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF({
            orientation: 'landscape',
            unit: 'mm'
        });

        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

        pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
        
        pdf.save(filename);
    } catch (error) {
        console.error('Error downloading chart:', error);
        alert('Error downloading chart', false);
    } finally {
        setIsDownloading(false);
    }
};

export default downloadChart;