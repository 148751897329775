import { get, post } from "services/AXIOS"
import { BENCHMARKING, BENCHMARKING_ANALYTICS, BENCHMARKING_ANALYTICS_PUBLIC } from "./CONSTANTS"

export const loadBenchmarkingForms = (query, body) =>
  post(
    `${BENCHMARKING}?limit=${query.limit}&offset=${(query.offsetParam, body)}`
  )

export const benchmarkingAnalytics = async (query) => {
  
  const queryString = new URLSearchParams(query).toString();

  const isLoggedIn = localStorage.getItem("token");
  const url = `${isLoggedIn ? BENCHMARKING_ANALYTICS : BENCHMARKING_ANALYTICS_PUBLIC}?${queryString}`;

  try {
    const response = await get(url); //TODO: use react query for caching
    return response;
  } catch (err) {
    console.error("Error fetching data:", err);
  }
}
