import { buildQueryParams } from "helpers/utlities"
import { get, post, put, patch, del, putFormData } from "./AXIOS"
import {
  TASKS_COUNT,
  CREATE_NEW_FORM,
  EDIT_SPECIFIC_MILESTONE,
  GET_BENCHMARKING_FORM_DETAILS,
  LIST_CURRENT_MILESTONE_OWNERS,
  PUBLISH_MILESTONE,
  SPECIFIC_FORM_QUESTIONS,
  ACCESSUSERLIST,
  BENCHMARKING_MILESTONES,
  ETRACKER_MILESTONES,
  COUNTRY_BENCHMARKING_MILESTONES,
  BENCHMARKING_TASKS,
  ETRACKER_TASKS,
  LANDSCAPING_TASKS,
  COUNTRY_LANDSCAPING_FOCAL_AREAS,
  LANDSCAPING_ANALYTICS,
  CREATE_ETRACKER_MILESTONE,
  CREATE_BENCHMARKING_MILESTONE,
  CREATE_LANDSCAPING_FOCAL_AREA,
  GET_LOCKED_ETRACKER_MILESTONE,
  GET_LOCKED_BENCHMARKING_MILESTONE,
  GET_LOCKED_LANDSCAPING_FOCAL_AREA,
  EDIT_ETRACKER_MILESTONE,
  EDIT_BENCHMARKING_MILESTONE,
  EDIT_LANDSCAPING_FOCAL_AREA,
  DELETE_ETRACKER_MILESTONE,
  DELETE_BENCHMARKING_MILESTONE,
  DELETE_LANDSCAPING_FOCAL_AREA,
  GET_LANDSCAPING_QUESTIONNAIRE,
  GET_MILESTONE_QUESTIONS,
  GET_ETRACKER_MILESTONE_QUESTIONS,
  ETRACKER_MILESTONES_STAGE,
  EDIT_COUNTRY_BENCHMARKING_MILESTONE_QUESTION,
  EDIT_COUNTRY_LANDSCAPING_FOCAL_AREA_QUESTION,
  EDIT_POLICY_ETRACKER_MILESTONE_QUESTION,
  LANDSCAPING_ANALYTICS_PUBLIC,
  LANDSCAPING_FOCAL_AREA_SELECT,
  LANDSCAPING_FOCAL_AREAS,
  COUNTRY_BENCHMARKING_MILESTONE,
  COUNTRY_LANDSCAPING_FOCAL_AREA,
  GET_UNLOCKED_ETRACKER_MILESTONE, GET_UNLOCKED_BENCHMARKING_MILESTONE, GET_UNLOCKED_LANDSCAPING_FOCAL_AREA
} from "./CONSTANTS"

export const tasksCount = () => get(TASKS_COUNT)

export const createNewForm = body => post(CREATE_NEW_FORM, body)

export const updateSpecificFormQuestions = (id, body) => {
  patch(`${SPECIFIC_FORM_QUESTIONS}/${id}`, body)
}

export const getCountrySpecificUsers = body => post(`${ACCESSUSERLIST}`, body)

export const getMilestoneOwners = id =>
  get(`${LIST_CURRENT_MILESTONE_OWNERS}/${id}`)

export const updateMilestoneOwners = (id, body) => {
  patch(`${LIST_CURRENT_MILESTONE_OWNERS}/${id}`, body)
}

export const publishMilestone = body => post(`${PUBLISH_MILESTONE}`, body)

export const getQuestionsOfExistingMilestone = id =>
  get(`${GET_QUESTIONS_OF_EXISTING_MILESTONE}/${id}`)

export const createETrackerMilestone = body => post(
  `${CREATE_ETRACKER_MILESTONE}`, body, { "headers": { "Content-Type": "application/json" } })


export const createBenchmarkingMilestone = body => post(
  `${CREATE_BENCHMARKING_MILESTONE}`, body, { "headers": { "Content-Type": "application/json" } })

export const createLandscapingFocalArea = body => post(
  `${CREATE_LANDSCAPING_FOCAL_AREA}`, body, { "headers": { "Content-Type": "application/json" } })

export const getLockedETrackerMilestone = (milestone_id) =>
  get(GET_LOCKED_ETRACKER_MILESTONE(milestone_id))

export const getUnlockedETrackerMilestone = (milestone_id) =>
  get(GET_UNLOCKED_ETRACKER_MILESTONE(milestone_id))

export const getStageMileStones = (stage_id) =>
  get(`${ETRACKER_MILESTONES_STAGE(stage_id)}`)

export const getLockedBenchmarkingMilestone = (milestone_id) =>
  get(GET_LOCKED_BENCHMARKING_MILESTONE(milestone_id))

export const getUnlockedBenchmarkingMilestone = (milestone_id) =>
  get(GET_UNLOCKED_BENCHMARKING_MILESTONE(milestone_id))

export const getLockedLandscapingFocalArea = (focal_area_id) =>
  get(GET_LOCKED_LANDSCAPING_FOCAL_AREA(focal_area_id))

export const getUnlockedLandscapingFocalArea = (focal_area_id) =>
  get(GET_UNLOCKED_LANDSCAPING_FOCAL_AREA(focal_area_id))

export const editETrackerMilestone = (milestone_id, body) =>
  put(EDIT_ETRACKER_MILESTONE(milestone_id), body, { "headers": { "Content-Type": "application/json" } })

export const editBenchmarkingMilestone = (milestone_id, body) =>
  put(EDIT_BENCHMARKING_MILESTONE(milestone_id), body, { "headers": { "Content-Type": "application/json" } })

export const editLandscapingFocalArea = (focal_area_id, body) =>
  put(EDIT_LANDSCAPING_FOCAL_AREA(focal_area_id), body, { "headers": { "Content-Type": "application/json" } })

export const deleteETrackerMilestone = (milestone_id) =>
  del(DELETE_ETRACKER_MILESTONE(milestone_id), { "headers": { "accept": "application/json" } })

export const deleteBenchmarkingMilestone = (milestone_id) =>
  del(DELETE_BENCHMARKING_MILESTONE(milestone_id), { "headers": { "accept": "application/json" } })

export const deleteLandscapingFocalArea = (focal_area_id) =>
  del(DELETE_LANDSCAPING_FOCAL_AREA(focal_area_id), { "headers": { "accept": "application/json" } })

export const updateSpecificMilestoneDetails = (id, body) =>
  patch(`${EDIT_SPECIFIC_MILESTONE}/${id}`, body)

export const getBenchmarkingFormDetails = query =>
  get(
    `${GET_BENCHMARKING_FORM_DETAILS}/${query.countryId}/${query.labelId}/${query.formId}`
  )

// export const getMilestones = (query, body) =>
//   post(`${GETFILTEREDFORMS}?limit=${query.limit}&offset=${query.offset}`, body)

export const getBenchmarkingMilestones = (query) => {
  const params = buildQueryParams({
    limit: query.limit,
    skip: query.offset,
    name: query.searchField,
    system_id: query.systems,
  });

  return get(`${BENCHMARKING_MILESTONES}?${params.toString()}`);
}

export const getBenchmarkingTasks = (query) => {
  const params = buildQueryParams({
    limit: query.limit,
    skip: query.offset,
    is_complete: query.complete,
    country_id: query.locations,
  });

  return get(`${BENCHMARKING_TASKS}?${params.toString()}`);
};

export const getCountryBenchmarkingMilestone = (country_id, milestone_id) => {
  return get(`${COUNTRY_BENCHMARKING_MILESTONE(country_id, milestone_id)}`);
}

export const getCountryBenchmarkingMilestones = (query) => {
  const params = buildQueryParams({
    limit: query.limit,
    skip: query.offset,
    is_complete: query.complete,
    country_id: query.locations,
  });

  return get(`${COUNTRY_BENCHMARKING_MILESTONES}?${params.toString()}`);
}

export const getETrackerMilestones = (query) => {
  const params = buildQueryParams({
    limit: query.limit,
    skip: query.offset,
    name: query.searchField,
    stage_id: query.stages,
  });

  return get(`${ETRACKER_MILESTONES}?${params.toString()}`);
}

export const getETrackerTasks = (query) => {
  const params = buildQueryParams({
    limit: query.limit,
    skip: query.offset,
    name: query.searchField,
    system_id: query.systems,
    country_id: query.locations,
    stage_id: query.stages,
    landscaping_category: query.categories,
    is_off_track: query.offTrack,
    is_delayed: query.delayed
  });
  return get(`${ETRACKER_TASKS}?${params.toString()}`);
};

export const getLandscapingFocalAreas = (query) => {
  const params = buildQueryParams({
    limit: query.limit,
    skip: query.offset,
    landscaping_category: query.landscapingCategories,
  });
  return get(`${LANDSCAPING_FOCAL_AREAS}?${params.toString()}`);
};


export const selectLandscapingFocalAreas = (category) => {
  if (!["MACRO", "MESO", "MICRO"].includes(category)) {
    console.error(`Invalid landscaping category: ${category}`);
    return Promise.reject("Invalid landscaping category");
  }

  return get(`${LANDSCAPING_FOCAL_AREA_SELECT}?landscaping_category=${category}`)
    .then(response => {
      // Check if response is directly the array of focal areas
      if (!response || !Array.isArray(response)) {
        console.warn("No valid data array received for focal areas");
        return []; // Return an empty array to avoid errors in the dropdown
      }
      // Map the array directly
      return response.map(item => ({
        value: item.id,
        label: item.name,
      }));
    })
    .catch(error => {
      console.error("Error fetching landscaping focal areas:", error);
    });
};


export const getCountryLandscapingFocalAreas = (query) =>{
  const params = buildQueryParams({
    limit: query.limit,
    skip: query.offset,
    country_id: query.locations,
    is_complete: query.complete
  });

  return get(`${COUNTRY_LANDSCAPING_FOCAL_AREAS}?${params.toString()}`);
}

export const getLandscapingTasks = (query) =>{
  const params = buildQueryParams({
    limit: query.limit,
    skip: query.offset,
    country_id: query.locations,
    is_complete: query.complete
  });

  return get(`${LANDSCAPING_TASKS}?${params.toString()}`);
}

// Function to dynamically request different view types (tabular or chart)
export const getLandscapingAnalytics = async (query) => {
  const queryString = new URLSearchParams(query).toString();
  const isLoggedIn = localStorage.getItem("token");
  const url = `${isLoggedIn ? LANDSCAPING_ANALYTICS : LANDSCAPING_ANALYTICS_PUBLIC }?${queryString}`;
  
  try {
    const response = await get(url);
    return response; // Return the full response
  } catch (error) {
    console.error(`Error fetching ${query.view_type} data:`, error);
  }
};

// export const getLandscapingQuestionnaire = (query) =>
// get(`${GET_LANDSCAPING_QUESTIONNAIRE}?countryId =${query.countryId}&focalAreaId =${query.focalAreaId}`)

export const getCountryLandscapingFocalArea = (country_id, focal_area_id) => {
  return get(`${COUNTRY_LANDSCAPING_FOCAL_AREA(country_id, focal_area_id)}`);
}

export const getLandscapingQuestionnaire = (country_id, focal_area_id) => {
  return get(GET_LANDSCAPING_QUESTIONNAIRE(country_id, focal_area_id))
}

export const getBenchmarkingQuestionnaire = (country_id, milestone_id) => {
  return get(GET_MILESTONE_QUESTIONS(country_id, milestone_id))
}

export const getEtrackerQuestionnaire = (policy_id, stage_id) => {
  return get(GET_ETRACKER_MILESTONE_QUESTIONS(policy_id, stage_id))
  .then((response) => {
    return response;
  })
  .catch((error) => {
    console.error("Error fetching E-tracker question data:", error); // Log any error that occurs
  });
}

// export const editCountryBenchmarkingMilestoneQuestion = (country_id, milestone_id, question_id, body) =>
//   put(EDIT_COUNTRY_BENCHMARKING_MILESTONE_QUESTION(country_id, milestone_id, question_id), body, {
//     "headers": {
//       "Accept": "application/json",
//       "Content-Type": "application/json",
//     }
//   })

export const editPolicyETrackerMilestoneQuestion = (policy_id, stage_id, milestone_id, question_id, body) =>
  putFormData(EDIT_POLICY_ETRACKER_MILESTONE_QUESTION(policy_id, stage_id, milestone_id, question_id), body, {
    "headers": {
      "Accept": "application/json",
      // "Content-Type": "application/json",
    }
  })

// export const editPolicyETrackerMilestoneQuestion = (
//   policy_id,
//   stage_id,
//   milestone_id,
//   question_id,
//   body
// ) =>
//   putFormData(
//     EDIT_POLICY_ETRACKER_MILESTONE_QUESTION(
//       policy_id,
//       stage_id,
//       milestone_id,
//       question_id
//     ),
//     body,
//     {
//       headers: {
//         Accept: "application/json",
//         "Content-Type": "application/json",
//       },
//     }
//   );


export const editCountryBenchmarkingMilestoneQuestion = (country_id, milestone_id, question_id, body) => {

  return putFormData(
    EDIT_COUNTRY_BENCHMARKING_MILESTONE_QUESTION(country_id, milestone_id, question_id),
    body,
    {
      "headers": {
        // "content-type": "multipart/form-data"
        "accept": "application/json",
      }
    }
  );
};


export const editCountryLandscapingFocalAreaQuestion = (country_id, focal_area_id, question_id, body) => {

  return putFormData(
    EDIT_COUNTRY_LANDSCAPING_FOCAL_AREA_QUESTION(country_id, focal_area_id, question_id),
    body,
    {
      "headers": {
        // "content-type": "application/json",
        "accept": "application/json",
      }
    }
  );
};