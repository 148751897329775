import { buildQueryParams } from "helpers/utlities";
import { get, post, put } from "./AXIOS";

import { GET_POLICIES,POST_POLICY_VIEW,GET_PUBLIC_POLICIES,GET_PUBLIC_POLICY_URL, EDIT_SYSTEM } from "./CONSTANTS";

export const getPolicies = (query) => {
  const params = buildQueryParams({
    limit: query.limit,
    skip: query.offset,
    name: query.searchTerm,
    is_agra_supported: query.AGRASupported?.value,
    system_id: query.systems.map(system => system.value),
    country_id: query.locations.map(location => location.value),
    stage_id: query.stages.map(stage => stage.value),
    landscaping_category: query.categories.map(category => category.value),
    policy_type: query.policyTypes.map(policyType => policyType.value.toUpperCase()),
    is_off_track: query.isOffTrack?.value,
    is_delayed: query.isDelayed?.value
  });
  
  return get(`${GET_POLICIES}?${params.toString()}`);
}

export const getPublicPolicies = (query) =>{
  const params = buildQueryParams({
    limit: query.limit,
    skip: query.offset,
    name: query.searchTerm,
    is_agra_supported: query.AGRASupported?.value,
    system_id: query.systems?.map(system => system.value),
    country_id: query.locations?.map(location => location.value),
    stage_id: query.stages?.map(stage => stage.value),
    landscaping_category: query.categories?.map(category => category.value),
    is_off_track: query.isOffTrack?.value,
    is_delayed: query.isDelayed?.value
  });

  return get(`${GET_PUBLIC_POLICIES}?${params.toString()}`);
}

// export const savePolicyView = (id,data) =>
//     post(`${POST_POLICY_VIEW(id)}`, data, { headers: { "Content-Type": "application/json" } })
export const savePolicyView = async (policyId, requestBody) => {
  console.log(`Sending request to: ${POST_POLICY_VIEW(policyId)}`);
  console.log("Request Body:", requestBody);

  try {
    const response = await post(POST_POLICY_VIEW(policyId), requestBody, {
      headers: { "Content-Type": "application/json" } // Ensure JSON
    });

    console.log("API Response:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error saving policy view:", error.response ? error.response.data : error);
    throw error;
  }
};

export const getPolicyDownloadUrl = (policy_id) =>
    get(GET_PUBLIC_POLICY_URL(policy_id))

export const editSpecificPolicyType = (id, body) =>
    post(`${GET_SPECIFIC_ORGANIZATION_TYPE_DETAILS}/${id}`, body)

export const editSpecificPolicy = (id, body) =>
    post(`${GET_SPECIFIC_POLICY_DETAILS}/${id}`, body)

export const getPolicySuggestions = input =>
    get(`${GET_POLICY_SUGGESTIONS}/${input}`)

// New function to edit system information
export const editSystemInformation = (systemId, body) =>
    put(`${EDIT_SYSTEM}/${systemId}`, body)