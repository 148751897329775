import React, { useState } from 'react';
import styles from "./expandable.module.css";

const ExpandableSystemStats = ({id,title, children}) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const togglePanel = () => {
        setIsExpanded(prevState => !prevState);
    };

    return (
        <div className={`${styles.expandablePanel} border border-[#EAEAEA] shadow-sm rounded-lg items-center`}>
            <div className={`${styles.panelHeader}`} onClick={togglePanel}>
                <h1 className="text-[#036F36] font-semibold text-lg">
                    {title}
                </h1>
                <button>{isExpanded ? 'Less' : 'More'}</button>
            </div>
            <div className={`${styles.panelContent} ${isExpanded ? `${styles.expanded}` : ''}`}>
                {children}
            </div>
      </div>
    );
};

export default ExpandableSystemStats;