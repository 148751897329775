import React, { useMemo } from 'react';
import { Chart as ChartJS, BarElement, LineElement, CategoryScale, LinearScale, PointElement } from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(BarElement, LineElement, CategoryScale, LinearScale, PointElement);

const DurationChart = ({chartData, title}) => {

  const data = {
    labels: ['1', '2', '3', '4', '5', '6'],
    datasets: [
      {
        type: 'bar',
        label: 'Time in Months',
        data: chartData,
        backgroundColor: '#ccf365',
        borderWidth: 1,
      },
      {
        type: 'line',
        label: 'Duration Trend',
        data: chartData,
        borderColor: 'green',
        borderWidth: 2,
        fill: false,
        tension: 0,  // To give a curved line
        pointBackgroundColor: 'green',
      },
    ],
  };

  //TODO: Fix useMemo error in console
  const max = useMemo(()=>{
    const maxValue = Math.max(...(chartData || [0]));
    const result = maxValue + 10;
    return result;
  }, [chartData]);

  const options = {
    responsive: false,
    maintainAspectRatio: true,  
    scales: {
      y: {
        beginAtZero: true,
        max: max,
        title: {
          display: true,
          text: 'Time in Months',
          color: 'green',
          font: {
            size: 14,
          },
        },
        ticks: {
          color: 'green',
        },
      },
      x: {
        title: {
          display: true,
          text: 'Stage',
          color: 'green',
          font: {
            size: 14,
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <div className="chart-container">
      <h3 className="section-title">{title}</h3>
      <div className='h-full flex items-center'>
        <Bar data={data} options={options} height={220} width={500}/>
      </div>
    </div>
  )
};

export default DurationChart;