// Import statements
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import FormsTable from "./LandscapingTable";
import Pagination from "components/Common/Pagination";
import SearchBox from "components/Common/SearchBox";
import ExportTable from "../../components/Common/ExportTable";
import { exportTableData } from "services/common";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import {
  getCountryLandscapingFocalAreas,
} from "../../services/milestoneServices";
import useDebounce from "hooks/useDebounce"; // Added debounce for optimized search
import LandscapingTasksFilters from "pages/Tasks/Landscaping/Filters";

const DataActionsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 96%;
`;

// CountryLandscaping component
const CountryLandscaping = ({alert }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [focalAreasData, setFocalAreasData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalEntries, setTotalEntries] = useState(0);
  const [offset, setOffset] = useState(0);
  const [searchField, setSearchField] = useState("");
  const debouncedSearchTerm = useDebounce(searchField, 500); // Use debounced search for better performance
  const [selectedLocations, setSelectedLocations] = useState([])
  const [complete, setComplete] = useState(null);

  const [modalFilter, setModalFilter] = useState(false);
  const toggleFilter = () => setModalFilter((prevState) => !prevState);

  const applyFilters = (locations, complete) => {
    setSelectedLocations(locations);
    setComplete(complete);
    toggleFilter();
  };

  const checkForFilters = () => selectedLocations.length || complete;

  const clearFilters = () => {
    setSelectedLocations([]);
    setComplete(null);
  };

  const searchChange = (e) => {
    setSearchField(e.target.value.toLowerCase());
    setOffset(0);
    setCurrentPage(1);
  };

  const goToPage = (page) => {
    const offsetParam = (page - 1) * 50;
    setOffset(offsetParam);
    setCurrentPage(page);
  };

  const exportTable = async (type) => {
    try {
      const query = { export_format: type };
      exportTableData(query, "Country_Landscaping_Focal_Areas");
    } catch (err) {
      console.error(err);
      alert("Data export failed", false);
    }
  };

  useEffect(() => {
    const fetchFocalAreas = async () => {
      setIsLoading(true);
      const filters = {
        searchField: debouncedSearchTerm,
        locations: selectedLocations?.map(location=>location.value),
        complete: complete?.value,
        limit: 50,
        offset
      };
      try {
        const { data, count } = await getCountryLandscapingFocalAreas(filters);
        setFocalAreasData(data);
        setTotalEntries(count);
      } catch (error) {
        setFocalAreasData([]);
        setTotalEntries(0);
        console.error("Error fetching focal areas data:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchFocalAreas();
  }, [currentPage, debouncedSearchTerm, selectedLocations, complete]);

  return (
    <>
        <DataActionsContainer>
          <SearchBox searchChange={searchChange} />
          <Button className="common-button" onClick={toggleFilter}>
            <i className="mdi mdi-filter-variant me-1" /> Filters
          </Button>
          {checkForFilters() && (
            <Button
              className="common-button"
              // style={{
              //   backgroundColor: "#f8f9fa",
              //   color: "black",
              //   margin: "15px 10px",
              //   border: "none",
              //   fontSize: "14px",
              // }}
              onClick={clearFilters}
              >
              <i className="ph-x me-1"></i>Clear All Filters
            </Button>
          )}
          <div className="ml-auto">
            <ExportTable loadFilteredData={exportTable} />
          </div>
        </DataActionsContainer>
        <Modal isOpen={modalFilter} toggle={toggleFilter} className="modal-lg">
          <ModalHeader toggle={toggleFilter}>
            <i className="mdi mdi-filter-variant me-1"></i>
            Filters
          </ModalHeader>
          <ModalBody>
            <LandscapingTasksFilters
              filteredLocations={selectedLocations}
              filteredComplete={complete}
              applyFilters={applyFilters}
            />
          </ModalBody>
        </Modal>

        {focalAreasData?.length > 0 ? (
        <>
          <FormsTable forms={focalAreasData} />
          <Pagination
            currentPage={currentPage}
            goToPage={goToPage}
            totalEntries={totalEntries}
          />
        </>
        ) : (
        <p>No data available.</p>
        )}
    </>
  );
};

export default CountryLandscaping;