import {
  LIST_COUNTRIES_SUCCESS,
  LIST_COUNTRIES_FAIL,
  LIST_STAGES_SUCCESS,
  LIST_STAGES_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  countries: [],
  stages: [],
  landscaping_stages: [],
  tracker_stages: [],
  error: {},
}

const list = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LIST_COUNTRIES_SUCCESS:
      return {
        ...state,
        countries: action.payload,
      }

    case LIST_COUNTRIES_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case LIST_STAGES_SUCCESS:
      return {
        ...state,
        stages: action.payload,
        landscaping_stages: action.payload.filter(
          stage => stage.type == "landscaping"
        ),
        tracker_stages: action.payload.filter(stage => stage.type == "tracker"),
        benchmarking_stages: action.payload.filter(stage => stage.type == "benchmarking") 
        //TODO: Remove stages, landscaping_stages, tracker_stages, and benchmarking_stages
      }

    case LIST_STAGES_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default list
