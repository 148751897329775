import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Row, Col, Input, Button } from "reactstrap";
import { FaTrashAlt } from "react-icons/fa"; // Import trash icon
import "./StepTwo.css"; // Import the CSS file
import styled from "@emotion/styled"


const StyledButton = styled(Button)`
  width: 200px;
  margin: 30px auto;
  // display: block;
  display: flex;
  align-items: center; /* Align icon and text vertically */
  justify-content: center; /* Center text and icon */
  gap: 8px; 
`;


const StepTwo = ({ questions, setQuestions, formType }) => {
  const questionObject = {
    question_text: "",
    required: false,
    evidenceRequired: false, // For Benchmarking and Landscaping
  };

  const onDragEnd = (data) => {
    const tempQuestions = [...questions];
    const fromIndex = data.source.index;
    const toIndex = data.destination.index;
    const fromQuestion = tempQuestions[fromIndex];
    tempQuestions[fromIndex] = tempQuestions[toIndex];
    tempQuestions[toIndex] = fromQuestion;
    setQuestions(tempQuestions);
  };

  const questionHandler = (operation, index, key, value) => {
    const tempQuestions = [...questions];
    switch (operation) {
      case "add":
        const newQuestion = { ...questionObject };
        tempQuestions.push(newQuestion);
        break;
      case "change":
        tempQuestions[index][key] = value;
        break;
      case "remove":
        tempQuestions.splice(index, 1);
        break;
      default:
        break;
    }
    setQuestions(tempQuestions);
  };

  return (
    <div className="step-two-container">
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {questions.map((question, index) => (
                <Draggable key={index} draggableId={index.toString()} index={index}>
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className="question-card"
                    >
                      <Row>
                        <Col md="12">
                          <Input
                            className="question-text-input"
                            value={question.question_text}
                            placeholder="Enter the question text"
                            onChange={(e) =>
                              questionHandler("change", index, "question_text", e.target.value)
                            }
                          />
                        </Col>
                      </Row>
                      <Row className="action-row">
                        <Col md="6" className="required-switch">
                          <div className="switch-inline">
                            <label className="required-label">Required question?</label>
                            <div
                              className={`switch-container ${
                                question.required ? "toggle-yes" : "toggle-no"
                              }`}
                            >
                              <input
                                type="checkbox"
                                className="toggle-switch"
                                checked={question.required}
                                onChange={(e) =>
                                  questionHandler("change", index, "required", e.target.checked)
                                }
                              />
                              <span>{question.required ? "Yes" : "No"}</span>
                            </div>
                          </div>
                        </Col>

                        {/* For Benchmarking and Landscaping: Is Evidence Required toggle */}
                        {(formType === "benchmarking" || formType === "landscaping") && (
                          <Col md="6" className="evidence-switch">
                            <div className="switch-inline">
                              <label className="required-label">Is Evidence Required?</label>
                              <div
                                className={`switch-container ${
                                  question.evidenceRequired ? "toggle-yes" : "toggle-no"
                                }`}
                              >
                                <input
                                  type="checkbox"
                                  className="toggle-switch"
                                  checked={question.evidenceRequired}
                                  onChange={(e) =>
                                    questionHandler("change", index, "evidenceRequired", e.target.checked)
                                  }
                                />
                                <span>{question.evidenceRequired ? "Yes" : "No"}</span>
                              </div>
                            </div>
                          </Col>
                        )}

                        <Col className="button-container">
                          <Button
                            color="danger"
                            className="delete-question-btn"
                            onClick={() => questionHandler("remove", index)}
                            style={{ display: 'flex', alignItems: 'center', gap: '2px' }}
                          >
                            <FaTrashAlt className="delete-icon-inline" /> Delete
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <div className="add-question-area">
        <StyledButton 
        // className="add-question-btn"
        className="left-button"
          color="primary"
         onClick={() => questionHandler("add")}>
          <i className="ph-plus-circle" /> Add Question
        </StyledButton>
      </div>
    </div>
  );
};

export default StepTwo;
