import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { useParams } from "react-router-dom"
import countriesFlag from "common/countriesFlag.js"
import { downloadPolicy, getPolicy } from "services/policyServices.js"
import { Icon } from "@iconify/react"
import { Link } from 'react-router-dom';
import DeletePolicy from "./DeletePolicy.jsx";
import CountDisplay from "pages/E-Tracker/CountDisplay.jsx"
import DurationChart from "pages/E-Tracker/Charts/DurationChart.jsx"
import "./durations.css";
import TimeBar from "./TimeBar.jsx"
import Sidebar from "pages/PolicyDirectory/PolicyInformation-Sidebar.jsx"
import { getStageMileStones } from "../../services/milestoneServices"

export const Policy = ({ alert }) => {
  const { id } = useParams();
  const [openCanvas, setOpenCanvas] = useState(false);
  const toggleCanvas = () => setOpenCanvas(!openCanvas);
  const [canvasTab, setCanvasTab] = useState(1);
  const [isLoading, setIsLoading] = useState(true); // Add loading state
  const [stageMilestoneCount, setStageMilestoneCount] = useState(0);

  const [stageSelectDropdown, setStageSelectDropdown] = useState({
    stage: null,
    show: false,
    hover: false,
  })

  const [policy, setPolicy] = useState(null)

  useEffect(() => {
    setIsLoading(true); // Set loading state to true before fetching
    setIsLoading(true); // Set loading state to true before fetching
    getPolicy(id)
      .then((policy) => {
        setPolicy(policy);
        setIsLoading(false); // Set loading state to false after fetching
      })
      .catch((err) => {
        alert('Fetching policy details', false);
        console.error(err);
        setIsLoading(false); // Set loading state to false even on error
        setIsLoading(false); // Set loading state to false even on error
      });
  }, [id]);

  useEffect(() => {
    if (policy && policy?.stage) {
      setStageSelectDropdown({
        ...stageSelectDropdown,
        stage: policy?.stage,
      })
      getPolicyForms(policy.stage)
    }
  }, [policy])

  useEffect(() => {
    if (policy) {
      getStageMileStones(policy.policy_data.stage_id)
        .then((data) => {
          setStageMilestoneCount(data.milestones.count)
        })
        .catch((err) => {
          alert("Fetching stage milestones failed", false)
          console.error("Milestone fetch error:", err)
        })
    }
  }, [policy])

  if (isLoading) {
    return <div className="page-content">Loading Policy...</div>; // Show loading state
  }

  if(!policy) return <div className="page-content">Policy Not Found</div>;

  return (
    <div className="page-content">
      <div className="flex justify-between items-center mx-3">
        <h1 className="text-2xl">{policy?.policy_data?.name}</h1>
        <div className="flex items-center">
          <DeletePolicy alert={alert} />
          <button
            onClick={() => window.open(`/policy/edit/${id}`, '_blank')}
            className="common-button"
          >
            <i className="ph-pencil-simple"></i>Edit Policy
          </button>
        </div>
      </div>

      <div className="grid grid-cols-2">
        <div className="mx-3">
          <div className="rounded border-1 p-3">
            <div className="d-flex">
              <img
                src={countriesFlag[policy?.policy_data?.country?.short_name]}
                height="23"
                width="23"
                className="me-2 country-flag"
              />
              <span>{policy?.policy_data?.country?.long_name}</span>
            </div>
            <div className="d-flex align-items-center justify-between my-3">
              <div>
                <button
                  className="common-outlined-button"
                  onClick={()=>{
                    toggleCanvas();
                    setCanvasTab('updates');
                  }}
                >
                  <Icon icon="solar:three-squares-outline" />
                  Updates and Emerging Outcomes
                </button>
                <button
                  className="common-outlined-button"
                  onClick={() => {
                    toggleCanvas();
                    setCanvasTab('details');
                  }}
                >
                  <Icon icon="typcn:document" />
                  View Policy Details
                </button>
                { policy?.policy_data?.policy_file ?
                  <button
                    className="common-outlined-button"
                    onClick={()=>downloadPolicy(id, policy?.policy_data?.name, policy?.policy_data?.policy_file, ()=>alert("Policy download", false))}
                  >
                    <Icon icon="material-symbols:download" />
                    Download Policy</button>
                  : null
                }
              </div>
              <div className="flex flex-col items-center">
                <p>Policy Win</p>
                {policy?.is_policy_win ?
                  <Icon icon="noto:trophy" height={58} />
                  :
                  <Icon icon="tabler:trophy-off" height={58} />
                }
                <p className="bg-gray-200 rounded-full px-3 my-1">Score: {policy?.policy_data?.average_score ?? 'N/A'}</p>
                {policy?.is_off_track && <p className="bg-red-500 rounded-full px-3 my-1">OFF TRACK</p>}
                {policy?.is_delayed && <p className="bg-red-500 rounded-full px-3 my-1">DELAYED</p>}
                {policy?.is_completed ? <p className="bg-green-400 rounded-full px-3 my-1">COMPLETED</p> : <p className="bg-gray-200 rounded-full px-3 my-1">PENDING</p>}
              </div>
            </div>
            <p className="font-medium my-3">Stage {policy?.policy_data?.stage_id} - {policy?.policy_data?.stage?.name}</p>
            <div className="my-3">
              <div className="divide-y divide-green-800 text-green-800 my-2"><p>Systems</p><div></div></div>
              <div className="divide-x divide-gray-400 flex space-x-3 my-1">
                {policy?.policy_data?.policy_systems?.map((system, i) => (
                  <p key={i}>{system?.system?.name}</p>
                ))}
              </div>
            </div>
            <div className="my-3">
              <div className="divide-y divide-green-800 text-green-800 my-2"><p>Partners</p><div></div></div>
              <div className="divide-y divide-gray-400">
                {policy?.policy_data?.policy_partners?.map((partner, i) => (
                  <div key={i} className="flex my-1 space-x-3 items-center py-1">
                    <p>{partner?.partner?.long_name}</p>
                    {partner?.partner?.is_technical_expert && <span className="bg-green-300 text-green-800 rounded-full px-2 py-1">Technical</span>}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="grid grid-cols-2 bg-gray-200 rounded p-3 mt-3">
            <div>
              <p>Created at: {policy?.policy_data?.created_at}</p>
              <p>Created by: {policy?.policy_data?.created_by?.first_name + ' ' + policy?.policy_data?.created_by?.last_name}</p>
            </div>
            <div>
              {policy?.policy_data?.updated_at && <p>Updated on: {policy?.policy_data?.updated_at}</p>}
              {policy?.policy_data?.updated_by && <p>Updated by: {policy?.policy_data?.updated_by?.first_name + ' ' + policy?.policy_data?.updated_by?.last_name}</p>}
            </div>
          </div>
        </div>
        <div className="mx-3">
          <div className="rounded border-1 p-3 flex flex-col items-center mb-3">
            <CountDisplay icon={'milestone'} title={'Number of Milestones'} count={stageMilestoneCount} />
            {/* <button className="common-button">Respond to Milestone Questions <Icon icon="grommet-icons:link-next" /></button> */}

            {/* {formId && (
              <Link to={`/etracker-forms/${id}/${formId}`} className="common-button">
                Respond to Milestone Questions
                <Icon icon="grommet-icons:link-next" />
              </Link>
            )} */}
            <Link to={`/etracker-forms/${id}/${policy?.policy_data?.stage_id}`} className="common-button">
              Respond to Milestone Questions
              <Icon icon="grommet-icons:link-next" />
            </Link>

          </div>

          <div className="border hidden border-lg my-4 border-gray-200">
              <table className="w-full durationsTable">
                <thead>
                  <tr>
                    <th>Stage</th>
                    <th>Duration</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Intiation</td>
                    <td>
                      <TimeBar months={policy?.policy_stage_duration?.durations_in_months?.[0]} />
                    </td>
                  </tr>
                  <tr>
                    <td>Development</td>
                    <td>
                      <TimeBar months={policy?.policy_stage_duration?.durations_in_months?.[1]} />
                    </td>
                  </tr>
                  <tr>
                    <td>Validation</td>
                    <td>
                      <TimeBar months={policy?.policy_stage_duration?.durations_in_months?.[2]} />
                    </td>
                  </tr>
                  <tr>
                    <td>Approval</td>
                    <td>
                      <TimeBar months={policy?.policy_stage_duration?.durations_in_months?.[3]} />
                    </td>
                  </tr>
                  <tr>
                    <td>Legislation</td>
                    <td>
                      <TimeBar months={policy?.policy_stage_duration?.durations_in_months?.[4]} />
                    </td>
                  </tr>
                  <tr>
                    <td>Implementation</td>
                    <td>
                      <TimeBar months={policy?.policy_stage_duration?.durations_in_months?.[5]} />
                    </td>
                  </tr>
                </tbody>
              </table>
          </div>
          <DurationChart chartData={policy?.policy_stage_duration?.durations_in_months} title={'Policy Stage Time Tracker'} />
        </div>
      </div>
      {openCanvas && (
        <Sidebar policy={policy?.policy_data} onClose={toggleCanvas} canvasTab={canvasTab} />
      )}
    </div>
  )
}

const mapStateToProps = state => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Policy)
