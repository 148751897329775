import Select from "react-select"
import React, { useState, useRef, useEffect } from "react";

const AuditFilters = ({
  userOptions,
  handleUser,
  handleRole,
  handleActivityType,
  toggleModal,
}) => {
  const [selectedUser, setSelectedUser] = useState("")
  const [selectedRole, setSelectedRole] = useState("")
  const [selectedActivityType, setSelectedActivityType] = useState("")
  const roleOptions = [
    { value: "admin", label: "Admin" },
    { value: "c_admin", label: "Country Admin" },
    { value: "user", label: "User" },
    { value: "cron_bot", label: "Cron Job" },
  ]

  //Access Code, Policy, Category, Subcategory, Milestone, System, Comment
  const activityOptions = [
    { value: "Access Code", label: "Access Code" },
    { value: "Policy", label: "Policy" },
    { value: "Category", label: "Category" },
    { value: "Subcategory", label: "Subcategory" },
    { value: "Milestone", label: "Milestone" },
    { value: "System", label: "System" },
    { value: "Comment", label: "Comment" },
  ]

  const handleFilterApply = () => {
    handleUser(selectedUser)
    handleActivityType(selectedActivityType)
    handleRole(selectedRole)
    toggleModal()
  }
  return (
    <div>
      <div className="d-flex my-2">
        <div className="d-flex align-items-center">
          <i className="mdi mdi-buffer light-icon me-1" />
          <h6 className="mb-0">User:</h6>
        </div>
        <div className="ms-2" style={{ width: "100%" }}>
          <Select
            options={userOptions}
            defaultValue={selectedUser !== "" ? selectedUser : null}
            className="basic-single"
            classNamePrefix="select"
            placeholder="Select a user"
            onChange={option => setSelectedUser(option?.value || "")}
          />
        </div>
      </div>
      <div className="d-flex my-2">
        <div className="d-flex align-items-center">
          <i className="mdi mdi-buffer light-icon me-1" />
          <h6 className="mb-0">Role:</h6>
        </div>
        <div className="ms-2" style={{ width: "100%" }}>
          <Select
            options={roleOptions}
            defaultValue={selectedRole !== "" ? selectedRole : null}
            className="basic-single"
            classNamePrefix="select"
            placeholder="Select a Role"
            onChange={option => setSelectedRole(option?.value || "")}
          />
        </div>
      </div>
      <div className="d-flex my-2">
        <div className="d-flex align-items-center">
          <i className="mdi mdi-account-outline light-icon me-1" />
          <h6 className="mb-0">Activity Type:</h6>
        </div>
        <div className="ms-2" style={{ width: "100%" }}>
          <Select
            defaultValue={
              selectedActivityType !== "" ? selectedActivityType : null
            }
            options={activityOptions}
            className="basic-single"
            classNamePrefix="select"
            placeholder="Select a Activity Type"
            onChange={option => setSelectedActivityType(option?.value || "")}
          />
        </div>
      </div>
      <div className="d-flex" style={{ justifyContent: "center" }}>
        <button
          className="btn btn-success common-button"
          style={{ width: "90%" }}
          onClick={handleFilterApply}
        >
          Apply
        </button>
      </div>
    </div>
  )
}

export default AuditFilters;
