import { React,useEffect,useState } from "react";
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
} from "reactstrap";
import { Icon } from '@iconify/react';
import styles from './stages_explainer.module.css'



const StagesExplainerModal = ({isOpen,onOpenStateChanged,options = {
    stage:'all'
}}) => {

    const [modalReason, setModalReason] = useState(false);

    useEffect(() => {
        console.log(`isOpen changed to ${JSON.stringify(options)}`);
        setModalReason(isOpen);
    }, [isOpen]);

    return (
        <Modal
                isOpen={modalReason}
                className="modal-lg"
                size="lg"
                // onClosed={()=>onOpenStateChanged(false)}
                // onOpened={()=>onOpenStateChanged(true)}
                toggle={() => setModalReason(!modalReason)}
            >
                <ModalHeader className="w-full">
                    <div className="flex d-flex justify-content-between justify-between">
                        <h4>Stages Explainer</h4>
                        
                        
                    </div>
                </ModalHeader>
                
                <ModalBody>

                    <p>
                        In APRITS every legal framework can be broken down into 6 distinct changes, below is an explanation of each stage and the activities that happen per stage
                    </p>

                    <div className={`${styles.stages_explainer} grid gap-4 ${options.stage==='all' ? 'grid-cols-3' : 'grid-cols-1'}`}>
                            {
                            (['all','Initiation'].includes(options.stage)) && (
                                <div className="">
                                    <h5>Initiation</h5>
                                    <div>
                                        <ol>
                                            <li>
                                            Identification of policy 
                                            challenge  
                                            </li>
                                            <li>
                                            Prioritization of 
                                            identified policy 
                                            challenges  
                                            </li>
                                            <li>
                                            Stakeholder 
                                            consultation on policy 
                                            priorities   
                                            </li>
                                            <li>
                                            Concept note 
                                            development  
                                            </li>
                                        </ol>
                                    </div>
                                </div>
                            )}

                            {
                            (['all','Development'].includes(options.stage)) && (
                                <div className="">
                                    <h5>Development</h5>
                                    <div>
                                        <ol>
                                            <li>
                                            Appraisal (regulatory 
                                            impact assessment, 
                                            cost benefit analysis)   
                                            </li>
                                            <li>
                                            Development of draft 
                                            policy/bill/regulation  
                                            </li>
                                            <li>
                                                Stakeholder 
                                                consultation on the 
                                                draft 
                                                policy/bill/regulation  
                                            </li>
                                            
                                        </ol>
                                    </div>
                                </div>
                            )}

                            {
                            (['all','Validation'].includes(options.stage)) && (
                                <div className="">
                                    <h5>Validation</h5>
                                    <div>
                                        <ol>
                                            <li>
                                            Review of draft 
                                            policy/bill/regulation
                                            </li>
                                            <li>
                                            Stakeholder 
                                            validation 
                                            workshops   
                                            </li>
                                        </ol>
                                    </div>
                                </div>
                            )}

                            {
                            (['all','Approval'].includes(options.stage)) && (
                                <div className="">
                                    <h5>Approval</h5>
                                    <div>
                                        <ol>
                                            <li>
                                                Review by the 
                                                committee of 
                                                principal/permanent 
                                                secretaries
                                            </li>
                                            <li>
                                                Cabinet memo from 
                                                lead ministry    
                                            </li>
                                            <li>
                                                Presentation to cabinet
                                            </li>
                                            <li>
                                                Cabinet 
                                                feedback/modifications 
                                                to policy/bill/regulation 
                                                draft  
                                            </li>
                                            <li>
                                                Socialization and policy 
                                                advocacy  
                                            </li>
                                        </ol>
                                    </div>
                                </div>
                            )}

                            {
                            (['all','Legislation'].includes(options.stage)) && (
                                <div className="">
                                    <h5>Legislation</h5>
                                    <div>
                                        <ol>
                                            <li>
                                            Submission to 
                                            parliamentary select 
                                            committee  
                                            </li>
                                            <li>
                                            Organizing workshops 
                                            with parliamentary 
                                            select committee (s)  
                                            </li>
                                            <li>
                                            Public hearing  
                                            </li>
                                            <li>
                                            Draft bill publication 
                                            </li>
                                            <li>
                                            Parliamentary debate 
                                            and passage of 
                                            bill/regulation  
                                            </li>
                                            <li>Presidential assent </li>
                                            
                                        </ol>
                                    </div>
                                </div>
                            )}

                            {
                            (['all','Implementation'].includes(options.stage)) && (
                                <div className="">
                                    <h5>Implementation</h5>
                                    <div>
                                        <ol>
                                            <li>
                                            Development & 
                                            Approval of 
                                            regulations 
                                            </li>
                                            <li>
                                            Interpretation & 
                                            translation of law 
                                            by policy enforcers 
                                            </li>
                                            <li>
                                            Development of 
                                            Standard Operating 
                                            Procedures  
                                            </li>
                                            <li>
                                            Technical and 
                                            financial capacity 
                                            </li>
                                            <li>
                                                Monitoring and 
                                                Evaluation 
                                                (assessing & 
                                                advocating 
                                                implementation) 
                                            </li>
                                        </ol>
                                    </div>
                                </div>
                            )}
                        </div>

                        <div>
                            <Button className="btn btn-primary btn-block w-full bg-[#036F36] my-3" onClick={() => {setModalReason(false);onOpenStateChanged(false);}}>Dismiss</Button>
                        </div>
                    
                </ModalBody>

                <ModalFooter>
                    
                </ModalFooter>
        </Modal>
    );
};


const StagesExplainer = ({ stage,previewcontent}) => {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <div>
            {previewcontent ? <Button className="w-full bg-white text-black p-0 border-none" onClick={() => setIsOpen(!isOpen)}>
                {previewcontent}
            </Button> : (
                <button className="btn stages-explainer btn-primary" onClick={() => setIsOpen(!isOpen)}>
                    <Icon icon="hugeicons:information-circle" />
                </button>
            )}
            
            <StagesExplainerModal isOpen={isOpen} options={{stage}} onOpenStateChanged={(state) => setIsOpen(state)}   />
        </div>
    );
};

export default StagesExplainer;

