import React, { useState, useEffect } from "react";
import DeleteModal from "components/Common/DeleteModal";
import {
  getCountryEvents,
  getPolicyUpdates,
  getCountries,
  getPolicyUpdatePicture,
  getPolicyUpdateUserPicture,
  editCountryEvent,
  editPolicyUpdateForm,
  deleteCountryEvent,
  deletePolicyUpdate, createPolicyUpdate
} from "services/policyUpdatesAndEventsService"
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input, UncontrolledTooltip
} from "reactstrap"
import SearchBox from "components/Common/SearchBox";
import Pagination from "components/Common/Pagination";
import AddUpdateOrEventButton from "./AddUpdateOrEventButton";
import "./PolicyUpdates.css";
import { formatDistanceToNow } from "date-fns";

const PolicyUpdates = ({alert,externalCountryID=null,showAdd=true}) => {
   const [updateOffset, setUpdateOffset] = useState(0)
  const [events, setEvents] = useState([]);
  const [policyUpdates, setPolicyUpdates] = useState([]);
  const [totalEventEntries, setTotalEventEntries] = useState(0);
  const [totalUpdateEntries, setTotalUpdateEntries] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [countries, setCountries] = useState([]);
  const [policyUpdatePictures, setPolicyUpdatePictures] = useState({});
  const [userPictures, setUserPictures] = useState({});

  const usePublic = !showAdd

  // Modal states for events
  const [editEvent, setEditEvent] = useState(null);
  const [isEventModalOpen, setIsEventModalOpen] = useState(false);

  const initialPolicyUpdate = {
      name: "",
      description: "",
      source: "",
      photo: null,
    }

  // Modal states for policy updates
  const [editPolicyUpdate, setEditPolicyUpdate] = useState(initialPolicyUpdate);
  const [isPolicyUpdateModalOpen, setIsPolicyUpdateModalOpen] = useState(false);

  // Delete modal states
  const [deleteModal, setDeleteModal] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [deleteType, setDeleteType] = useState(""); // "event" or "policyUpdate"

  const updateLimit = 10;
  const eventLimit = 3;

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const countriesResponse = await getCountries();
        setCountries(countriesResponse);
      } catch (err) {
        console.error("Error fetching countries:", err);
      }
    };

    fetchCountries();
  }, []);

  const getCountryName = (countryId) => {
    const country = countries.find((c) => c.id === countryId);
    return country ? country.long_name : "Unknown";
  };

  // Function to load events
  const loadEvents = async (query) => {
    try {
      const data = await getCountryEvents(query);
      setTotalEventEntries(data.length);
      setEvents(data);
    } catch (err) {
      console.error("Error fetching events:", err);
      setEvents([]);
    }
  };

  // Function to load policy updates and their pictures
  const loadPolicyUpdates = async (query) => {
    try {
      const { data, count } = await getPolicyUpdates(query);
      setTotalUpdateEntries(count);
      setPolicyUpdates(data);

      // Load pictures for each policy update and user
      data.forEach((update) => {
        loadPolicyUpdatePicture(update.id);
        const userIdToLoad = update.updated_by_id || update.created_by_id;
        loadUserPicture(userIdToLoad);
      });
    } catch (err) {
      console.error("Error fetching policy updates:", err);
      setPolicyUpdates([]);
    }
  };

  const loadPolicyUpdatePicture = async (policy_update_id) => {
    try {
      const picture = await getPolicyUpdatePicture(policy_update_id);
      const pictureUrl = URL.createObjectURL(picture);
      setPolicyUpdatePictures((prevPictures) => ({
        ...prevPictures,
        [policy_update_id]: pictureUrl,
      }));
    } catch (err) {
      console.error("Error fetching policy update picture:", err);
    }
  };

  const loadUserPicture = async (user_id) => {
    try {
      const picture = await getPolicyUpdateUserPicture(user_id);
      const pictureUrl = URL.createObjectURL(picture);
      setUserPictures((prevPictures) => ({
        ...prevPictures,
        [user_id]: pictureUrl,
      }));
    } catch (err) {
      console.error("Error fetching user picture:", err);
    }
  };

  const formatDateToInput = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  // Function to refresh the events list
  const refreshEvents = () => {
    loadEvents({ limit: eventLimit, offset: 0,countryId: externalCountryID,usePublic, searchTerm });
  };

  // Function to refresh the policy updates list
  const refreshPolicyUpdates = () => {
    loadPolicyUpdates({ limit: updateLimit, offset: updateOffset,countryId: externalCountryID,usePublic, searchTerm });
  };

  const handlePageChange = pageNum => {
    const updateOffset = (pageNum - 1) * updateLimit;
    const eventOffset = (pageNum - 1) * eventLimit;
    setCurrentPage(pageNum);
    setUpdateOffset(updateOffset);
    loadEvents({ limit: eventLimit, offset: eventOffset, searchTerm });
    loadPolicyUpdates({ limit: updateLimit, offset: updateOffset, searchTerm });
  };

  // Open modal for editing event
  const openEditEventModal = (event) => {
    setEditEvent(event);
    setIsEventModalOpen(true);
  };

  // Open modal for editing policy update
  const openEditPolicyUpdateModal = (update) => {
    setEditPolicyUpdate(update);
    setIsPolicyUpdateModalOpen(true);
  };

  // Open delete modal
  const openDeleteModal = (item, type) => {
    setItemToDelete(item);
    setDeleteType(type);
    setDeleteModal(true);
  };

  // Handle delete action
  const handleDelete = async () => {
    try {
      if (deleteType === "event") {
        await deleteCountryEvent(itemToDelete.id);
        alert("Country Event Deletion", true)
        refreshEvents();
      } else if (deleteType === "policyUpdate") {
        await deletePolicyUpdate(itemToDelete.id);
         alert("Policy Update Deletion", true)
        refreshPolicyUpdates();
      }
      setDeleteModal(false);
      setItemToDelete(null);
    } catch (err) {
      console.error("Error deleting item:", err);
    }
  };

  const handleSearchInputChange = (event) => {
    setSearchTerm(event.target.value);
    // loadEvents({ limit: eventLimit, offset: 0, searchTerm: event.target.value });
    loadPolicyUpdates({ limit: updateLimit, offset: updateOffset, searchTerm: event.target.value });
  };

  useEffect(() => {
    refreshEvents();
    refreshPolicyUpdates();
  }, [searchTerm,externalCountryID]);

  // Event and Policy Update Modals and Save Logic
  const handleEventInputChange = (e) => {
    const { name, value } = e.target;
    setEditEvent((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handlePolicyUpdateInputChange = (e) => {
    if (e.target.name === "photo"){
      setEditPolicyUpdate({...editPolicyUpdate, photo: e.target.files[0]});
    } else {
      setEditPolicyUpdate({...editPolicyUpdate, [e.target.name]: e.target.value});
    }
  };

  const handleSaveEvent = async () => {
    try {
      const formattedEventData = {
        ...editEvent,
        start_date: new Date(editEvent.start_date).toISOString(),
        end_date: new Date(editEvent.end_date).toISOString(),
      };

      await editCountryEvent(editEvent.id, formattedEventData);
      alert("Country Event Updation", true)
      setIsEventModalOpen(false);
      refreshEvents();
    } catch (error) {
      console.error("Error saving the event:", error);
    }
  };

  const handleSavePolicyUpdate = async () => {
    try {

      const formData = new FormData();

      const policyUpdateBody = {
        name: editPolicyUpdate.name,
        description: editPolicyUpdate.description,
        source: editPolicyUpdate.source,
      };

      formData.append("policy_update_update_request_body", JSON.stringify(policyUpdateBody));

      const photo = editPolicyUpdate.photo
      if (photo && photo instanceof File) {
        formData.append("photo", photo);
      }

      await editPolicyUpdateForm(editPolicyUpdate.id, formData);
      alert("Policy Update Updation", true)
      setIsPolicyUpdateModalOpen(false);
      refreshPolicyUpdates();

    } catch (error) {
      console.error("Error saving the policy update:", error);
    }
  };

  return (
    <div className="policy-updates-page">
      <div className="d-flex justify-content-between align-items-center">
        <div className="filters-and-search">
          <SearchBox searchChange={handleSearchInputChange} />
          {/*<Button*/}
          {/*  style={{*/}
          {/*    backgroundColor: "#036F36",*/}
          {/*    color: "#FFFDB8",*/}
          {/*    margin: "15px 10px",*/}
          {/*    border: "none",*/}
          {/*    fontSize: "14px",*/}
          {/*  }}*/}
          {/*  // onClick={loadEvents}*/}
          {/*>*/}
          {/*  <i className="mdi mdi-filter-variant me-1" /> Filters*/}
          {/*</Button>*/}
        </div>
        {showAdd && (
              <AddUpdateOrEventButton alert={alert} refreshEvents={refreshEvents} refreshPolicyUpdates={refreshPolicyUpdates} />
        )}
      </div>

      <div className="content-container">
        <div className="left-content">
          <div className="updates-section">
            <h3>Policy Updates</h3>

            {policyUpdates.length > 0 ? (
              policyUpdates.map((update) => {
                const lastUpdatedDate = update.updated_at
                  ? new Date(update.updated_at)
                  : new Date(update.created_at);

                const userIdToShow = update.updated_by_id || update.created_by_id;

                return (
                  <div key={update.id} className="update-item">
                    <img
                      src={policyUpdatePictures[update.id] || update.photo}
                      alt={update.name}
                      className="update-image"
                    />
                    <div className="update-content">
                      <h4>{update.name}</h4>
                      <p>{update.description}</p>
                      <p>
                        Source: {" "}
                        <a href={update.source} target="_blank" rel="noopener noreferrer">
                          {update.source}
                        </a>
                      </p>
                      <div className="update-meta">
                        <div className="meta-left">
                          <img
                            src={userPictures[userIdToShow] || "path/to/default/profile-photo.jpg"}
                            alt={`${update.created_by.first_name} ${update.created_by.last_name}`}
                            className={`profile-photo ${showAdd?"":"hidden"}`}
                          />
                          <div className={`meta-details ${showAdd?"":"hidden"}`}>
                            <span className="user-name">
                              {`${update.created_by.first_name} ${update.created_by.last_name}`}
                            </span>
                            <span className="timestamp">
                              {formatDistanceToNow(lastUpdatedDate, { addSuffix: true })}
                            </span>
                          </div>
                        </div>
                        <div className={`action-buttons ${showAdd?"":"hidden"}`}>
                          <button className="edit-button" onClick={() => openEditPolicyUpdateModal(update)}>
                            <i className="fas fa-edit" id="updateedittooltip"></i>
                            <UncontrolledTooltip placement="top" target="updateedittooltip">
                            Edit
                            </UncontrolledTooltip>
                          </button>
                          <button onClick={() => openDeleteModal(update, "policyUpdate")}>
                            <i className="mdi mdi-delete font-size-18 del-icon text-danger" id="updatedeletetooltip"/>
                            <UncontrolledTooltip placement="top" target="updatedeletetooltip">
                            Delete
                            </UncontrolledTooltip>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <p>No updates found</p>
            )}
          </div>
        </div>

        <div className="right-content">
          <div className="events-container">
            <h3>
              <i className="fas fa-calendar-alt"></i> Upcoming Events
            </h3>

            {events.length > 0 ? (
              events.map((event) => (
                <div key={event.id} className="event-card">
                  <div className="event-date">
                    <span>{new Date(event.start_date).toLocaleString("default", { month: "short" }).toUpperCase()}</span>
                    <span className="date-number">{new Date(event.start_date).getDate()}</span>
                    <span className="date-year">{new Date(event.start_date).getFullYear()}</span>
                  </div>
                  <div className="event-details">
                    <h5>{event.name}</h5>
                    <p>
                      <span>
                        <i className="fas fa-map-marker-alt"></i>
                      </span>
                      Venue: {event.venue}
                    </p>
                    <p>
                      <span>
                        <i className="fas fa-flag"></i>
                      </span>
                      Country: {getCountryName(event.country_id)}
                    </p>
                    <p>
                      <span>
                        <i className="fas fa-calendar-day"></i>
                      </span>
                      Dates: {" "}
                      {new Date(event.start_date).toLocaleDateString("en-GB", {
                        day: "numeric",
                        month: "short"
                      })} {" "}
                      - {" "}
                      {new Date(event.end_date).toLocaleDateString("en-GB", {
                        day: "numeric",
                        month: "short"
                      })}
                    </p>
                    <span className="event-type">{event.event_type}</span>
                  </div>

                  <div className={`event-actions ${showAdd?"":"hidden"}`}>
                    <button className="edit-button" onClick={() => openEditEventModal(event)}>
                      <i className="fas fa-edit" id="eventedittooltip"></i>
                      <UncontrolledTooltip placement="top" target="eventedittooltip">
                        Edit
                      </UncontrolledTooltip>
                    </button>
                    <button onClick={() => openDeleteModal(event, "event")}>
                      <i className="mdi mdi-delete font-size-18 del-icon text-danger" id="eventdeletetooltip" />
                      <UncontrolledTooltip placement="top" target="eventdeletetooltip">
                        Delete
                      </UncontrolledTooltip>
                    </button>
                  </div>
                </div>
              ))
            ) : (
              <p>No events found</p>
            )}
          </div>
        </div>
      </div>

      <Pagination
        currentPage={currentPage}
        goToPage={handlePageChange}
        totalEntries={totalUpdateEntries}
        entriesPerPage={10}
        // limit={updateLimit}
      />

      {/* Modal for editing event */}
      {editEvent && (
        <Modal
          isOpen={isEventModalOpen}
          toggle={() => setIsEventModalOpen(!isEventModalOpen)}
          className="custom-modal"
        >
          <ModalHeader toggle={() => setIsEventModalOpen(!isEventModalOpen)}>
            Edit Event
          </ModalHeader>

          <div className="modal-subtext">
            <small>All fields marked with asterisks (<span className="required">*</span>) are compulsory.</small>
          </div>

          <ModalBody>
            <Form>
            <FormGroup>
                <Label for="eventName">Event Name<span className="required">*</span></Label>
                <Input
                  type="text"
                  name="name"
                  id="eventName"
                  value={editEvent.name}
                  onChange={handleEventInputChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="eventVenue">Venue<span className="required">*</span></Label>
                <Input
                  type="text"
                  name="venue"
                  id="eventVenue"
                  value={editEvent.venue}
                  onChange={handleEventInputChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="eventStartDate">Start Date<span className="required">*</span></Label>
                <Input
                  type="date"
                  name="start_date"
                  id="eventStartDate"
                  value={formatDateToInput(editEvent.start_date)}
                  onChange={handleEventInputChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="eventEndDate">End Date<span className="required">*</span></Label>
                <Input
                  type="date"
                  name="end_date"
                  id="eventEndDate"
                  value={formatDateToInput(editEvent.end_date)}
                  onChange={handleEventInputChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="eventType">Event Type<span className="required">*</span></Label>
                <Input
                  type="text"
                  name="event_type"
                  id="eventType"
                  value={editEvent.event_type}
                  onChange={handleEventInputChange}
                />
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button
              //  color="primary"
              // className="btn btn-primary btn-block rounded-[2.25rem] text-[#FFF605] flex flex-row items-center justify-center !important"
              style={{
                backgroundColor: '#036F36',
                color: '#FFF605',
                borderRadius: '2.25rem',
                width: '100%',
              }}
              onClick={handleSaveEvent}
            >
              Save Changes
            </Button>{" "}
            {/* <Button color="secondary" onClick={() => setIsEventModalOpen(false)}>
              Cancel
            </Button> */}
          </ModalFooter>
        </Modal>
      )}

      {/* Modal for editing policy update */}
      {editPolicyUpdate && (
        <Modal
          isOpen={isPolicyUpdateModalOpen}
          toggle={() => setIsPolicyUpdateModalOpen(!isPolicyUpdateModalOpen)}
          className="custom-modal"
        >
          <ModalHeader toggle={() => setIsPolicyUpdateModalOpen(!isPolicyUpdateModalOpen)}>
            Edit Policy Update
          </ModalHeader>

          <div className="modal-subtext">
            <small>All fields marked with asterisks (<span className="required">*</span>) are compulsory.</small>
          </div>

          <ModalBody>
            <Form>
              <FormGroup>
                <Label for="policyUpdateName">Update Name<span className="required">*</span></Label>
                <Input
                  type="text"
                  name="name"
                  id="policyUpdateName"
                  value={editPolicyUpdate.name}
                  onChange={handlePolicyUpdateInputChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="policyUpdateDescription">Description<span className="required">*</span></Label>
                <Input
                  type="textarea"
                  name="description"
                  id="policyUpdateDescription"
                  value={editPolicyUpdate.description}
                  onChange={handlePolicyUpdateInputChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="policyUpdateSource">Source<span className="required">*</span></Label>
                <Input
                  type="url"
                  name="source"
                  id="policyUpdateSource"
                  value={editPolicyUpdate.source}
                  onChange={handlePolicyUpdateInputChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="policyUpdatePhoto">Upload Photo</Label>
                <Input
                  type="file"
                  name="photo"
                  className="input-field"
                  id="policyUpdatePhoto"
                  onChange={handlePolicyUpdateInputChange}
                  // onChange={(e) => setEditPolicyUpdate((prev) => ({ ...prev, photo: e.target.files[0] }))}
                />
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button
              // color="primary" 
              style={{
                backgroundColor: '#036F36',
                color: '#FFF605',
                borderRadius: '2.25rem',
                width: '100%',
              }}
              onClick={handleSavePolicyUpdate}
            >
              Save Changes
            </Button>{" "}
            {/* <Button color="secondary" onClick={() => setIsPolicyUpdateModalOpen(false)}>
              Cancel
            </Button> */}
          </ModalFooter>
        </Modal>
      )}

      {/* Modal for deleting items */}
      {deleteModal && (
        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDelete}
          onCloseClick={() => setDeleteModal(false)}
          item={deleteType}
        />
      )}
    </div>
  );
};

export default PolicyUpdates;
