import { get, post, postFormData, axiosApi } from "./AXIOS"
import {
  USER_SESSION,
  USER_LOGOUT,
  UPDATE_PROFILE,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  SIGN_IN,
  SIGN_UP,
} from "./CONSTANTS"

export const userSession = () => get(USER_SESSION)

export const forgotPassword = email => post(`${FORGOT_PASSWORD}/${email}`)

export const resetPassword = (body) =>
  post(RESET_PASSWORD, body, {
    headers: { "Content-Type": "application/json" }
  })

export const updateProfile = profile =>
  postFormData(UPDATE_PROFILE, profile, {
    headers: { "content-type": "multipart/form-data" },
  })

// Gets the login user data from local session
export const getLoggedInUser = () => {
  const user = localStorage.getItem("user")
  if (user) return JSON.parse(user)
  return null
}

//is user is logged in
export const isUserAuthenticated = () => {
  return getLoggedInUser() !== null
}

export const registerNewUser = async data => {
  try {
    const response = await postFormData(SIGN_UP, data, {
      headers: { "content-type": "application/json" },
    })
    console.log("REsponse", response)
    return response
  } catch (error) {
    return error
  }
}

// Register Method
export const postRegister = data => {
  return (
    axiosApi
      //.post(`${url.SIGN_UP}/${token}`, data, { params: { token } })
      .post(SIGN_UP, data, {
        headers: { "content-type": "application/json" },
      })
      .then(response => {
        if (response.status >= 200 || response.status <= 299) return response
        throw response
      })
      .catch(err => {
        if (err.response) {
          let message
          err.response.status =
            err.response.data.statusCode || err.response.status
          if (err.response && err.response.status) {
            switch (err.response.status) {
              // case 404:
              // message = "Sorry! the page could not be found"
              //   break
              default:
                message =
                  err.response.data.message || "Sorry! something went wrong"
                break
            }
          }
          throw message
        } else {
          throw "Server Error"
        }
      })
  )
}

export const postLogin = data => {
  // Prepare the data in URL-encoded format
  const urlencoded = new URLSearchParams()
  urlencoded.append("username", data.username)
  urlencoded.append("password", data.password)

  return axiosApi
    .post(`${SIGN_IN}`, urlencoded)
    .then(response => {
      // Check if the response status is in the 2xx range
      if (response.status >= 200 && response.status <= 299) {
        return response.data;
      }
      throw new Error(response.data);
    })
    .catch(err => {
      console.error(err)
      // Handle errors
      if (err.response) {
        let message;
        err.response.status =
          err.response.data.statusCode || err.response.status;
        if (err.response && err.response.status) {
          switch (err.response.status) {
            // case 404:
            //   message = "Sorry! the page you are looking for could not be found"
            //   break
            // case 500:
            //   message =
            //     "Sorry! something went wrong, please contact our support team"
            //   break
            // case 401:
            //   message = "Invalid credentials"
            //   break
            default:
              message =
                err.response.data.detail || "Sorry! something went wrong";
              break
          }
        }

        throw message
      } else {
        // Handle server or network errors
        throw new Error("Server Error. Please try again later.")
      }
    })
}

export const getUserData = async () => {
  try {
    const loggedInUser = getLoggedInUser()
    return loggedInUser
  } catch (error) {
    console.log("Error", error)
  }
}