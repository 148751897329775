import React, { useState } from 'react';
import PartnerSelection from './PartnerSelection';
import CreatePartner from './CreatePartner';
import { Icon } from '@iconify/react';

function Step3({formData, setFormData, setCurrentStep}) {
    const [validationErrors, setValidationErrors] = useState({});

    const validateForm = () => {
        const newErrors = {};

        // Validate that at least one partner is selected
        if (!formData.partner_request_bodies?.length) {
            newErrors.partners = 'At least one partner must be selected';
        }

        setValidationErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handlePartnerRemove = (index) => {
        setFormData(prev => ({
            ...prev,
            partner_request_bodies: prev.partner_request_bodies.filter((_, i) => i !== index)
        }));
    };

    const handleNext = () => {
        const isValid = validateForm();
        if (isValid) {
            setCurrentStep(4);
        }
    };

    return (
        <div className="space-y-6">
            <div className="flex justify-between items-start">
                <div className="relative flex-1 max-w-md">
                    <label className="block mb-1 text-sm">Select a partner profile*</label>
                    <PartnerSelection
                        countryID={formData.policy_request_body.country_id}
                        setFormData={setFormData}
                        selectedPartners={formData.partner_request_bodies}
                        onSelect={() => setValidationErrors({})} // Clear errors on new selection
                    />
                    {validationErrors.partners && (
                        <span className="text-red-500 text-sm mt-1 block">
                            {validationErrors.partners}
                        </span>
                    )}
                </div>
                <div>
                    <p className="text-sm text-gray-600">If you can't find a partner on the list,</p>
                    <p className="text-sm text-gray-600">please click below to create one.</p>
                    <CreatePartner 
                        setFormData={setFormData}
                        countryID={formData.policy_request_body.country_id}
                        onCreateSuccess={() => setValidationErrors({})} // Clear errors on new partner creation
                    />
                </div>
            </div>

            {/* Selected Partners */}
            <div className="space-y-2">
                <h3 className="text-sm font-medium">Selected partners</h3>
                {formData.partner_request_bodies.length === 0 ? (
                    <div className="p-3 bg-gray-50 rounded text-gray-500 text-sm">
                        No partners selected
                    </div>
                ) : (
                    formData.partner_request_bodies.map((partner, index) => (
                        <div 
                            key={index} 
                            className={'flex items-center justify-between p-3 rounded bg-gray-50'}
                        >
                            <div>
                                <span>{partner.long_name}</span>
                                {partner.is_technical_expert && (
                                    <span className="inline-block px-2 py-1 bg-green-100 text-green-800 text-xs rounded ml-2">
                                        Technical
                                    </span>
                                )}
                            </div>
                            <button
                                onClick={() => handlePartnerRemove(index)}
                                className="text-red-500 text-sm flex items-center hover:text-red-600"
                                aria-label={`Remove ${partner.long_name}`}
                            >
                                <Icon icon="gg:remove-r" className='mr-1'/>
                                Remove Partner
                            </button>
                        </div>
                    ))
                )}
            </div>

            <div className="flex justify-between">
                <button
                    onClick={() => setCurrentStep(2)}
                    className="px-6 py-2 border rounded text-gray-600 hover:bg-gray-50"
                >
                    Previous
                </button>
                <button
                    className="px-6 py-2 bg-green-100 text-green-800 rounded hover:bg-green-200"
                    onClick={handleNext}
                >
                    Next
                </button>
            </div>
        </div>
    );
}

export default Step3;