import React, { useState } from "react";
import Policies from "./Policy";
import "./Policy.css";
import Sidebar from "./PolicyInformation-Sidebar";
import PartnersDropdown from "./Partner-Dropdown";
import { FaFileAlt } from 'react-icons/fa'; // File icon for the last column

export const CustomTable = ({ data }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [selectedPolicy, setSelectedPolicy] = useState(null);
  
  const openSidebar = (policy) => {
    setSelectedPolicy(policy);
    setIsSidebarOpen(true);
  };

  const closeSidebar = () => {
    setIsSidebarOpen(false);
    setSelectedPolicy(null);
  };

  return (
    <>
      <div className="table-responsive">
        <table className="table text-sm">
          <thead className="table-header">
            <tr>
              <th className="col-w-lg fix-col">Location</th>
              <th className="col-w-lg fix-col-1">Policy Name</th>
              <th className="col-w-lg">Systems</th>
              <th className="col-w-md">Policy Stage</th>
              <th className="col-w-md">Status</th>
              <th className="col-w-lg">Partners</th>
              <th className="col-w-lg">Policy Progress Score</th>
              <th className="col-w-md">Progress Rating</th>
              <th className="col-w-md">Policy Info</th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(data) && data.length > 0
              ? data.map((policy, idx) => (
                <tr key={idx}>
                  <td scope="row" className="col-w-lg fix-col">{policy.country?.long_name || "Test Location"}</td>
                  <td className="col-w-lg fix-col-1">
                    <a href={"/policy/" + policy.id} target="_blank" rel="noopener noreferrer" className="flex items-start">
                      <span className="arrow-icon">↗</span>
                      <p className="text-green-700">{policy.name.replaceAll('_', ' ')}</p>
                    </a>
                  </td>
                  <td className="col-w-lg">
                    <ul style={{ padding: 0, margin: 0, listStyleType: "none" }}>
                      {policy.policy_systems.map((system) => (
                        <li key={system.system_id}>{system.system.name}</li>
                      ))}
                    </ul>
                  </td>
                  <td className="col-w-md">{policy.stage?.name || "N/A"}</td>
                  <td className="col-w-md">
                    {policy.is_off_track && <span className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-gray-500/10 ring-inset">Off Track</span>}
                    {policy.is_delayed && <span className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-gray-500/10 ring-inset">Delayed</span>}
                  </td>
                  <td className="col-w-lg">
                    <PartnersDropdown partners={policy.policy_partners} />
                  </td>
                  <td className="col-w-lg">{policy.average_score}</td>
                  <td className="col-w-md">{policy.progress_rating}</td>
                  <td className="col-w-md flex items-center space-x-1">
                    <FaFileAlt
                      style={{ cursor: "pointer", color: "#036f36" }}
                      onClick={() => openSidebar(policy)}
                    />
                    <span
                      style={{ cursor: "pointer", color: "#036f36" }}
                      onClick={() => openSidebar(policy)}
                    >
                        View Details
                      </span>
                  </td>
                </tr>
                ))
              : null}
          </tbody>
        </table>
      </div>

      {isSidebarOpen && selectedPolicy && (
        <Sidebar policy={selectedPolicy} onClose={closeSidebar} />
      )}
    </>
  );
};

export default function PolicyDirectoryManagement() {
  document.title = "Policy Directory";

  return (
    <div className="page-content">
      <Policies />
    </div>
  );
}