import '@fortawesome/fontawesome-free/css/all.min.css';
import React, { useEffect, useRef, useState } from "react";
import "./SideBar.css";
import { getUserProfile, getUserPicture } from "services/userProfileService"; // Ensure you have getUserPicture service
import profile1 from "assets/images/profile-img.png"; // Fallback profile image

const Sidebar = ({ policy, onClose, canvasTab = "updates" }) => {
  const [activeTab, setActiveTab] = useState(canvasTab);
  const [userProfile, setUserProfile] = useState(null);
  const [userPhotos, setUserPhotos] = useState({}); // Store user photos in an object
  const [loading, setLoading] = useState(true);
  const sidebarRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  // Fetch user profile and picture
  useEffect(() => {
    const userId = 1; // Hardcoded user ID for now; replace with dynamic value if necessary
    loadUserProfile(userId);
  }, []);

  const loadUserProfile = async (id) => {
    try {
      const profileData = await getUserProfile(id); // Fetch profile
      setUserProfile(profileData);

      if (profileData?.id) {
        loadUserPicture(profileData.id);
      }

      setLoading(false);
    } catch (error) {
      console.error("Error fetching user profile:", error);
      setLoading(false);
    }
  };

  const loadUserPicture = async (user_id) => {
    try {
      const picture = await getUserPicture(user_id);
      const pictureUrl = URL.createObjectURL(picture);
      setUserPhotos((prevPhotos) => ({
        ...prevPhotos,
        [user_id]: pictureUrl
      }));
    } catch (err) {
      console.error("Error fetching user picture:", err);
    }
  };

  const renderBulletPoints = (text, user, timestamp, userPhoto) => {
    return (
      <ul>
        {text
          .split("\\n")
          .filter((item) => item.trim() !== "")
          .map((item, index) => (
            <li key={index}>
              {item}
              <div className="meta-info">
                <div className="user-timestamp">
                  <div className="user-info">
                    <img
                      className="profile-photo"
                      src={userPhoto}
                      alt="Profile"
                      onError={(e) => { e.target.onerror = null; e.target.src = profile1; }} // Fallback on error
                    />
                    <div className="name-timestamp">
                      <span className="user-name">{user}</span>
                      <span className="timestamp">{new Date(timestamp).toLocaleString()}</span>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          ))}
      </ul>
    );
  };

  const getUserInfo = (item) => {
    const user = item.updated_by
      ? `${item.updated_by.first_name} ${item.updated_by.last_name}`
      : `${item.created_by.first_name} ${item.created_by.last_name}`;

    const timestamp = item.updated_by ? item.updated_at : item.created_at;
    const userId = item.updated_by ? item.updated_by.id : item.created_by.id;
    const userPhoto = userPhotos[userId] || profile1; // Fallback if no photo

    return { user, timestamp, userPhoto };
  };

  return (
    <div className="sidebar" ref={sidebarRef}>
      <div className="sidebar-title-container">
        <div className="sidebar-title">
          <h3>Policy Information</h3>
          <button className="close-icon" onClick={onClose}>
            <i className="fas fa-times-circle"></i>
          </button>
        </div>
      </div>
      <div className="sidebar-content-container">
        <div className="sidebar-tabs">
          <button
            className={activeTab === "updates" ? "active tab-button" : "tab-button"}
            onClick={() => setActiveTab("updates")}
          >
            Updates & Emerging Outcomes
          </button>
          <button
            className={activeTab === "details" ? "active tab-button" : "tab-button"}
            onClick={() => setActiveTab("details")}
          >
            Policy Details
          </button>
        </div>
        <div className="sidebar-content">
          {activeTab === "updates" && (
            <div className="tab-content">
              {policy.updates_and_emerging_outcomes ? (
                renderBulletPoints(
                  policy.updates_and_emerging_outcomes,
                  getUserInfo(policy).user,
                  getUserInfo(policy).timestamp,
                  getUserInfo(policy).userPhoto // Pass the user photo
                )
              ) : (
                <p>No updates available</p>
              )}
            </div>
          )}
          {activeTab === "details" && (
            <div className="tab-content">
              {policy.details ? (
                renderBulletPoints(
                  policy.details,
                  getUserInfo(policy).user,
                  getUserInfo(policy).timestamp,
                  getUserInfo(policy).userPhoto // Pass the user photo
                )
              ) : (
                <p>No details available</p>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;