import PropTypes from "prop-types"
import React, { useState } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
import SidebarContent from "./SidebarContent"

import { Link } from "react-router-dom"

import logo from "../../assets/images/agra_logo2_cropped.png"
import logoSmall from "../../assets/images/collapsed.png"
import { Icon } from "@iconify/react"

const Sidebar = props => {

  const [isCollapsed, setIsCollapsed] = useState(false); // Sidebar state

  function tToggle() {
    var body = document.body
    setIsCollapsed(!isCollapsed);
    if (window.screen.width <= 998) {
      body.classList.toggle("sidebar-enable")
    } else {
      body.classList.toggle("vertical-collpsed")
      body.classList.toggle("sidebar-enable")
    }
  }

  return (
    <React.Fragment>
      <div className="vertical-menu">
        <div className="navbar-brand-box">
          <div className="flex items-center justify-center p-2">
            <Link to="/" className="logo logo-dark">
              <span className="logo-sm">
                <img src={logoSmall} alt="" width={50}/>
              </span>
              <span className="logo-lg">
                <img src={logo} alt="" width={150}/>
              </span>
            </Link>
            <Link to="/" className="logo logo-light">
              <span className="logo-sm">
                <img src={logoSmall} alt="" width={50}/>
              </span>
              <span className="logo-lg">
                <img src={logo} alt="" width={150}/>
              </span>
            </Link>
          </div>
          <div className={`${isCollapsed ? '' : 'flex justify-center'}`}>
            <button
              onClick={tToggle}
              className={`flex items-center justify-center p-2 bg-[#036f36] h-4 text-xs cursor-pointer text-[#fff720] rounded ${isCollapsed ? 'w-full' : 'w-16'}`}
            >
              {isCollapsed ? 
                <div title="Expand sidebar"><Icon icon="stash:burger-arrow-right" width="32" height="16"/></div> : 
                <div title="Collapse sidebar"><Icon icon="stash:burger-arrow-left" width="32" height="16"/></div>
              }
            </button>
          </div>
        </div>
        <div data-simplebar>
          <SidebarContent />
        </div>
        {/* <div className="sidebar-background">
        </div> */}
      </div>
    </React.Fragment>
  )
}

Sidebar.propTypes = {
  type: PropTypes.string,
}

const mapStatetoProps = state => {
  return {
    layout: state.Layout,
  }
}
export default connect(
  mapStatetoProps,
  {}
)(withRouter(withTranslation()(Sidebar)))
