import React, { useState, useEffect, useCallback } from "react"
import PropTypes from "prop-types"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"
// Redux
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"

import { logoutUser } from "../../../store/actions"

import { getMePicture } from "../../../services/userProfileService"

const ProfileMenu = (props) => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)
  const { user } = useSelector((state) => state.Profile)

  const [username, setUsername] = useState("AGRA")
  const [email, setEmail] = useState("")
  const [mePicture, setMePicture] = useState(null)

  const dispatch = useDispatch()

  const loadMePicture = async () => {
    try {
      const picture = await getMePicture()
      const pictureUrl = URL.createObjectURL(picture)
      setMePicture(pictureUrl) // Set the user's picture from the API
    } catch (err) {
      console.error("Error fetching user picture:", err)
    }
  }

  useEffect(() => {
    if (user) {
      setUsername(user.first_name)
      setEmail(user.email)

      // Load the user's picture
      loadMePicture()
    }
  }, [user])

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item flex items-center"
          id="page-header-user-dropdown"
          tag="button"
        >
          {user.id && (
            !mePicture ? ( // Show avatar with initials if picture isn't loaded yet
              <div
                className="avatar-xs rounded-circle header-profile-user"
                style={{ float: "left" }}
              >
                <p className={"avatar-title bg-info text-white"}>
                  {user.first_name.charAt(0)}
                </p>
              </div>
            ) : ( // Show loaded picture when available
              <img
                className="rounded-circle header-profile-user"
                src={mePicture} // Use the picture fetched from the API
                alt="Profile"
              />
            )
          )}
          <span
            className={
              "d-none d-xl-inline-block mx-1"
            }
          >
            {username}
          </span>
          <i className="mdi mdi-chevron-down" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <Link to={"/profile"} className="dropdown-item">
            <i className="bx bx-user font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("Profile")}</span>
          </Link>
          <div className="dropdown-divider" />
          <Link to="/" onClick={()=>dispatch(logoutUser())} className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("Logout")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
}

const mapStatetoProps = (state) => {
  const { error, success } = state.Profile
  return { error, success }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
)
