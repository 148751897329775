import React from "react"
import CustomTabs from "components/Common/Custom-Tabs"
import Visualisation from "./Visualisation"
import CountryBenchmarking from "./CountryBenchmarking"

export default function BenchmarkingTabs({alert}) {
//   document.title = "Benchmarking"
  const triggers = [
    { name: "Visualisation", value: "tab1" },
    { name: "Data", value: "tab2" },
  ]
  const content = [
    { value: "tab1", component: <Visualisation alert={alert} /> },
    { value: "tab2", component: <CountryBenchmarking alert={alert}/> },
  ]

  return (
    <div className="page-content">
      <CustomTabs
        ariaLabel="Benchmarking"
        defaultValue="tab1"
        triggers={triggers}
        content={content}
      />
    </div>
  )
}
