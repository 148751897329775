import React, { useEffect, useState } from "react"
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap"
import { listAllPublicSystems, listAllSystems } from "services/listServices";

function SystemsDropdown({systemID, setSystemID}) {

    const [systems, setSystems] = useState([]);
    const [selectedSystem, setSelectedSystem] = useState({});
    const [menu, setMenu] = useState(false);

    const toggle = () => {
        setMenu(!menu)
    }

    const selectSystem = system => {
        setSelectedSystem(system);
        setSystemID(system.id);
        toggle();
    }

    useEffect(()=>{
        const fetchSystems = async () => {
            try{
                let systemList = window.location.pathname === '/home-benchmarking' ? await listAllPublicSystems() : await listAllSystems();
                if (systemList && systemList.length > 0) {
                    systemList = [{ name: "All", id: 0 }, ...systemList]
                    setSystems(systemList);
                    if (systemID) {
                        const selected = systemList.find((system) => system.id === systemID);
                        if (selected) {
                            setSelectedSystem(selected);
                        }
                    }else{
                        setSelectedSystem({ name: "All", id: 0 });
                    }
                }
            }catch(error){
                console.error("Failed to fetch systems!", error);
            }
        }
        fetchSystems();
    }, [])

    return (
        <>
            <Dropdown isOpen={menu} toggle={toggle} className="d-inline-block">
                <DropdownToggle className="d-flex align-items-center btn bg-white" tag="button">
                    {selectedSystem.name}
                    <i className="mdi mdi-chevron-down mx-2" />
                </DropdownToggle>
                <DropdownMenu>
                    {systems.map(system => (
                    <DropdownItem
                        key={system.id}
                        onClick={() => selectSystem(system)}
                        className={`d-flex align-items-center ${
                            selectedSystem.id === system.id ? "bg-slate-100 justify-content-between" : ""
                        }`}
                    >
                        <span>{system.name}</span>
                        { selectedSystem.id === system.id && <i className="mdi mdi-check ms-auto" />}
                    </DropdownItem>
                    ))}
                </DropdownMenu>
            </Dropdown>
        </>
    );
}

export default SystemsDropdown;