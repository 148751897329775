import React, { useState } from "react";
import AuditTable from "./AuditTable";
import { searchAudits } from "services";
import useDebounce from "hooks/useDebounce";
import { useQuery } from "@tanstack/react-query";
import Pagination from "components/Common/Pagination";
import styled from "styled-components";
import SearchBox from "components/Common/SearchBox";
import { Button, ModalHeader, Modal, ModalBody } from "reactstrap";
import AuditFilters from "./AuditFilters";
import ExportTable from "../../components/Common/ExportTable";
import { exportTableData } from "services/common";
import LoadingSpinner from "components/Common/Loading-Spinner";

const StyledAuditPage = styled.div`
  & > .actions {
    & > .basic-single {
      flex-basis: 100px;
    }
  }
`;

const Audit = (props) => {
  document.title = "Audit Logs | AGRA";

  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm);
  const [offset, setOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalEntries, setTotalEntries] = useState(0);

  //Filter
  const [selectedUser, setSelectedUser] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  const [selectedActivity, setSelectedActivity] = useState("");
  const [modalFilter, setModalFilter] = useState(false);
  const toggleFilter = () => setModalFilter((prevState) => !prevState);

  //sort
  const [sortBy, setSortBy] = useState("DESC");
  const sortOptions = [
    { value: "DESC", label: "Newest" },
    { value: "ASC", label: "Oldest" },
  ];

  const loadData = async (
    debouncedSearchTerm,
    offsetParam = offset,
    user = "",
    role = "",
    activity_type = ""
  ) => {
    const limit = 50;
    try {
      const res = await searchAudits({ limit, offset: offsetParam });
      setTotalEntries(res?.count);
      return res.data;
    } catch (err) {
      return err;
    }
  };

  const { data, isLoading, isError } = useQuery({
    queryKey: [
      "audits",
      {
        searchTerm: debouncedSearchTerm,
        page: currentPage,
        selectedUser,
        selectedRole,
        selectedActivityType: selectedActivity,
        sortBy,
      },
    ],
    queryFn: () =>
      loadData(debouncedSearchTerm, offset, selectedUser, selectedRole, selectedActivity),
  });

  const handleSearchInputChange = (evt) => {
    setSearchTerm(evt.target.value);
    setOffset(0);
    setCurrentPage(1);
    setTotalEntries(data?.count);
  };

  const handlePageChange = (pageNum) => {
    const offsetVal = pageNum * 50 - 50;
    setOffset(offsetVal);
    setCurrentPage(pageNum);
  };

  const areAnyFiltersSelected = () => {
    return selectedActivity !== "" || selectedRole !== "" || selectedUser !== "";
  };

  const clearFilters = () => {
    setSelectedActivity("");
    setSelectedUser("");
    setSelectedRole("");
  };

  const exportTable = async (type) => {
    try {
      const query = {
        export_format: type,
      };
      exportTableData(query, "Audit");
    } catch (err) {
      console.error(err);
      props.alert("Data export", false);
    }
  };

  return (
    <StyledAuditPage className="page-content">
      <div className="d-flex align-items-center gap-1 actions" style={{ width: "96%", marginInline: "auto" }}>
        <SearchBox searchChange={handleSearchInputChange} />
        {/* <AuditFilters /> */}
        <Button
          // style={{
          //   backgroundColor: "#f8f9fa",
          //   color: "black",
          //   margin: "15px 10px",
          //   border: "none",
          //   fontSize: "14px",
          // }}
          className="common-button"
          onClick={toggleFilter}
        >
          <i className="mdi mdi-filter-variant me-1"></i>
          Filter
        </Button>
        {areAnyFiltersSelected() && (
          <Button
            className="d-flex align-items-center"
            style={{
              backgroundColor: "#f8f9fa",
              color: "black",
              margin: "15px 10px",
              border: "none",
              fontSize: "14px",
            }}
            onClick={clearFilters}
          >
            <i className="ph-x me-1"></i>
            Clear All Filters
          </Button>
        )}

        {areAnyFiltersSelected() && (
          <Button
            className="d-flex align-items-center"
            style={{
              backgroundColor: "#036F36",
              color: "#FFFDB8",
              margin: "15px 10px",
              border: "none",
              fontSize: "14px",
            }}
            onClick={clearFilters}
          >
            <i className="ph-x me-1"></i>
            Clear All Filters
          </Button>
        )}

        {/* This pushes the ExportTable to the far right */}
        <div className="ml-auto">
          <ExportTable loadFilteredData={exportTable} />
        </div>
      </div>

      <Modal isOpen={modalFilter} toggle={toggleFilter} className="modal-lg">
        {/* <ModalHeader toggle={toggleFilter}>
          <i className="mdi mdi-filter-variant me-1"></i>
          Filters
        </ModalHeader> */}
        <ModalBody>
          <AuditFilters
            selectedUser={selectedUser}
            selectedRole={selectedRole}
            selectedActivityType={selectedActivity}
            handleUser={setSelectedUser}
            handleRole={setSelectedRole}
            handleActivityType={setSelectedActivity}
          />
        </ModalBody>
      </Modal>

      {!isLoading && !isError && data?.count === 0 && (
        <p style={{ color: "red" }}>
          {`We couldn't find any data for ${debouncedSearchTerm}. Please try another search.`}
        </p>
      )}
      {isLoading && <LoadingSpinner />}

      <div style={{ width: "96%", marginInline: "auto" }}>
        {!isLoading && data?.length ? (
          <>
            <AuditTable data={data} />
            <Pagination
              currentPage={currentPage}
              goToPage={handlePageChange}
              totalEntries={totalEntries}
            />
          </>
        ) : null}
      </div>
    </StyledAuditPage>
  );
};

export default Audit;
