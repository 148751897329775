import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Route, Redirect } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { listCountries, listStages } from "../store/list/actions"
import { userSession } from "../store/auth/profile/actions"
import { setAccessToken } from "../store/auth/login/actions"
import ErrorAlert from "components/Common/Alerts/ErrorAlert"
import SuccessAlert from "components/Common/Alerts/SuccessAlert"
import SessionExpiredModal from "pages/Authentication/SessionExpiredModal"

const Authmiddleware = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  title,
  path,
  iconClass,
  ...rest
}) => {
  const dispatch = useDispatch()
  const { accessToken } = useSelector(state => state.Login)

  useEffect(() => {
    if (isAuthProtected && localStorage.getItem("token") && accessToken == "") {
      dispatch(setAccessToken(localStorage.getItem("token")))
      dispatch(userSession())
      dispatch(listCountries())
      dispatch(listStages())
    }
  }, [])

  const [successModal, setSuccessModal] = useState(false)
  const toggleSuccessModal = () => setSuccessModal(!successModal)
  const [errorModal, setErrorModal] = useState(false)
  const toggleErrorModal = () => setErrorModal(!errorModal)

  const [alertContent, setAlertContent] = useState()

  const alert = (content, successBool) => {
    setAlertContent(content)
    successBool ? toggleSuccessModal() : toggleErrorModal()
  }

  return (
    <Route
      {...rest}
      render={props => {
        if (isAuthProtected && !localStorage.getItem("token")) {
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          )
        }
        if (!isAuthProtected && localStorage.getItem("token")) {
          return (
            <Redirect
              to={{ pathname: "/dashboard", state: { from: props.location } }}
            />
          )
        }

        return (
          <Layout title={title} iconClass={iconClass}>
            <Component {...props} alert={alert}/>
            {isAuthProtected && <SessionExpiredModal/>}
            <ErrorAlert
              modal={errorModal}
              toggle={toggleErrorModal}
              content={alertContent}
            />
            <SuccessAlert
              modal={successModal}
              toggle={toggleSuccessModal}
              content={alertContent}
            />
          </Layout>
        )
      }}
    />
  )
}

Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
  iconClass: PropTypes.node,
}

const mapStateToProps = state => {
  // const { isLoggedIn } = state.Login
  // return {
  //   isLoggedIn: isLoggedIn,
  // }
}

const mapDispatchToProps = {
  //listCountries,
}

//export default connect(mapStateToProps, mapDispatchToProps)(Authmiddleware)
export default Authmiddleware
