import React, { useState } from "react";
import "./Partners.css";
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css"></link>

const LongNameDropdown = ({ name }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="name-dropdown">
      <button onClick={toggleDropdown} className="dropdown-button">
        {isOpen ? name : name.slice(0, 20) + (name.length > 20 ? "" : "")}
        <span className="dropdown-icon">
          {/* {isOpen ? "▲" : "▼"}  */}
          <i className={isOpen ? "fas fa-chevron-up" : "fas fa-chevron-down"}></i>
        </span>
      </button>
      {isOpen && (
        <div className="dropdown-content">
          <p>{name}</p>
        </div>
      )}
    </div>
  );
};

export default LongNameDropdown;