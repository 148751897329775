import { Icon } from '@iconify/react';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { deletePolicyService } from 'services/policyServices';

function DeletePolicy({alert}) {

    const [deleteModal, setDeleteModal] = useState(false);
    const toggleModal = () => {
        setDeleteModal(!deleteModal);
    }

    const deletePolicy = (id) => {
        deletePolicyService(id)
        .then(()=>{
            alert("Policy deletion", true);
            setTimeout(()=>window.location.href = '/policy-directory', 1000);
        })
        .catch(err=>{
            alert("Policy deletion", false);
            console.log(err);
        })
    }

    const {id} = useParams();

    return (
        <div>
            <button className="delete-button" onClick={toggleModal}><Icon icon="material-symbols:delete-outline" />Delete</button>
            <Modal isOpen={deleteModal} toggle={toggleModal} centered={true}>
                <ModalBody>
                    <div className='flex items-center text-lg mb-2'>
                        <Icon icon="material-symbols:delete-outline" className='mr-1'/>
                        <h1>Delete this policy</h1>
                    </div>
                    <p>This policy will be deleted from the repository.</p>
                    <div className='w-100 flex justify-end mt-2 space-x-3'>
                        <div
                            className="common-outlined-button"
                            onClick={toggleModal}
                        >
                            <Icon icon="material-symbols-light:cancel-outline" />Cancel
                        </div>
                        <div
                            className='delete-button'
                            onClick={()=>{
                                deletePolicy(id);
                                toggleModal();
                            }}
                        >
                            <Icon icon="material-symbols:delete-outline"/>Delete
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </div>
    );
}

export default DeletePolicy;