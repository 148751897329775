import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import annotationPlugin from 'chartjs-plugin-annotation';
import NoData from "components/Common/NoData";

// Register necessary chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, annotationPlugin);

const BenchmarkingBarChart = ({data, tab, filterSelected, dottedLineValue, region}) => {
  // Check if data is the "No data available" message
  const isNoData = data?.message === "No data available";
  
  if (isNoData) {
    return <NoData/>;
  }

  // Custom tooltip to show region names
  const customTooltip = {
    callbacks: {
      label: function(context) {
        const datasetLabel = context.dataset.label;
        const value = context.parsed.y;
        if (tab === 'system' && datasetLabel === 'Regional system average score') {
          const regionName = data[context.dataIndex].region;
          return [`${regionName} average score: ${value}`];
        }
        return `${datasetLabel}: ${value}`;
      }
    }
  };

  // Preparing the chart data
  const chartData = {
    labels: data?.map((item) => item.long_name || item.system_name),
    datasets: tab === 'system' ? [
      {
        label: filterSelected ? `${filterSelected} average score` : "Average score",
        data: data?.map((item) => filterSelected ? item.country_system_average : item.all_systems_average),
        backgroundColor: "#5D8736",
        borderColor: "#5D8736",
        borderWidth: 1,
      },
      {
        label: 'Regional system average score',
        data: data?.map((item) => filterSelected ? item.regional_system_average : item.regional_all_systems_average),
        backgroundColor: data?.map(item => "#F4FFC3"),
        borderColor: "#F4FFC3",
        borderWidth: 1,
        regionNames: data?.map(item => item.region),
      },
    ] : filterSelected ? [
      {
        label: `${filterSelected} average score`,
        data: data?.map((item) => item.average_score),
        backgroundColor: "#809D3C",
        borderColor: "#809D3C",
        borderWidth: 1,
      },
      {
        label: `${region} average score`,
        data: data?.map((item) => item.regional_system_average),
        backgroundColor: "#A9C46C",
        borderColor: "#A9C46C",
        borderWidth: 1,
      },
      {
        label: 'All countries average score',
        data: data?.map((item) => item.all_countries_system_average),
        backgroundColor: "#5D8736",
        borderColor: "#5D8736",
        borderWidth: 1,
      },
    ] : [
      {
        label: "All countries average score",
        data: data?.map((item) => item.all_countries_average),
        backgroundColor: "#5D8736",
        borderColor: "#5D8736",
        borderWidth: 1,
      },
      {
        label: "South Africa average score",
        data: data?.map((item) => item.southern_africa_average),
        backgroundColor: "#809D3C",
        borderColor: "#809D3C",
        borderWidth: 1,
      },
      {
        label: "East Africa average score",
        data: data?.map((item) => item.east_africa_average),
        backgroundColor: "#A9C46C",
        borderColor: "#A9C46C",
        borderWidth: 1,
      },
      {
        label: "West Africa average score",
        data: data?.map((item) => item.west_africa_average),
        backgroundColor: "#F4FFC3",
        borderColor: "#F4FFC3",
        borderWidth: 1,
      }, 
    ],
  };

  // Chart options
  const options = {
    responsive: true,
    scales: {
      x: {
        title: {
          display: true,
          text: tab === 'system' ? "Countries" : 'Systems',
          font: {
            size: 14,
          },
          color: 'green',
        },
      },
      y: {
        title: {
          display: true,
          text: "Benchmarking Scores",
          font: {
            size: 14,
          },
          color: 'green',
        },
        beginAtZero: true,
      },
    },
    plugins: {
      tooltip: customTooltip,
      legend: {
        position: "top",
        labels: {
          font: {
            size: 14,
          },
          generateLabels: (chart) => {
            const defaultLabels = Legend.defaults.labels.generateLabels(chart);
            
            // Add custom legend item for dotted line
            if (dottedLineValue) {
              defaultLabels.push({
                text: 'All countries system average',
                strokeStyle: 'rgba(0, 128, 0, 1)',
                lineDash: [6, 6],
                lineWidth: 2,
                fillStyle: 'transparent',
              });
            }
            
            return defaultLabels;
          }
        }
      },
      ...dottedLineValue && {annotation: {
        annotations: {
          line1: {
            type: 'line',
            yMin: dottedLineValue,
            yMax: dottedLineValue,
            borderColor: 'rgba(0, 128, 0, 1)',
            borderWidth: 2,
            borderDash: [6, 6],
          }
        }
      }},
    },
  };

  return (
      <div className="w-full border rounded-lg p-2">
        <div className="section-title text-center">Title placeholder</div>
        <div className="flex items-center justify-center h-[25rem]">
          <Bar data={chartData} options={options}  />
        </div>
    </div>
  );
};

export default BenchmarkingBarChart;