import { buildQueryParams } from "helpers/utlities";
import { del, get, post, put } from "./AXIOS"
import {
  GET_PARTNERS,
  CREATE_NEW_PARTNER,
  EDIT_PARTNER, 
  DELETE_PARTNER
} from "./CONSTANTS"

export const createNewPartner = body => post(CREATE_NEW_PARTNER, body)

export const getPartners = (query) => {
  const params = buildQueryParams({
    limit: query.limit,
    skip: query.offset,
    name: query.searchField,
    country_id: query.locations,
  });

  return get(`${GET_PARTNERS}?${params.toString()}`);
} 

export const editSpecificPartner = (id, body) =>
  put(EDIT_PARTNER(id), body, {"headers": {"Content-Type": "application/json"}})

//Delete partner
export const deletePartner = (id) =>
  del(DELETE_PARTNER(id), {"headers": { "accept": "application/json"}}); // Ensure the id is correctly placed
