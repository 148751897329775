import React, { useEffect, useState } from 'react';
import ToggleSwitch from '../ToggleSwitch';
import { Offcanvas, OffcanvasBody, OffcanvasHeader } from 'reactstrap';
import { Icon } from '@iconify/react';
import { getPartners } from 'services/partnerServices';

function CreatePartner({ setFormData, onCreateSuccess, countryID }) {
  const [showCreatePartner, setShowCreatePartner] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [partners, setPartners] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  
  const [partnerData, setPartnerData] = useState({
    short_name: '',
    long_name: '',
    is_technical_expert: false
  });

  useEffect(()=>{
    const fetchPartners = async () => {
      if (!countryID) return;
      setIsLoading(true);
      try {
        const { data } = await getPartners({
          locations: countryID,
        });
        setPartners(data);
      } catch (err) {
        console.error('Error fetching partners:', err);
        setValidationErrors(prev => ({
          ...prev,
          api: 'Failed to load existing partners to check for duplicacy. Please try again.'
        }));
      } finally{
        setIsLoading(false);
      }
    };

    fetchPartners();
  },[countryID])

  const checkForDuplicates = (field, value) => {
    const normalizedValue = value.trim()?.toLowerCase();
    
    // Check in existing partners list
    const existingPartner = partners?.find(partner => partner[field]?.toLowerCase().trim() === normalizedValue);
    
    let isDuplicateInSelected = '';

    // Check in currently selected partners
    setFormData(prev => {
      isDuplicateInSelected = prev.partner_request_bodies.some(
        partner => partner[field]?.toLowerCase().trim() === normalizedValue
      );
      return prev;
    });
      
    if (existingPartner || isDuplicateInSelected) {
      return `This ${field === 'short_name' ? 'short name' : 'long name'} already exists in the partners list`;
    }

    return '';
  };

  const validateField = (name, value) => {
    switch (name) {
      case 'short_name':
        if (!value.trim()) {
          return 'Short name is required';
        }
        if (value.length > 50) {
          return 'Short name must be less than 50 characters';
        }
        if (!/^[a-zA-Z0-9\s-_]+$/.test(value)) {
          return 'Short name can only contain letters, numbers, spaces, hyphens, and underscores';
        }
        break;
      case 'long_name':
        if (!value.trim()) {
          return 'Long name is required';
        }
        if (value.length > 200) {
          return 'Long name must be less than 200 characters';
        }
        break;
      default:
        return '';
    }
    return '';
  };

  const validateForm = () => {
    const errors = {};
    Object.keys(partnerData).forEach(key => {
      if (key !== 'is_technical_expert') {
        const error = validateField(key, partnerData[key]);
        if (error) {
          errors[key] = error;
        }
      }
    });

    const apiError = validationErrors.api;

    setValidationErrors({
      ...errors,
      ...apiError && {api: apiError}
    });
    
    return Object.keys(errors).length === 0;
  };

  const toggleCreatePartner = () => {
    if (showCreatePartner) {
      // Reset form when closing
      setPartnerData({
        short_name: '',
        long_name: '',
        is_technical_expert: false
      });
      setValidationErrors({});
    }
    setShowCreatePartner(!showCreatePartner);
  };
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPartnerData(prev => ({
      ...prev,
      [name]: value
    }));

    // Validate field on change
    const error = validateField(name, value);
    setValidationErrors(prev => ({
      ...prev,
      [name]: error
    }));
  };

  const handleBlur = (e) => {
    const { name } = e.target;
    
    const error = validateField(name, partnerData[name]);
    setValidationErrors(prev => ({
      ...prev,
      [name]: error
    }));
  };

  const handleToggleChange = () => {
    setPartnerData(prev => ({
      ...prev,
      is_technical_expert: !prev.is_technical_expert
    }));
  };

  const handlePartnerSave = () => {
    if (!validateForm()) {
      return;
    }

    // Check for duplicates
    const duplicateLongName = checkForDuplicates('long_name', partnerData.long_name);
    const duplicateShortName = checkForDuplicates('short_name', partnerData.short_name);
    if (duplicateShortName || duplicateLongName) {
      setValidationErrors(prev => ({
        ...prev,
        ...Boolean(duplicateShortName) && {short_name: duplicateShortName},
        ...Boolean(duplicateLongName) && {long_name: duplicateLongName}
      }));
      return;
    }

    setFormData(prev => {
      const updatedData = {
        ...prev,
        partner_request_bodies: [
          ...prev.partner_request_bodies,
          partnerData,
        ]
      };

      // Reset and close form
      toggleCreatePartner();
      onCreateSuccess?.();
      return updatedData;
    });
  };

  return (
    <div>
      <button
        onClick={toggleCreatePartner}
        className="mt-2 px-4 py-2 bg-green-600 text-white rounded-md flex items-center justify-center w-full hover:bg-green-700"
      >
        <Icon icon="material-symbols:add" className="mr-1" />
        Create Partner
      </button>

      <Offcanvas
        direction="end"
        toggle={toggleCreatePartner}
        isOpen={showCreatePartner}
      >
        <OffcanvasHeader toggle={toggleCreatePartner} className="text-xl">
          Create Partner
        </OffcanvasHeader>
        <OffcanvasBody>
          {validationErrors.api && (
            <div className="mb-4 p-2 bg-red-50 text-red-600 rounded">
              {validationErrors.api}
            </div>
          )}
          <div className="space-y-4">
            <div>
              <label className="block text-sm mb-1">
                Short name*
                <span className="text-gray-500 text-xs ml-1">(max 50 characters)</span>
              </label>
              <input
                name="short_name"
                type="text"
                value={partnerData.short_name}
                className={'w-full p-2 border rounded'}
                placeholder="Enter partner short name"
                onChange={handleInputChange}
                onBlur={handleBlur}
              />
              {validationErrors.short_name && (
                <div className="text-red-500 text-sm mt-1 ml-1">
                  {validationErrors.short_name}
                </div>
              )}
            </div>

            <div>
              <label className="block text-sm mb-1">
                Long name*
                <span className="text-gray-500 text-xs ml-1">(max 200 characters)</span>
              </label>
              <input
                name="long_name"
                type="text"
                value={partnerData.long_name}
                className={'w-full p-2 border rounded'}
                placeholder="Enter partner long name"
                onChange={handleInputChange}
                onBlur={handleBlur}
              />
              {validationErrors.long_name && (
                <div className="text-red-500 text-sm mt-1 ml-1">
                  {validationErrors.long_name}
                </div>
              )}
              <div className="text-right text-sm text-gray-500 mt-1">
                {partnerData.long_name.length}/200
              </div>
            </div>

            <div className="pt-2">
              <ToggleSwitch
                label="Is a technical partner?"
                defaultChecked={partnerData.is_technical_expert}
                onChange={handleToggleChange}
              />
            </div>

            <div className="flex justify-end space-x-3 mt-6">
              <button
                onClick={toggleCreatePartner}
                className="px-4 py-2 border rounded text-gray-600 hover:bg-gray-50 flex items-center"
              >
                <Icon icon="material-symbols:cancel-outline" className="mr-1"/>
                Cancel
              </button>
              <button
                className={'px-4 py-2 text-white rounded flex items-center bg-green-600 hover:bg-green-700'}
                onClick={handlePartnerSave}
                disabled={isLoading}
              >
                <Icon icon="dashicons:saved" className="mr-1"/>
                Save
              </button>
            </div>
          </div>
        </OffcanvasBody>
      </Offcanvas>
    </div> 
  );
}

export default CreatePartner;