import React from "react"
import "./index.scss"
import CustomTabs from "../../components/Common/Custom-Tabs"
import ETrackerTasks from "./ETracker"
import BenchmarkingTasks from "./Benchmarking"
import LandscapingTasks from "./Landscaping"
import TasksCount from "./cards/TasksCard"

export default function TasksIndex({alert}) {
  document.title = "Tasks | AGRA"

  const triggers = [
    { name: "E-Tracker Tasks", value: "tab1" },
    { name: "Benchmarking Tasks", value: "tab2" },
    { name: "Landscaping Tasks", value: "tab3" },
  ]
  const content = [
    { value: "tab1", component: <ETrackerTasks alert={alert}/> },
    { value: "tab2", component: <BenchmarkingTasks alert={alert}/> },
    { value: "tab3", component: <LandscapingTasks alert={alert}/>}
  ]
  return (
    <div className="page-content">
      <TasksCount />
      <CustomTabs
        ariaLabel="Milestone Management"
        defaultValue="tab1"
        triggers={triggers}
        content={content}
      />
    </div>
  )
}