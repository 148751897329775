import React from 'react';
import './duration-display.css';

const DurationItem = ({ value, unit }) => (
  <div>
    <p className='duration-value'>{value || 0}</p>
    <p>{unit}</p>
  </div>
);

const DurationDisplay = ({data, stageNumber}) => {
  return (
    <div className='policy-duration-container'>
        <h6 className='policy-duration-title'>
            Average Duration Of Policies 
            {/* {stageNumber && 'In Stage' + stageNumber} */}
        </h6>
        <div className='duration-items-container'>
            <DurationItem value={data?.years} unit={'Years'}/>
            <div className='divider'/>
            <DurationItem value={data?.months} unit={'Months'}/>
            <div className='divider'/>
            <DurationItem value={data?.weeks} unit={'Weeks'}/>
            <div className='divider'/>
            <DurationItem value={data?.days} unit={'Days'}/>
        </div>
    </div>
  );
};

export default DurationDisplay;