import { buildQueryParams } from "helpers/utlities"
import { del, get, post, put } from "./AXIOS"
import {
  CREATE_NEW_ORGANIZATION_TYPE,
  CREATE_NEW_ORGANIZATION,
  LIST_ORGANIZATION_TYPES,
  LIST_ORGANIZATIONS,
  GET_ORGANIZATION_TYPES,
  GET_ORGANIZATIONS,
  GET_ORGANIZATION_TYPE_SUGGESTIONS,
  GET_SPECIFIC_ORGANIZATION_TYPE_DETAILS,
  GET_SPECIFIC_ORGANIZATION_DETAILS,
  GET_ORGANIZATION_SUGGESTIONS,
  GET_USER_ORGANIZATIONS,
  EDIT_ORGANIZATIONS,
  DeleteOrganization,
  EDIT_USER_ORGANISATION, DELETE_USER_ORGANISATION
} from "./CONSTANTS"

export const listOrganizationTypes = params =>
  post(LIST_ORGANIZATION_TYPES, params)

export const listOrganizations = params => post(LIST_ORGANIZATIONS, params)
export const getUserOrganizations = async () => {
  try {
    const organisations = await get(GET_USER_ORGANIZATIONS)
    return organisations
  } catch (e) {
    console.log("OrgError", e)
  }
}

export const createNewOrganizationType = body =>
  post(CREATE_NEW_ORGANIZATION_TYPE, body)

export const createNewOrganization = body => post(CREATE_NEW_ORGANIZATION, body)

export const getOrganizationTypes = (query, body) => {
  if (query?.searchTerm) {
    return post(
      `${GET_ORGANIZATION_TYPES}/${query.searchTerm}?limit=${query.limit}&offset=${query.offset}`,
      body
    )
  } else {
    return post(
      `${GET_ORGANIZATION_TYPES}?limit=${query.limit}&offset=${query.offset}`,
      body
    )
  }
}

// export const getOrganizations = (query, body) => {
//   if (query?.searchTerm) {
//     return post(
//       `${GET_ORGANIZATIONS}/${query.searchTerm}?limit=${query.limit}&offset=${query.offset}`,
//       body
//     )
//   } else {
//     return post(
//       `${GET_ORGANIZATIONS}?limit=${query.limit}&offset=${query.offset}`,
//       body
//     )
//   }
// }

export const getOrganizations = (query) => {
  const params = buildQueryParams({
    limit: query.limit,
    skip: query.offset,
    name: query.searchField,
    country_id: query.locations,
  });
  return get(`${GET_ORGANIZATIONS}?${params.toString()}`);
};

export const editOrganization = (id, body) =>
  put(`${EDIT_ORGANIZATIONS.replace("{organisation_id}", id)}`, body);

export const deleteOrganization = id => {
  return DeleteOrganization(`/organisation/${id}`);
};

export const getOrgTypeSuggestions = input =>
  get(`${GET_ORGANIZATION_TYPE_SUGGESTIONS}/${input}`)

export const getOrganizationSuggestions = input =>
  get(`${GET_ORGANIZATION_SUGGESTIONS}/${input}`)

export const getSpecificOrgTypeDetails = id =>
  get(`${GET_SPECIFIC_ORGANIZATION_TYPE_DETAILS}/${id}`)

export const editSpecificOrgType = (id, body) =>
  post(`${GET_SPECIFIC_ORGANIZATION_TYPE_DETAILS}/${id}`, body)

export const editSpecificOrganization = (id, body) =>
  post(`${GET_SPECIFIC_ORGANIZATION_DETAILS}/${id}`, body)

export const editUserOrganisation = (id, body) =>
  put(EDIT_USER_ORGANISATION(id), body, {"headers": {"Content-Type": "application/json"}})

//Delete partner
export const deleteUserOrganisation = (id) =>
  del(DELETE_USER_ORGANISATION(id), {"headers": { "accept": "application/json"}}); // Ensure the id is correctly placed
