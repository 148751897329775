import React, { Fragment } from "react"
import * as Popover from "@radix-ui/react-popover";
import { formateDate, formatTimeAgo, formatDateUTC } from "helpers/utlities"
import { Table } from "reactstrap"
import { AUDITROLES } from "constants/global"
import styled from "@emotion/styled"
import "./Audit.css"

const StyledTable = styled(Table)`
  width: 100%;
  table-layout: fixed; /* Ensure the table layout is fixed */
  background-color: transparent;
  border-radius: 16px 16px 0px 0px;
  overflow: hidden;

  & thead {
    background-color: #f8f9fa;
  }

  & td {
    padding: 1em;
    text-align: left;
    font-size: 14px;
    font-weight: 500;
    border: none;
    border-bottom: 1px solid #dee2e6;
    vertical-align: middle;
    background-color: white;
  }

  & tbody {
    & tr {
      background-color: white !important;
      cursor: default;

      &:hover {
        background-color: #f8f9fa;
      }

      & .activity_cell {
        white-space: pre-wrap; /* Allows wrapping for long words */
        word-wrap: break-word; /* Break long words */
        overflow-wrap: break-word; /* Ensures long words are broken */
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 300px; /* Set a max-width to control the cell width */
      }
    }
  }

  & .popover-trigger {
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: pre-wrap;
    max-width: 200px;
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    vertical-align: top;
  }

  & tbody tr td:first-of-type {
    .name-cell {
      padding: 0.2em 0.4em;
      border-radius: 0.8em;
      background-color: rgba(80, 165, 241, 0.1);
      color: rgba(80, 165, 241);
    }
  }
`;

const StyledTableContainer = styled.div`
  width: 100%;
  padding: 1em;
  overflow-x: auto; /* Allows horizontal scroll if necessary */
`;

const StyledTableRow = styled.tr`
    // text-transform: capitalize;
    z-index: 1;
    cursor: default;

    &:hover {
        background-color: #d3eed6 !important;
    }

    & .event_cell > p {
        background-color: ${props => props.eventColor};
        padding: 0.4em 0.6em;
        width: max-content;
        border-radius: 1000vmax;
        word-wrap: break-word;
    }

    & .activity_cell {
        & > div {
            display: flex;
            gap: 1em;
            align-items: baseline;

            & p {
                white-space: pre-wrap;
                word-wrap: break-word;
                overflow-wrap: break-word;
            }
        }
    }
`

const AuditLogRow = ({ log }) => {
  const ACTION_COLORS = new Map([
    ["created", "#D7FEEA"],
    ["updated", "#FEDCDC"],
    ["deleted", "#FEF0F1"]
  ])
  const timestamp = formatDateUTC(log.created_at)
  return (
    <StyledTableRow eventColor={ACTION_COLORS.get(log.activity) ?? "#FEDCDC"}>
      <td>
        <p style={{ color: "#495057" }}>
          {`${log.user?.first_name}  ${log.user?.last_name}` ?? "N/A"}
        </p>
      </td>
      <td className="activity_cell">
        <p>
          {log.user?.email ?? "N/A"}
        </p>
      </td>
      <td>
        <p style={{ fontWeight: "500" }}>
          {log.user?.is_admin ? "Admin" : "User"}
        </p>
      </td>
      <td>
        <p>
          {log.user?.is_active ? "Active" : "Inactive"}
        </p>
      </td>
      <td className="activity_cell"> {/* Updated class for activity column */}
        <p>{log.activity || ""}</p>
      </td>
      <td>
        {log.created_at || ""}
      </td>
    </StyledTableRow>
  )
}

function AuditTable({ data }) {
  return (
    <StyledTableContainer>
      <StyledTable className="custom-table">
        <colgroup>
          <col style={{ width: "15%" }} />
          <col style={{ width: "15%" }} />
          <col style={{ width: "15%" }} />
          <col style={{ width: "15%" }} />
          <col style={{ width: "20%" }} />
          <col style={{ width: "20%" }} />
        </colgroup>
        <thead>
          <tr className="table-head">
            <th scope="col">Full Name</th>
            <th scope="col">Email</th>
            <th scope="col">User Role</th>
            <th scope="col">User Status</th>
            <th scope="col">Activity</th>
            <th scope="col">Timestamp</th>
          </tr>
        </thead>
        <tbody>
          {data.map(log => (
            <Fragment key={log.audit_id}>
              <AuditLogRow log={log} />
            </Fragment>
          ))}
        </tbody>
      </StyledTable>
    </StyledTableContainer>
  )
}

export default AuditTable;
