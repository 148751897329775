import axios from "axios"
import { useSessionStore } from "services/sessionStore"
//import authHeader from "helpers/jwt-token-access/auth-token-header"
// import LocalStorageService from './services/storage/localstorageservice'
// import router from './router/router'

//pass new generated access token here
//const token = authHeader()

//const localStorageService = LocalStorageService.getService()

// Add a request interceptor
// axios.interceptors.request.use(
//   config => {
//     const token = localStorageService.getAccessToken()
//     if (token) {
//       config.headers['Authorization'] = 'Bearer ' + token
//     }
//     // config.headers['Content-Type'] = 'application/json';
//     return config
//   },
//   error => {
//     Promise.reject(error)
//   }
// )

// https://lightrains.com/blogs/axios-intercepetors-react/

//apply base url for axios
const API_URL = process.env.REACT_APP_INTELLIGENCE_API

const axiosApi = axios.create({
  baseURL: API_URL,
  // timeout: 10000,
  //   headers: { 'api-key': 'eyJz-CI6Ikp-4pWY-lhdCI6' }
})

// if (localStorage.getItem("token")) {
//   const token = `Bearer ${localStorage.getItem("token")}`
//   axiosApi.defaults.headers.common["Authorization"] = token
// }

const requestHandler = request => {
  request.headers.Authorization = process.env.REACT_APP_INTELLIGENCETOKEN
  return request
}

const errorHandler = error => {
  if (error.response.data.detail === "Could not validate credentials") {
    useSessionStore.setState({ isSessionExpired: true });
  }
  // if (error.response.status == 403) window.location = "/pages-404"
  return Promise.reject(error)
}

axiosApi.interceptors.request.use(
  request => requestHandler(request),
  error => errorHandler(error)
)

// axiosClient.defaults.timeout = 2000;

// axiosClient.defaults.withCredentials = true;

// axios.interceptors.request.use(function (request) {
//   request.headers['Content-Type'] = 'multipart/form-data';
//   return request;
// }, null, { synchronous: true });

const responseHandler = response => {
  if (response.status === 401) {
    window.location = "/login"
  }

  return response
}

axiosApi.interceptors.response.use(
  response => responseHandler(response),
  error => errorHandler(error)
)

export async function postFormData(url, data, config = {}) {
  return axiosApi.postForm(url, data, { ...config }).then(response => response.data)
}

export async function post(url, data, config = {}) {
  return axiosApi.post(url, { ...data }, { ...config }).then(response => {
    return response.data
  })
}

export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then(response => response.data)
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function patch(url, data, config = {}) {
  return axiosApi
    .patch(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
}
