import React, { useState, useRef, useEffect } from "react";
import "./Partners-Dropdown.css";

const PartnersDropdown = ({ partners }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  // Toggle the dropdown
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  // Handle clicking outside of the dropdown to close it
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);  // Close the dropdown if clicked outside
    }
  };

  // Add/remove the event listener for clicks
  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    
    // Cleanup the event listener when the component is unmounted
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div className="partners-dropdown" ref={dropdownRef}>
      <div className="w-full">
        <div className="partners-text truncate">{partners[0].partner.long_name}</div>
      </div>
      
      <button onClick={toggleDropdown} className="dropdown-button">
        Show all
        <span className="dropdown-icon">
          <i className={isOpen ? "fas fa-chevron-up" : "fas fa-chevron-down"}></i>
        </span>
      </button>

      {/* Dialog for partners list */}
      {isOpen && (
        <div className="dropdown-content dialog-box">
          <ul style={{ padding: 0, margin: 0, listStyleType: "none" }}>
            {partners.map((partner, index) => (
              <li key={index} style={{ color: "#000000" }}>
                {partner.partner.long_name} {partner.partner.is_technical_expert && "(Technical Expert)"}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};


export default PartnersDropdown;
