import React, { useState, useEffect, useRef } from "react";
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { getData } from "services/etracker";
import './etracker.scss';
import CountriesDropdown from "../../components/Common/countries-dropdown";
import { Button } from "reactstrap";
import Overview from "./Overview";
import Stages from "./Stages";
import PolicyWins from "./PolicyWins";
import { Icon } from "@iconify/react";
import { useSelector } from "react-redux";
import AGRASupportedDropdown from "components/Common/AGRASupportedDropdown";

const Tracker = ({ alert }) => {
  const contentRef = useRef(null);
  const [isDownloading, setIsDownloading] = useState(false);
  
  const tabs = {
    overview: 'Overview',
    stage1: '1. Initiation',
    stage2: '2. Development',
    stage3: '3. Validation',
    stage4: '4. Approval',
    stage5: '5. Legislation',
    stage6: '6. Implementation',
    policyWin: 'Policy Wins'
  };

  const [activeTabID, setActiveTabID] = useState(0);
  const [overViewTab, setOverViewTab] = useState('number_of_policies');
  const [stageTab, setStageTab] = useState('policy_distribution');
  const [policyWinsTab, setPolicyWinsTab] = useState('policy_distribution');
  const [countryID, setCountryID] = useState(null);
  const [data, setData] = useState();
  const [path, setPath] = useState('');
  const [AGRASupported, setAGRASupported] = useState(null);
  const [isAGRAUser] = useState(
    Boolean(
      useSelector(
        (state) => state.Profile)
        .user
        ?.user_organisation
        ?.organisation
        ?.short_name === 'AGRA'
      )
    );

  const getTabData = async () => {
    try {
      let query = {};

      if (activeTabID === 0) {
        query = {
          metric: overViewTab,
        };
      } else if (activeTabID >= 1 && activeTabID <= 6) {
        query = {
          analysis_type: stageTab,
        };
      } else if (activeTabID === 7) {
        query = {
          analysis_type: policyWinsTab,
        };
      }
      
      countryID ? query['country_id'] = countryID : null;
      if(AGRASupported?.label) query['is_agra_supported'] = AGRASupported.value;
      
      setData(await getData(activeTabID, query));
    } catch(err) {
      setData();
      console.error(err);
      alert('Fetching data', false);
    }
  };

  const generatePDF = async () => {
    try {
      setIsDownloading(true);
      
      // Get the content container
      const content = document.getElementById('print-content');
      if (!content) {
          throw new Error('Content not found');
      }

      // Create PDF document
      const pdf = new jsPDF('p', 'mm', 'a4');
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const margin = 10; // margin in mm

      // Add header
      pdf.setFontSize(16);
      pdf.text('E-Tracker Report', pdfWidth / 2, margin, { align: 'center' });
      
      // Add filters information
      pdf.setFontSize(12);
      const filterText = [
        `Country: ${countryID ? data?.location?.data?.find(c => c.id === countryID)?.name : 'All Countries'}`,
        `AGRA Supported: ${AGRASupported.label ? AGRASupported.label : 'Not Selected'}`
      ].join(' | ');
      pdf.text(filterText, pdfWidth / 2, margin + 10, { align: 'center' });

      // Convert the content to canvas
      const canvas = await html2canvas(content, {
        scale: 2,
        useCORS: true,
        allowTaint: true,
        backgroundColor: '#ffffff'
      });

      // Calculate dimensions to fit the page width
      const imgWidth = pdfWidth - (2 * margin);
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      
      // If content is taller than one page, split it across multiple pages
      let remainingHeight = canvas.height;
      let yPosition = 0;
      let pdfYPosition = margin + 20; // Start after header

      while (remainingHeight > 0) {
        // Calculate height that can fit on current page
        const availableHeight = pdfHeight - pdfYPosition - margin;
        const heightToUse = Math.min(availableHeight, imgHeight);
        
        // Add image to PDF
        const imgData = canvas.toDataURL('image/png');
        pdf.addImage(
          imgData,
          'PNG',
          margin,
          pdfYPosition,
          imgWidth,
          heightToUse,
          '',
          'FAST'
        );

        remainingHeight -= (heightToUse * canvas.width) / imgWidth;
        
        // if (remainingHeight > 0) {
        //   pdf.addPage();
        //   pdfYPosition = margin;
        //   yPosition += heightToUse;
        // }
      }

      // Add footer with date
      const date = new Date().toLocaleDateString();
      pdf.setFontSize(10);
      pdf.text(`Generated on ${date}`, margin, pdfHeight - margin);

      // Save the PDF
      const fileName = `E-Tracker_Report_${date.replace(/\//g, '-')}.pdf`;
      pdf.save(fileName);
    } catch (error) {
      console.error('Error generating PDF:', error);
      alert('Error generating PDF', false);
    } finally {
      setIsDownloading(false);
    }
  };

  useEffect(() => {
    getTabData();
  }, [activeTabID, countryID, policyWinsTab, stageTab, overViewTab, AGRASupported]);

  useEffect(() => {
    setPath(window.location.pathname);
  }, []);

  return (
    <div className={path !== '/home-tracker' ? 'page-content' : ''}>
      <div className="d-flex justify-content-between items-center">
        <div className="country-selection">
          <div className="flex items-center mx-3">
            <span className="mr-2 text-nowrap">Showing data for </span>
            <CountriesDropdown countryID={countryID} setCountryID={setCountryID}/>
          </div>
          {
            isAGRAUser ? 
            <AGRASupportedDropdown selectedOption={AGRASupported} setSelectedOption={setAGRASupported}/>
            : null
          }
        </div>
        <Button 
          className="common-button" 
          onClick={generatePDF}
          disabled={isDownloading}
        >
          {isDownloading ? <Icon icon="codex:loader"/> : <i className="bx bx-download"/>}
        </Button>
      </div>
      <div className="d-flex p-0 h-14">
        {Object.keys(tabs)?.map((tab, index) => (
          <div
            key={index}
            className={`main-tab ${index === activeTabID ? 'main-tab-active' : ''}`}
            onClick={() => setActiveTabID(index)}
          >
            <p>{tabs[tab]}</p>
          </div>
        ))}
      </div>
      <div className="data-page">
        <div className="data-page-content" ref={contentRef}>
          {data && (
            activeTabID === 0 ?
              <Overview 
                overViewTab={overViewTab} 
                setOverViewTab={setOverViewTab} 
                data={data} 
                countryID={countryID} 
                setCountryID={setCountryID}
              />
              : activeTabID >= 1 && activeTabID <= 6 ? 
              <Stages 
                stageTab={stageTab} 
                setStageTab={setStageTab} 
                data={data} 
                countryID={countryID} 
                setCountryID={setCountryID}
              /> 
              :
              <PolicyWins 
                policyWinsTab={policyWinsTab} 
                data={data} 
                countryID={countryID} 
                setCountryID={setCountryID} 
                setPolicyWinsTab={setPolicyWinsTab}
              />
          )}
        </div>
      </div>
    </div>
  );
};

export default Tracker;