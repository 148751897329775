import * as Popover from "@radix-ui/react-popover";
import React from "react";
import { Table } from "reactstrap";
import DeleteModal from "components/Common/DeleteModal";
import styled from "@emotion/styled";
import "./systems.css";

// Styling for the button group
const StyledButtonGroup = styled.div`
  display: flex;
  gap: 1em;
  border-collapse: separate;

  & button {
    all: unset;
    cursor: pointer;
  }

  & .mdi-pencil {
    color: #4caf50; /* Green for edit */
    transition: transform 0.2s;
  }

  & .mdi-delete {
    color: #f44336; /* Red for delete */
    transition: transform 0.2s;
  }

  & button:hover .mdi-pencil,
  & button:hover .mdi-delete {
    transform: scale(1.1);
  }
`;

// Styling for the Popover trigger
const PopoverTrigger = styled(Popover.Trigger)`
  all: unset;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  cursor: pointer;
`;

// Styling for the Popover content
const PopoverContent = styled(Popover.Content)`
  background-color: #000;
  color: whitesmoke;
  padding: 0.5em 1em;
  border-radius: 0.5em;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
  z-index: 999;

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: -5px;
    width: 10px;
    height: 10px;
    background-color: #000;
    transform: rotate(45deg);
    z-index: -1;
  }
`;

// Styling for the table container to ensure it takes up the full space and allows scrolling
const StyledTableContainer = styled.div`
  width: 100%;
  padding: 1em;
  overflow-x: auto; /* Allows horizontal scroll if necessary */
`;

// Main styling for the table
const StyledTable = styled(Table)`
  width: 100%;
  table-layout: fixed;
  background-color: transparent;
  border-radius: 16px 16px 0px 0px;
  overflow: hidden;

  & thead {
    background-color: #f8f9fa;
  }

  // & th {
  //   padding-block: 1.5em;
  //   background-color: ##F0F0F0;
  //   text-align: left;
  //   font-size: 1rem;
  //   color: #495057;
  //   border-bottom: 2px solid #dee2e6;
  // }

  & td {
    padding: 1em;
    text-align: left;
    font-size: 15px;
    font-weight: 500;
    border: none; /* Remove all borders */
    border-bottom: 1px solid #dee2e6; /* Only keep the bottom border */
    vertical-align: middle;
    background-color: white; /* Ensure the background is white */
  }

  // & td:first-child {
  //   border-top: none; /* Ensure no top border on the first row */
  //   color: #036F36; /* Set the text color for the first column */
  // }

  & tbody {
    & tr {
      background-color: white !important; /* Ensure all rows have a white background */
      cursor: default;

      &:hover {
        background-color: #f8f9fa; /* Add a hover effect for rows */
      }

      & .description_cell {
        white-space: pre-wrap;
        word-wrap: break-word;
        text-overflow: ellipsis;
        overflow-wrap: break-word;
        max-width: 200px;
      }
    }

  }

  & .popover-trigger {
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: pre-wrap;
    max-width: 200px;
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    vertical-align: top;
  }

  & tbody tr td:first-of-type {
    .name-cell {
      padding: 0.2em 0.4em;
      border-radius: 0.8em;
      // background-color: rgba(80, 165, 241, 0.1);
      // color: rgba(80, 165, 241);
    }
  }
`;

export const SystemsTable = ({ systemsData, initiateEdit, caption, onDeleteClick }) => {
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [deletionObjectId, setDeletionObjectId] = React.useState();

  return (
    <StyledTableContainer>
      <StyledTable className="custom-table">
        <colgroup>
          <col style={{ width: "25%" }} />
          <col style={{ width: "20%" }} />
          <col style={{ width: "15%" }} />
          <col style={{ width: "15%" }} />
          <col style={{ width: "15%" }} />
          <col style={{ width: "15%" }} />
          <col style={{ width: "10%" }} />
        </colgroup>
        <thead>
          <tr>
            <th>Name</th>
            <th>Description</th>
            <th scope="col">Created By</th>
            <th scope="col">Created At</th>
            <th scope="col">Updated By</th>
            <th scope="col">Updated At</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {systemsData.map((system) => (
            <tr key={system.id}>
              <td style={{ color: "#495057" }}>
                {system.name}
              </td>
              <td className="description_cell">
                <Popover.Root>
                  <PopoverTrigger className="popover-trigger">
                    {system.description ?? ""}
                  </PopoverTrigger>
                  <Popover.Anchor />
                  <Popover.Portal>
                    <PopoverContent sideOffset={15} side="right">
                      {system.description ?? ""}
                      <Popover.Arrow />
                    </PopoverContent>
                  </Popover.Portal>
                </Popover.Root>
              </td>
              <td>{`${system.created_by?.first_name || ""} ${system.created_by?.last_name || ""}`.trim() || ""}</td>
              <td>{system?.created_at || ""}</td>
              <td>{`${system.updated_by?.first_name || ""} ${system.updated_by?.last_name || ""}`.trim() || ""}</td>
              <td>{system?.updated_at || ""}</td>
              <td>
                <StyledButtonGroup>
                  <button onClick={() => initiateEdit(system.id)}>
                    <i className="mdi mdi-pencil font-size-18 hover-event text-success" />
                  </button>
                  <button
                    onClick={() => {
                      setDeleteModal(true);
                      setDeletionObjectId(system.id);
                    }}
                  >
                    <i className="mdi mdi-delete font-size-18 del-icon text-danger" />
                  </button>
                </StyledButtonGroup>
              </td>
            </tr>
          ))}
        </tbody>
        <DeleteModal
          show={deleteModal}
          onCloseClick={() => setDeleteModal(false)}
          onDeleteClick={() => onDeleteClick(deletionObjectId)}
          item={caption}
        />
      </StyledTable>
    </StyledTableContainer>
  );
};

