import { buildQueryParams } from "helpers/utlities";
import { del, get, post, put } from "./AXIOS"
import {
  LIST_ALL_USERS,
  USER,
  USER_POLICY_TASKS_HISTORY,
  USER_BENCHMARKING_TASKS_HISTORY,
  INVITE_USER,
  LIST_ACTIVE_USERS
} from "./CONSTANTS"

export const getUsers = (query) => {

  const params = buildQueryParams({
    limit: query.limit,
    skip: query.offset,
    is_admin: query.role?.value
  });

  return get(`${LIST_ALL_USERS}?${params.toString()}`);
}

export const inviteUser = async (formData) => {
  try {
    const response = await post(INVITE_USER, formData, {
      "headers": {
        'Content-Type': 'application/json',
      },
    });
    return response;
  } catch (error) {
    throw new Error('Error creating event: ' + error.message);
  }
};

export const getUserDetail = id => get(`${USER}/${id}`)

export const updateUser = (id, user) => put(`${USER}/${id}`, user)

export const deleteUser = id => del(`${USER}/${id}`)

export const getPolicyTasksHistory = query =>
  get(
    `${USER_POLICY_TASKS_HISTORY}?limit=${query.limit}&offset=${query.offset}`
  )
export const getBenchmarkingTasksHistory = query =>
  get(
    `${USER_BENCHMARKING_TASKS_HISTORY}?limit=${query.limit}&offset=${query.offset}`
  )

  export const fetchActiveUsers = async () => {
    try {
      const response = await get(LIST_ACTIVE_USERS);
      return response?.data || []; // Return active users
    } catch (error) {
      console.error("Error fetching active users:", error);
      throw new Error("Failed to fetch active users.");
    }
  };
  
  // Fetch supervisors (admins)
  export const fetchSupervisors = async () => {
    try {
      const response = await get(`${LIST_ACTIVE_USERS}?is_admin=True`);
      return response?.data || []; // Return active admin users
    } catch (error) {
      console.error("Error fetching supervisors:", error);
      throw new Error("Failed to fetch supervisors.");
    }
  };