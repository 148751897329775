// ScoringGuide.jsx
import React from 'react';
import styled from '@emotion/styled';

// Styled Components
const ScoringGuideContainer = styled.div`
  margin: 20px auto;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
`;

const ScoringTitle = styled.h3`
  text-align: center;
  font-weight: bold;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
`;

const TableHeader = styled.th`
  background-color: #036F36;
  color: white;
  padding: 10px;
  font-weight: bold;

  &.weight-class {
    text-align: right;  /* Align to the right */
  }
`;



const TableRow = styled.tr`
  border-bottom: 1px solid #ddd;
`;

const TableCell = styled.td`
  padding: 15px;
  text-align: left;

   &.weight-class {
    text-align: right;  /* Align to the right */
  }
`;

const ScoringGuide = () => {
  return (
    <ScoringGuideContainer>
      <ScoringTitle>Scoring Guide</ScoringTitle>
      <Table>
        <thead>
          <TableRow>
            <TableHeader>Option</TableHeader>
            <TableHeader className='weight-class'>Weight</TableHeader>
          </TableRow>
        </thead>
        <tbody>
          <TableRow>
            <TableCell>Yes</TableCell>
            <TableCell className='weight-class'>1</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>No</TableCell>
            <TableCell className='weight-class'>0</TableCell>
          </TableRow>
          {/* <TableRow>
            <TableCell>There is considerable policy implementation but still room for improvement</TableCell>
            <TableCell>2</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Satisfactory progress with policy implementation</TableCell>
            <TableCell>3</TableCell>
          </TableRow> */}
        </tbody>
      </Table>
    </ScoringGuideContainer>
  );
};

export default ScoringGuide;
