import PropTypes from "prop-types"
import React, { useRef } from "react"

import {
  Alert,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap"
import { FiArrowRight } from "react-icons/fi"
//redux
import { useSelector, useDispatch } from "react-redux"

import { withRouter, Link } from "react-router-dom"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

// actions
import { apiError, loginUser } from "../../store/actions"

// import images
import ReCAPTCHA from "react-google-recaptcha"

const Login = props => {
  //meta title
  const dispatch = useDispatch()
  const captchaRef = useRef(null)

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password").min(4),
    }),
    onSubmit: values => {
      if (captchaRef.current.getValue() == "") {
        dispatch(apiError("Please Fill recaptcha"))
      } else {
        values.recaptchaValue = captchaRef.current.getValue()
        dispatch(loginUser(values))
      }
    },
  })

  const { error } = useSelector(state => ({
    error: state.Login.error,
  }))

  // useEffect(() => {
  //   document.body.className = "auth-body"
  // }, [])
  return (
    <React.Fragment>
        {/* <div className="fixed left-0 top-0 bottom-0">
            <img src={auth1Image} alt="" className="w-auto h-full" />
          </div>
          <div className="fixed right-0 top-0 bottom-0">
            <img src={auth2Image} alt="" className="w-auto h-full" />
          </div> */}
        <div className="flex flex-row justify-start items-center">
            <div className="bg-white rounded-2xl ">
              <div className="p-4 pt-4 pb-4">
                <div className="text-primary pb-4 ml-0">
                  <h2 className="text-primary text-2xl ml-0">Login</h2>
                </div>
                <Form
                  className="form-horizontal"
                  onSubmit={e => {
                    e.preventDefault()
                    validation.handleSubmit()
                    return false
                  }}
                >
                  {error ? <Alert color="danger">{error}</Alert> : null}

                  <div className="mb-3">
                    <Label className="form-label text-primary ml-0 font-medium">Email</Label>
                    <Input
                      name="email"
                      className="form-control h-12 rounded-lg"
                      placeholder="Enter email"
                      type="email"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      //value={validation.values.email || ""}
                      invalid={
                        !!(validation.touched.email && validation.errors.email)
                      }
                    />
                    {validation.touched.email && validation.errors.email ? (
                      <FormFeedback type="invalid">
                        {validation.errors.email}
                      </FormFeedback>
                    ) : null}
                  </div>

                  <div className="mb-3">
                    <Label className="form-label text-primary ml-0 font-medium">Password</Label>
                    <Input
                      className="h-12 rounded-lg"
                      name="password"
                      //value={validation.values.password || ""}
                      type="password"
                      placeholder="Enter Password"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      invalid={
                        !!(
                          validation.touched.password &&
                          validation.errors.password
                        )
                      }
                    />
                    {validation.touched.password &&
                    validation.errors.password ? (
                      <FormFeedback type="invalid">
                        {validation.errors.password}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <ReCAPTCHA
                      sitekey={process.env.REACT_APP_SITE_KEY}
                      ref={captchaRef}
                    />
                  </div>
                  <div className="d-grid">
                    <button
                      className="btn btn-primary btn-block rounded-[2.25rem] text-[#FFF605] flex flex-row items-center justify-center"
                      type="submit"
                    >
                      Login
                      <div className="rounded-full flex justify-center items-center w-4 h-4 bg-[#EAE200] ml-1">
                        <FiArrowRight color="#036F36" />
                      </div>
                    </button>
                  </div>
                  <div className="mt-3 text-center">
                    <Link to="/forgot-password" className="text-muted">
                      <i className="mdi mdi-lock me-1" />
                      Forgot your password?
                    </Link>
                  </div>
                  {/* <div className="mt-2 text-center">
                        <p className="text-muted">
                          Don&#39;t have an account ?{" "}
                          <Link to="/register" className="fw-medium text-primary">
                            {" "}
                            Signup now{" "}
                          </Link>{" "}
                        </p>
                      </div> */}
                </Form>
              </div>
          </div>
        </div>
    </React.Fragment>
  )
}

export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
}
