import React, { useState, useEffect } from "react"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, Label, FormGroup, Form } from "reactstrap"
import SearchBox from "components/Common/SearchBox"
import Pagination from "components/Common/Pagination"
import ExportTable from "../../components/Common/ExportTable"
import FormsTable from "./UserTable"
import LoadingSpinner from "../../components/Common/Loading-Spinner"
import useDebounce from "hooks/useDebounce"
import { exportTableData } from "services/common"
import { getUserOrganizations, getUsers, inviteUser, listCountries } from "../../services"
import { StyledContainer } from "pages/Labels/Systems"
import "./User.css"
import "./user-details.css"
import "./CreateNewUser.css"
import Filters from "./Filters"
import { areObjectsEqual } from "helpers/utlities"

const UserManagement = ({ alert }) => {
  document.title = "User Management | AGRA"

  const [showModal, setShowModal] = useState(false)
  const [countries, setCountries] = useState([])
  const [organizations, setOrganizations] = useState([])
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    country_id: "",
    organisation_id: "",
    organisation_short_name: "",
    organisation_long_name: ""
  })
  const [createNewOrg, setCreateNewOrg] = useState(false)

  const [searchField, setSearchField] = useState("")
  const debouncedSearchTerm = useDebounce(searchField)
  const [selectedLocations, setSelectedLocations] = useState([])

  const [currentPage, setCurrentPage] = useState(1)
  const [totalEntries, setTotalEntries] = useState(0)
  const [offset, setOffset] = useState(0)

  const [usersData, setUsersData] = useState(null)

  // Manage loading, error, and milestonesData states
  const [isLoading, setIsLoading] = useState(true)
  const [isEmailValid, setIsEmailValid] = useState(true)


  // Modal management
  const openModal = () => setShowModal(true)

  //filters
  const initialFiltersState = {
    role: null
  }

  const [appliedFilters, setAppliedFilters] = useState(initialFiltersState);

  const applyFilters = (filters) => {
    setAppliedFilters(filters);
    toggleModalFilter();
  }

  const areAnyFiltersSelected = () => !areObjectsEqual(appliedFilters, initialFiltersState);

  const clearFilters = () => setAppliedFilters(initialFiltersState);

  const [modalFilter, setModalFilter] = useState(false)
  const toggleModalFilter = () => setModalFilter(prevState => !prevState)

  const searchChange = e => {
    setSearchField(e.target.value.toLowerCase())
    setOffset(0)
    setCurrentPage(1)
  }

  const goToPage = page => {
    if (page > 0) {
      const offsetParam = page * 50 - 50
      setOffset(offsetParam)
      setCurrentPage(page)
    } else {
      console.error("Page number should be greater than 0")
    }
  }

  const fetchUser = async () => {
    const query = {
      ...appliedFilters,
      limit: 50,
      offset
    }
    setIsLoading(true)
    try {
      const { data, count } = await getUsers(query);
      setUsersData(data)
      setTotalEntries(count)
    } catch (error) {
      console.error(error)
      alert("Fetching data", false)
    } finally {
      setIsLoading(false)
    }
  }

  const fetchCountries = async () => {
    const response = await listCountries()
    setCountries(response || [])
  }

  const fetchOrganizations = async () => {
    const response = await getUserOrganizations()
    setOrganizations(response || [])
  }

  const toggleModal = () => setShowModal(!showModal)

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData((prev) => ({ ...prev, [name]: value }))

    if (name === "email") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      setIsEmailValid(emailRegex.test(value))
    }

    if (name === "organisation_id") {
      setCreateNewOrg(!value) // Enable checkbox if no organization is selected
    }
  }

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData((prev) => ({ ...prev, [name]: value }));
  // };

  const handleOrgToggle = () => {
    setCreateNewOrg(!createNewOrg)
    if (!createNewOrg) {
      setFormData((prev) => ({ ...prev, organisation_short_name: "", organisation_long_name: "" }))
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (!isEmailValid) {
      alert("Please enter a valid email address.", false)
      return
    }

    try {
      const payload = {
        user_invite_create_request_body: {
          first_name: formData.first_name,
          last_name: formData.last_name,
          email: formData.email,
          country_id: formData.country_id
        },
        organisation_request_body: createNewOrg
          ? { short_name: formData.organisation_short_name, long_name: formData.organisation_long_name }
          : { id: formData.organisation_id }
      }

      const res = await inviteUser(payload)

      if (res && res.message) {
        alert(res.message, true)
      } else {
        alert("User Invitation", false)
      }
      toggleModal()
    } catch (error) {
      alert("Error creating user.", false)
    }
  }

  // Fetch countries and organizations when modal opens
  useEffect(() => {
    if (showModal) {
      fetchCountries()
      fetchOrganizations()
    }
  }, [showModal])

  useEffect(() => {
    fetchUser()
  }, [
    appliedFilters,
    debouncedSearchTerm,
    currentPage])

  const exportTable = async type => {
    try {
      const query = {
        export_format: type
      }
      exportTableData(query, "Users")

    } catch (err) {
      console.error(err)
      alert("Data export", false)
    }
  }

  return (
    <div className="page-content">
      <div className="d-flex justify-content-between">
        <StyledContainer>
          <SearchBox searchChange={searchChange} />
          <Button
            className="common-button"
            onClick={toggleModalFilter}
          >
            <i className="mdi mdi-filter-variant me-1" /> Filters
          </Button>
          {areAnyFiltersSelected() && (
            <Button
              className="common-button"
              onClick={clearFilters}
            >
              <i className="ph-x me-1"></i>
              Clear All Filters
            </Button>
          )}
        </StyledContainer>
        <Button
          className="common-button"
          onClick={openModal}
          style={{ marginInline: 0 }}
        >
          Create New
        </Button>

        {/* Create User Modal */}
        {/* <Modal isOpen={showModal} toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>Create New User</ModalHeader>
          <Form onSubmit={handleSubmit}>
            <ModalBody>
              <FormGroup>
                <Label>First Name:</Label>
                <Input type="text" name="first_name" value={formData.first_name} onChange={handleChange} required />
              </FormGroup>
              <FormGroup>
                <Label>Last Name:</Label>
                <Input type="text" name="last_name" value={formData.last_name} onChange={handleChange} required />
              </FormGroup>
              <FormGroup>
                <Label>Email:</Label>
                <Input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  invalid={!isEmailValid}
                />
                {!isEmailValid && <div className="text-danger">Please enter a valid email address.</div>}
              </FormGroup>
              <FormGroup>
                <Label>Country</Label>
                <Input type="select" name="country_id" value={formData.country_id} onChange={handleChange} required>
                  <option value="">Select Country</option>
                  {countries.map((country) => (
                    <option key={country.id} value={country.id}>{country.long_name}</option>
                  ))}
                </Input>
              </FormGroup>
              <FormGroup>
                <Label>Organization</Label>
                <Input
                  type="select"
                  name="organisation_id"
                  value={formData.organisation_id}
                  onChange={handleChange}
                  disabled={createNewOrg}
                  required={!createNewOrg}
                >
                  <option value="">Select Organization</option>
                  {organizations.map((org) => (
                    <option key={org.id} value={org.id}>{org.long_name}</option>
                  ))}
                </Input>
                <div className="mt-2">
                  <Label check>
                    Can't find an organisation? Create new:

                    <Input
                      type="checkbox"
                      checked={createNewOrg}
                      onChange={handleOrgToggle}
                      disabled={!!formData.organisation_id}
                      style={{ marginLeft: "10px" }} // Adjust the margin as needed
                    />{" "}
                  </Label>
                </div>
              </FormGroup>
              {createNewOrg && (
                <>
                  <FormGroup>
                    <Label>Organization Short Name:</Label>
                    <Input
                      type="text"
                      name="organisation_short_name"
                      value={formData.organisation_short_name}
                      onChange={handleChange}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Organization Long Name:</Label>
                    <Input
                      type="text"
                      name="organisation_long_name"
                      value={formData.organisation_long_name}
                      onChange={handleChange}
                      required
                    />
                  </FormGroup>
                </>
              )}
            </ModalBody>
            <ModalFooter>
              <Button type="submit" color="primary">Create User</Button>
              <Button color="secondary" onClick={toggleModal}>Cancel</Button>
            </ModalFooter>
          </Form>
        </Modal> */}


        <Modal
          isOpen={showModal}
          toggle={toggleModal}
          className="custom-modal"
          onOpened={fetchCountries}
        >
          <ModalHeader
            className="modal-header"
            toggle={toggleModal}
          >
            <b>
              <p className="modal-title">
                <i className="mdi mdi-plus me-2"></i>
                Create New User
              </p>
            </b>
          </ModalHeader>

          <div className="modal-subtext">
            <small>All fields marked with asterisks (<span className="required">*</span>) are compulsory.</small>
          </div>

          <Form onSubmit={handleSubmit}>
            <ModalBody className="modal-body">
              <label>First Name<span className="required">*</span></label>
              <Input
                className="input-field"
                type="text"
                name="first_name"
                value={formData.first_name}
                onChange={handleChange}
                placeholder="Enter first name"
                required
              />
              <label>Last Name<span className="required">*</span></label>
              <Input
                className="input-field"
                type="text"
                name="last_name"
                value={formData.last_name}
                onChange={handleChange}
                placeholder="Enter last name"
                required
              />
              <label>Email<span className="required">*</span></label>
              <Input
                className="input-field"
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Enter email"
                required
                invalid={!isEmailValid}
              />
              {!isEmailValid && (
                <div className="text-danger">Please enter a valid email address.</div>
              )}
              <label>Country<span className="required">*</span></label>
              <Input
                className="input-field"
                type="select"
                name="country_id"
                value={formData.country_id}
                onChange={handleChange}
                required
              >
                <option value="">Select Country</option>
                {countries.map((country) => (
                  <option key={country.id} value={country.id}>
                    {country.long_name}
                  </option>
                ))}
              </Input>
              <label>Organization<span className="required">*</span></label>
              <Input
                className="input-field"
                type="select"
                name="organisation_id"
                value={formData.organisation_id}
                onChange={handleChange}
                disabled={createNewOrg}
                required={!createNewOrg}
              >
                <option value="">Select Organization</option>
                {organizations.map((org) => (
                  <option key={org.id} value={org.id}>
                    {org.long_name}
                  </option>
                ))}
              </Input>
              <div className="mt-2" style={{ display: "flex", alignItems: "center" }}>
                <Label check style={{ alignItems: "center" }}>
                  Can't find an organisation? Create new:
                  <span>
                  <Input
                    type="checkbox"
                    checked={createNewOrg}
                    onChange={handleOrgToggle}
                    disabled={!!formData.organisation_id}
                    style={{ 
                      marginLeft: "10px" ,
                      width: "10px",
                      height: "10px",
                      top: "-30px"
                    }}
                  />
                  </span>
                </Label>
              </div>
              {createNewOrg && (
                <>
  
                  <label>Organization Short Name<span className="required">*</span></label>
                  <Input
                    className="input-field"
                    type="text"
                    name="organisation_short_name"
                    value={formData.organisation_short_name}
                    onChange={handleChange}
                    placeholder="Enter short name"
                    required
                  />
  
                  <label>Organization Long Name<span className="required">*</span></label>
                  <Input
                    className="input-field"
                    type="text"
                    name="organisation_long_name"
                    value={formData.organisation_long_name}
                    onChange={handleChange}
                    placeholder="Enter long name"
                    required
                  />
                </>
              )}
            </ModalBody>
            <ModalFooter className="modal-footer">
            <Button
                style={{
                  backgroundColor: '#036F36',
                  color: '#FFF605',
                  borderRadius: '2.25rem',
                  flex: '1',
                }}
                type="submit">
                Confirm
              </Button>
              <Button
                style={{
                  backgroundColor: '#036F36',
                  color: '#FFF605',
                  borderRadius: '2.25rem',
                  flex: '1',
                  marginRight: '10px',
                }}
                onClick={toggleModal}>
                Cancel
              </Button>
            </ModalFooter>
          </Form>
        </Modal>


        <ExportTable loadFilteredData={exportTable}></ExportTable>
        <Modal
          isOpen={modalFilter}
          toggle={toggleModalFilter}
          className="modal-lg"
        >
          <ModalHeader toggle={toggleModalFilter}>
            <i className="mdi mdi-filter-variant me-1"></i>
            Filters
          </ModalHeader>
          <ModalBody>
            <Filters appliedFilters={appliedFilters} applyFilters={applyFilters} />
          </ModalBody>
        </Modal>
      </div>
      {isLoading ? <LoadingSpinner /> :
        usersData?.length > 0 ? (
          <>
            <FormsTable
              forms={usersData || []}
            />
            <Pagination
              currentPage={currentPage}
              goToPage={goToPage}
              totalEntries={totalEntries}
            />
          </>
        ) : null}
    </div>
  )
}

export default UserManagement
