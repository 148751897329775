import React, { useEffect } from "react"
import AfricaChoroplethMap from '../E-Tracker/Charts/Map/AfricaMap';
import PolicyStageChart from '../E-Tracker/Charts/PolicyStageChart';
import DonutChart from '../E-Tracker/Charts/DonutChart/DonutChart';
import SystemsChart from '../E-Tracker/Charts/SystemsChart';
import { getData,getEthiopiaPolicyRegulatoryFramework } from "services/etracker"
import CustomTabs from "../../components/Common/Custom-Tabs"
import { getHomePageDashboardData } from "../../services/dashboardService"
import ExpandableSystemStats from "./includes/ExpandableSystemStats";
import { useSelector } from "react-redux"
import { Icon } from '@iconify/react';
import StagesExplainer from "components/Common/Explainers/StagesExplainer";
import CountriesDropdown from "components/Common/countries-dropdown";

const DashNew = () => {

    const [countryID, setCountryID] = React.useState(null);
    const [data, setData] = React.useState();
    const [summaries, setSummaries] = React.useState([]);
    const [systems, setSystems] = React.useState([]);
    const { user } = useSelector((state) => state.Profile)
    const [mapData, setMapData] = React.useState(null);
    const overViewTab = 'number_of_policies'

    const labelMap = {
        number_of_policies : {
            id: 1,
            dataField: 'number_of_policies',
            textField: 'Policies'
        },
        policy_score: {
            id: 2,
            dataField: 'average_score',
            textField: 'Avg. Score'
        },
        legal_framework: {
            id: 2,
            dataField: 'number_of_policies',
            textField: 'Legal framework'
        }
    }

    const [legalFrameworkData, setLegalFrameworkData] = React.useState(null);

    const label = labelMap[overViewTab];

    const fetchDashboardData = async () => {
        try {
            const dashboardData = await getHomePageDashboardData({countryID});

            if (dashboardData) {
                const fetchedSummaries = dashboardData.summaries?.data || [];
                const fetchedSystems = dashboardData.systems?.data.map(system => ({
                    system: system.system_name,
                    policy_summary: {
                        total: system.policies_summary.total,
                        approved: system.policies_summary.approved,
                        implementation: system.policies_summary.implementation,
                    },
                })) || [];

                setSummaries(fetchedSummaries);
                setSystems(fetchedSystems);
            }
        } catch (e) {
            console.error("Error fetching dashboard data", e);
        }
    };

    const fetchEthiopiaLegalFramework = async () => {
        const response = await getEthiopiaPolicyRegulatoryFramework();
        const _data = response.data;
        // add total property sum of no_of_policies[arr] in data
        _data['name'] = _data.ethiopia_policy_type;
        _data['Legal framework'] = _data.ethiopia_policy_type
        setLegalFrameworkData(response.data);
    }

    const fetchData = async () => {
        let query = {
            metric: 'number_of_policies',
            ...(countryID && {country_id: countryID})
        };
        setData(await getData(0,query));
        // if countryID is not null and == 2 then fetchEthiopiaLegalFramework
        if(countryID && countryID === 2) {
            fetchEthiopiaLegalFramework();
        }
    }

    useEffect(() => {
        if(data && data.location_overview?.data) {
            setMapData(data.location_overview.data)
        }
    },[data])

    useEffect(() => {
        fetchData()
        fetchDashboardData();
    }, [countryID])

  useEffect(() => {
    if (user?.user_country?.length) {
        setCountryID(user.user_country.length > 1 ? null : user.user_country[0]?.country_id) //can be removed if taking accessible countries in country dropdown
      }
    }, [user])

    const triggers = [
        { name: "Stages", value: "tab1" },
        { name: "Systems", value: "tab2" },
      ]
    const content = [
        { value: "tab1", component: <DonutChart chartData={data?.stage_overview?.data} title={`Number of policies in different stages`} label={label}/> },
        { value: "tab2", component: <SystemsChart chartData={data?.system_overview?.data} title={`Number of policies in different systems`} label={label} /> },
    ]

    const getHugeIconClass = (index) => {
        if (index === 0) {
          return "task-02";
        } else if (index === 1) {
          return "tick-double-03";
        } else if (index === 2) {
          return "legal-hammer";
        }
        return "task-02";
      };

    return (
        <div className={`${window.location.pathname === '/dashboard' ? 'page-content' : 'mt-3'} no-border-chart`}>
            <div className="d-flex">
                <div className="w-1/2 border-r border-[#EAEAEA] px-2">
                    <h1 className="text-3xl font-bold mb-1">
                        APRITS DASHBOARD
                    </h1>
                    <div className="flex items-center country-selection">
                        <span className="mr-2 text-nowrap">Showing data for </span>
                        <CountriesDropdown countryID={countryID} setCountryID={setCountryID}/>
                    </div>
                    <div className="flex items-center justify-center">
                        <AfricaChoroplethMap chartData={mapData} countryID={countryID} setCountryID={setCountryID} label={label}/>
                    </div>
                    <div className="my-4 flex justify-content-center">
                        <StagesExplainer stage="all" previewcontent={(
                                <div className="flex items-center border-2 gap-2 rounded-lg border-dashed border-[#036F36] p-2 py-3 w-12/12">
                                    <div className="flex-shrink-0">
                                        <Icon icon="hugeicons:hand-pointing-right-04" className="text-[#036F36] h-5 w-5 md:h-8 md:w-8" />
                                    </div>
                                    <p className="">
                                        Take a minute to understand how APRITS clarifies policy stages. Click here
                                    </p>
                                </div>
                            )}>
                        </StagesExplainer>
                    </div>
                    <div className='flex my-4 justify-center chart-tabs'>
                        <CustomTabs
                            ariaLabel="Milestone Management"
                            defaultValue="tab1"
                            triggers={triggers}
                            content={content}
                        />
                    </div>
                    
                </div>
                <div className="flex-1 border-l border-[#EAEAEA] px-2">
                    <div className="flex gap-2" >
                        {summaries.map((summary, i) => (
                            <div className="relative w-1/3 border border-[#EAEAEA] shadow-sm rounded-lg" key={i}>
                                <div className="absolute top-0 left-0 p-1">
                                    <Icon icon={`hugeicons:${getHugeIconClass(i)}`} color="#036F36" className="text-[#036F36] h-5 w-5 md:h-10 md:w-10 border border-[#036F36] rounded-full p-1" />
                                </div>
                                <div className="justify-center h-32 items-center top-0 bottom-0 p-4 flex" >
                                    <h1>
                                        <span className="text-xl md:text-2xl mx-2 font-bold mb-1">
                                            {summary.value}
                                        </span>
                                    </h1>
                                    <p>
                                        {summary.description}
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="my-4">
                        <h1 className="section-title">
                            Summary of policies supported across systems
                        </h1>
                       <div className="overflow-auto" style={countryID === 2 ? {height:'53vh'} : {height:'90vh'}}>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                {systems.map((system, i) => (
                                    <div key={i}>
                                        <ExpandableSystemStats title={system.system} id={`accordion_${i}`}>
                                            <>
                                                <div className="w-full bg-[#036F36] my-1 h-1"></div>
                                                <div>
                                                    <div className="my-2 flex align-items-center gap-2">
                                                        <Icon icon="hugeicons:task-02" className="text-[#036F36] h-5 w-5" />
                                                        <p className="text-[#036F36]">
                                                            {system.policy_summary.total} Policies
                                                        </p>
                                                    </div>
                                                    <div className="flex items-center gap-2">
                                                        <Icon icon="hugeicons:tick-double-03" className="text-[#036F36] h-5 w-5" />
                                                        <p>
                                                            {system.policy_summary.approved} Policies Approved
                                                        </p>
                                                    </div>
                                                    <div className="my-2 flex gap-2">
                                                        <Icon icon="hugeicons:legal-hammer" className="text-[#036F36] h-5 w-5" />
                                                        <p>
                                                            {system.policy_summary.implementation} in Implementation
                                                        </p>
                                                    </div>
                                                </div>
                                            </>
                                        </ExpandableSystemStats>
                                    </div>
                                ))}
                            </div>
                       </div>
                    </div>
                    <div className='flex my-4 justify-center chart-tabs'>
                        {countryID && countryID == 2 && legalFrameworkData && (
                            <div className={`my-3 border-t-2 py-3 border-[#EAEAEA]`}>
                                <h1>
                                    Policy classification according to the legal framework
                                </h1>
                                <DonutChart chartData={legalFrameworkData} title={`Policies per legal framework`} label={labelMap['legal_framework']}/>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="border-t-2 border-gray-200">
                <PolicyStageChart title="Number of policies within systems distribution and stages" chartData={data?.system_stage_overview?.data} tab = {overViewTab}/>
            </div>
        </div>
    )
}

const style = document.createElement('style');
style.textContent = `
    /* Global class override */
    .no-border-chart .chart-container {
        border:none
    }

    .no-border-chart .chart-tabs button {
        font-size: 16px;
    }

    .no-border-chart .chart-tabs .legend-item {
        font-size: 14px;
    }
    .no-border-chart .chart-container h3 {
        font-size: 16px;
    }
    `;
document.head.appendChild(style);

export default DashNew
