import Select from "react-select"
import React, { useEffect, useState } from "react"
import { getCountryOptions } from "common/SelectOptions/countryOptions"

const OrganisationFilters = ({
  filteredLocations,
  applyFilters
}) => {
  const [locationOptions, setLocationOptions] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState(filteredLocations);

  useEffect(() => {
    const fetchOptions = async () => {
      setLocationOptions(await getCountryOptions());
    };
    fetchOptions();
  }, []);

  return (
    <div>
      {/* Filter by Location */}
      <div className="d-flex my-2">
        <div className="d-flex align-items-center">
          <i className="mdi mdi-map-marker-outline me-1" />
          <h6 className="mb-0">Locations:</h6>
        </div>
        <div className="ms-2" style={{ width: "100%" }}>
          <Select
            isMulti
            options={locationOptions}
            defaultValue={selectedLocations}
            className="basic-single"
            classNamePrefix="select"
            placeholder="Select locations"
            onChange={options => setSelectedLocations(options)}
          />
        </div>
      </div>

      {/* Apply Filters Button */}
      <div className="d-flex" style={{ justifyContent: "center" }}>
        <button
          className="btn btn-success common-button"
          style={{ width: "90%" }}
          onClick={()=>
            applyFilters(
              selectedLocations 
            )}
        >
          Apply
        </button>
      </div>
    </div>
  )
}

export default OrganisationFilters
