import React from 'react';
import SystemsChart from './Charts/SystemsChart';
import AfricaChoroplethMap from './Charts/Map/AfricaMap';
import CountDisplay from './CountDisplay';
import DurationDisplay from './DurationDisplay';
import './Charts/gauge.css'

function Stages({stageTab, setStageTab, data, countryID, setCountryID}) {

    const labelMap = {
        policy_distribution : {
            id: 1,
            dataField: 'number_of_policies',
            textField: 'Policies'
        }, 
        time_analysis: {
            id: 2,
            dataField: 'average_month_duration',
            textField: 'Time duration in months'
        }
    }

    const label = labelMap[stageTab]; //TODO: add useEffect to update only when subTab changes

    return (
        <>
            <div className='d-flex justify-center'>
                <div className={`sub-tab mx-1 ${stageTab === 'policy_distribution' ? 'sub-tab-active' : ''}`} onClick={()=>setStageTab('policy_distribution')}>Policy Distribution</div>
                <div className={`sub-tab mx-1 ${stageTab === 'time_analysis' ? 'sub-tab-active' : ''}`} onClick={()=> setStageTab('time_analysis')}>Time Analysis</div>
            </div>
            {data &&
                <div id="print-content">
                    {stageTab === 'policy_distribution' ?
                        <CountDisplay title = {'Total number of policies'} count={data?.total_number_of_policies}/>
                        : 
                        <DurationDisplay data={data?.average_duration}/>
                    }
                    <div className='flex space-x-3 justify-center'>
                        <AfricaChoroplethMap chartData={data?.location?.data} countryID={countryID} setCountryID={setCountryID} label={label}/>
                        <SystemsChart chartData={data?.system?.data} label={label}/>
                    </div>
                </div>
            }
        </>
    );
}

export default Stages;