import React from "react"
import { CloseButton, Modal, ModalBody } from "reactstrap"
import Lottie from "react-lottie"
import animationData from "../../../assets/lotties/success-alert"

function SuccessAlert({ modal, toggle, content }) {
  const defaultOptions = {
    loop: 1,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  }

  return (
    <Modal isOpen={modal} toggle={toggle}>
      <ModalBody style={{ backgroundColor: "#EEFDF3FF" }}>
        <div className="d-flex">
          <div className="me-4">
            <Lottie
              options={defaultOptions}
              height={40}
              width={40}
              eventListeners={[
                {
                  eventName: "loopComplete",
                  callback: () => toggle(),
                },
              ]}
            />
          </div>
          <div className="w-100">
            <div className="d-flex justify-content-between">
              <h4 style={{ color: "#1DD75BFF" }}>Success</h4>
              <CloseButton onClick={toggle} />
            </div>
            {content} successful!
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default SuccessAlert
