import React, { useState } from "react"
import { Input } from "reactstrap"
import PasswordStrengthBar from "react-password-strength-bar"
import { changeMePassword } from "../../../services/userProfileService"
import "./sidebar.css"
import { FiArrowRight } from "react-icons/fi"
import { FaEye, FaEyeSlash } from "react-icons/fa"

const ChangePasswordSidebar = ({ alert, isOpen, toggleSidebar }) => {
  const [currentPassword, setCurrentPassword] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [showCurrentPassword, setShowCurrentPassword] = useState(false)
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")

  const toggleCurrentPasswordVisibility = () => {
    setShowCurrentPassword(!showCurrentPassword)
  }

  // Toggle visibility for new password field
  const toggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword)
  }

  // Toggle visibility for confirm password field
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword)
  }

  // Function to handle password update
  const handleUpdatePassword = async () => {
    if (!currentPassword) {
      setError("Please enter your current password.")
      return
    }

    if (newPassword !== confirmPassword) {
      setError("Passwords do not match")
      return
    }

    setError("")  // Clear any previous errors
    setLoading(true)  // Show loading indicator while making request

    try {
      // Prepare request body
      const requestBody = {
        current_password: currentPassword,
        new_password: newPassword
      }

      // Call changeUserPassword function
      const response = await changeMePassword(requestBody)

      if (response && response.message) {
        alert(response.message, true)
      } else {
        alert("Password not updated successfully", false)
      }
    } catch (err) {
      // Log error details for debugging, if necessary
      console.error("Error updating password:", error)
      // Show error alert to the user
      alert("An error occurred while updating the password. Please try again.", false)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className={`sidebar ${isOpen ? "open" : ""}`}>
      <div className="sidebar-title-container">
        <div className="sidebar-title">
          <h3>Change Password</h3>
          <button className="close-icon" onClick={toggleSidebar}>
            <i className="fas fa-times-circle"></i>
          </button>
        </div>
      </div>
      <div className="sidebar-body">
        {/* Current Password Input */}
        <div className="form-group">
          <label htmlFor="currentPassword" className="label">Current Password <span
            className="required">*</span></label>
          <div className="password-wrapper">
            <Input
              type={showCurrentPassword ? "text" : "password"}
              id="currentPassword"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              placeholder="Enter current password"
              className="input-field"
            />
            <button className="toggle-visibility" onClick={toggleCurrentPasswordVisibility}>
              {showCurrentPassword ? <FaEyeSlash /> : <FaEye />}
            </button>
          </div>
        </div>

        {/* New Password Input */}
        <div className="form-group">
          <label htmlFor="newPassword" className="label">New Password <span className="required">*</span></label>
          <div className="password-wrapper">
            <Input
              type={showNewPassword ? "text" : "password"}
              id="newPassword"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              placeholder="Enter new password"
              className="input-field"
            />
            <button className="toggle-visibility" onClick={toggleNewPasswordVisibility}>
              {showNewPassword ? <FaEyeSlash /> : <FaEye />}
            </button>
          </div>
          <div className="password-strength-bar-wrapper">
            {/* Ensure password value is passed correctly */}
            <PasswordStrengthBar password={newPassword} />
          </div>
          <small className="description">This is the description area</small>
        </div>

        {/* Confirm Password Input */}
        <div className="form-group">
          <label htmlFor="confirmPassword" className="label">Confirm Password <span
            className="required">*</span></label>
          <div className="password-wrapper">
            <Input
              type={showConfirmPassword ? "text" : "password"}
              id="confirmPassword"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholder="Confirm password"
              className="input-field"
            />
            <button className="toggle-visibility" onClick={toggleConfirmPasswordVisibility}>
              {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
            </button>
          </div>
          <small className="description">This is the description area</small>
        </div>

        {error && <div className="error-message">{error}</div>}
        {success && <div className="success-message">{success}</div>}

        <div className="d-grid">
          <button
            className="btn btn-primary btn-block rounded-[2.25rem] text-[#FFF605] flex flex-row items-center justify-center"
            onClick={handleUpdatePassword}
            disabled={loading}
          >
            Update Password
            <div className="rounded-full flex justify-center items-center w-4 h-4 bg-[#EAE200] ml-1">
              <FiArrowRight color="#036F36" />
            </div>
          </button>
        </div>
      </div>
    </div>
  )
}

export default ChangePasswordSidebar
