import { get, post } from "./AXIOS"
import {
  DASHBOARD_COUNTS,
  DASHBOARD_LABELS_SDG,
  DASHBOARD_STAGES_POLICIES,
  RECENT_COMMENTS,
  DASHBOARD_LANDSCAPING_STAGES_POLICIES,
  DASHBOARD_TRACKER_STAGES_POLICIES,
  DASHBOARD_NOTIFICATIONS_COUNT,
  DASHBOARD_TASKS,
  USER_LOGS,
  STATISTICS_ETRACKER,
  INTELLIGENCE_SENTIMENT_SCORES,
  INTELLIGENCE_TWEETS,
  INTELLIGENCE_INDICATOR_TRENDS,
  INTELLIGENCE_POLICIES_AFFECTED,
  INDICATOR_TRENDS,
  INDICATOR_TRENDS_MAP,
  TRENDS_MAP_HOVER,
  PROFILE_STATS,
  STATISTICS_TRACKER,
  DASHBOARD_BENCHMARKING_TASKS,
  DASHBOARD_ADMIN_TASKS,
} from "./CONSTANTS"

// Dashboard Count
export const dashboardCounts = params => post(DASHBOARD_COUNTS, params)

// export const dashboardLabelsSDG = params => post(DASHBOARD_LABELS_SDG, params)

// export const getDashboardStagesPolicies = params =>
//   post(DASHBOARD_STAGES_POLICIES, params)

// export const getRecentComments = params => post(RECENT_COMMENTS, params)

// export const getDashboardLandscapingStagesPolicies = params =>
//   post(DASHBOARD_LANDSCAPING_STAGES_POLICIES, params)

// export const getDashboardTrackerStagesPolicies = params =>
//   post(DASHBOARD_TRACKER_STAGES_POLICIES, params)

export const getDashboardNotificationsCount = params =>
  post(DASHBOARD_NOTIFICATIONS_COUNT, params)

export const getDashboardTasks = params => post(DASHBOARD_TASKS, params)
export const getDashboardBenchmarkingTasks = params =>
  post(DASHBOARD_BENCHMARKING_TASKS, params)

export const getDashboardAdminTasks = body => post(DASHBOARD_ADMIN_TASKS, body)
export const getProfileStats = id => get(`${PROFILE_STATS}/${id}`)

export const getUserLogs = id => get(`${USER_LOGS}/${id}`)

export const getStatisticsETracker = params => post(STATISTICS_ETRACKER, params)

export const getStatisticsTracker = params => post(STATISTICS_TRACKER, params)

export const getIntelligenceSentimentScores = params =>
  post(INTELLIGENCE_SENTIMENT_SCORES, params)

export const getIntelligenceIndicatorTrends = params =>
  post(INTELLIGENCE_INDICATOR_TRENDS, params)

export const getIntelligenceTweets = params => post(INTELLIGENCE_TWEETS, params)

export const getIntelligencePoliciesAffected = params =>
  post(INTELLIGENCE_POLICIES_AFFECTED, params)

export const indicatorTrendsMap = params =>
  post(INDICATOR_TRENDS_MAP, { ...params, format: "location-indicator-count" })

export const indicatorTrends = params =>
  post(INDICATOR_TRENDS, { ...params, format: "indicator-trends" })

export const trendsMapHover = params =>
  post(TRENDS_MAP_HOVER, { ...params, format: "donut-chart" })
