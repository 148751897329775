import { get, post, patch } from "./AXIOS"

import { CREATE_NOTIFICATION, GET_NOTIFICATIONS, UPDATE_NOTIFICATION } from "./CONSTANTS"

export const createNotification = ( body, supervisorCanUpdate, entityCountryId) =>
  post(`${CREATE_NOTIFICATION}?supervisor_can_update=${supervisorCanUpdate}&entity_country_id=${entityCountryId}`,
    body, {"headers": {"Content-Type": "application/json"}})

export const getNotifications = () => get(`${GET_NOTIFICATIONS}`)

export const updateNotification = (notification_id, body) => patch(UPDATE_NOTIFICATION(notification_id), body, { "headers": { "Content-Type": "application/json" } })
