import React, { useEffect, useState } from 'react';
import { Modal} from 'reactstrap';
import { Icon } from '@iconify/react';
import { useSessionStore } from 'services/sessionStore';
import { useDispatch } from 'react-redux';
import { logoutUser } from 'store/actions';

const SessionExpiredModal = () => {
  const { isSessionExpired } = useSessionStore();
  const [modal, setModal] = useState(isSessionExpired);
  const toggle = () => setModal(!modal);
  const dispatch = useDispatch()

  useEffect(()=>{
    setModal(isSessionExpired)
  },[isSessionExpired])

  const handleLogin = () => {
    toggle();
    dispatch(logoutUser());
  };

  if (!isSessionExpired) return null;

  return (
    <Modal 
      isOpen={modal} 
      toggle={toggle}
      centered={true} 
      backdrop="static"
    >
      <div className="m-3">
        <div className='flex items-center mb-3'>
          <Icon icon="mdi-light:alert" className="h-7 w-7 text-orange-400 mr-2" />
          <div>
            <h1 className="text-lg font-semibold mb-2 flex items-center">
              Your session has expired
            </h1>
            <p>
              Please log in again to continue using the app.
            </p>
          </div>
        </div>
        <div className="flex justify-end">
          <button
            className='common-button m-0'
            onClick={handleLogin}
            >
            Log in
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default SessionExpiredModal;