import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import styled from "@emotion/styled";
import { Button, Input } from "reactstrap";
import { BiArrowBack, BiCheckCircle } from "react-icons/bi";
import Sidebar from "components/Common/UploadEvidence/UploadEvidenceSidebar";
import ScoringGuide from "./ScoringGuide";
import {
  editCountryLandscapingFocalAreaQuestion, getCountryLandscapingFocalArea,
  getLandscapingQuestionnaire
} from "services/milestoneServices" // Import the Landscaping questionnaire API
import { useSelector } from "react-redux";
import { createNotification } from "../../services/notificationServices"

// Styled Components
const PageContent = styled.div`
  background-color: #f8f9fa;
  min-height: 100vh;
  padding: 2em;
  padding-top: 5em;

  & h2 {
    margin-top: 25px;
    margin-bottom: 20px;
    color: #049046;
    font-weight: 400;
    font-size: 20px;
  }
`;

const ProgressBarContainer = styled.div`
  width: 80%;
  height: 15px;
  background-color: #e0e0e0;
  border-radius: 10px;
  margin: 20px auto;
  overflow: hidden;
`;

const ProgressBarFill = styled.div`
  height: 100%;
  background-color: ${({ progress }) =>
    progress <= 50 ? "#ff4e4e" : progress <= 75 ? "#ff9900" : "#198754"};
  width: ${({ progress }) => `${progress}%`};
  transition: width 0.4s ease, background-color 0.4s ease;
`;

const QuestionBlock = styled.div`
  background-color: white;
  padding: 1.5em;
  margin-bottom: 20px;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  position: relative;

  & p {
    font-size: 1.1rem;
    font-weight: 400;
    margin-top: 20px;
    margin-bottom: 20px;
  }
`;

const RequiredTag = styled.span`
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: #f0f0f0;
  color: #888;
  font-size: 0.8rem;
  padding: 2px 8px;
  border-radius: 4px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const AnswerOptions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const AnswerButton = styled.button`
  background-color: ${(props) => (props.selected ? "#d4f8d4" : "#f0f0f0")};
  color: ${(props) => (props.selected ? "#198754" : "#333")};
  border: 1px solid ${(props) => (props.selected ? "#198754" : "#e0e0e0")};
  padding: 10px 20px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 5px;
  width: 500px;

  &:hover {
    background-color: ${(props) => (props.disabled ? "#f0f0f0" : "#d4f8d4")};
  }

  & svg {
    visibility: ${(props) => (props.selected ? "visible" : "hidden")};
  }
`;

const StyledButton = styled(Button)`
  width: 200px;
  margin: 30px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

function FillFormLandscaping(props) {
  const { countryId, focalAreaId } = useParams();
  const { user } = useSelector((state) => state.Profile);
  const currentUserId = user?.id;

  const [focalAreaDetails, setFocalAreaDetails] = useState({
    name: "",
    landscaping_category: "",
  ownerId: null,
  supervisorId: null,
  isComplete: false,
  approvedBySupervisor: false,
  });

  const [formData, setFormData] = useState(null);
  const [responses, setResponses] = useState([]);
  const [progress, setProgress] = useState(0);
  const history = useHistory();
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [evidenceData, setEvidenceData] = useState({});
  const [selectedQuestionId, setSelectedQuestionId] = useState(null);
  const [isLoading, setIsLoading] = useState(true)

  // State variables for permissions
  const [ownerId, setOwnerId] = useState(null);
  const [supervisorId, setSupervisorId] = useState(null);
  const [updateForbidden, setUpdateForbidden] = useState(false);
  const [ownerCanUpdate, setOwnerCanUpdate] = useState(false);
  const [supervisorCanUpdate, setSupervisorCanUpdate] = useState(false);
  const [isOwnerAndSupervisorSame, setIsOwnerAndSupervisorSame] = useState(false);

  const toggleSidebar = (questionId) => {
    setSelectedQuestionId(questionId);
    setSidebarOpen((prev) => !prev);
  };

  useEffect(() => {
    const loadFormDetails = async () => {
      setIsLoading(true);
      try {
         const [data, focalAreaData] = await Promise.all([
          getLandscapingQuestionnaire(countryId, focalAreaId),
          getCountryLandscapingFocalArea(countryId, focalAreaId),
        ]);

        setFocalAreaDetails({
          name: focalAreaData?.landscaping_focal_area?.name,
          landscaping_category: focalAreaData?.landscaping_focal_area?.landscaping_category,
        ownerId: focalAreaData?.owner_and_supervisor?.owner_id,
        supervisorId: focalAreaData?.owner_and_supervisor?.supervisor_id,
        isComplete: focalAreaData?.is_complete,
        approvedBySupervisor: focalAreaData?.approved_by_supervisor,
      });

        setFormData(data);
        setResponses(
          data?.landscaping_focal_area_questions?.map((question) => ({
            question_id: question?.question_id,
            question_response: question?.question_response || "",
            question_response_score: question?.question_response_score || 0,
            question_note: question?.question_note || "",
            question: question?.landscaping_focal_area_question?.question || "",
          })) || []
        );
      } catch (err) {
        console.error("Error loading form details:", err);
      } finally {
        setIsLoading(false);
      }
    };
    loadFormDetails();
  }, [countryId, focalAreaId]);

  // Separate useEffect for permission calculations
  useEffect(() => {
    if (!focalAreaDetails.ownerId) return; // Avoid running when focalAreaDetails is empty

    const { ownerId, supervisorId, isComplete, approvedBySupervisor } = focalAreaDetails;

    setOwnerId(ownerId);
    setSupervisorId(supervisorId);

    const isOwnerAndSupervisorSame = ownerId === supervisorId;
    const isCurrentUserOwner = currentUserId === ownerId;
    const isCurrentUserSupervisor = currentUserId === supervisorId;


    const canOwnerUpdate = isCurrentUserOwner && !approvedBySupervisor;
    const canSupervisorUpdate = isCurrentUserSupervisor && isComplete;
    const forbiddenUpdate = !canOwnerUpdate && !canSupervisorUpdate && !(isOwnerAndSupervisorSame && isCurrentUserOwner);

    setIsOwnerAndSupervisorSame(isOwnerAndSupervisorSame);
    setOwnerCanUpdate(canOwnerUpdate);
    setSupervisorCanUpdate(canSupervisorUpdate);
    setUpdateForbidden(forbiddenUpdate);

  }, [focalAreaDetails, currentUserId]);


  useEffect(() => {
    if (responses.length > 0) {
      const totalQuestions = responses.length;
      const answeredQuestions = responses.filter(
        (res) => res.question_response !== ""
      ).length;
      const progressPercentage = Math.floor(
        (answeredQuestions / totalQuestions) * 100
      );
      setProgress(progressPercentage);
    }
  }, [responses]);

  const handleResponseChange = (question_id, answer) => {
    const score = parseInt(answer.split("]")[0].replace("[", ""), 10) || 0;

    setResponses((prevResponses) =>
      prevResponses.map((res) =>
        res.question_id === question_id
          ? {
            ...res,
            question_response: answer.split("]")[1].trim(),
            question_response_score: score,
          }
          : res
      )
    );
  };

  const handleSave = () => {
    if (updateForbidden) {
      props.alert("You do not have permission to save this focal area.", false);
      return;
    }
    saveResponses();
  };

  const { mutate: saveResponses } = useMutation(
    async () => {
      for (const response of responses) {
        if (response.question_response) {
          let body = {
            focal_area_update_request_body: {
              question_update_request_body: {
                question_response: response.question_response,
                question_response_score: response.question_response_score,
              },
              question_evidence_update_request_bodies: [],
            },
          };

          if (response.question_note) {
            body.focal_area_update_request_body.question_update_request_body.question_note =
              response.question_note;
          }

          const formData = new FormData();

          if (evidenceData[response.question_id]) {
            evidenceData[response.question_id].forEach((entry) => {
              const evidenceEntry = { policy_name: entry.policyName };

              body.focal_area_update_request_body.question_evidence_update_request_bodies.push(
                evidenceEntry
              );

              if (entry.file instanceof File) {
                formData.append("policy_evidence_files", entry.file);
              }
            });
          }

          formData.append(
            "focal_area_update_request_body",
            JSON.stringify(body.focal_area_update_request_body)
          );

          await editCountryLandscapingFocalAreaQuestion(
            countryId,
            focalAreaId,
            response.question_id,
            formData
          );
        }
      }
    },
    {
      onSuccess: async () => {
        props.alert("All responses saved!", true);

        if (supervisorCanUpdate) {
          // Send notification of user activity on supervisor approval
          try {
            await createNotification({
                message: `updated ${focalAreaDetails?.landscaping_category}'s ${focalAreaDetails?.name} focal area`,
                resource_url: window.location.href, // Current page URL
                entity_type: "landscaping",
              }, supervisorCanUpdate, countryId
            )
          } catch (error) {
            console.error("Failed to send notification:", error);
          }
        }

        history.push(`/landscaping-forms/${countryId}/${focalAreaId}`);
      },
      onError: (error) => {
        props.alert("Failed to save responses!", false);
        console.error("Error saving responses:", error);
      },
    }
  );

  const onAddFile = (fileData) => {
    setEvidenceData((prevData) => {
      const updatedData = { ...prevData };
      const currentEvidence = updatedData[selectedQuestionId] || [];
      updatedData[selectedQuestionId] = [...currentEvidence, fileData];
      return updatedData;
    });
  };

  const actionText = isOwnerAndSupervisorSame && (currentUserId === ownerId && currentUserId === supervisorId)
  ? "Submit Responses"
  : ownerCanUpdate
  ? "Submit Responses for Supervisor Approval"
  : supervisorCanUpdate
  ? "Approve Submitted Responses"
  : null;

  return (
    <PageContent>
      {(!isLoading && updateForbidden && (
        <div className="alert alert-danger" role="alert" style={{ marginTop: "40px", textAlign: "center"}}>
          You do not have permission to edit this focal area.
        </div>
      ))}

      <ProgressBarContainer>
        <ProgressBarFill progress={progress} />
      </ProgressBarContainer>

      <ScoringGuide />

      <div className="mt-4">
        <h2>{formData?.landscaping_focal_area || "Landscaping Form"}</h2>
      </div>

      <div className="mt-4">
        {responses.map((question, idx) => (
              <QuestionBlock key={question.question_id}>
                <RequiredTag>Required</RequiredTag>
                <p>{`${idx + 1}. ${question.question}`}</p>
                <AnswerOptions>
                  {["[1]Yes", "[0]No"].map((option) => (
                    <AnswerButton
                      key={option}
                      selected={question.question_response === option.split("]")[1].trim()}
                      onClick={() => handleResponseChange(question.question_id, option)}
                      disabled={updateForbidden}
                      style={{
                    cursor: updateForbidden ? "not-allowed" : "pointer",
                    opacity: updateForbidden ? 0.6 : 1,
                  }}
                    >
                      <BiCheckCircle />
                      {option}
                    </AnswerButton>
                  ))}
                </AnswerOptions>
                <Button
                  color="primary"
                  onClick={() => toggleSidebar(question.question_id)}
                  style={{ marginTop: "15px" }}
                  disabled={updateForbidden}
                >
                  Upload Evidence
                </Button>
                <Input
                  type="textarea"
                  placeholder="Add a note..."
                  value={question.question_note || ""}
                  onChange={(e) =>
                    setResponses((prevResponses) =>
                      prevResponses.map((res) =>
                        res.question_id === question.question_id
                          ? { ...res, question_note: e.target.value }
                          : res
                      )
                    )
                  }
                  disabled={updateForbidden}
                  style={{
                    marginTop: "10px",
                    cursor: updateForbidden ? "not-allowed" : "text",
                    opacity: updateForbidden? 0.6 : 1,
                }}
                />
              </QuestionBlock>
            ))}
          </div>

      <div className="button-container">
        <StyledButton
          className="left-button"
          color="primary"
          onClick={() => history.goBack()}
        >
          <BiArrowBack style={{ marginRight: "4px" }} /> Go Back
        </StyledButton>
        {actionText && (
          <StyledButton
            className="right-button"
            color="primary"
            onClick={handleSave}
          >
            {actionText}
          </StyledButton>
        )}
      </div>

      <Sidebar
        isOpen={isSidebarOpen}
        onClose={() => setSidebarOpen(false)}
        evidenceData={evidenceData[selectedQuestionId] || []}
        onAddFile={onAddFile}
        onFileRemove={(index) => {
          setEvidenceData((prevData) => {
            const updatedData = { ...prevData };
            updatedData[selectedQuestionId].splice(index, 1);
            return updatedData;
          });
        }}
        onSave={() => {
          setSidebarOpen(false);
        }}
      />
    </PageContent>
  );
}

export default FillFormLandscaping;