import React, { useState } from 'react';
import {
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Button,
} from 'reactstrap'; // Import Button from reactstrap
import './ExportTable.css';

function ExportTable({ loadFilteredData }) {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle} direction="down" className="export-dropdown">
      {/* Replacing DropdownToggle with the Button component */}
      <Button onClick={toggle} className="common-button d-flex align-items-center">
        <i className="bx bx-printer me-1" />
        Export Table
      </Button>
      <DropdownMenu className="px-2">
        <DropdownItem className="rounded" onClick={() => loadFilteredData('pdf')}>
          <i className="bx bx-file-blank me-1" />
          PDF
        </DropdownItem>
        <DropdownItem className="rounded" onClick={() => loadFilteredData('excel')}>
          <i className="bx bx-spreadsheet me-1" />
          EXCEL
        </DropdownItem>
        <DropdownItem className="rounded" onClick={() => loadFilteredData('stata')}>
          <i className="bx bx-data me-1" />
          STATA
        </DropdownItem>
        <DropdownItem className="rounded" onClick={() => loadFilteredData('spss')}>
          <i className="bx bx-analyse me-1" />
          SPSS
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
}

export default ExportTable;
