import React, { useEffect, useState } from "react";
import SingleQuestion from "./SingleQuestion";
import { data } from "./data";
import "./styles.css";

export default function FAQ() {

  document.title = "Frequently Asked Questions | AGRA"

  const [questions, setQuestions] = useState(data);

  const [path, setPath] = useState('');

  useEffect(()=>{
    setPath(window.location.pathname);
  },[]);

  return (
    <div className={path !== '/home-frequently-asked-questions' ? 'page-content' : 'max-w-6xl mx-auto p-4'}>
      <h1 className="text-3xl font-bold text-center rounded-md bg-gray-100 p-4" style={{ fontSize: '24px' }}>
        Empowering You with Insights: APRITS FAQs
      </h1>
      <div>
        {questions.map((question) => (
          <SingleQuestion
            key={question.id}  // Ensure each item has a unique key
            {...question}
          />
        ))}
      </div>
    </div>
  );
}
