import styled from "styled-components"
import React, { useState, useEffect } from "react";
import PolicyLandscapingTable from "./ProgressCustomChart/PolicyLandscapingTable";
import CustomTabs from "../../components/Common/Custom-Tabs"
import CountriesDropdown from '../../components/Common/countries-dropdown';
import {  listStages } from "../../services/listServices";
import { getStageMileStones } from "../../services/milestoneServices"
import {getPolicyTrackerAnalytics} from "../../services/etracker"
import PolicyTrackingTable from "./ProgressCustomChart/PolicyTrackingTable";
import LandscapingCategoryDropdown from "../E-Tracker/LandscapingCategoryDropdown"

const StyledCountryLandscapingPage = styled.div`
    & > .actions {
        & > .basic-single {
            flex-basis: 100px;
        }
    }
`

const LandscapingVisual = () => {

    const [stages,setStages] = useState([]);
    const [countries, setCountries] = useState([]);
    const [countryID, setCountryID] = useState(null);

    useEffect(() => {
        // load list of stages and their milestones

        const fetchETrackerData = async () => {
          try {
            const response = await getPolicyTrackerAnalytics(countryID);
            const countiries_with_policies = response.data.map((data) => {
              // map long_name to name
              data.name = data.long_name;
              return data;
            });
            // setStages(data);
            setCountries(countiries_with_policies);
          } catch (err) {
            console.error(err);
          }
        }

        const fetchStages = async () => {
            try {
                const data = await listStages();

                // await all promises of getting milestones for all stages
                const results = await Promise.all(data.map(async (stage) => {
                  let milestones_results = await getStageMileStones(stage.id)
                  // console.log(milestones_results);
                  let milestones_names = milestones_results.milestones.data.map(milestone => milestone.name)
                  return {
                    name: stage.name,
                    milestones: milestones_names
                  };
                }));
                setStages(results);
                fetchETrackerData()
                return results;
            } catch (err) {
                console.error(err);
                return err;
            }
        }
        fetchStages();
    }, [countryID]);

    const triggers = [
      { name: "Diamond chart visualization", value: "tab1" },
      { name: "Horizontal bars visualization", value: "tab2" },
    ]
    const content = [
      { value: "tab1", component: <PolicyLandscapingTable label="Policy & Regulatory Reforms Progress" stages={stages} countries={countries} /> },
      { value: "tab2", component: <PolicyTrackingTable label="Policy & Regulatory Reforms Progress" stages={stages} countries={countries} /> },
    ]

    return (
        <StyledCountryLandscapingPage className="page-content">
          <div className="country-selection space-x-5">
            <div className="flex items-center">
              <span className="mr-2 text-nowrap">Showing data for </span>
              <CountriesDropdown countryID={countryID} setCountryID={setCountryID}/>
            </div>
          </div>
          <div className="mt-1 mb-1">
            <CustomTabs
              ariaLabel="Milestone Management"
              defaultValue="tab1"
              triggers={triggers}
              content={content}
            />
          </div>
        </StyledCountryLandscapingPage>
    )
}

export default LandscapingVisual