import { v4 as uuidv4 } from "uuid"

export const data = [
  {
    id: uuidv4(),
    question: "How does APRITS support the tracking of policies and investments in real time?",
    answer: "APRITS includes a comprehensive e-tracking feature that allows AGRA-focus countries to monitor the progress of " +
      "policies and investment related reforms throughout their lifecycle, from initiation to implementation. This feature " +
      "ensures that users can efficiently gather data and track key activities at every stage of the policy process, helping " +
      "stakeholders stay informed about reforms, their progress, and any challenges that arise. By providing a clear, " +
      "structured overview of these reforms, APRITS enables better decision-making and ensures transparency in tracking " +
      "policies and investments across AGRA-focus countries."
  },
  {
    id: uuidv4(),
    question: "Does APRITS support the integration of emerging issues like climate change, youth employment, and nutrition?",
    answer: "Yes. APRITS includes a feature that allows designated country administrators to seamlessly create " +
      "and integrate new systems on the platform informed by emerging issues such as climate change, youth and women employment, " +
      "and nutrition. This capability ensures that the platform remains adaptable and aims to strengthen the capacity of " +
      "AGRA-focus countries' governments to proactively address evolving needs and ensure that policy tracking and reforms are always " +
      "in sync with emerging issues."
  },
  {
    id: uuidv4(),
    question: "What data visualisation features does APRITS offer to simplify data interpretation?",
    answer: "APRITS offers a robust suite of interactive data visualisation tools designed to make complex information " +
      "easily understandable. Key features include tooltips, pop-ups, and click events, which provide additional details " +
      "and redirection to AGRA-focus countries or other crucial data points directly within the visualisations. Users can " +
      "explore interactive maps with smooth performance and responsiveness, allowing them to dive deeper into the data. " +
      "The platform also incorporates clear and intuitive infographics such as pie charts, donut charts, bar charts, and " +
      "choropleth maps. For instance, in the policy e-Tracking section, half-donut charts visually demonstrate the impact " +
      "of completed versus pending milestones on policy scores, while choropleth maps show policy distribution across " +
      "countries. Additionally, APRITS supports customisation of these visualisations, allowing users to focus on specific " +
      "countries and key metrics, enabling more targeted and actionable insights."
  },
  {
    id: uuidv4(),
    question: "Can countries track policy shifts news and events within APRITS?",
    answer: "Yes. APRITS includes a dedicated page where countries can easily document and track policy shifts as well as key " +
      "policy events such as workshops, seminars, and other engagements. This feature allows authorised users of the platform " +
      "to add detailed information about changes in policy direction in a country and record significant events that influence " +
      "policy development. By centralising this information, users can monitor the progress and impact of these shifts over time, " +
      "providing a clear timeline of policy evolution and the activities that drive it. This ensures that countries can keep " +
      "stakeholders informed and maintain an up-to-date overview of all policy-related events and decisions."
  },
  {
    id: uuidv4(),
    question: "Does APRITS support report generation of policy insights?",
    answer: "APRITS offers powerful report generation capabilities under eTracking section, providing users with high-level " +
      "summary insights, detailed infographics, and comprehensive information that can be used to create policy briefs and " +
      "other valuable insights. These reports distill complex data into easy-to-understand formats, highlighting key trends " +
      "and findings from policy reforms lifecycle across AGRA-focus countries. Whether you're preparing policy briefs or " +
      "conducting deeper analysis, APRITS ensures that the information is both actionable and presented in a visually engaging " +
      "way, making it easier for stakeholders and users to draw meaningful conclusions and make informed decisions."
  },
  {
    id: uuidv4(),
    question: "Does APRITS support data export compatible with leading analysis tools like Excel, SPSS, and STATA?",
    answer: "APRITS offers robust data export capabilities, allowing users to export information in formats compatible " +
      "with popular data analysis software and spreadsheets such as Excel, SPSS, and STATA. This feature ensures that " +
      "stakeholders and any user of the platform can easily transfer data from the platform into their preferred analytical " +
      "tools for further processing and detailed analysis."
  }
]