import React, { useState, useEffect } from "react"
import { Card, CardBody, Col, Container, Row, Button, Input } from "reactstrap"
import "./user-details.css"
import {
  getMeProfile,
  getMePicture,
  changeMeProfile
} from "../../../services/userProfileService" // Import changeUserName
import ChangePasswordSidebar from "./ChangePasswordSidebar" // Import the sidebar component
import EditProfileSidebar from "./EditProfileSidebar";

import profile1 from "assets/images/profile-img.png"

const UserProfile = ({ alert }) => {

  const initialUserProfileForm = {
    first_name: "",
    last_name: "",
    photo: null
  }

  // const [userProfileForm, setUserProfileForm] = useState({initialUserProfileForm})

  const [userProfile, setUserProfile] = useState({ initialUserProfileForm })
  const [userPhoto, setUserPhoto] = useState(profile1) // Set default photo as fallback
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [editMode, setEditMode] = useState(false) // For toggling the edit mode
  const [editedFirstName, setEditedFirstName] = useState("") // Store the edited first name
  const [editedLastName, setEditedLastName] = useState("") // Store the edited last name
  const [isSidebarOpen, setIsSidebarOpen] = useState(false) // Control sidebar visibility
  const [isEditSidebarOpen, setIsEditSidebarOpen] = useState(false);

  useEffect(() => {
    loadMyProfile()
  }, [])

  const loadMyProfile = async () => {
    try {
      const myProfileData = await getMeProfile()
      setUserProfile(myProfileData)
      setEditedFirstName(myProfileData.first_name) // Pre-fill the current first name
      setEditedLastName(myProfileData.last_name)   // Pre-fill the current last name

      if (myProfileData) {
        try {
          const photoBlob = await getMePicture()
          const photoUrl = URL.createObjectURL(photoBlob)
          setUserPhoto(photoUrl)
        } catch (err) {
          console.error("Error fetching profile picture:", err)
        }
      }

      setLoading(false)
    } catch (error) {
      console.error("Error fetching user profile:", error)
      setError("Failed to load user profile.")
      setLoading(false)
    }
  }

  const handleCancel = () => {
    setEditMode(false) // Exit the edit mode without saving
    setEditedFirstName(userProfile.first_name) // Reset to the original first name
    setEditedLastName(userProfile.last_name) // Reset to the original last name
  }

  const updateUserProfile = (updatedData) => {
    setUserProfile((prevProfile) => ({
      ...prevProfile,
      ...updatedData,
    }));
  };

  // Toggle function for Edit Profile Sidebar
  const toggleEditSidebar = () => setIsEditSidebarOpen(!isEditSidebarOpen);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen) // Toggle the sidebar visibility
  }

  // Close sidebar if clicked outside
  const handleClickOutside = (event) => {
    if (isSidebarOpen && !event.target.closest(".sidebar")) {
      setIsSidebarOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [isSidebarOpen])

  if (loading) {
    return <p>Loading user data...</p>
  }

  if (error) {
    return <p>{error}</p>
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row className="justify-content-center">
            <Col lg="10" md="12">
              <Card className="user-detail-card bg-white">
                <CardBody>
                  {/* Top-right Edit Button */}
                  <div className="d-flex justify-content-end">
                    <Button
                      color="link"
                      className="text-primary"
                      onClick={toggleEditSidebar}
                    ><i className="fas fa-edit"></i> Edit
                    </Button>
                  </div>

                  <Row className="align-items-center">
                    <Col lg="4" md="6" sm="12" className="text-center mb-4">
                      <div className="profile-card">
                        <img
                          src={userPhoto}
                          alt="Profile"
                          className="img-fluid rounded-circle profile-image"
                        />
                        <h4 className="mt-4">{`${userProfile.first_name} ${userProfile.last_name}`}</h4>
                        <div className="status-badge">
                          <span className={`dot ${userProfile.is_active ? "bg-success" : "bg-danger"}`}></span>
                          <span className="ml-3">{userProfile.is_active ? "Active" : "Inactive"}</span>
                        </div>
                      </div>
                    </Col>

                    <Col lg="8" md="12">
                      <div className="user-info-table bg-white">
                        {/* Email */}
                        <div className="user-info-row">
                        <span className="info-icon">
                          <i className="fas fa-envelope"></i>
                        </span>
                          <span className="info-label">Email</span>
                          <span className="info-value">{userProfile.email}</span>
                        </div>

                        {/* Username */}
                        <div className="user-info-row">
                        <span className="info-icon">
                          <i className="fas fa-user"></i>
                        </span>
                          <span className="info-label">Username</span>
                          <span className="info-value">{`${userProfile.first_name} ${userProfile.last_name}` }</span>
                        {/*  <span className="info-value edit-section">*/}
                        {/*  {editMode ? (*/}
                        {/*    <>*/}
                        {/*      <Input*/}
                        {/*        type="text"*/}
                        {/*        value={editedFirstName}*/}
                        {/*        onChange={(e) => setEditedFirstName(e.target.value)}*/}
                        {/*        className="username-input"*/}
                        {/*      />*/}
                        {/*      <Input*/}
                        {/*        type="text"*/}
                        {/*        value={editedLastName}*/}
                        {/*        onChange={(e) => setEditedLastName(e.target.value)}*/}
                        {/*        className="username-input mt-2"*/}
                        {/*      />*/}
                        {/*      <div className="action-buttons">*/}
                        {/*        <Button onClick={handleSaveChanges} className="action-button success">*/}
                        {/*          &#10004; Save Changes*/}
                        {/*        </Button>*/}
                        {/*        <Button onClick={handleCancel} className="action-button danger ml-2">*/}
                        {/*          &#10006; Cancel*/}
                        {/*        </Button>*/}
                        {/*      </div>*/}
                        {/*    </>*/}
                        {/*  ) : (*/}
                        {/*    <>*/}
                        {/*      {`${userProfile.first_name} ${userProfile.last_name}`}*/}
                        {/*      <Button*/}
                        {/*        color="link"*/}
                        {/*        className="text-primary ml-2"*/}
                        {/*        onClick={() => setEditMode(true)}*/}
                        {/*      >*/}
                        {/*        Edit*/}
                        {/*      </Button>*/}
                        {/*    </>*/}
                        {/*  )}*/}
                        {/*</span>*/}
                        </div>

                        {/* Location */}
                        <div className="user-info-row">
                        <span className="info-icon">
                          <i className="fas fa-map-marker-alt"></i>
                        </span>
                          <span className="info-label">Location</span>
                          <span className="info-value">
                          {userProfile.user_country && userProfile.user_country.length > 0 ? (
                            userProfile.user_country
                              .map((countryObj) => countryObj.country.long_name)
                              .join(", ")
                          ) : (
                            "N/A"
                          )}
                        </span>
                        </div>

                        {/* Organization */}
                        <div className="user-info-row">
                        <span className="info-icon">
                          <i className="fas fa-building"></i>
                        </span>
                          <span className="info-label">Organization</span>
                          <span className="info-value">
                          {userProfile.user_organisation?.organisation?.long_name || "N/A"}
                        </span>
                        </div>

                        {/* Access Level */}
                        <div className="user-info-row">
                        <span className="info-icon">
                          <i className="fas fa-user-shield"></i>
                        </span>
                          <span className="info-label">Access Level</span>
                          <span className="info-value">
                          {userProfile.is_admin ? "Administrator" : "User"}
                        </span>
                        </div>

                        {/* Password */}
                        <div className="user-info-row">
                        <span className="info-icon">
                          <i className="fas fa-lock"></i>
                        </span>
                          <span className="info-label">Password</span>
                          <span className="info-value">
                          ******{" "}
                            <Button color="link" className="text-primary" onClick={toggleSidebar}>
                            Change/Reset password
                          </Button>
                        </span>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {/* Edit Profile Sidebar */}
      {isEditSidebarOpen && (
        <EditProfileSidebar
          alert={alert}
          userProfile={userProfile}
          isOpen={isEditSidebarOpen}
          toggleSidebar={toggleEditSidebar}
          updateUserProfile={updateUserProfile}
        />
      )}

      {/* Sidebar Component */}
      {isSidebarOpen && <ChangePasswordSidebar alert={alert} isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />}

    </React.Fragment>
  )

}

export default UserProfile