import React from 'react';

const MapLegend = ({label}) => {

  // Dynamically determine the label
  const labelMap = {
    average_score: {
      name: 'Avg. policy score',
      legendItems: [
        { range: '80+', color: '#401201' },
        { range: '61 - 80', color: '#A64005' },
        { range: '41 - 60', color: '#F2811D' },
        { range: '21 - 40', color: '#F29727' },
        { range: '0 - 20', color: '#F2B33D' },
      ]
    },
    number_of_policies: {
      name: 'No. of policies',
      legendItems: [
        { range: '20+', color: '#401201' },
        { range: '16 - 20', color: '#A64005' },
        { range: '11 - 15', color: '#F2811D' },
        { range: '6 - 10', color: '#F29727' },
        { range: '0 - 5', color: '#F2B33D' },
      ]
    },
    average_month_duration: {
      name: 'Time duration in months',
      legendItems: [
        { range: '20+', color: '#401201' },
        { range: '16 - 20', color: '#A64005' },
        { range: '11 - 15', color: '#F2811D' },
        { range: '6 - 10', color: '#F29727' },
        { range: '0 - 5', color: '#F2B33D' },
      ] 
    } 
  };

  return (
    <div className="flex flex-col items-center text-nowrap">
      <h3 className="font-semibold mb-2">{labelMap?.[label]?.name}</h3>
      <div className="flex flex-col">
        {labelMap?.[label]?.legendItems?.map((item, index) => (
          <div key={index} className="flex items-center mb-1">
            <div 
              className="w-4 h-4 mr-2" 
              style={{ backgroundColor: item?.color }}
            />
            <span>{item?.range}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MapLegend;