import React, { useEffect } from 'react';
import DonutChart from './Charts/DonutChart/DonutChart';
// import DurationChart from './Charts/DurationChart';
import SystemsChart from './Charts/SystemsChart';
import AfricaChoroplethMap from './Charts/Map/AfricaMap';
import PolicyStageChart from './Charts/PolicyStageChart';

function Overview({overViewTab, setOverViewTab, data, countryID, setCountryID}) {

    const labelMap = {
        number_of_policies : {
            id: 1,
            dataField: 'number_of_policies',
            textField: 'Policies'
        }, 
        policy_score: {
            id: 2,
            dataField: 'average_score',
            textField: 'Avg. Score'
        }
    }

    const label = labelMap[overViewTab]; //TODO: add useEffect to update only when subTab changes

    return (
        <>
            <div className='d-flex justify-center'>
                <div className={`sub-tab mr-1 ${overViewTab === 'number_of_policies' ? 'sub-tab-active' : ''}`} onClick={()=>setOverViewTab('number_of_policies')}>No. of Policies</div>
                <div className={`sub-tab ml-1 ${overViewTab === 'policy_score' ? 'sub-tab-active' : ''}`} onClick={()=> setOverViewTab('policy_score')}>Policy Scores</div>
            </div>
            <div className='flex justify-center' id="print-content">
            {data && 
                <div className='space-y-3'>
                    <div className='flex space-x-3'>
                        <DonutChart chartData={data?.stage_overview?.data} label={label}/>
                        <AfricaChoroplethMap chartData={data?.location_overview?.data} countryID={countryID} setCountryID={setCountryID} label={label}/>
                    </div>
                    <div className='flex space-x-3'>
                        {/* {overViewTab === 'number_of_policies' && (
                            <DurationChart 
                                chartData={data?.duration_overview?.data?.average_month_duration} 
                                title="Average duration in months per stage" 
                            />
                        )} */}
                        <div className={`flex-1 flex justify-center`}>
                            <SystemsChart chartData={data?.system_overview?.data} label={label} />
                        </div>
                    </div>
                    <PolicyStageChart chartData={data?.system_stage_overview?.data} tab = {overViewTab}/>
                </div>
            }
            </div>
        </>
    );
}

export default Overview;