import React, { useState } from "react"
import { useQuery } from "@tanstack/react-query"
import SearchBox from "components/Common/SearchBox"
import Pagination from "components/Common/Pagination"
import FilterLabels from "./FilterLabels"
import {
  getSystems,
  createNewSystem,
  editSpecificSystem, // Ensure this is imported
  deleteSystem  // Import the delete function
} from "services/indicatorServices"
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label
} from "reactstrap"
import useDebounce from "hooks/useDebounce"
import styled from "@emotion/styled"
import LoadingSpinner from "components/Common/Loading-Spinner"
import { SystemsTable } from "./SystemsTable"
import ExportTable from "components/Common/ExportTable"
import './EditSystemsModal.css'
import { exportTableData } from "../../services/common"

export const StyledContainer = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    gap: 0.5em;

    & .basic-single {
        flex-basis: 150px;
    }
`

const Systems = ({ alert }) => {

  // State for managing modal fields
  const [newSystem, setNewSystem] = useState({
    name: "",
    description: ""
  })

  // State for editing functionality
  const [isUserEditing, setIsUserEditing] = useState(false)
  const [editingRowID, setEditingRowID] = useState(null)

  const [modalFilter, setModalFilter] = useState(false)
  const toggleModalFilter = () => setModalFilter(prevState => !prevState)

  //pagination
  const [offset, setOffset] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalEntries, setTotalEntries] = useState(0)
  const handlePageChange = (pageNum) => {
    const offsetVal = pageNum * 50 - 50
    setOffset(offsetVal)
    setCurrentPage(pageNum)
  }

  //filters
  const [selectedCountryFilters, setSelectedCountryFilters] = useState([])
  const [selectedTypeFilters, setSelectedTypeFilters] = useState([])

  const applyFilters = (selectedCountries, selectedTypes) => {
    setSelectedCountryFilters(selectedCountries)
    setSelectedTypeFilters(selectedTypes)
  }

  const areAnyFiltersSelected = () => {
    if (selectedCountryFilters.length > 0 || selectedTypeFilters.length > 0) {
      return true
    } else {
      return false
    }
  }

  //search
  const [searchTerm, setSearchTerm] = useState("")
  const debouncedSearchTerm = useDebounce(searchTerm)

  const {
    data: systemsData,
    refetch,
    isLoading
  } = useQuery({
    queryKey: [
      "Systems",
      {
        debouncedSearchTerm,
        page: currentPage
      }
    ],
    queryFn: async() => {
      const { data, count } = await getSystems({ searchField: debouncedSearchTerm, limit: 50, offset })
      setTotalEntries(count);
      return data;
    }
  })

  const handleSearchInputChange = (evt) => {
    setSearchTerm(evt.target.value)
    setOffset(0)
    setCurrentPage(1)
    setTotalEntries(systemsData?.count)
  }

  // Modal management
  const [showModal, setShowModal] = useState(false)
  const openModal = () => setShowModal(true)
  const closeModal = () => {
    setShowModal(false)
    setNewSystem({
      name: "",
      description: ""
    })
    setIsUserEditing(false)
    setEditingRowID(null)
  }

  // Initiate edit function
  const initiateEdit = (id) => {
    const selectedRowData = systemsData?.find((row) => row.id === id)
    setNewSystem({
      name: selectedRowData.name,
      description: selectedRowData.description
    })
    setIsUserEditing(true)
    setEditingRowID(selectedRowData.id)
    openModal()
  }

  const handleSystemCreate = async () => {
    if (!newSystem.name) {
      alert("Name field cannot be empty", false)
      return
    }

    try {
      const requestBody = {
        name: newSystem.name,
        description: newSystem.description
      }

      if (isUserEditing) {
        await editSpecificSystem(editingRowID, requestBody) // Ensure the correct id is passed
        alert("System updation", true)
      } else {
        await createNewSystem(requestBody)
        alert("System creation", true)
      }

      closeModal()
      refetch()
    } catch (err) {
      console.error("Error response:", err.response?.data)
      alert("Failed to create system", false)
    }
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setNewSystem((prevSystem) => ({ ...prevSystem, [name]: value }))
  }

  const onDeleteClick = async (id) => {
    try {
      await deleteSystem(id) // Use id instead of policyId
      alert("System deletion", true)
      refetch() // Refresh the systems list after deletion
    } catch (err) {
      alert("Failed to delete system", false)
      console.error("Error deleting system:", err)
    }

  }

    const exportTable = async type => {
    try {
      const query = {
        export_format: type,
      };

      const exportEntity = "Systems"

      exportTableData(query, exportEntity)

    } catch (err) {
      console.error(err)
      alert("Data export", false)
    }
  }

  return (
    <div>
      <div className="d-flex justify-content-between">
        <StyledContainer>
          <SearchBox searchChange={handleSearchInputChange} />
        </StyledContainer>
        <Button
          className="common-button"
          onClick={openModal}
        >
          Create New
        </Button>
        <ExportTable loadFilteredData={exportTable}></ExportTable>

        {/* Modal for creating new system */}
        {/* <Modal isOpen={showModal} toggle={closeModal}>
          <ModalHeader toggle={closeModal}>
            <b>{isUserEditing ? "Edit System" : "Create New System"}</b>
          </ModalHeader>
          <ModalBody>
            <Label className="form-label" htmlFor="name">
              System Name:
            </Label>
            <Input
              id="name"
              name="name"
              type="text"
              value={newSystem.name}
              required
              onChange={handleInputChange}
            />
            <Label className="form-label" htmlFor="description">
              Add A Short Description:
            </Label>
            <Input
              id="description"
              name="description"
              type="textarea"
              rows={1}
              value={newSystem.description}
              onChange={handleInputChange}
            />
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={handleSystemCreate}>
              {isUserEditing ? "Save" : "Create"}
            </Button>
          </ModalFooter>
        </Modal> */}

        <Modal isOpen={showModal} toggle={closeModal} className="custom-modal">
          <ModalHeader className="modal-header" toggle={closeModal}>
            <b>{isUserEditing ? "Edit System" : "Create New System"}</b>
          </ModalHeader>

          <div className="modal-subtext">
            <small>All fields marked with asterisks (<span className="required">*</span>) are compulsory.</small>
          </div>

          <ModalBody className="modal-body">
            <Label className="form-label" htmlFor="name">
              System Name:<span className="required">*</span>
            </Label>
            <Input
              id="name"
              name="name"
              type="text"
              className="input-field"
              value={newSystem.name}
              required
              onChange={handleInputChange}
            />

            <Label className="form-label mt-3" htmlFor="description">
              Add A Short Description:
            </Label>
            <Input
              id="description"
              name="description"
              type="textarea"
              className="input-field"
              rows={1}
              value={newSystem.description}
              onChange={handleInputChange}
            />
          </ModalBody>

          <ModalFooter className="modal-footer">
            <Button
              // className="btn-primary"
              style={{
                backgroundColor: '#036F36',
                color: '#FFF605',
                borderRadius: '2.25rem',
                width: '100%',
              }}
              onClick={handleSystemCreate}>
              {isUserEditing ? "Save" : "Create"}
            </Button>
            {/* <Button className="btn-secondary" onClick={closeModal}>
              Cancel
            </Button> */}
          </ModalFooter>
        </Modal>

      </div>

      {isLoading && <LoadingSpinner />}
      {!isLoading && systemsData?.length > 0 ? (
        <>
          <SystemsTable
            systemsData={systemsData}
            initiateEdit={initiateEdit}
            onDeleteClick={onDeleteClick} // Pass delete function to table
          />
          <Pagination
            currentPage={currentPage}
            goToPage={handlePageChange}
            totalEntries={totalEntries}
          />
        </>
      ) : null}
    </div>
  )
}

export default Systems