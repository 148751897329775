import React, { useEffect, useState } from 'react';
import Pagination from "components/Common/Pagination"
import SearchBox from "components/Common/SearchBox"
import { getPublicPolicies, savePolicyView } from "services/policyDirectoryServices"
import { use } from 'i18next';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Table,
    Input,
    Label,
    Form,
    FormGroup,
} from "reactstrap"
import { set } from 'lodash';
import { GET_PUBLIC_POLICY_URL } from "../../services/CONSTANTS"; // Import the EDIT_SYSTEM constant
import useDebounce from "hooks/useDebounce"
import PolicyDirectoryFilters from "../PolicyDirectory/PolicyDirectoryFilters"
import ExportTable from "../../components/Common/ExportTable";
import { exportTableData } from "services/common"
import { Icon } from '@iconify/react';
import CountriesDropdown from 'components/Common/countries-dropdown';

export default function HomePolicies() {

    const [currentPage, setCurrentPage] = useState(1);
    const [totalEntries, setTotalEntries] = useState(0);
    const [modalReason, setModalReason] = useState(false);
    const [downloadingPolicy, setDownloadingPolicy] = useState(null);
    const [policyPreview, setPolicyPreview] = useState(null);
    const [modalPreview, setModalPreview] = useState(false);
    const [data, setData] = useState({});
    const [intention, setIntention] = useState({
        interest_group: "Academic and Research Institutions",
        reason: "Research and Analysis"
    })

    const togglePreviewModal = () => setModalPreview(!modalPreview);

    const loadData = async () => {
        const limit = 50;
        try {
            const query = {
                limit,
                offset: ((currentPage - 1) * limit),
                searchTerm,
                ...appliedFilters
            };
            const { data, count } = await getPublicPolicies(query);
            setData(data);
            setTotalEntries(count);
        } catch (err) {
            console.error(err);
            // return err;
        }
    };

    const requestDownloadFileUrl = async () => {
        try {
            const url = GET_PUBLIC_POLICY_URL(downloadingPolicy);
            window.open(url, "_blank");
        } catch (err) {
            console.error(err);
        }
    }

    // const saveReasonsAndDownload = async () => {
    //     // setModalReason(true);
    //     // console.log(`Saving reasons  ${JSON.stringify(intention)} for policy id ${downloadingPolicy}`);
    //     // try {

    //     //     const response = await savePolicyView(downloadingPolicy, intention);
    //     //     console.log(response);
    //     //     requestDownloadFileUrl();

    //     // } catch (err) {
    //     //     console.error(err);
    //     //     // return err;
    //     // }
    //     console.log(`Saving reasons ${JSON.stringify(intention)} for policy id ${downloadingPolicy}`);

    //     try {
    //         // Send the POST request with selected reason and interest group
    //         const response = await savePolicyView(downloadingPolicy, intention);
    //         console.log("Policy View Saved:", response);

    //         // Proceed to download the file
    //         requestDownloadFileUrl();
    //     } catch (err) {
    //         console.error("Error saving policy view:", err);
    //     }


    // }

    // const handlePageChange = (page) => {
    //     setCurrentPage(page);
    // };
    const saveReasonsAndDownload = async () => {
        console.log(`Saving reasons ${JSON.stringify(intention)} for policy id ${downloadingPolicy}`);

        try {
            // Send API request to save the policy view
            const response = await savePolicyView(downloadingPolicy, intention);
            console.log("Policy View Saved:", response);

            // Close the modal after successful request
            setModalReason(false);

            // Proceed to download the file
            requestDownloadFileUrl();
        } catch (err) {
            console.error("Error saving policy view:", err);
        }
    };

    /*
    Handle search and filtering of the query
    */
    //search term and debounce handler
    const [searchTerm, setSearchTerm] = useState("")
    const debouncedSearchTerm = useDebounce(searchTerm)

    const handleSearchInputChange = evt => {
        setSearchTerm(evt.target.value)
        setCurrentPage(1)
    }

    //filters
    const initialFiltersState = {
        locations: [],
        systems: [],
        stages: [],
        categories: [],
        isOffTrack: null,
        isPolicyWin: null,
        isComplete: null,
        isDelayed: null,
        AGRASupported: null,
    }
    const [appliedFilters, setAppliedFilters] = useState(initialFiltersState);

    const applyFilters = (filters) => {
        setAppliedFilters(filters);
        toggleModalFilter();
    }
    function areObjectsEqual(obj1, obj2) {
        const obj1Keys = Object.keys(obj1);

        // Check if all values for each key are equal
        for (const key of obj1Keys) {
            if (obj1[key] !== obj2[key]) {
                return false;
            }
        }

        return true;
    }

    const areAnyFiltersSelected = () => areObjectsEqual(appliedFilters, initialFiltersState);
    const clearFilters = () => setAppliedFilters(initialFiltersState);
    const [modalFilter, setModalFilter] = useState(false)
    const toggleModalFilter = () => setModalFilter(prevState => !prevState)

    const exportTable = async (type) => {
        try {
            const query = {
                export_format: type,
                // ...(selectedUser && { user_id: selectedUser }),
                // ...(selectedActivity && { activity: selectedActivity }),
            };

            exportTableData(query, "Policies_Public");
        } catch (err) {
            console.error(err);
            alert("Data Export", false)
        }
    }

    const [countryID, setCountryID] = useState(null);

    useEffect(() => {
        loadData();
    }, [currentPage, searchTerm, appliedFilters]);

    return (
        <div className='w-11/12 mx-auto my-4'>
            <div className='my-4 flex justify-between'>
                <div className="d-flex align-items-center">
                    <SearchBox searchChange={handleSearchInputChange} />
                    <Button
                        className="common-button"
                        onClick={toggleModalFilter}
                    >
                        <i className="mdi mdi-filter-variant me-1" /> Filters
                    </Button>
                    {areAnyFiltersSelected() && (
                        <Button
                            className="d-flex align-items-center"
                            style={{
                                backgroundColor: "#f8f9fa",
                                color: "black",
                                margin: "15px 10px",
                                border: "none",
                                fontSize: "14px",
                            }}
                            onClick={clearFilters}
                        >
                            <i className="ph-x me-1"></i>
                            Clear All Filters
                        </Button>
                    )}
                </div>
                <ExportTable loadFilteredData={exportTable}></ExportTable>
            </div>
            <Modal
                isOpen={modalFilter}
                toggle={toggleModalFilter}
                className="modal-lg"
            >
                <ModalHeader toggle={toggleModalFilter}>
                    <i className="mdi mdi-filter-variant me-1"></i>
                    Filters
                </ModalHeader>
                <ModalBody>
                    <PolicyDirectoryFilters
                        appliedFilters={appliedFilters}
                        applyFilters={applyFilters}
                    />
                </ModalBody>
            </Modal>
            <Modal
                isOpen={modalReason}
                className="modal-lg"
            >
                <ModalHeader >
                    <i className="mdi mdi-filter-variant me-1"></i>
                    Select your research goal.
                </ModalHeader>
                <ModalBody>
                    <Form inline onSubmit={(e) => e.preventDefault()}>
                        <FormGroup>
                            <Label for="backdrop">Organization / Institution / Interest</Label>{' '}
                            <Input
                                type="select"
                                name="backdrop"
                                id="backdrop"
                                // onChange={changeBackdrop}
                                onChange={(e) => setIntention({ ...intention, interest_group: e.target.value })}
                            >
                                <option value="Academic and Research Institutions">Academic and Research Institutions</option>
                                <option value="Government and Public Sector">Government and Public Sector</option>
                                <option value="Non-Governmental and Advocacy Organisations">Non-Governmental and Advocacy Organisations</option>
                                <option value="Private Sector and Industry Associations">Private Sector and Industry Associations</option>
                                <option value="International Organizations and Development Agencies">International Organizations and Development Agencies</option>
                                <option value="Media, Journalism, and Public Information Outlets">Media, Journalism, and Public Information Outlets</option>
                                <option value="Financial and Investment Entities">Financial and Investment Entities</option>

                            </Input>
                        </FormGroup>

                        <FormGroup>
                            <Label for="backdrop">Reason</Label>{' '}
                            <Input
                                type="select"
                                name="backdrop"
                                id="backdrop"
                                // onChange={changeBackdrop}
                                onChange={(e) => setIntention({ ...intention, reason: e.target.value })}
                            >
                                <option value="Research and Analysis">Research and Analysis</option>
                                <option value="Policy Development and Benchmarking">Policy Development and Benchmarking</option>
                                <option value="Advocacy and Public Awareness">Advocacy and Public Awareness</option>
                                <option value="Business and Investment Planning">Business and Investment Planning</option>
                                <option value="Educational Purposes">Educational Purposes</option>
                                <option value="Legal Compliance and Guidance">Legal Compliance and Guidance</option>
                                <option value="Program Implementation and Monitoring">Program Implementation and Monitoring</option>
                                <option value="Media and Journalism">Media and Journalism</option>
                                <option value="Personal Interest and Civic Engagement">Personal Interest and Civic Engagement</option>
                                <option value="Funding and Grant Applications">Funding and Grant Applications</option>
                                <option value="Strategic Partnership Building">Strategic Partnership Building</option>
                                <option value="Environmental and Social Impact Assessment">Environmental and Social Impact Assessment</option>
                                <option value="International Policy Comparison and Diplomacy">International Policy Comparison and Diplomacy</option>

                            </Input>
                        </FormGroup>

                        {/* <FormGroup>
                        <Label for="backdrop">Organization/Institution name</Label>
                        <Input
                            id="exampleText"
                            name="text"
                            type="text"
                            placeholder='Valley National Institute of Technology'
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="backdrop">Reason for downloading</Label>
                        <Input
                            id="exampleText"
                            name="text"
                            type="textarea"
                            placeholder='Research policy'
                        />
                    </FormGroup> */}
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={() => setModalReason(false)}>
                        Cancel
                    </Button>
                    <Button color="primary" type="submit" onClick={saveReasonsAndDownload}>
                        Download Policy File PDF
                    </Button>

                </ModalFooter>
            </Modal>
            {(appliedFilters && appliedFilters.locations?.length > 0) ? (
                <Table className="table table-bordered border-success mb-0 table-striped">
                    <thead>
                        <tr>
                            <th>Systems</th>
                            <th>Policy Name</th>
                            <th>Legal framework</th>
                            <th>Region</th>
                            <th>Download</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data && data.length > 0 ? (
                            data.map((row, i) => (
                                <tr key={i}>
                                    <td>
                                        {row.policy_systems.map((system, i) => (
                                            <div key={i}>{system.system.name}</div>
                                        ))}
                                    </td>
                                    <td>{row.name}</td>
                                    <td>{row.ethiopia_policy_type}</td>
                                    <td>{row.ethiopia_region}</td>

                                    {/* <td>
                                        {row.policy_file ? (<Button
                                            color="primary"
                                            size="sm"
                                            onClick={() => { setDownloadingPolicy(row.id); setModalReason(true) }}
                                        >
                                            Download
                                        </Button>) : (<p>No download available</p>)}
                                    </td> */}
                                    <td>
                                        {row.policy_file ? (
                                            <>
                                                <Button
                                                    color="secondary"
                                                    size="sm"
                                                    className="me-2"
                                                    onClick={() => {
                                                        setPolicyPreview(row);
                                                        setModalPreview(true);
                                                    }}
                                                >
                                                    Preview
                                                </Button>
                                                <Button
                                                    color="primary"
                                                    size="sm"
                                                    onClick={() => { setDownloadingPolicy(row.id); setModalReason(true) }}
                                                >
                                                    Download
                                                </Button>
                                            </>
                                        ) : (
                                            <p>No download available</p>
                                        )}
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="4">No data available</td>
                            </tr>
                        )
                        }
                    </tbody>
                </Table>

            ) : (
                <div className='flex flex-col items-center py-5 px-4 justify-center'>
                    <Icon icon="hugeicons:package-search" className="text-[#036F36] h-5 w-5 md:h-14 md:w-14" />
                    <p className='my-5'>
                        Kindly specify the nature of your search using the filters button or start by choosing a country.
                    </p>

                    <div className='border-2 border-[#036F36] rounded-lg p-2'>
                        <CountriesDropdown countryID={countryID} setCountryID={setCountryID} setCountry={(data) => {
                            // append to applied filters
                            setAppliedFilters({
                                ...appliedFilters,
                                locations: [...appliedFilters.locations, {
                                    value: data.id,
                                    label: data.long_name,
                                }]
                            });
                        }} hasRAC={true} />
                    </div>
                </div>
            )}

            <Modal isOpen={modalPreview} toggle={togglePreviewModal} size="lg">
                <ModalHeader toggle={togglePreviewModal}>Policy Details</ModalHeader>
                <ModalBody>
                    {policyPreview?.details ? (
                        <div>
                            <h5>Details:</h5>
                            <p>{policyPreview.details}</p>
                        </div>
                    ) : (
                        <p>No details available for this policy.</p>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={togglePreviewModal}>
                        Close
                    </Button>
                </ModalFooter>
            </Modal>



            <Pagination
                currentPage={currentPage}
                // goToPage={handlePageChange}
                totalEntries={totalEntries}
            />
        </div>


    )
}