import { get, patch, patchFormData } from "./AXIOS"
import {
  GET_USER_PROFILE,
  USER_PROFILE_PHOTO,
  CHANGE_ME_PASSWORD,
  CHANGE_USER_NAME, ME_PROFILE_PHOTO, EDIT_PARTNER, ME_PROFILE, CHANGE_ME_PROFILE
} from "./CONSTANTS"

export const getMeProfile = () => get(ME_PROFILE)
export const getMePicture = () => get(ME_PROFILE_PHOTO, { responseType: 'blob'})

export const getUserProfile = (user_id) => get(GET_USER_PROFILE(user_id));

export const getUserPicture = (user_id) =>
    get(USER_PROFILE_PHOTO(user_id), { responseType: 'blob' })

export const changeMePassword = (body) =>
  patch(CHANGE_ME_PASSWORD, body, {"headers": {"Content-Type": "application/json"}})

export const changeMeProfile = (formData) =>
  patchFormData(CHANGE_ME_PROFILE, formData, { "headers": {"accept": "application/json"}})

//  export const changeMePassword = async (body) => {
//   try {
//     const headers = {
//       "Content-Type": "application/json",
//     };
//
//     // Make the PATCH request with the necessary headers and body
//     const response = await patch(CHANGE_ME_PASSWORD, body, { headers });
//
//     return response;
//   } catch (error) {
//     console.error("Error updating password:", error.response?.data || error.message);
//   }
// }

   // Patch request to update user name
   export const changeUserName = async (data) => {
    try {
      // Create an object that wraps the user data as per API requirement
      const userUpdateBody = {
        first_name: data.first_name,
        last_name: data.last_name,
      };

      // Send the PATCH request with the necessary headers and body
      const response = await patch(CHANGE_USER_NAME, userUpdateBody, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      // Log the full response for debugging purposes
      console.log("Full Response:", response);

      // Check if there's data in the response
      if (response && response.data) {
        console.log("Response received:", response.data);
        return response.data;
      } else {
        console.warn("No data returned in the response.");
        console.warn("Full Response Object:", response); // Log the full response object
        return {}; // Fallback if no data is returned
      }
    } catch (error) {
      // Log the error details for better debugging
      console.error("Error updating user name:", error.response ? error.response.data : error);
    }
  };