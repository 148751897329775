import React from 'react'

const TimeBar = ({months}) => {

    const monthsToHumanReadable = (months) => {
        const monthNames = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];

        // evaluate the number of years and remaining months
        const years = Math.floor(months / 12);
        const remainingMonths = months % 12;

        // construct the human-readable string if the years are less than 1 then just show months
        if (years < 1) {
            return `${remainingMonths} month${remainingMonths === 1 ? '' : 's'}`;
        }
        let result = `${years} year${years === 1 ? '' : 's'}`;
        if (remainingMonths > 0) {
            result += ` and ${remainingMonths} month${remainingMonths === 1 ? '' : 's'}`;
        }

        return result;
    }


    return (
        <>
            <div>
                {monthsToHumanReadable(months)}
            </div>
        </>
    )
}

export default TimeBar