import React from "react";
import PolicyUpdates from "./PolicyUpdates";

export default function PolicyUpdatesAndEvents({alert}) {
  
  document.title = "Policy Updates and Events";

  return (
    <div className="page-content">
      <PolicyUpdates alert={alert} />
    </div>
  );
}
