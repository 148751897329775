import React from 'react'
import styles from './styles/policy_landscaping.module.css'



const PolicyTableCell = ({ active, className,stage_index,policy }) => {

    const canShowPolicy = () => {
        if (stage_index>policy.starts_at && stage_index<policy.ends_at){
            return true
        }
        if(stage_index==policy.ends_at){
            return true
        }
        if(stage_index==policy.starts_at){
            return true
        }
    }
    return (
        <div
            className={`${className} ${styles.policyTableCell}`}
        >
            <div className={`${styles.policyProgressIndicator} ${styles.flexRCcenterY}`}>
                <div className={styles.policyProgressIndicatorBar} style={{ width: `${ canShowPolicy() ? 100 : 0}%` }}>

                </div>
                {policy.currently_at.currently_at === stage_index? <div style={{left: `${policy.currently_at.coverage_percentage}%`}} className={styles.policyProgressIndicatorMarker}></div>:''}

                {policy.starts_at === stage_index? <div className={styles.policyStartEndMarker}></div>:''}
                {policy.ends_at === stage_index? <div className={`${styles.policyStartEndMarker} ${styles.policyStartEndMarkerEnd}`}></div>:''}


            </div>
            
        </div>
    )
}

export default PolicyTableCell