import { getETrackerMilestones } from "services/milestoneServices"
import React, { useState, useEffect } from "react";
import { Button, Card, CardBody, CardHeader, ModalHeader, Modal, ModalBody } from "reactstrap";
import { Link } from "react-router-dom";
import SearchBox from "components/Common/SearchBox";
import Pagination from "components/Common/Pagination";
import FormsTable from "../FormsTable";
import LoadingSpinner from "components/Common/Loading-Spinner";
import { useSelector } from "react-redux";
import useDebounce from "hooks/useDebounce";
import { StyledContainer } from "pages/Labels/Systems";
import {
  deleteETrackerMilestone
} from "../../../services/milestoneServices"
import ExportTable from "../../../components/Common/ExportTable";
import { exportTableData } from "services/common";
import ETrackerFilters from "./Filters";

const ETrackerMilestone = ({ alert }) => {

  const { user } = useSelector(state => state.Profile);

  const [searchField, setSearchField] = useState("");
  const debouncedSearchTerm = useDebounce(searchField);
  // const [deletingRowID, setDeletingRowID] = useState(null)

  const searchChange = e => {
    setSearchField(e.target.value.toLowerCase());
    setOffset(0);
    setCurrentPage(1);
  };

  const [selectedStages, setSelectedStages] = useState([]);
  const [modalFilter, setModalFilter] = useState(false);
  const toggleFilter = () => setModalFilter(prevState => !prevState);

  const applyFilters = (stages) => {
    setSelectedStages(stages);
    toggleFilter();
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [totalEntries, setTotalEntries] = useState(0);
  const [offset, setOffset] = useState(0);

  const goToPage = page => {
    if (page > 0) {
      const offsetParam = page * 50 - 50;
      setOffset(offsetParam);
      setCurrentPage(page);
    } else {
      console.error("Page number should be greater than 0");
    }
  };

  const checkForFilters = () => Boolean(selectedStages.length)

  const clearFilters = () => setSelectedStages([]);

  // Manage loading, error, and milestonesData states
  const [milestonesData, setMilestonesData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    localStorage.setItem("formType", "ETracker");
  }, []);

  const fetchMilestones = async () => {
    setIsLoading(true);
    const filters = {
        limit: 50, 
        offset,
        searchField: debouncedSearchTerm,
        stages: selectedStages?.map(stage=>stage.value)
    }
    try {
      const { data, count } = await getETrackerMilestones(filters);
      setMilestonesData(data);
      setTotalEntries(count);
    } catch (error) {
      console.error(error);
      alert("Fetching data", false);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchMilestones();
  }, [selectedStages, debouncedSearchTerm, currentPage]);

  const [deleteModal, setDeleteModal] = useState({
    show: false,
    form: {},
  });

  const handleDeleteMilestone = async (id) => {

    // if (deletingRowID === null) return; // Ensure we have a valid ID

    const selectedRowData = milestonesData?.find((row) => row.id === id);

    try {
      const response = await deleteETrackerMilestone(selectedRowData.id);

      if (response && response.message) {
        alert(response.message, true);
      } else {
        alert("Milestone Deletion Failed", false);
      }
    } catch (err) {
      console.error(err);
      alert("Milestone Deletion Failed", false);
    } finally {
      setDeleteModal({ show: false });
      fetchMilestones();
    }
  };

  const exportTable = async type => {
    try {
      const query = {
        export_format: type,
      };
      exportTableData(query, "ETracker_Milestones")
    } catch (err) {
      console.error(err)
      alert("Data export", false)
    }
  }

  const initiateEdit = (id) => {
    const selectedRowData = milestonesData?.find((row) => row.id === id);
    if (selectedRowData) {
      localStorage.setItem("MilestoneRecordData", JSON.stringify(selectedRowData));
    }
  };

  // Function to open the delete modal
  // const openDeleteModal = (id) => {
  //   setDeletingRowID(id); // Set the ID of the milestone to delete
  //   setDeleteModal({ show: true }); // Show the delete modal
  // };

  return (
    <div>
      <Card>
        <CardHeader className="bg-white">
          {/*<h4><i className="bx bx-file"></i> {title}</h4>*/}
          <div className="d-flex justify-content-between">
            <StyledContainer>
              <SearchBox searchChange={searchChange} />
              <Button
                className="common-button"
                onClick={toggleFilter}
              >
                <i className="mdi mdi-filter-variant me-1"></i>
                Filter
              </Button>

              <Modal isOpen={modalFilter} toggle={toggleFilter} className="modal-lg">
                <ModalHeader toggle={toggleFilter}>
                  <i className="mdi mdi-filter-variant me-1"></i>
                  Filters
                </ModalHeader>
                <ModalBody>
                  <ETrackerFilters
                    filteredStages={selectedStages}
                    applyFilters={applyFilters}
                  />
                </ModalBody>
              </Modal>
              {/* <Select
                  options={sortOptions}
                  defaultValue={sortForm}
                  className="basic-single"
                  classNamePrefix="select"
                  placeholder="Sort by"
                  onChange={option => setSortForm(option?.value || "ASC")}
                /> */}
              {checkForFilters() && (
                <Button
                  className="common-button"
                  onClick={clearFilters}
                >
                  <i className="ph-x me-1"></i>
                  Clear All Filters
                </Button>
              )}
            </StyledContainer>
            {user.is_admin && (
              <div>
                <Link to={{
                  pathname: "/create-form",
                  state: { formType: "ETracker" } // Pass the formType in state
                }}>
                  <Button className="common-button">+ Create New</Button>
                </Link>
              </div>
            )}
            <ExportTable loadFilteredData={exportTable}></ExportTable>
          </div>
        </CardHeader>
        {isLoading && <LoadingSpinner />}
        <CardBody>
          {milestonesData?.length > 0 ? (
            <>
              <FormsTable
                forms={milestonesData || []}
                userRole={user.role}
                formType={"ETracker"}
                initiateEdit={initiateEdit}
                onDeleteClick={handleDeleteMilestone}
                caption="ETracker"
                adminCountryLength={user?.user_country?.length}
              />
              <Pagination
                currentPage={currentPage}
                goToPage={goToPage}
                totalEntries={totalEntries}
              />
            </>
          ) : null}
        </CardBody>
      </Card>
    </div>
  );
};

export default ETrackerMilestone;

