import { Country } from "constants/global"
import React, { useState, useEffect } from "react"
import ReactApexChart from "react-apexcharts"
import LoadingSpinner from "components/Common/Loading-Spinner"

const filterMap = new Map([
  ["number_of_policies", "Number of Policies"],
  ["policy_win", "Policy Wins"],
  // ["days_past_deadline", "Past Deadline"],
  ["policy_score", "Avg. Policy Score"],
])
export default function LocationStatsGraph({ data, filterValue, isLoading }) {
  if (isLoading) return <LoadingSpinner />
  const keys = Object.keys(data)
  const [values, setValues] = useState([])
  const [name, setName] = useState("")
  const countryNames = keys?.map(countryCode => Country[countryCode])

  useEffect(() => {
    setValues(Object.values(data)?.map(obj => obj[filterValue]))
    setName(filterValue)
  }, [data, filterValue])
  const options = {
    chart: {
      type: "bar",
      height: 350,
    },
    colors: ["#49a839"],
    xaxis: {
      categories: countryNames,
      labels: {
        rotate: 0,
        hideOverlappingLabels: false,
        style: {
          fontSize: "14px",
        },
      },
    },
    yaxis: {
      title: {
        text: filterMap.get(name),
        style: {
          fontSize: "16px",
          fontWeight: 500,
        },
      },
      forceNiceScale: true,
      decimalsInFloat: 0,
      tickAmount: Math.min(...values) >= 10 ? Math.min(...values) / 5 : 2,
      labels: {
        formatter: val => Math.floor(val),
        style: {
          fontSize: "14px",
        },
      },
    },
  }

  const series = [
    {
      name: filterMap.get(name),
      data: values,
    },
  ]
  return (
    <div
      style={{
        marginBlock: "3rem",
        boxShadow: "var(--shadow-elevation-low)",
      }}
    >
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height={350}
      />
    </div>
  )
}
