import React from "react";
import Visualisation from "./Visualisation";
import BenchmarkingTabs from "./BenchmarkingTabs";

const Benchmarking = ({alert}) => {
  
  if(window.location.pathname === '/home-benchmarking'){
    return <Visualisation alert={alert}/>
  }else{
    return  <BenchmarkingTabs alert={alert}/>
  }
}

export default Benchmarking
