import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const SystemsChart = ({chartData, label,title}) => {
  // Data for the chart
  const systems = chartData?.system_name;

  const data = {
    labels: systems,
    datasets: [
      {
        label: label?.textField,
        data: chartData?.[label?.dataField],
        backgroundColor: '#3cc4d4',  // Color of the bars
        borderRadius: 5,  // Make the bars have rounded corners
        borderSkipped: false,  // Remove the border skip on top/bottom of bars
      },
    ],
  };

  // Chart options
  const options = {
    indexAxis: 'y',  // This makes the chart horizontal
    scales: {
      x: {
        beginAtZero: true,
        title: {
          display: true,
          text: label?.textField,
          color: 'green',
        },
        ticks: {
          color: 'green',
        },
      },
      y: {
        ticks: {
          color: 'green',
        },
      },
    },
    plugins: {
      legend: {
        display: false,  // Remove the legend
      }
    },
    maintainAspectRatio: false,  // Ensure it can adjust based on parent container
    responsive: true,
  };

  const chartHeight = systems?.length * 25;

  return (
    <div className="chart-container">
        <h3 className="section-title">{title?title : `Policies across Systems`}</h3>
        <div style={{ width: '400px', height: `${chartHeight}px` }}> 
            <Bar data={data} options={options} />
        </div>
    </div>
  );
};

export default SystemsChart;