import { Icon } from '@iconify/react';
import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/User-Profile/UserProfile"

// Dashboard
import Dashboard from "../pages/Dashboard/index"

// Access Code
import AccessCodeList from "../pages/Access-Codes/AccessCodeList/access-codes-list"
import AccessCodeDetail from "../pages/Access-Codes/AccessCodeDetail/access-code-detail"

import Tracker from "pages/E-Tracker/index"
import Index from "pages/FormManagement"
import TasksIndex from "pages/Tasks"
import FAQ from "pages/FAQ"
import { Policy } from "pages/Policy/policy"

import Audit from "pages/Audit/Audit"
import Policies from "pages/PolicyDirectory/PolicyDirectory-management"

import StatisticsPolicyLandscaping from "pages/Statistics/index"

import NewsInsights from "pages/Qualitative/index"

import DataIntelligence from "pages/DataIntelligence"

import Trends from "pages/Trends"

import Pages404 from "../pages/Utility/pages-404"
import Pages500 from "../pages/Utility/pages-500"
import FillForm from "pages/E-Tracker/fill-form"
import FillFormBenchmarking from "pages/Benchmarking/FillForm/FillForm"
import FillFormLandscaping from "pages/Landscaping/FillForm"
import FillFormEtracker from "pages/E-Tracker/fill-form"
import OrganizationManagement from "pages/Organization-Management/Organization-Management"
import SystemsManagement from "pages/Labels/Systems-Management"
import PartnerManagement from "pages/Partner-Management/Partner-Management"
import PolicyUpdatesAndEvents from "pages/Policy-UpdatesAnd-Events/PolicyUpdates-Management"
import EditMilestone from "pages/FormManagement/EditMilestone/Edit-Milestone"
import CreateMilestone from "pages/FormManagement/CreateMilestone/Create-Milestone"
import { Welcome } from "pages/Onboarding/Welcome"
import Landscaping from "../pages/Landscaping/Landscaping"
import LandscapingVisual from "../pages/Landscaping/LandscapingVisual"
import Benchmarking from "pages/Benchmarking/Benchmarking"
import dataIntelligenceIcon from '@iconify/icons-mdi/chart-line';
import taskIcon from '@iconify/icons-mdi/clipboard-check-outline';
import benchmarkingIcon from '@iconify/icons-mdi/chart-box-outline';
import editIcon from '@iconify/icons-mdi/pencil-outline'
import policyDirectoryIcon from '@iconify/icons-mdi/file-search-outline'
import groupIcon from '@iconify/icons-mdi/account-group-outline'
import policyUpdatesIcon from '@iconify/icons-mdi/calendar-text-outline'
import questionMarkIcon from '@iconify/icons-mdi/help-circle-outline'
import systemsManagementIcon from '@iconify/icons-mdi/cog-outline'
import trackingIcon from '@iconify/icons-mdi/clipboard-text-outline'
import userManagementIcon from '@iconify/icons-mdi/account-cog-outline'
import buildingIcon from '@iconify/icons-mdi/office-building-outline'
import auditIcon from '@iconify/icons-mdi/history'

import DashNew from "pages/Dashboard/DashNew"
import UserProfileDetail from "../pages/UserManagement/UserDetail"
import UserManagement from "../pages/UserManagement/User"
import PolicyForm from 'pages/Policy/Form';
import HomePolicies from "pages/Policy/HomePolicies"
import HomePolicyUpdates from "pages/Policy-UpdatesAnd-Events/HomePolicyUpdates"

const authProtectedRoutes = [
  {
    path: "/data-intelligence",
    component: DataIntelligence,
    title: "Data Intelligence",
    icon: <Icon icon={dataIntelligenceIcon} className="me-2" width="20" height="20" />,
  },
  {
    path: "/create-policy",
    component: PolicyForm,
    title: "Create Policy",
  },
  {
    path: "/policy/edit/:policyID",
    component: PolicyForm,
    title: "Edit Policy",
  },
  //profile
  {
    path: "/profile",
    component: UserProfile,
    title: "Profile Information",
    icon: <Icon icon={"bx-user"} className="me-2" width="20" height="20" />,
  },

  // Access Code
  {
    path: "/access-code-list",
    component: AccessCodeList,
    title: "Access Codes",
    icon: "bx-lock-open",
  },
  {
    path: "/access-code-detail/:id",
    component: AccessCodeDetail,
    title: "Access Code Information",
    icon: "bx-lock-open",
  },
  //Partners
  {
    path: "/partner-management",
    component: PartnerManagement,
    title: "Partner Management",
    icon: <Icon icon={groupIcon} className="me-2" width="20" height="20" />,
  },
  //Policy Updates and Events
  {
    path: "/policy-updates-and-events-management",
    component: PolicyUpdatesAndEvents,
    title: "Policy Updates and Events",
    icon: <Icon icon={policyUpdatesIcon} className="me-2" width="20" height="20" />,
  },
  //Organization
  {
    path: "/organization-management",
    component: OrganizationManagement,
    title: "Organization Management",
    icon: <Icon icon={buildingIcon} className="me-2" width="20" height="20" />,
  },
  // User
  {
    path: "/user-management",
    component: UserManagement,
    title: "Users",
    icon: <Icon icon={userManagementIcon} className="me-2" width="20" height="20" />,
  },
  {
    path: "/user-management/:id",
    component: UserProfileDetail,
    title: "User Information",
    icon: <Icon icon={"bx-user"} className="me-2" width="20" height="20" />,
  },

  //Policy
  {
    path: "/policy/:id",
    component: Policy,
    title: "Policy Lifecycle Management",
  },

  // Form Management
  {
    path: "/forms",
    component: Index,
    title: "Tracking and Assessment Management",
    icon: <Icon icon={trackingIcon} className="me-2" width="20" height="20" />,
  },
  // {
  //   path: "/benchmarking-forms",
  //   component: BenchmarkingMilestone,
  //   title: "Benchmarking Milestone Management",
  //   icon: "bx-edit",
  // },
  //  {
  //   path: "/e-tracker-forms",
  //   component: ETrackerMilestone,
  //   title: "ETracker Milestone Management",
  //   icon: "bx-edit",
  // },

  {
    path: "/create-form",
    component: CreateMilestone,
    title: "Milestone Questions",
    icon: <Icon icon={"bx-edit"} className="icon-size" width="20" height="20" />,
  },
  {
    path: "/edit-form",
    component: EditMilestone,
    title: "Edit Milestone",
      icon: <Icon icon={"bx-edit"} className="icon-size" width="20" height="20" />,
  },
  {
    path: "/forms/:policyId/:stageId/:formId",
    component: FillForm,
    title: "Fill Milestone Data",
    icon: <Icon icon={"bx-edit"} className="icon-size" width="20" height="20" />,
  },
  {
    path: "/system-forms/:countryId/:milestoneId",
    component: FillFormBenchmarking,
    title: "Benchmarking Forms",
    icon: <Icon icon={"bx-edit"} className="icon-size" width="20" height="20" />,
  },
  // {
  //   path: "/system-forms/:countryId/:milestoneId/:ownerId/:supervisorId",
  //   component: FillFormBenchmarking,
  //   title: "Benchmarking Forms"
  // },
  // {
  //   path: "/system-forms/:countryId/:focalAreaId",
  //   component: FillFormLandscaping,
  // },
  {
    path: "/landscaping-forms/:countryId/:focalAreaId",
    component: FillFormLandscaping,
    title: "Landscaping Forms",
    icon: <Icon icon={"bx-edit"} className="icon-size" width="20" height="20" />,
  },

  {
    path: "/etracker-forms/:policyId/:stageId",
    component: FillFormEtracker,
    title: "E-Tracker Forms",
    icon: <Icon icon={"bx-edit"} className="icon-size" width="20" height="20" />,
  },

  //Indicators
  {
    path: "/labels",
    component: SystemsManagement,
    title: "Systems Management",
    icon: <Icon icon={systemsManagementIcon} className="me-2" width="20" height="20" />,
  },

  //Policy Directory
  {
    path: "/policy-directory",
    component: Policies,
    title: "Policy Directory",
    icon: <Icon icon={policyDirectoryIcon} className="me-2" width="20" height="20" />,
  },

  // My Tasks
  {
    path: "/my-tasks",
    component: TasksIndex,
    title: "Tasks",
    icon: <Icon icon={taskIcon} className="me-2" width="20" height="20" />,
  },

  // //Questionnaire
  // {
  //   path: "/questionnaire/:countryId/:labelId/:formId", // Route for the generated questionnaire
  //   component: FillFormBenchmarking,
  //   title: "Generated Questionnaire",
  //   icon: <Icon icon={"bx-edit"} className="icon-size" width="20" height="20" />,
  // },
  {
    path: "/dashboard",
    component: DashNew,
    title: "Home Page",
    icon: <Icon icon={"ph-house-line"} className="icon-size" width="20" height="20" />,
  },

  {
    path: "/frequently-asked-questions",
    component: FAQ,
    title: "Frequently Asked Questions",
    icon: <Icon icon={questionMarkIcon} className="me-2" width="20" height="20" />,
  },
  {
    path: "/benchmarking",
    component: Benchmarking,
    title: "Benchmarking",
    icon: <Icon icon={benchmarkingIcon} className="me-2" width="20" height="20" />,
  },
  {
    path: "/policy-landscape",
    component: Landscaping,
    title: "Policy Landscaping",
    icon: <Icon icon={"bx-file"} className="me-2" width="20" height="20" />,
  },
  {
    path: "/policy-progress",
    component: LandscapingVisual,
    title: "Policy Tracking",
    icon: <Icon icon={"bx-file"} className="me-2" width="20" height="20" />,
  },
  {
    path: "/e-tracker",
    component: Tracker,
    title: "E-Tracker",
    icon: <Icon icon={editIcon} className="me-2" width="20" height="20" />,
  },
  //Tasks Management
  // {
  //   path: "/tasks",
  //   component: TasksManagement,
  //   title: "Tasks Management",
  //   icon: "ph-check-square-offset",
  // },

  //Audit
  {
    path: "/audit",
    component: Audit,
    title: "Audit Logs",
    icon: <Icon icon={auditIcon} className="me-2" width="20" height="20" />,
  },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/dashboard" />
  },
]

const publicRoutes = [
  { path: "/login", component: Welcome },
  { path: "/dash-new", component: DashNew },
  { path: "/forgot-password", component: Welcome },
  { path: "/reset-password", component: Welcome },
  { path: "/register/:token?", component: Welcome },
  { path: "/pages-404", component: Pages404 },
  { path: "/pages-500", component: Pages500 },
  {
    path: "/",
    component: Dashboard,
    title: "Home",
    icon: "bx-home-circle"
  },
  {
    path: "/home-frequently-asked-questions",
    component: FAQ,
    title: "Frequently Asked Questions",
  },
  {
    path: "/home-benchmarking",
    component: Benchmarking,
    title: <Icon icon={benchmarkingIcon} width="20" />,
  },
  {
    path: "/home-landscape",
    component: Landscaping,
    title: "Policy Landscaping",
    icon: "bx-file",
  },
  { path: "/home-tracker", component: DashNew },
  { path: "/home-policies", component: HomePolicies },
  { path: "/home-policy-updates", component: HomePolicyUpdates },


]

const reportRoutes = [
  {
    path: "/statistics/e-tracker",
    component: Tracker,
    title: "Data Intelligence Dashboard",
    icon: <Icon icon={"ph-chart-line"} className="me-2" width="20" height="20" />,
  },
  {
    path: "/statistics/policy-landscaping",
    component: StatisticsPolicyLandscaping,
    title: "Policy Landscaping",
    icon: <Icon icon={"bx-search"} className="me-2" width="20" height="20" />,
  },
  {
    path: "/qualitative/news-insights",
    component: NewsInsights,
    title: "Data Intelligence Dashboard",
    icon: <Icon icon={"ph-chart-line"} className="me-2" width="20" height="20" />,
  },
  {
    path: "/trends",
    component: Trends,
    title: "Data Intelligence Dashboard",
    icon: <Icon icon={"ph-chart-line"} className="me-2" width="20" height="20" />,
  },
]

export { publicRoutes, authProtectedRoutes, reportRoutes }
