import React, { useEffect, useRef, useState } from "react"
import { Alert, Input, Label, Form, FormFeedback } from "reactstrap"
// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

// action
import { registerUser, apiError, registerUserFailed } from "../../store/actions"

//redux
import { useSelector, useDispatch } from "react-redux"

import { Link } from "react-router-dom"
import { Redirect } from "react-router"

// import images
import ReCAPTCHA from "react-google-recaptcha"
// import loginLogo from "assets/images/new-logo.svg"
import { FiArrowRight } from "react-icons/fi"
import PasswordStrengthBar from "react-password-strength-bar"
const Register = props => {
  //meta title
  // document.title = "Register"

  // const [organizationName, setOrganizationName] = useState("")
  // const [userData, setUserData] = useState({})
  // const {
  //   match: { params },
  // } = props
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const userToken = urlParams.get("token")
  const dispatch = useDispatch()
  const captchaRef = useRef(null)
  const [enable, setEnable] = useState(false)

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
      password: "",
      confirm_password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password").min(4),
      confirm_password: Yup.string()
        .label("confirm password")
        .required()
        .oneOf([Yup.ref("password"), null], "Passwords must match"),
      // organization: Yup.string().required("Please Enter Your Organization"),
    }),
    onSubmit: values => {
      setEnable(true)
      if (captchaRef.current.getValue() == "") {
        dispatch(registerUserFailed("Please Fill recaptcha"))
      } else if (!userToken) {
        dispatch(registerUserFailed("Invalid Request"))
      } else {
        const userObject = {
          token: userToken ?? "",
          email: values["email"],
          password: values["password"],
          confirm_password: values["confirm_password"],
        }
        const token = captchaRef.current.getValue()
        values.recaptchaValue = token
        // setUserData(user)
        dispatch(registerUser(userObject))
        validation.resetForm()
      }

      captchaRef.current.reset()
    },
  })

  const { user, registrationError, loading } = useSelector(state => ({
    user: state.Account.user,
    registrationError: state.Account.registrationError,
    loading: state.Account.loading,
  }))

  useEffect(() => {
    dispatch(apiError(""))
  }, [])

  return (
    <React.Fragment>
      {/* <div className="bg-auth"> */}
        {/* <div className="flex flex-row w-full h-full pl-24 justify-start items-center"> */}
          {/* <div className="flex flex-col"> */}
            {/* <div className="rounded-[2.25rem] w-[13.5rem] px-3 py-2 bg-white mb-4">
              <img
                src={loginLogo}
                alt=""
                className="rounded-3xl max-w-full h-auto"
              />
            </div> */}
            <div className="bg-white rounded-2xl">
              <div className="p-4 pt-4 pb-4">
                <div className="text-primary pb-3 ml-0">
                  <h1 className="text-primary text-2xl ml-0">Register</h1>
                </div>
                <Form
                  className=""
                  onSubmit={e => {
                    e.preventDefault()
                    validation.handleSubmit()
                    return false
                  }}
                >
                  {user && user ? (
                    <>
                      <Alert color="success">Register User Successfully</Alert>
                      <Redirect to="/login" />
                    </>
                  ) : null}
                  {registrationError && registrationError ? (
                    <Alert color="danger">{registrationError}</Alert>
                  ) : null}

                  <div className="mb-3">
                    <Label className="form-label text-primary ml-0 font-medium">Email</Label>
                    <Input
                      id="email"
                      name="email"
                      className="form-control h-12 rounded-lg"
                      placeholder="Enter email"
                      type="email"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.email || ""}
                      invalid={
                        validation.touched.email && validation.errors.email
                          ? true
                          : false
                      }
                    />
                    {validation.touched.email && validation.errors.email ? (
                      <FormFeedback type="invalid">
                        {validation.errors.email}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div className="mb-2">
                    <Label className="form-label text-primary ml-0 font-medium">Password</Label>
                    <Input
                      name="password"
                      type="password"
                      placeholder="Enter Password"
                      className="h-12 rounded-lg"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.password || ""}
                      invalid={
                        validation.touched.password &&
                        validation.errors.password
                          ? true
                          : false
                      }
                    />
                    {validation.touched.password &&
                    validation.errors.password ? (
                      <FormFeedback type="invalid">
                        {validation.errors.password}
                      </FormFeedback>
                    ) : null}
                    <div className="mt-3">
                      <PasswordStrengthBar
                        shortScoreWord=""
                        password={validation.values.password}
                      />
                    </div>
                  </div>
                  <div className="mb-3">
                    <Label className="form-label text-primary ml-0 font-medium">
                      Confirm password
                    </Label>
                    <Input
                      name="confirm_password"
                      type="password"
                      placeholder="Enter Password"
                      className="h-12 rounded-lg"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.confirm_password || ""}
                      invalid={
                        validation.touched.confirm_password &&
                        validation.errors.confirm_password
                          ? true
                          : false
                      }
                    />
                    {validation.touched.confirm_password &&
                    validation.errors.confirm_password ? (
                      <FormFeedback type="invalid">
                        {validation.errors.confirm_password}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <ReCAPTCHA
                      sitekey={process.env.REACT_APP_SITE_KEY}
                      ref={captchaRef}
                    />
                  </div>
                  <div className="d-grid">
                    <button
                      className="btn btn-primary btn-block rounded-[2.25rem] text-[#FFF605] flex flex-row items-center justify-center"
                      type="submit"
                    >
                      Register
                      <div className="rounded-full flex justify-center items-center w-4 h-4 bg-[#EAE200] ml-1">
                        <FiArrowRight color="#036F36" />
                      </div>
                    </button>
                  </div>
                </Form>
                <div className="mt-3 text-center">
                  <p>
                    Already have an account?
                    <Link
                      to="/login"
                      className="font-weight-medium text-primary ml-1"
                    >
                      Login
                    </Link>
                  </p>
                </div>
              </div>
            </div>
    </React.Fragment>
  )
}

export default Register
