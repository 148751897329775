import { downloadTable } from "components/Common/download";
import {
  EXPORT_AUDIT,
  EXPORT_BENCHMARKING_MILESTONES, EXPORT_BENCHMARKING_TASKS,
  EXPORT_COUNTRY_BENCHMARKING_MILESTONES, EXPORT_COUNTRY_LANDSCAPING_FOCAL_AREAS,
  EXPORT_ETRACKER_MILESTONES,
  EXPORT_ETRACKER_TASKS, EXPORT_LANDSCAPING_FOCAL_AREAS, EXPORT_LANDSCAPING_TASKS,
  EXPORT_ORGANISATIONS,
  EXPORT_PARTNERS,
  EXPORT_POLICIES, EXPORT_POLICIES_PUBLIC,
  EXPORT_SYSTEMS,
  EXPORT_USERS
} from "./CONSTANTS"
import { get } from "./AXIOS";

export const exportTableData = (query, entity) => {

    const entities = {
      "ETracker_Milestones": EXPORT_ETRACKER_MILESTONES,
      "Benchmarking_Milestones": EXPORT_BENCHMARKING_MILESTONES,
      "Country_Benchmarking_Milestones": EXPORT_COUNTRY_BENCHMARKING_MILESTONES,
      "Country_Landscaping_Focal_Areas": EXPORT_COUNTRY_LANDSCAPING_FOCAL_AREAS,
      "Audit": EXPORT_AUDIT,
      "Organisations": EXPORT_ORGANISATIONS,
      "Systems": EXPORT_SYSTEMS,
      "Partners": EXPORT_PARTNERS,
      "Policies": EXPORT_POLICIES,
      "Policies_Public": EXPORT_POLICIES_PUBLIC,
      "Users": EXPORT_USERS,
      "ETracker_Tasks": EXPORT_ETRACKER_TASKS,
      "Benchmarking_Tasks": EXPORT_BENCHMARKING_TASKS,
      "Landscaping_Tasks": EXPORT_LANDSCAPING_TASKS,
      "Landscaping_FocalAreas": EXPORT_LANDSCAPING_FOCAL_AREAS,
    }
    
    const queryString = new URLSearchParams(query).toString();
  
    const url = `${entities[entity]}?${queryString}`;
    
    get(url, { responseType: 'blob' })
    .then(file=>{
      downloadTable(file, entity, query.export_format);
    })
    .catch(err=>console.error(err)); 
};