import React from 'react'
import styles from './styles/policy_landscaping.module.css'
import PolicyTrackingTableCell from './PolicyTrackingTableCell';
import MileStonePopUp from './MileStonePopUp';


const PolicyTrackingTable = ({ label, stages,countries }) => {

    const randomColor = () => {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
          color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
      }


    return (
        <div className={`${styles.policyLandscaping}`}>

            <div className={` ${styles.flexR} ${styles.flexRCcenterY}`}>
                <div className={`${styles.flexG} ${styles.flexC}`}>
                    <h1 className={`${styles.policyBold} ${styles.policyHeading}`}>
                        {label}
                    </h1>
                    <div className={`${styles.glexG} ${styles.dividerT}`}>
                    </div>
                </div>
                <div>
                <img
                    src="/static/media/agra_logo2_cropped.41762811ca376b496d62.png"
                    alt="Logo"
                    width={90}
                    height={45}
                    className={styles.logoImage} // Apply the custom styles
                />
                </div>
            
            </div>

            <div>
            </div>

            <div className={styles.tableScroll}>
                <table className={styles.policyTrackingTable}>
                    <tbody>
                        {countries?.map((country, c_index) => (
                            country.policies?.map((policy, index) => (
                                <tr key="{c_index}-{index}">
                                    <td style={{ width: "33.33%" }}>{policy.name}</td>
                                    <td style={{ width: "66.66%" }} className={styles.visualCell}>
                                        <PolicyTrackingTableCell currently_at={policy.currently_at.currently_at} coverage_percentage={policy.currently_at.coverage_percentage} />
                                    </td>
                                </tr>
                            ))
                        ))}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td>

                            </td>
                            <td className={styles.policyStageMilestonesRCell}>
                                <div className={`${styles.flexR} ${styles.flexRCcenterY} `}>
                                    {
                                        stages?.map((stage, index) => (
                                            <div key={`stage-${index}`} className={`${styles.flexG} ${styles.flexC} ${styles.borderX} ${styles.pSt}`}>

                                                <div className={`${styles.flexR} ${styles.flexRCcenterY} ${styles.flexGapSm}`}>
                                                    {stage.milestones?.map((milestone, m_index) => (
                                                        <div key={milestone.name} className={`${styles.flexG} ${styles.milestoneIndicator}`} style={{backgroundColor: randomColor()}}>
                                                            <MileStonePopUp hoverConent={``} mainContent={milestone}/>
                                                        </div>
                                                    ))}
                                                </div>

                                                <p className={`${styles.policyBold} ${styles.textCenter}`}>
                                                {stage.name}
                                                </p>

                                            </div>
                                        ))
                                    }  
                                </div> 
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>

    )

}


export default PolicyTrackingTable
