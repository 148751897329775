import React, { useState } from "react";
import { Button } from "reactstrap";
import { FaTrashAlt, FaEdit } from "react-icons/fa";
import "./StepThree.css";
import styled from "@emotion/styled"

const StyledButton = styled(Button)`
  width: 200px;
  margin: 30px auto;
  // display: block;
  display: flex;
  align-items: center; /* Align icon and text vertically */
  justify-content: center; /* Center text and icon */
  gap: 8px; 
`;

const StepThree = ({ questions, setQuestions, save, createQuestionnaire }) => {
  const [editIndex, setEditIndex] = useState(null);
  const [editedQuestion, setEditedQuestion] = useState("");

  const handleEditClick = (index, questionText) => {
    setEditIndex(index);
    setEditedQuestion(questionText);
  };

  const handleSaveEdit = (index) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index].question_text = editedQuestion;
    setQuestions(updatedQuestions);
    setEditIndex(null);
  };

  const handleDelete = (index) => {
    const updatedQuestions = questions.filter((_, i) => i !== index);
    setQuestions(updatedQuestions);
  };

  const toggleRequired = (index) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index].required = !updatedQuestions[index].required;
    setQuestions(updatedQuestions);
  };

  const handleSaveAndCreateQuestionnaire = async () => {
    await save();
    createQuestionnaire(questions);
  };

  return (
    <div className="step-three-container">
      {questions.map((question, index) => (
        <div className="question-card-preview" key={index}>
          {editIndex === index ? (
            <input
              type="text"
              value={editedQuestion}
              onChange={(e) => setEditedQuestion(e.target.value)}
              className="edit-question-input"
            />
          ) : (
            <p className="question-text-preview">{question.question_text}</p>
          )}

          <div className="required-container">
            <label className="required-label">Required question?</label>
            <div className="switch-container">
              <input
                type="checkbox"
                className="toggle-switch"
                checked={question.required}
                onChange={() => toggleRequired(index)}
              />
              <span>{question.required ? "Yes" : "No"}</span>
            </div>
          </div>

          {/* For Benchmarking and Landscaping: Evidence file upload preview */}
          {question.evidenceRequired && (
            <div className="file-upload">
              <label className="required-label">Upload Evidence</label>
              <input type="file" className="file-upload-input" />
            </div>
          )}

          <div className="button-container">
            <Button
              color="danger"
              className="delete-question-btn"
              onClick={() => handleDelete(index)}
              style={{ display: 'flex', alignItems: 'center', gap: '2px' }}
            >
              <FaTrashAlt className="delete-icon-inline" /> Delete 
            </Button>
            {editIndex === index ? (
              <Button
                color="success"
                className="save-question-btn"
                onClick={() => handleSaveEdit(index)}
                style={{ display: 'flex', alignItems: 'center', gap: '2px' }}
              >
                Save
              </Button>
            ) : (
              <Button
                color="primary"
                className="edit-question-btn"
                onClick={() => handleEditClick(index, question.question_text)}
                style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
              >
                <FaEdit className="edit-icon-inline" /> Edit
              </Button>
            )}
          </div>
        </div>
      ))}

      <div className="save-submit-container">
        <StyledButton className="left-button" color="primary" onClick={handleSaveAndCreateQuestionnaire}>
          Save and Submit <i className="ph-arrow-right"></i>
        </StyledButton>
      </div>
    </div>
  );
};

export default StepThree;
