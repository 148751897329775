import React from 'react';

const SearchBox = ({ searchChange }) => {
  return (
    <div style={{ width: "400px", marginLeft: "20px", position: "relative" }}>
      <form
        style={{
          display: 'flex',
          alignItems: 'center',
          border: '1px solid #036F36',
          borderRadius: '5px',  
          backgroundColor: '#ffffff',
          padding: '6px 12px',
          position: "relative"  
        }}
      >
        {/* Search Icon */}
        <span
          style={{
            color: "#036F36",
            fontSize: "18px",
            marginRight: "10px",
            display: "flex",    
            alignItems: "center",
          }}
          className="bx bx-search"
        />

        {/* Input Field */}
        <input
          onChange={searchChange}
          type="search"
          placeholder="Enter Text To Search"
          style={{
            backgroundColor: '#ffffff',
            border: 'none',  // Remove border for consistency
            fontSize: '14px',  // Match font-size
            width: '100%',
            height: '22px',  // Match height to line-height of common button
          }}
        />
      </form>
    </div>
  );
};

export default SearchBox;
