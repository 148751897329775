import { downloadFile } from "components/Common/download"
import { del, get, post, postFormData, putFormData } from "./AXIOS"
import { DOWNLOAD_POLICY, GET_COUNTRIES, POLICY, POLICY_UPDATES } from "./CONSTANTS"

export const getPolicyUpdates = policyID => get(`${POLICY_UPDATES}/${policyID}`)

// export const getOrganizations = (query) =>
//     get(`${GET_ORGANIZATIONS}?limit=${query.limit}&skip=${query.offset}`)

export const postPolicyUpdate = body => post(`${POLICY_UPDATES}`, body)

export const getPolicy = policyID => {
    return get(`${POLICY}${policyID}`);
}

export const getCountry = countryID => {
    return get(GET_COUNTRIES + countryID)
}

export const updatePolicy = (policyID, policyData, policyFile) => {
    const formData = new FormData();

    const policy_update_data = {
        policy_update_request_body: {
            name: policyData.policy_request_body.name,
            details: policyData.policy_request_body.details,
            ...(policyData.policy_request_body.country_id == 2 && {policy_type: policyData.policy_request_body.policy_type}),
            country_id: parseInt(policyData.policy_request_body.country_id),
            stage_id: parseInt(policyData.policy_request_body.stage_id),
            is_off_track: Boolean(policyData.policy_request_body.is_off_track),
            is_delayed: Boolean(policyData.policy_request_body.is_delayed),
            is_complete: Boolean(policyData.policy_request_body.is_complete),
            updates_and_emerging_outcomes: policyData.policy_request_body.updates_and_emerging_outcomes || ''
        },
        // Move system_id to system_ids array
        system_ids: policyData.system_ids,
        partner_update_request_bodies: policyData.partner_request_bodies.map(partner => ({
            short_name: partner.short_name || '',
            long_name: partner.long_name || '',
            is_technical_expert: Boolean(partner.is_technical_expert),
            id: parseInt(partner.id)
        }))
    };
  
    // Append the JSON data
    formData.append('policy_update_data', JSON.stringify(policy_update_data));
    
    // Append the file if it exists
    if (policyFile instanceof File) {
        formData.append('policy_file', policyFile);
    }

    return putFormData(`${POLICY}${policyID}`, formData);
}

export const deletePolicyService = policyID => {
    return del(`${POLICY}${policyID}`);
} 

export const createPolicyService = (policyData, policyFile) => {
    const formData = new FormData();

    const policy_create_data = {
        policy_create_request_body: {
            name: policyData.policy_request_body.name,
            details: policyData.policy_request_body.details,
            ...(policyData.policy_request_body.country_id == 2 && {policy_type: policyData.policy_request_body.policy_type}),
            country_id: parseInt(policyData.policy_request_body.country_id),
            stage_id: parseInt(policyData.policy_request_body.stage_id),
            is_off_track: Boolean(policyData.policy_request_body.is_off_track),
            is_delayed: Boolean(policyData.policy_request_body.is_delayed),
            is_complete: Boolean(policyData.policy_request_body.is_complete),
            updates_and_emerging_outcomes: policyData.policy_request_body.updates_and_emerging_outcomes || ''
        },
        // Move system_id to system_ids array
        system_ids: policyData.system_ids,
        partner_create_request_bodies: policyData.partner_request_bodies.map(partner => ({
            short_name: partner.short_name || '',
            long_name: partner.long_name || '',
            is_technical_expert: Boolean(partner.is_technical_expert),
            id: parseInt(partner.id)
        }))
    };
  
    // Append the JSON data
    formData.append('policy_create_data', JSON.stringify(policy_create_data));
    
    // Append the file if it exists
    if (policyFile) {
        formData.append('policy_file', policyFile);
    }

    return postFormData(POLICY, formData);
}

export const downloadPolicy = (policyID, filename, fileNameWithExt, alert) => {
    get(DOWNLOAD_POLICY(policyID), { responseType: "blob" })
    .then(file => downloadFile(fileNameWithExt, filename, file))
    .catch(error => {
        alert();
        console.error('Error downloading the file:', error);
    });
} 