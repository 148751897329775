import Select from "react-select"
import React, { useEffect, useState } from "react"
import { getStageOptions } from "common/SelectOptions/stageOptions";

const ETrackerFilters = ({
  filteredStages,
  applyFilters
}) => {
  const [stageOptions, setStageOptions] = useState([]);
  const [selectedStages, setSelectedStages] = useState(filteredStages);

  useEffect(() => {
    const fetchOptions = async () => {
      setStageOptions(await getStageOptions());
    };
    fetchOptions();
  }, []);

  return (
    <div>
      {/* Filter by Location */}
      <div className="d-flex my-2">
        <div className="d-flex align-items-center">
          <i className="mdi mdi-map-marker-outline me-1" />
          <h6 className="mb-0">Stages:</h6>
        </div>
        <div className="ms-2" style={{ width: "100%" }}>
          <Select
            isMulti
            options={stageOptions}
            defaultValue={selectedStages}
            className="basic-single"
            classNamePrefix="select"
            placeholder="Select systems"
            onChange={options => setSelectedStages(options)}
          />
        </div>
      </div>

      {/* Apply Filters Button */}
      <div className="d-flex" style={{ justifyContent: "center" }}>
        <button
          className="btn btn-success common-button"
          style={{ width: "90%" }}
          onClick={()=>
            applyFilters(
              selectedStages
            )}
        >
          Apply
        </button>
      </div>
    </div>
  )
}

export default ETrackerFilters
