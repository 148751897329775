import React, { useState, useEffect } from 'react';
import { MapContainer, GeoJSON, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import africaGeoJson from './data/africaGeo.json';
import L from 'leaflet';
import countriesFlag from 'common/countriesFlag';
import MapLegend from './MapLegend';
import { Button } from 'reactstrap';

const AfricaChoroplethMap = ({chartData, countryID, setCountryID, label}) => {
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [key, setKey] = useState(0);

    // Function to get color based on the number of policies
    const getColor = (value) => { 
      //TODO: Optimise this function??
      if(label?.dataField === 'number_of_policies' || label?.dataField === 'average_month_duration'){
        return value > 20 ? '#401201' :
              value > 15 ? '#A64005' :
              value > 10 ? '#F2811D' :
              value > 5 ? '#F29727' :
                            '#F2B33D'
      }else if(label?.dataField === 'average_score'){
        return value > 80 ? '#401201' :
              value > 60 ? '#A64005' :
              value > 40 ? '#F2811D' :
              value > 20 ? '#F29727' :
                            '#F2B33D'
        // return value > 1 ? '#401201' : '#F2B33D'
      }
    }; 

    // Function to style each country dynamically
    const countryStyle = (feature) => {
        const countryIndex = chartData?.long_name.indexOf(feature.properties.name);

        // If the country is not in the API data, return no color (transparent fill)
        if (countryIndex === -1) {
          return {
            fillColor: 'transparent', // No color for countries without data
            weight: 1,
            color: 'black',
            fillOpacity: 0.7,
          };
        }else{
          const value = chartData?.[label?.dataField]?.[countryIndex];
          return {
            fillColor: getColor(value),
            weight: 1,
            color: 'black',
            fillOpacity: 1,
          };
        }
    };
    
    // Handle click on a country
    const onCountryClick = (event) => {
        const layer = event.target;
        const countryName = layer.feature.properties.name;
        const countryIndex = chartData?.long_name.indexOf(countryName);

        if (countryIndex !== -1) {
            setCountryID(chartData?.country_id[countryIndex]);
            setSelectedCountry({
                ...layer.feature.properties, // Set the country properties
                feature: layer.feature
            });
        }
    };

  // Add popup information and hover effect
  const onEachCountry = (country, layer) => {
    const countryName = country.properties.name;
    const countryIndex = chartData?.long_name.indexOf(countryName);
    const tooltipText = `${countryIndex !== -1 ? 
                            chartData?.[label?.dataField]?.[countryIndex] + ' ' + label?.textField
                            : 'No data'
                          }`

    // Check if the flag image exists for the country
    const flagImage = countriesFlag[country.properties.postal]
        ? `<img src="${countriesFlag[country.properties.postal]}" alt="${countryName}" height="12" width="18" style="margin-right: 8px;" />`
        : '';

    const tooltipContent = `
        <div style="background-color: #F2F2A3; border-radius: 5px; width: max-content; padding: 2px">
            <div style="display: flex; align-items: center;">
                ${flagImage}
                <p>${countryName}</p>
            </div>
            <strong>${tooltipText}</strong>
        </div>
    `;

    layer.bindTooltip(tooltipContent, {
      permanent: false,
      sticky: true,
    });

    layer.on({
      click: onCountryClick, // Handle click event
      mouseover: (e) => {
        const layer = e.target;
        layer.setStyle({
          weight: 2,
          color: '#666',
          fillOpacity: 0.9,
        });
      },
      mouseout: (e) => {
        const layer = e.target;
        layer.setStyle(countryStyle(layer.feature)); // Reset style on mouse out
      },
    });
  };

    const MapOptions = () => {
        const map = useMap();
        
        useEffect(() => {
            map.setMinZoom(2);
            map.setMaxBounds([[-90, -180], [90, 180]]);
            
            // Remove attribution control
            map.attributionControl.remove();

            // Set background to transparent
            map.getContainer().style.background = 'none';

        }, [map]);

        return null;
    };

    useEffect(() => {
        setKey(prevKey => prevKey + 1);
    }, [chartData]);

  // Automatically select a country if `countryID` is provided
  useEffect(() => {
    if (countryID && countryID !== 0 && chartData) {
      const countryIndex = chartData?.country_id.indexOf(countryID);
      if (countryIndex !== -1) {
        const countryName = chartData?.long_name[countryIndex];
        const selectedFeature = africaGeoJson.features.find(
          (feature) => feature.properties.name === countryName
        );

        if (selectedFeature) {
          setSelectedCountry({
            ...selectedFeature.properties,
            feature: selectedFeature
          });
        }
      }
    }else{
        setSelectedCountry(null);
    }
  }, [countryID, chartData]); // Listen for changes in countryID and chartData

    // Custom component to zoom into a country using its bounds
    const MapInteractionHandler = ({ selectedCountry }) => {
        const map = useMap();

        useEffect(() => {
            if (selectedCountry && selectedCountry.feature) {
                const layer = L.geoJSON(selectedCountry.feature);
                const bounds = layer.getBounds();
                map.fitBounds(bounds, { padding: [20, 20] }); // Zoom into the selected country
            }else {
                const africaBounds = L.geoJSON(africaGeoJson).getBounds();
                map.fitBounds(africaBounds);
            }
        }, [selectedCountry, map]);

        return null;
    };

    // Filter GeoJSON to show only the selected country, or all countries if none selected
    const filteredGeoJson = selectedCountry
        ? {
            type: 'FeatureCollection',
            features: [selectedCountry.feature]
        }
        : africaGeoJson;

    if (!chartData) {
        return <div>Loading data...</div>;
    } 

  return (
    <div className="chart-container">
      <h3 className="section-title">{label?.textField} per country</h3>
        <div className='mt-6'>
          {selectedCountry && (
            <div className='d-flex justify-content-between'>
              <div className='common-outlined-button'>
                  <img
                      src={countriesFlag[selectedCountry.postal]}
                      alt={selectedCountry.short_name}
                      height={16}
                      width={16}
                      className="me-2"
                  />
                  <h3>{selectedCountry.name}</h3>
                  {/* <p>{selectedCountry.policies} Policies</p> */}
              </div>
              <Button 
                onClick={() => {
                  setSelectedCountry(null);
                  setCountryID(null);
                }} 
                className='common-button mb-2' //TODO: Change to secondary button gray
                >
                Reset to Africa
              </Button>
            </div>
          )}
          <div className='chart-legend-wrapper'>
              <MapContainer style={{ height: '250px', width: '300px' }} key={key} zoomControl={false}>
                  <GeoJSON
                      key={`${key}-${selectedCountry ? 'selected' : 'all'}`} //Shows only the selected country
                      data={filteredGeoJson}  // Filtered GeoJSON to show either all countries or the selected country
                      style={countryStyle}   // Style each country dynamically
                      onEachFeature={onEachCountry}  // Add interactivity to each country
                  />
                  <MapInteractionHandler selectedCountry={selectedCountry} />
                  <MapOptions/>
              </MapContainer>
              <MapLegend label={label?.dataField}/>
              {/*TODO: optimise legend values for different maps ?*/}
          </div>
        </div>
      </div>
  );
};

export default AfricaChoroplethMap;