import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Card, CardBody, Input, Button } from "reactstrap";
import Select from "react-select";
import { listAllSystems } from "services/listServices";
import styled from "@emotion/styled"
import { useHistory } from "react-router-dom"
import { BiArrowBack } from "react-icons/bi";

const StyledButton = styled(Button)`
  width: 200px;
  margin: 30px auto;
  // display: block;
  display: flex;
  align-items: center; /* Align icon and text vertically */
  justify-content: center; /* Center text and icon */
  gap: 8px; 
`;

const StepOne = ({ form, formType, handleInputChange, changeTab, save, activeTab }) => {
  const [benchmarkingOptions, setBenchmarkingOptions] = useState([]);
  const [systemsCache, setSystemsCache] = useState(null); 
  const history = useHistory()// Cache fetched systems

  const trackerStagesOptions = useMemo(() => [
    { value: 1, label: "Initiation" },
    { value: 2, label: "Development" },
    { value: 3, label: "Validation" },
    { value: 4, label: "Approval" },
    { value: 5, label: "Legislation" },
    { value: 6, label: "Implementation" },
  ], []);

  const landscapingCategories = useMemo(() => [
    { value: "Macro", label: "Macro" },
    { value: "Meso", label: "Meso" },
    { value: "Micro", label: "Micro" },
  ], []);

  // Fetch systems data for benchmarking
  const loadSystems = useCallback(async () => {
    try {
      const data = await listAllSystems();
      return data.map(system => ({
        label: system.name,
        value: system.id,
      }));
    } catch (err) {
      console.error(err);
      return [];
    }
  }, []);

  // Caching systems data to avoid redundant fetching
  useEffect(() => {
    const fetchSystems = async () => {
      if (formType === "benchmarking" && !systemsCache) {
        const systems = await loadSystems();

        setBenchmarkingOptions(systems);
        setSystemsCache(systems); // Cache the fetched systems
      } else if (systemsCache) {
        setBenchmarkingOptions(systemsCache); // Use cached data
      }
    };
    fetchSystems();
  }, [formType, systemsCache, loadSystems]);

  // Proceed to next step
  const proceed = () => {
    save(1);
    // alert("Milestone details saved in draft", true);
    changeTab("stepAddOwners");
  };

  // Helper component for rendering select dropdowns
  const CustomSelect = ({ options, value, onChange, placeholder }) => (
    <Select
      className="basic-single"
      classNamePrefix="select"
      options={options}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
    />
  );

  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <>
      <Card className="mt-5" style={{ border: "none" }}>
        <CardBody style={{ border: "2px solid #f4dac6", borderRadius: "5px" }}>
          <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "1em" }}>
            {/* Name field */}
            <Input
              type="text"
              style={{ borderRadius: 5, borderWidth: 1, gridColumn: "1/-1" }}
              className={`form-control  ${form.error && "border-danger"}`}
              id="name"
              value={form?.name}
              onChange={e => handleInputChange("name", e.target.value)}
              placeholder="Name"
            />

            {/* E-Tracker fields */}
            {formType === "tracker" && (
              <CustomSelect
                options={trackerStagesOptions}
                value={trackerStagesOptions.find(option => option.value === form?.stage_id) || null}
                onChange={option => handleInputChange("stage_id", option.value)}
                placeholder="Assign Stage"
              />
            )}

            {/* Benchmarking fields */}
            {formType === "benchmarking" && (
              <>
                {/* Show a loading message while systems data is being fetched */}
                {benchmarkingOptions.length === 0 ? (
                  <p>Loading systems...</p>
                ) : (
                  <CustomSelect
                    options={benchmarkingOptions}
                    value={benchmarkingOptions.find(option => option.value === form?.system_id) || null}
                    onChange={option => handleInputChange("system_id", option.value)}
                    placeholder="Select System"
                  />
                )}
              </>
            )}

            {/* Landscaping fields */}
            {formType === "landscaping" && (
              <>
                <CustomSelect
                  options={landscapingCategories}
                  value={landscapingCategories.find(option => option.value === form?.landscaping_category) || null}
                  onChange={option => handleInputChange("landscaping_category", option.value)}
                  placeholder="Select Landscaping Category"
                />

                {/* Description field */}
                <Input
                  type="textarea"
                  style={{ borderRadius: 5, borderWidth: 1, gridColumn: "1/-1" }}
                  className={`form-control`}
                  value={form?.description}
                  onChange={e => handleInputChange("description", e.target.value)}
                  placeholder="Description"
                />
              </>
            )}
          </div>
        </CardBody>
      </Card>

      {/* Proceed Button */}
      <div className="d-flex justify-content-end">
      <StyledButton className="left-button" color="primary" onClick={handleGoBack}>
          <BiArrowBack style={{ marginRight: '4px' }} />Go Back
        </StyledButton>
        <StyledButton
          color="primary"
          className="right-button"
          // style={{ backgroundColor: "#8353E2", border: "none" }}
          onClick={proceed}
        >
          Next{" "}
          <i className="ph-caret-right" style={{ verticalAlign: "middle" }} />
        </StyledButton>
      </div>
    </>
  );
};

export default StepOne;
