import PropTypes from "prop-types"
import React, { useState } from "react"
import {
  Alert,
  FormFeedback,
  Input,
  Label,
  Form,
} from "reactstrap"

import { withRouter, Link } from "react-router-dom"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"
import { resetPassword } from "services/authServices"
import { FiArrowRight } from "react-icons/fi"

const ResetPassword = props => {
  //meta title
  document.title = "Reset Password | AGRA"

  const [forgetError, setForgetError] = useState()
  const [forgetSuccessMsg, setForgetSuccessMsg] = useState()

  // Extract token from query string
  const query = new URLSearchParams(props.location.search)
  const token = query.get("token")

  const validate = values => {
    let errors = {}
    const passwordRegex = /(?=.*[0-9])/
    if (!values.password) {
      errors.password = "Password Required"
    } else if (!values.confirm_password) {
      errors.confirm_password = "Confirm Password Required"
    } else if (values.password.length < 8) {
      errors.password = "*Password must be 8 characters long."
    } else if (values.confirm_password.length < 8) {
      errors.confirm_password = "*Password must be 8 characters long."
    } else if (!passwordRegex.test(values.password)) {
      errors.password = "Invalid password. Must contain one number."
    } else if (!passwordRegex.test(values.password)) {
      errors.confirm_password = "Invalid password. Must contain one number."
    } else if (values.password !== values.confirm_password) {
      errors.password = "Password not matched"
      errors.confirm_password = "Password not matched"
    }
    return errors
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      password: "",
      confirm_password: "",
    },
    validate,
    onSubmit: values => {
      if(token){
        resetPassword({new_password: values.password, token: token})
        .then(res => {
          setForgetSuccessMsg(res.message)
          validation.resetForm()
        })
        .catch(err => {
          setForgetError(err.response.data.message || "Something went wrong.")
        })
      } else {
        setForgetError("Invalid or missing token.")
      }
    },
  })

  return (
    <React.Fragment>
      <div className="flex justify-start items-center">
        <div className="bg-white rounded-2xl w-96">
          <div className="p-4 pt-4 pb-4">
            <div className="text-primary pb-4 ml-0">
              <h2 className="text-primary text-2xl text-nowrap ml-0">Reset Password</h2>
            </div>
            {forgetError && forgetError ? (
              <Alert color="danger">
                {forgetError}
              </Alert>
            ) : null}
            {forgetSuccessMsg ? (
              <Alert color="success">
                {forgetSuccessMsg}
              </Alert>
            ) : null}
            <Form
              className="form-horizontal"
              onSubmit={e => {
                e.preventDefault()
                validation.handleSubmit()
                return false
              }}
            >
              <div className="mb-3">
                <Label className="form-label text-primary ml-0 font-medium">Password</Label>
                <Input
                  name="password"
                  className="form-control"
                  placeholder="Enter Password"
                  type="password"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.password || ""}
                  invalid={
                    validation.touched.password &&
                    validation.errors.password
                      ? true
                      : false
                  }
                />
                {validation.touched.password &&
                validation.errors.password ? (
                  <FormFeedback type="invalid">
                    {validation.errors.password}
                  </FormFeedback>
                ) : null}
              </div>

              <div className="mb-3">
                <Label className="form-label text-primary ml-0 font-medium">Confirm Password</Label>
                <Input
                  name="confirm_password"
                  className="form-control"
                  placeholder="Enter Confirm Password"
                  type="password"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.confirm_password || ""}
                  invalid={
                    validation.touched.confirm_password &&
                    validation.errors.confirm_password
                      ? true
                      : false
                  }
                />
                {validation.touched.confirm_password &&
                validation.errors.confirm_password ? (
                  <FormFeedback type="invalid">
                    {validation.errors.confirm_password}
                  </FormFeedback>
                ) : null}
              </div>
              <div className="d-grid">
                <button
                  className="btn btn-primary btn-block rounded-[2.25rem] text-[#FFF605] flex flex-row items-center justify-center"
                  type="submit"
                >
                  Reset
                  <div className="rounded-full flex justify-center items-center w-4 h-4 bg-[#EAE200] ml-1">
                    <FiArrowRight color="#036F36" />
                  </div>
                </button>
              </div>
              <div className="mt-3 text-center">
                <p>
                  Go back to
                  <Link to="/login" className="font-weight-medium text-primary ml-1">
                  Login
                  </Link>
                </p>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

ResetPassword.propTypes = {
  history: PropTypes.object,
}

export default withRouter(ResetPassword)
