import React from 'react';
import Visualisation from './Visualisation';
import LandscapingTabs from './LandscapingTabs';

function Landscaping({alert}) {

  if(window.location.pathname === '/home-landscape'){
    return <Visualisation alert={alert}/>
  }else{
    return  <LandscapingTabs alert={alert}/>
  }
}

export default Landscaping;  