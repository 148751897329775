import { create } from "zustand"
export const useUserListStore = create(set => ({
  searchQuery: "",
  isAdmin: true,
  currentPage: 0,
  addSearchQuery: search => set(state => ({ searchQuery: search })),
  setIsAdmin: admin => set(state => ({ isAdmin: admin })),
  nextPage: () => set(state => ({ currentPage: state.currentPage + 1 })),
  previousPage: () => set(state => ({ currentPage: state.currentPage - 1 })),
}))
