import {
  LIST_COUNTRIES,
  LIST_COUNTRIES_SUCCESS,
  LIST_COUNTRIES_FAIL,
  LIST_STAGES,
  LIST_STAGES_SUCCESS,
  LIST_STAGES_FAIL,
} from "./actionTypes"

export const listCountries = () => ({
  type: LIST_COUNTRIES,
})

export const listCountriesSuccess = countries => ({
  type: LIST_COUNTRIES_SUCCESS,
  payload: countries,
})

export const listCountriesFail = error => ({
  type: LIST_COUNTRIES_FAIL,
  payload: error,
})

export const listStages = () => ({
  type: LIST_STAGES,
})

export const listStagesSuccess = stages => ({
  type: LIST_STAGES_SUCCESS,
  payload: stages,
})

export const listStagesFail = error => ({
  type: LIST_STAGES_FAIL,
  payload: error,
})
