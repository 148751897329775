import React, { useState } from "react"
import { BsPlus } from "react-icons/bs"
import { FaMinus } from "react-icons/fa"


export default function SingleQuestion({ question, answer }) {
  const [showAnswer, setShowAnswer] = useState(false)

  return <>
    <div>
      <div onClick={() => setShowAnswer(!showAnswer)}
           className="flex items-center justify-between border-2 p-3 my-2 rounded-md cursor-pointer">
        <h2 className="text-xl font-semibold tracking-wider" style={{ fontSize: "16px" }}>
          {question}
        </h2>
        {
          showAnswer ? (
            <button className="text-green-700 hover:text-green-800">
              <FaMinus />
            </button>
          ) : (
            <button onClick={() => setShowAnswer(!showAnswer)} className="text-green-700 hover:text-green-800">
              <BsPlus className="text-xl" />
            </button>
          )
        }
      </div>
      <div className="px-5 py-0.5"></div>
      {showAnswer && <p
        className="text-gray-800 text-lg py-3 px-5 rounded-md shadow-sm transition duration-200 hover:bg-gray-50"
        style={{ fontSize: "14px" }}>
        {answer}
      </p>
      }

    </div>
  </>
}