import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import {
  Alert,
  FormFeedback,
  Input,
  Label,
  Form,
} from "reactstrap"

import { withRouter, Link } from "react-router-dom"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

// action
import { forgotPassword } from "services/authServices"

import { FiArrowRight } from "react-icons/fi"

const ForgetPasswordPage = props => {
  //meta title
  // document.title = "Forget Password | AGRA"

  const [forgetError, setForgetError] = useState()
  const [forgetSuccessMsg, setForgetSuccessMsg] = useState()

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
    }),
    onSubmit: values => {
      forgotPassword(values.email)
        .then(res => {
          setForgetSuccessMsg(res.message)
          validation.resetForm()
        })
        .catch(err => {
          setForgetError(err.response.data.detail)
        })
    },
  })

  useEffect(() => {
    document.body.className = "bg-login"
  }, [])

  return (
    <React.Fragment>
      <div>
        <div className="flex flex-row justify-start items-center">
            <div className="bg-white rounded-2xl w-96">
              <div className="p-4 pt-4 pb-4">
                <div className="text-primary pb-4 ml-0">
                  <h2 className="text-primary text-2xl text-nowrap ml-0">Reset Password</h2>
                </div>
                {forgetError && forgetError ? (
                    <Alert color="danger">
                    {forgetError}
                    </Alert>
                ) : null}
                {forgetSuccessMsg ? (
                    <Alert color="success">
                    {forgetSuccessMsg}
                    </Alert>
                ) : null}
                <Form
                  className="form-horizontal"
                  onSubmit={e => {
                    e.preventDefault()
                    validation.handleSubmit()
                    return false
                  }}
                >
                  <div className="mb-3">
                    <Label className="form-label text-primary ml-0 font-medium">Email</Label>
                    <Input
                      name="email"
                      className="form-control h-12 rounded-lg"
                      placeholder="Enter email"
                      type="email"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      //value={validation.values.email || ""}
                      invalid={
                        !!(validation.touched.email && validation.errors.email)
                      }
                    />
                    {validation.touched.email && validation.errors.email ? (
                      <FormFeedback type="invalid">
                        {validation.errors.email}
                      </FormFeedback>
                    ) : null}
                  </div>

                  <div className="d-grid">
                    <button
                      className="btn btn-primary btn-block rounded-[2.25rem] text-[#FFF605] flex flex-row items-center justify-center"
                      type="submit"
                    >
                      Reset
                      <div className="rounded-full flex justify-center items-center w-4 h-4 bg-[#EAE200] ml-1">
                        <FiArrowRight color="#036F36" />
                      </div>
                    </button>
                  </div>
                  <div className="mt-3 text-center">
                    <p>
                      Go back to
                      <Link
                      to="login"
                      className="font-weight-medium text-primary ml-1"
                      >
                      Login
                      </Link>
                    </p>
                  </div>
                </Form>
              </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

ForgetPasswordPage.propTypes = {
  history: PropTypes.object,
}

export default withRouter(ForgetPasswordPage)
