import { ETRACKER_OVERVIEW,
    ETRACKER_ETHIOPIA_POLICY_REGULATORY_FRAMEWORK_ANALYSIS,
     ETRACKER_POLICY_WINS, ETRACKER_STAGE,ETRACKER_POLICY_ANALYTICS,
      ETRACKER_OVERVIEW_PUBLIC, ETRACKER_STAGE_PUBLIC, ETRACKER_POLICY_WINS_PUBLIC } from "./CONSTANTS";
import { get } from "./AXIOS";

export const getData = async (activeTabId, query) => {

    let api = "";
    const isLoggedIn = localStorage.getItem('token');

    if(activeTabId === 0){
        api = isLoggedIn ? ETRACKER_OVERVIEW : ETRACKER_OVERVIEW_PUBLIC;
    }else if(activeTabId >= 1 && activeTabId <= 6){
        api = isLoggedIn ? ETRACKER_STAGE(activeTabId) : ETRACKER_STAGE_PUBLIC(activeTabId);
    }else{
        api = isLoggedIn ? ETRACKER_POLICY_WINS : ETRACKER_POLICY_WINS_PUBLIC;
    }

    const queryString = new URLSearchParams(query).toString();

    const url = `${api}?${queryString}`;

    try {
        const response = await get(url); //TODO: use react query for caching
        return response;
    } catch (err) {
        console.error("Error fetching data:", err);
    }
};

export const getPolicyTrackerAnalytics  = (country_id) => get(ETRACKER_POLICY_ANALYTICS(country_id));


export const getEthiopiaPolicyRegulatoryFramework = () => get(ETRACKER_ETHIOPIA_POLICY_REGULATORY_FRAMEWORK_ANALYSIS);
