import React, { Fragment, useRef, useState } from "react";
import PropTypes from "prop-types";
import "./DefaultTableContainer.css";
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
} from "react-table";
import {
  Table,
  Row,
  Col,
  Button,
  Input,
  Alert,
} from "reactstrap";
import LoadingSpinner from "./Loading-Spinner";
import { useUserListStore } from "store/zustand/userListStore";
import ExportTable from "./ExportTable";
import { exportTableData } from "services/common";

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  headerGroups,
  filteredRowCount,
  toggleFilter,
}) {
  const userListStore = useUserListStore((state) => state);
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    userListStore.addSearchQuery(value);
  }, 200);

  return (
    <div className="flex flex-row items-center">
      <div>
        <form className="app-search py-0">
          <div className="position-relative">
            <span style={{ color: "black" }} className="bx bx-search"></span>
            <input
              onChange={(e) => {
                setValue(e.target.value);
                onChange(e.target.value);
              }}
              type="search"
              className="form-control"
              placeholder="Enter text to search"
            />
          </div>
        </form>
      </div>
      <div>
        <Button
          style={{
            backgroundColor: "#f8f9fa",
            color: "black",
            margin: "15px 10px",
            border: "none",
            fontSize: "14px",
          }}
          onClick={toggleFilter}
        >
          <i className="mdi mdi-filter-variant me-1"></i>
          Filter
        </Button>
      </div>
    </div>
  );
}

const DefaultTableContainer = ({
  columns,
  data,
  isGlobalFilter,
  className,
  customPageSize,
  customSort,
  isExport,
  openFilterModal,
  setFilterCountries,
  isLoading,
  createUser,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    rows,
    setGlobalFilter,
    setFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: customPageSize,
        sortBy: [customSort || { desc: true }],
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination
  );

  const userListStore = useUserListStore((state) => state);

  const generateSortingIndicator = (column) => {
    return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : "";
  };

  const onChangeInSelect = (event) => {
    setPageSize(Number(event.target.value));
  };

  const onChangeInInput = (event) => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0;
    gotoPage(page);
  };

  const exportTable = async (type) => {
    try {
      const query = {
        export_format: type,
      };
      exportTableData(query, "Users");
    } catch (err) {
      console.log(err);
      alert("Data export failed", false);
    }
  };

  return (
    <Fragment>
      <Row>
        <div className="d-flex justify-content-between">
          <div className="d-flex" style={{ margin: "15px 10px" }}>
            {isGlobalFilter && (
              <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={state.globalFilter}
                setGlobalFilter={setGlobalFilter}
                headerGroups={headerGroups}
                filteredRowCount={rows.length}
                toggleFilter={openFilterModal}
              />
            )}
          </div>

          <div className="flex flex-row items-center">
            <Button
              type="button"
              color="primary"
              className="common-button btn btn-secondary"
              onClick={createUser}
            >
              <i className="mdi mdi-plus-circle-outline me-1" />
              New
            </Button>
            <ExportTable loadFilteredData={exportTable} />
          </div>
        </div>
      </Row>

      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <Fragment>
          <div className="table-responsive react-table">
            <Table
              {...getTableProps()}
              className={`custom-table ${className}`}
            >
              <thead className="custom-header">
                {headerGroups.map((headerGroup) => (
                  <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th key={column.id}>
                        <div {...column.getSortByToggleProps()}>
                          {column.render("Header")}
                          {generateSortingIndicator(column)}
                        </div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>

              <tbody {...getTableBodyProps()}>
                {page && page.length <= 0 && (
                  <tr className="text-center">
                    <td colSpan={columns.length}>
                      <Alert color="warning" role="alert">
                        No Data Available
                      </Alert>
                    </td>
                  </tr>
                )}
                {page && page.length > 0
                  ? page.map((row) => {
                      prepareRow(row);
                      return (
                        <tr
                          key={row.getRowProps().key}
                          className="custom-row"
                        >
                          {row.cells.map((cell) => (
                            <td key={cell.id} {...cell.getCellProps()}>
                              {cell.render("Cell")}
                            </td>
                          ))}
                        </tr>
                      );
                    })
                  : null}
              </tbody>
            </Table>
          </div>

          <Row className="justify-content-md-end justify-content-center align-items-center">
            <Col className="col-md-auto">
              <div className="flex-grow-1 align-self-center">
                <p className="mb-0">Total Records: {rows.length}</p>
              </div>
            </Col>

            <Col className="col-md-auto">
              <div className="d-flex gap-1">
                <Button
                  color="primary"
                  onClick={() => gotoPage(0)}
                  disabled={!canPreviousPage}
                >
                  {"<<"}
                </Button>
                <Button
                  color="primary"
                  onClick={previousPage}
                  disabled={!canPreviousPage}
                >
                  {"<"}
                </Button>
              </div>
            </Col>
            <Col className="col-md-auto d-none d-md-block">
              Page{" "}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>
            </Col>
            <Col className="col-md-auto">
              <Input
                type="number"
                min={1}
                style={{ width: 70 }}
                max={pageOptions.length}
                defaultValue={pageIndex + 1}
                onChange={onChangeInInput}
              />
            </Col>

            <Col className="col-md-auto">
              <div className="d-flex gap-1">
                <Button
                  color="primary"
                  onClick={nextPage}
                  disabled={!canNextPage}
                >
                  {">"}
                </Button>
                <Button
                  color="primary"
                  onClick={() => gotoPage(pageCount - 1)}
                  disabled={!canNextPage}
                >
                  {">>"}
                </Button>
              </div>
            </Col>
          </Row>
        </Fragment>
      )}
    </Fragment>
  );
};

DefaultTableContainer.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default DefaultTableContainer;
