// common/Filters/systemOptions.js
import { listAllSystems } from "services/listServices";

let cachedSystemOptions = null;

const fetchSystemOptions = async () => {
  if (!cachedSystemOptions) {
    try {
      const systems = await listAllSystems();
      cachedSystemOptions = systems.map((system) => ({
        label: system.name,
        value: system.id,
      }));
    } catch (error) {
      console.error("Failed to fetch systems:", error);
      cachedSystemOptions = []; // Default to an empty array on failure
    }
  }
  return cachedSystemOptions;
};

export const getSystemOptions = fetchSystemOptions;