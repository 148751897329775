import React from "react"

export const HomeInfoCard = ({ caption, title, description }) => {
  return (
    <div className="flex flex-row shadow-lg rounded-2xl px-4 py-4 bg-white">
      <div className="flex flex-col items-start w-[22.65rem]">
        <div className="rounded-full flex justify-center items-center bg-[#EAE200] mb-2 w-28">
          <div className="font-normal text-lg text-[#036F36]">{caption}</div>
        </div>
        <div className="font-normal text-4xl text-[#036F36]">{title}</div>
      </div>
      <div className="flex flex-col max-w-[24.18rem]">
        <div className="flex flex-row justify-center">
          <div className="h-full w-0.5 bg-[#036F36] mr-10"></div>
          <div className="font-regular text-base text-[#036F36] w-full">
            {description}
          </div>
        </div>
      </div>
    </div>
  )
}
