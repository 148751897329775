import React, { useEffect, useState } from "react"
import CountUp from "react-countup";
import "./TasksCard.css"
import { tasksCount } from "../../../services/milestoneServices"

function TasksCount() {
  const [taskCounts, setTaskCounts] = useState({
    eTrackerTasks: 0,
    benchmarkingTasks: 0,
    landscapingTasks: 0
  })

  // Fetch tasks count on component mount
  useEffect(() => {
    const fetchTaskCounts = async () => {
      try {
        const response = await tasksCount() // Fetch data from the tasksCount API
        setTaskCounts({
          eTrackerTasks: response.e_tracker_tasks,
          benchmarkingTasks: response.benchmarking_tasks,
          landscapingTasks: response.landscaping_tasks
        })
      } catch (error) {
        console.error("Error fetching task counts:", error)
      }
    }

    fetchTaskCounts()
  }, []) // Empty dependency array ensures this only runs on mount


  return (
    <div className="cards-container">
      <Card
        icon="📄"
        title="Total E-Tracker Tasks"
        count={<
          CountUp
          start={0}
          end={taskCounts.eTrackerTasks}
          duration={3} key={`tasks-${taskCounts.eTrackerTasks}`} /> }
      />
      <Card
        icon="📊"
        title="Total Benchmarking Tasks"
        count={<
          CountUp
          start={0}
          end={taskCounts.benchmarkingTasks}
          duration={3}
          key={`tasks-${taskCounts.benchmarkingTasks}`}/>}
      />
      <Card
        icon="🏞️"
        title="Total Landscaping Tasks"
        count={<
          CountUp
          start={0}
          end={taskCounts.landscapingTasks}
          duration={3}
          key={`tasks-${taskCounts.landscapingTasks}`}/>}
      />
    </div>
  )
}

function Card({ icon, title, count }) {
  return (
    <div className="custom-card">
      <div className="card-left">
        <span className="card-icon">{icon}</span>
        <div className="card-title">{title}</div>
      </div>
      <div className="card-divider"></div>
      <div className="card-count">{count}</div>
    </div>
  )
}

export default TasksCount