import React from "react"
import SunburstChart from "sunburst-chart"
import fromKapsule from "react-kapsule"
import NoData from "components/Common/NoData";

const ReactSunburst = fromKapsule(SunburstChart, {
  methodNames: ["focusNode"]
})

const getColorByDepth = (depth) => {
  switch (depth) {
    case 0:
      return '#228B22'; // Forest Green for root
    case 1:
      return '#32CD32'; // Lime Green for first level
    case 2:
      return '#3CB371'; // Medium Sea Green for second level
    case 3:
      return '#66CDAA'; // Medium Aquamarine for third level
    case 4:
      return '#98FB98'; // Pale Green for fourth level
    case 5:
      return '#7FFF00'; // Chartreuse for fifth level
    default:
      return '#556B2F'; // Dark Olive Green for deeper levels or undefined
  }
};

const Chart = ({ data }) => {

  // Check if data is the "No data available" message
  const isNoData = data?.message === "No data available";
  
  if (isNoData) {
    return <NoData/>;
  }
  
  return (
    <ReactSunburst
      width={550}
      height={550}
      excludeRoot
      label="name"
      size="size"
      color={(d, node) => getColorByDepth(node?.depth)}
      centerRadius={0.3}
      radiusScaleExponent={0.7}
      tooltipContent={(d, node) => `Policies: ${node.data.size}`}
      data={data}
    />
  )
}

export default Chart