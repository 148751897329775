import { theme } from 'common/selectCustomStyles';
import React, { useState } from 'react';
import ToggleSwitch from './ToggleSwitch';
import Select from "react-select";

function Step1({isEditMode, formData, setFormData, handleInputChange, setCurrentStep, countryOptions, stageOptions, systemOptions, isLoading, errors, policyTypeOptions, milestoneOptions}) {
    // Local state for validation errors
    const [validationErrors, setValidationErrors] = useState({});

    const validateForm = () => {
        const newErrors = {};
        
        // Validate Policy Name
        if (!formData.policy_request_body?.name?.trim()) {
            newErrors.name = 'Policy name is required';
        } else if (formData.policy_request_body.name.length > 100) {
            newErrors.name = 'Policy name must be less than 100 characters';
        }

        // Validate Country
        if (!formData.policy_request_body?.country_id) {
            newErrors.country = 'Please select a country';
        }

        // Validate Policy Type (only for country_id === 2)
        if (formData.policy_request_body?.country_id === 2 && !formData.policy_request_body?.policy_type) {
            newErrors.policy_type = 'Please select a policy type';
        }

        // Validate Stage
        if (!formData.policy_request_body?.stage_id) {
            newErrors.stage = 'Please select a stage';
        }

        // Validate Systems
        if (!formData.system_ids?.length) {
            newErrors.systems = 'Please select at least one system';
        }

        setValidationErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleToggleChange = (field) => {
      setFormData(prev => ({
        ...prev,
        policy_request_body: {
          ...prev.policy_request_body,
          [field]: !formData.policy_request_body[field]
        }
      }));
    };
    
    const handleSystemChange = (systems) => {  
      setFormData(prev => ({
        ...prev,
        system_ids: systems.map((option => {
          return {id: parseInt(option.value)};
        }))
      }));
      // Clear system validation error when systems are selected
      if (systems.length > 0) {
        setValidationErrors(prev => ({ ...prev, systems: undefined }));
      }
    };

    const handleNameChange = (name, value) => {
      handleInputChange(name, value);
      // Clear name validation error when user types
      setValidationErrors(prev => ({ ...prev, name: undefined }));
    };

    const getDefaultValue = (options, value, isMulti = false) => {
      if (!options || options.length === 0) return isMulti ? [] : null;
      if (isMulti) {
          return options.filter(option => 
              value.some(v => parseInt(option.value) === v.id)
          );
      }
      return options.find(option => option.value === value) || null;
    };

    const handleNext = () => {
      const isValid = validateForm();
      if (isValid) {
        setCurrentStep(2);
      }
    };

    return (
        <div className="space-y-4">
          <div>
            <label className="block mb-1 text-sm">Policy Name*</label>
            <input
              type="text"
              name="name"
              value={formData.policy_request_body?.name}
              onChange={(e) => handleNameChange(e.target.name, e.target.value)}
              className={`w-full p-2 border rounded`}
              placeholder="Enter policy name"
            />
            {validationErrors.name && (
              <span className="text-red-500 text-sm mt-1 ml-1">{validationErrors.name}</span>
            )}
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div className="flex items-center">
              <label className="block text-sm">Country*</label>
              <div className="ms-2" style={{ width: "100%" }}>
                <Select
                  options={countryOptions}
                  isDisabled={isEditMode}
                  value={getDefaultValue(
                    countryOptions,
                    formData.policy_request_body.country_id
                  )}
                  className={`basic-single`}
                  isLoading={isLoading.countryOptions}
                  classNamePrefix="select"
                  placeholder="Select a country"
                  theme={theme}
                  onChange={(option) => {
                    handleInputChange("country_id", option.value);
                    setValidationErrors(prev => ({ ...prev, country: undefined }));
                  }}
                />
                {(errors.countryOptions || validationErrors.country) && (
                  <span className="text-red-500 text-sm mt-1">
                    {errors.countryOptions || validationErrors.country}
                  </span>
                )}
              </div>
            </div>
            
            {formData.policy_request_body.country_id === 2 && (
              <div className="flex items-center">
                <label className="block text-sm">Policy Type*</label>
                <div className="ms-2" style={{ width: "100%" }}>
                  <Select
                    options={policyTypeOptions}
                    value={getDefaultValue(
                      policyTypeOptions,
                      formData.policy_request_body.policy_type
                    )}
                    className={`basic-single`}
                    classNamePrefix="select"
                    placeholder="Select policy type"
                    theme={theme}
                    onChange={(option) => {
                      handleInputChange("policy_type", option.value);
                      setValidationErrors(prev => ({ ...prev, policy_type: undefined }));
                    }}
                  />
                  {validationErrors.policy_type && (
                    <span className="text-red-500 text-sm mt-1">{validationErrors.policy_type}</span>
                  )}
                </div>
              </div>
            )}
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div className="flex items-center">
              <label className="block text-sm">Stage*</label>
              <div className="ms-2" style={{ width: "100%" }}>
                <Select
                  options={stageOptions}
                  isDisabled={isEditMode}
                  value={getDefaultValue(
                    stageOptions,
                    formData.policy_request_body.stage_id
                  )}
                  className={`basic-single`}
                  isLoading={isLoading.stageOptions}
                  classNamePrefix="select"
                  placeholder="Select a stage"
                  theme={theme}
                  onChange={(option) => {
                    handleInputChange("stage_id", option.value);
                    setValidationErrors(prev => ({ ...prev, stage: undefined }));
                  }}
                />
                {(errors.stageOptions || validationErrors.stage) && (
                  <span className="text-red-500 text-sm mt-1">
                    {errors.stageOptions || validationErrors.stage}
                  </span>
                )}
              </div>
            </div>

            <div className="flex items-center">
              <label className="block text-sm">Systems*</label>
              <div className="ms-2" style={{ width: "100%" }}>
                <Select
                  isMulti
                  options={systemOptions}
                  value={getDefaultValue(systemOptions, formData.system_ids, true)}
                  className={`basic-single`}
                  isLoading={isLoading.systemOptions}
                  classNamePrefix="select"
                  placeholder="Select systems"
                  theme={theme}
                  onChange={handleSystemChange}
                />
                {(errors.systemOptions || validationErrors.systems) && (
                  <span className="text-red-500 text-sm mt-1">
                    {errors.systemOptions || validationErrors.systems}
                  </span>
                )}
              </div>
            </div>

            <div className="flex items-center">
              <label className="block text-sm">Milestones Completed In Current Stage</label>
              <div className="ms-2" style={{ width: "100%" }}>
                <Select
                  isMulti
                  options={milestoneOptions}
                  value={getDefaultValue(milestoneOptions, formData.system_ids, true)} //update
                  className={`basic-single`}
                  isLoading={isLoading.milestoneOptions}
                  classNamePrefix="select"
                  placeholder="Select milestones"
                  theme={theme}
                  onChange={handleSystemChange} //update
                />
                {(errors.milestoneOptions) && (
                  <span className="text-red-500 text-sm mt-1">
                    {errors.milestoneOptions}
                  </span>
                )}
              </div>
            </div>
          </div>

          <div className="flex items-center justify-between py-2">
            <ToggleSwitch
              label="Policy is on track?"
              defaultChecked={!formData.policy_request_body.is_off_track}
              onChange={() => handleToggleChange('is_off_track')}
            />
            
            <ToggleSwitch
              label="Policy is delayed?"
              defaultChecked={formData.policy_request_body.is_delayed}
              onChange={() => handleToggleChange('is_delayed')}
            />
          </div>

          <div className="flex justify-end mt-6">
            <button
              onClick={handleNext}
              className="px-6 py-2 bg-green-100 text-green-800 rounded hover:bg-green-200"
            >
              Next
            </button>
          </div>
        </div>
    );
}

export default Step1;