import Select from "react-select"
import React, { useEffect, useState } from "react"
import { getCountryOptions } from "common/SelectOptions/countryOptions"
import { getSystemOptions } from "common/SelectOptions/systemOptions"
import { getStageOptions } from "common/SelectOptions/stageOptions"
import { landscapingCategoryOptions } from "common/SelectOptions/landscapingCategoryOptions"

const ETrackerTasksFilters = ({
  filteredLocations,
  filteredSystems,
  filteredCategories,
  filteredStages,
  filteredOffTrack,
  filteredDelayed,
  applyFilters
}) => {
  const [locationOptions, setLocationOptions] = useState([]);
  const [systemOptions, setSystemOptions] = useState([]);
  const [stageOptions, setStageOptions] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState(filteredLocations);
  const [selectedSystems, setSelectedSystems] = useState(filteredSystems);
  const [selectedCategories, setSelectedCategories] = useState(filteredCategories);
  const [selectedStages, setSelectedStages] = useState(filteredStages);
  const [offTrack, setOffTrack] = useState(filteredOffTrack);
  const [delayed, setDelayed] = useState(filteredDelayed);

  useEffect(() => {
    const fetchOptions = async () => {
      setLocationOptions(await getCountryOptions());
      setSystemOptions(await getSystemOptions());
      setStageOptions(await getStageOptions());
    };
    fetchOptions();
  }, []);

  return (
    <div>
      {/* Filter by Location */}
      <div className="d-flex my-2">
        <div className="d-flex align-items-center">
          <i className="mdi mdi-map-marker-outline me-1" />
          <h6 className="mb-0">Locations:</h6>
        </div>
        <div className="ms-2" style={{ width: "100%" }}>
          <Select
            isMulti
            options={locationOptions}
            defaultValue={selectedLocations}
            className="basic-single"
            classNamePrefix="select"
            placeholder="Select locations"
            onChange={options => setSelectedLocations(options)}
          />
        </div>
      </div>

      {/* Filter by Systems */}
      <div className="d-flex my-2">
        <div className="d-flex align-items-center">
          <i className="mdi mdi-link-variant me-1" />
          <h6 className="mb-0">Systems:</h6>
        </div>
        <div className="ms-2" style={{ width: "100%" }}>
          <Select
            isMulti
            options={systemOptions}
            defaultValue={selectedSystems}
            className="basic-single"
            classNamePrefix="select"
            placeholder="Select systems"
            onChange={options => setSelectedSystems(options)}
          />
        </div>
      </div>

      {/* Filter by Stage */}
      <div className="d-flex my-2">
        <div className="d-flex align-items-center">
          <i className="mdi mdi-progress-check me-1" />
          <h6 className="mb-0">Stages:</h6>
        </div>
        <div className="ms-2" style={{ width: "100%" }}>
          <Select
            isMulti
            options={stageOptions}
            defaultValue={selectedStages}
            className="basic-single"
            classNamePrefix="select"
            placeholder="Select stages"
            onChange={options => setSelectedStages(options)}
          />
        </div>
      </div>

      {/* Filter by Categories */}
      <div className="d-flex my-2">
        <div className="d-flex align-items-center">
          <i className="mdi mdi-chart-bar me-1" />
          <h6 className="mb-0">Landscaping Categories:</h6>
        </div>
        <div className="ms-2" style={{ width: "100%" }}>
          <Select
            isMulti
            options={landscapingCategoryOptions}
            defaultValue={selectedCategories}
            className="basic-single"
            classNamePrefix="select"
            placeholder="Select categories"
            onChange={options => setSelectedCategories(options)}
          />
        </div>
      </div>

      <div className="d-flex my-2">
        <div className="d-flex align-items-center">
          <i className="mdi mdi-chart-bar me-1" />
          <h6 className="mb-0">Off Track:</h6>
        </div>
        <div className="ms-2" style={{ width: "100%" }}>
          <Select
            isClearable
            options={[{label: "True", value: true}, {label: "False", value: false}]}
            defaultValue={offTrack}
            className="basic-single"
            classNamePrefix="select"
            // placeholder="Select categories"
            onChange={options => setOffTrack(options)}
          />
        </div>
      </div>

      <div className="d-flex my-2">
        <div className="d-flex align-items-center">
          <i className="mdi mdi-chart-bar me-1" />
          <h6 className="mb-0">Delayed:</h6>
        </div>
        <div className="ms-2" style={{ width: "100%" }}>
          <Select
            isClearable
            options={[{label: "True", value: true}, {label: "False", value: false}]}
            defaultValue={delayed}
            className="basic-single"
            classNamePrefix="select"
            // placeholder="Select categories"
            onChange={options => setDelayed(options)}
          />
        </div>
      </div>
    
      {/* Apply Filters Button */}
      <div className="d-flex" style={{ justifyContent: "center" }}>
        <button
          className="btn btn-success common-button"
          style={{ width: "90%" }}
          onClick={()=>
            applyFilters(
              selectedLocations, 
              selectedSystems, 
              selectedStages, 
              selectedCategories, 
              offTrack, 
              delayed
            )}
        >
          Apply
        </button>
      </div>
    </div>
  )
}

export default ETrackerTasksFilters