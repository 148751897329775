// import { exp } from "@amcharts/amcharts5/.internal/core/util/Ease"

//REGISTER
export const API_URL = process.env.REACT_APP_APIURL

//SSE
export const SSE = API_URL + "/policies/sse"

//FILTER
export const FILTERUSERS = API_URL + "/user/filter-users/"

//ACCESS USER LIST
export const ACCESSUSERLIST = API_URL + "/user/stage-country-users/"

// AUTH
export const SIGN_UP = API_URL + "/user/register"
export const SIGN_IN = API_URL + "/login/access-token"
export const USER_SESSION = API_URL + "/user/me"
export const FORGOT_PASSWORD = API_URL + "/password-recovery"
export const RESET_PASSWORD = API_URL + "/reset-password"

// PROFILE
export const UPDATE_PROFILE = API_URL + "/user/profile"
export const PROFILE_PHOTO = API_URL + "/user/profile/"
export const ME_PROFILE = API_URL + "/user/me"
export const ME_PROFILE_PHOTO = API_URL + "/user/me/profile-picture"
export const USER_PROFILE_PHOTO = (user_id) =>
  `${API_URL}/user/${user_id}/profile-picture`
export const GET_USER_PROFILE = (user_id) => `${API_URL}/user/${user_id}`

// ACCESS CODE
export const ACCESS_CODES = API_URL + "/access-code"
export const ADD_NEW_ACCESS_CODE = API_URL + "/access-code"
export const GET_ACCESS_CODE_DETAIL = API_URL + "/access-code"
export const DELETE_ACCESS_CODE = API_URL + "/access-code"
export const UPDATE_ACCESS_CODE = API_URL + "/access-code"

// User
export const USER = API_URL + "/user"
export const USER_POLICY_TASKS_HISTORY = API_URL + "/task/history-policy-tasks"
export const USER_BENCHMARKING_TASKS_HISTORY =
  API_URL + "/task/history-benchmarking-tasks"

export const LIST_COUNTRIES = API_URL + "/country/"
export const LIST_PUBLIC_COUNTRIES = API_URL + "/country/public"
export const LIST_STAGES = API_URL + "/stage/"
export const LIST_PUBLIC_STAGES = API_URL + "/stage/public"
export const LIST_ALL_STAGES = API_URL + "/stage/"
export const LIST_ACTIVE_USERS = API_URL + "/user/active"

//Labels
export const DELETE_LABEL = API_URL + "/labels/remove"
export const DELETE_LABEL_TYPE = API_URL + "/labels/remove-type"
export const LABELS = API_URL + "/system/"
export const FIND_LABELS = API_URL + "/system/"
// export const EDIT_SYSTEM = "/system/{system_id}"
export const GET_SYSTEM = system_id => `${API_URL}/system/${system_id}`
export const EDIT_SYSTEM = system_id => `${API_URL}/system/${system_id}`
export const EXPORT_SYSTEMS = API_URL + "/system/export"
export const CREATE_LABELS = API_URL + "/labels/create"
export const LABELTYPES = API_URL + "/labels/list/labels/types"
export const GET_LABEL_TYPES = API_URL + "/labels/types/find"
export const GET_SPECIFIC_LABEL_TYPE_DETAILS = API_URL + "/labels/type"
export const CREATE_NEW_LABEL_TYPE = API_URL + "/labels/type"
export const GET_LABEL_SUGGESTIONS = API_URL + "/labels/suggest"
export const CREATE_NEW_SYSTEM = API_URL + "/system/"
// export const DELETE_SYSTEM = API_URL + "/policy/{policy_id}"
export const DELETE_SYSTEM = system_id => `${API_URL}/system/${system_id}`
export const GET_SPECIFIC_LABEL_DETAILS = API_URL + "/labels/type"
export const LIST_LABELS_FOR_BENCHMARKING = API_URL + "/labels/list/labels"
export const LABEL_SPECIFIC_COUNTRIES = API_URL + "/labels/countries"
export const ALL_LABELS = API_URL + "/labels/all"

export const ALL_SYSTEMS = API_URL + "/system/select"
export const ALL_PUBLIC_SYSTEMS = API_URL + "/system/select/public"

//Policies
export const POLICIES = API_URL + "/policy"
export const POLICIES_SSE = API_URL + "/policies/sse"
export const CREATE_POLICY = API_URL + "/policies/create"
export const UPDATE_POLICY_VERSION = API_URL + "/policies/update-policy-version"
export const GET_VERSIONS = API_URL + "/policies/policy-versions"
export const DOWNLOAD_VERSION = API_URL + "/policies/download/version"
export const DOWNLOAD_FILE = API_URL + "/policies/download"
export const PARTNERS = API_URL + "/partner/"
export const OWNERS = API_URL + "/owner/all"
export const POLICIES_WITH_SUBCATEGORY = API_URL + "/policies/sub-policies"
export const DELETE = API_URL + "/policies/delete"
export const SOFTDELETEPOLICY = API_URL + "/policies/soft-delete"
export const POLICYACTIVITY = API_URL + "/policies/activity"
export const LATEST_V_NUM = API_URL + "/policies/latest-v-num"
export const POLICY_NAME_VALID = API_URL + "/policies/name-validation"
export const EDIT_POLICY_METADATA = API_URL + "/policies/update"
export const POLICY_UPDATES = API_URL + "/policies/notes"
// Reports
export const KEY_METRICS_HOME = API_URL + "/data-analytics/home-page"
export const DASHBOARD_COUNTS = API_URL + "/report/dashboard/counts"


// Home Page Dashboard
export const HOME_PAGE_DASHBOARD = API_URL + "/data-analytics/home-page-dashboard/"
export const HOME_PAGE_DASHBOARD_PUBLIC = API_URL + "/data-analytics/home-page-dashboard/public"

// export const DASHBOARD_LABELS_SDG = API_URL + "/report/dashboard/labels/sdg"
// export const DASHBOARD_STAGES_POLICIES =
//   API_URL + "/report/dashboard/stages/policies"
// export const RECENT_COMMENTS = API_URL + "/report/comments/recent"
// export const DASHBOARD_LANDSCAPING_STAGES_POLICIES =
//   API_URL + "/report/policies/stages/landscaping"
// export const DASHBOARD_TRACKER_STAGES_POLICIES =
//   API_URL + "/report/policies/stages/tracker"
export const DASHBOARD_NOTIFICATIONS_COUNT =
  API_URL + "/report/notifications/count"
export const PROFILE_STATS = API_URL + "/report/profile/stats"
export const DASHBOARD_TASKS = API_URL + "/report/dashboard/tasks"
export const DASHBOARD_BENCHMARKING_TASKS =
  API_URL + "/report/dashboard/benchmarking-tasks"
export const DASHBOARD_ADMIN_TASKS = API_URL + "/report/dashboard/admin-tasks"
export const INTELLIGENCE_INDICATOR_TRENDS =
  API_URL + "/report/intelligence/indicator/trends"
export const INTELLIGENCE_POLICIES_AFFECTED =
  API_URL + "/report/intelligence/policies-affected"
export const INTELLIGENCE_SENTIMENT_SCORES =
  API_URL + "/report/intelligence/sentiment/scores"
export const INTELLIGENCE_TWEETS = API_URL + "/report/intelligence/tweets"

export const USER_LOGS = API_URL + "/report/user/logs"

export const STATISTICS_ETRACKER = API_URL + "/report/statistics/etracker"

export const STATISTICS_TRACKER = API_URL + "/report/statistics/tracker"

//landscape
export const CATEGORY = API_URL + "/category/country"
export const DELETECATEGORY = API_URL + "/category/delete"
export const DELETESUBCATEGORY = API_URL + "/sub-category/delete"

//get sub categories in a category
export const GETCATEGORY = API_URL + "/category/catId"

//forms
export const FORMSINSTAGE = API_URL + "/form/stage"

export const STAGE = API_URL + "/stage"

export const INDICATOR_TRENDS =
  API_URL + "/report/intelligence/indicator/trends"

export const INDICATOR_TRENDS_MAP =
  API_URL + "/report/intelligence/indicator/trends"

export const TRENDS_MAP_HOVER =
  API_URL + "/report/intelligence/indicator/trends"

export const GETALLFORMS = API_URL + "/form/getall"
export const GETFILTEREDFORMS = API_URL + "/form/find"

// Benchmarking Milestones
export const BENCHMARKING_MILESTONES = API_URL + "/benchmarking-milestone/"
export const BENCHMARKING_TASKS = API_URL + "/country-benchmarking-milestone/tasks/"
export const EXPORT_BENCHMARKING_MILESTONES = API_URL + "/benchmarking-milestone/export"
export const COUNTRY_BENCHMARKING_MILESTONE = (country_id, milestone_id) => `${API_URL}/country-benchmarking-milestone/${country_id}/${milestone_id}`
export const COUNTRY_BENCHMARKING_MILESTONES = API_URL + "/country-benchmarking-milestone/"
export const EXPORT_COUNTRY_BENCHMARKING_MILESTONES = API_URL + "/country-benchmarking-milestone/export"
export const CREATE_BENCHMARKING_MILESTONE = API_URL + "/benchmarking-milestone/"
export const GET_LOCKED_BENCHMARKING_MILESTONE = milestone_id => `${API_URL}/benchmarking-milestone/locked/${milestone_id}`
export const GET_UNLOCKED_BENCHMARKING_MILESTONE = milestone_id => `${API_URL}/benchmarking-milestone/unlocked/${milestone_id}`
export const EDIT_BENCHMARKING_MILESTONE = milestone_id => `${API_URL}/benchmarking-milestone/${milestone_id}`
export const DELETE_BENCHMARKING_MILESTONE = milestone_id => `${API_URL}/benchmarking-milestone/${milestone_id}`
export const BENCHMARKING_QUESTIONNAIRE = API_URL + "/country-benchmarking-milestone-question/{country_id}/{milestone_id}"
export const EDIT_COUNTRY_BENCHMARKING_MILESTONE_QUESTION =
  (country_id, milestone_id, question_id) =>
    `${API_URL}/country-benchmarking-milestone-question/${country_id}/${milestone_id}/${question_id}`

export const LANDSCAPING_FOCAL_AREA_SELECT = `${API_URL}/landscaping-focal-area/select`;

// ETracker Milestones
export const ETRACKER_MILESTONES = API_URL + "/e-tracker-milestone/"
export const ETRACKER_MILESTONES_STAGE = stage_id => `${API_URL}/e-tracker-milestone/stage/${stage_id}`
export const EXPORT_ETRACKER_MILESTONES = API_URL + "/e-tracker-milestone/export"
export const ETRACKER_TASKS = API_URL + "/policy/tasks"
export const CREATE_ETRACKER_MILESTONE = API_URL + "/e-tracker-milestone/"
// export const GET_MILESTONE_QUESTIONS = API_URL + "/e-tracker-milestone/{milestone_id}"
export const EDIT_POLICY_ETRACKER_MILESTONE_QUESTION = (policy_id, stage_id, milestone_id, question_id) =>
    `${API_URL}/policy-e-tracker-milestone-question/${policy_id}/${stage_id}/${milestone_id}/${question_id}`
// export const EDIT_POLICY_ETRACKER_MILESTONE_QUESTION = (
//   policy_id,
//   stage_id,
//   milestone_id,
//   question_id
// ) =>
//   `${API_URL}/policy-e-tracker-milestone-question/${policy_id}/${stage_id}/${milestone_id}/${question_id}`;

export const GET_LOCKED_ETRACKER_MILESTONE = milestone_id => `${API_URL}/e-tracker-milestone/locked/${milestone_id}`
export const GET_UNLOCKED_ETRACKER_MILESTONE = milestone_id => `${API_URL}/e-tracker-milestone/unlocked/${milestone_id}`
export const EDIT_ETRACKER_MILESTONE = milestone_id => `${API_URL}/e-tracker-milestone/${milestone_id}`
export const DELETE_ETRACKER_MILESTONE = milestone_id => `${API_URL}/e-tracker-milestone/${milestone_id}`

// Landscaping
export const COUNTRY_LANDSCAPING_FOCAL_AREAS = API_URL + "/country-landscaping-focal-area/"
export const LANDSCAPING_FOCAL_AREAS = API_URL + "/landscaping-focal-area/"
export const LANDSCAPING_TASKS = API_URL + "/country-landscaping-focal-area/tasks/"
export const LANDSCAPING_ANALYTICS = API_URL + "/data-analytics/landscaping"
export const LANDSCAPING_ANALYTICS_PUBLIC = API_URL + "/data-analytics/landscaping/public"
export const CREATE_LANDSCAPING_FOCAL_AREA = API_URL + "/landscaping-focal-area/"
export const GET_LOCKED_LANDSCAPING_FOCAL_AREA = focal_area_id => `${API_URL}/landscaping-focal-area/locked/${focal_area_id}`
export const GET_UNLOCKED_LANDSCAPING_FOCAL_AREA = focal_area_id => `${API_URL}/landscaping-focal-area/unlocked/${focal_area_id}`
export const EDIT_LANDSCAPING_FOCAL_AREA = focal_area_id => `${API_URL}/landscaping-focal-area/${focal_area_id}`
export const DELETE_LANDSCAPING_FOCAL_AREA = focal_area_id => `${API_URL}/landscaping-focal-area/${focal_area_id}`
// export const GET_LANDSCAPING_QUESTIONNAIRE = API_URL + "GET_LANDSCAPING_QUESTIONNAIRE"
export const COUNTRY_LANDSCAPING_FOCAL_AREA = (country_id, focal_area_id) => `${API_URL}/country-landscaping-focal-area/${country_id}/${focal_area_id}`
export const GET_LANDSCAPING_QUESTIONNAIRE = (country_id, focal_area_id) => `${API_URL}/country-landscaping-focal-area-question/${country_id}/${focal_area_id}`

export const LANDSCAPING_RESPONSE = API_URL + "/landscaping-response"

export const EDIT_COUNTRY_LANDSCAPING_FOCAL_AREA_QUESTION =
  (country_id, focal_area_id, question_id) =>
    `${API_URL}/country-landscaping-focal-area-question/${country_id}/${focal_area_id}/${question_id}`


//audit
export const AUDIT = API_URL + "/audit/add"
export const EXPORT_AUDIT = API_URL + "/audit/export"

//qualitative stats
export const ETRACKERSTATS = API_URL + "/report/statistics/tracker"

export const SEARCH_AUDITS = API_URL + "/audit/"

//tasks
export const GET_TASKS = API_URL + "/task/search"
export const LIST_ALL_USERS = API_URL + "/user/all"
export const REASSIGN_POLICY_TASK = API_URL + "/task/reassign-policy-tasks"
export const REASSIGN_BENCHMARKING_TASK =
  API_URL + "/task/reassign-benchmarking-tasks"

export const LIST_ORGANIZATIONS = API_URL + "/partner/"
export const LIST_ORGANIZATION_TYPES =
  API_URL + "/organization/list/organizations/types"

export const GET_USER_ORGANIZATIONS = API_URL + "/organisation/select"

export const CREATE_NEW_ORGANIZATION_TYPE = API_URL + "/organization/type"
export const CREATE_NEW_ORGANIZATION = API_URL + "/organization"

export const GET_ORGANIZATION_TYPES = API_URL + "/organization/types/find"

export const GET_ORGANIZATIONS = API_URL + "/organisation/all"
export const EDIT_ORGANIZATIONS = API_URL + "/organisation/{organisation_id}"
export const DeleteOrganization = API_URL + "/organisation/{organisation_id}"

export const GET_ORGANIZATION_TYPE_SUGGESTIONS =
  API_URL + "/organization/types/suggest"

export const GET_ORGANIZATION_SUGGESTIONS = API_URL + "/organization/suggest"
export const GET_SPECIFIC_ORGANIZATION_TYPE_DETAILS =
  API_URL + "/organization/type"

export const GET_SPECIFIC_ORGANIZATION_DETAILS = API_URL + "/organization"

//Organization
export const EXPORT_ORGANISATIONS = API_URL + "/organisation/export"
export const EDIT_USER_ORGANISATION = organisation_id => `${API_URL}/organisation/${organisation_id}`
export const DELETE_USER_ORGANISATION = organisation_id => `${API_URL}/organisation/${organisation_id}`

//Milestone
export const TASKS_COUNT = API_URL + "/tasks-count/"
export const CREATE_NEW_FORM = API_URL + "http://localhost:3001/form/create"
export const SPECIFIC_FORM_QUESTIONS = API_URL + "/form/question"
export const LIST_CURRENT_MILESTONE_OWNERS = API_URL + "/form/owners"
export const PUBLISH_MILESTONE = API_URL + "/form/create"
// export const GET_QUESTIONS_OF_EXISTING_MILESTONE = API_URL + "/form/questions"
export const EDIT_SPECIFIC_MILESTONE = API_URL + "/form/edit"

//Benchmarking
export const BENCHMARKING = API_URL + "/form/benchmarking"

export const BENCHMARKING_RESPONSE = API_URL + "country-benchmarking-milestone-question"
export const GET_BENCHMARKING_FORM_DETAILS = API_URL + "/form/label"

export const SYSTEMFORMFILEDOWNLOAD = API_URL + "/form/download"
export const GET_MILESTONE_QUESTIONS = (country_id, milestone_id) => `${API_URL}/country-benchmarking-milestone-question/${country_id}/${milestone_id}`

// Landscaping
export const EXPORT_LANDSCAPING_FOCAL_AREAS = API_URL + "/landscaping-focal-area/export"
export const LIST_LANDSCAPING_FOCAL_AREAS = (landscapingCategory) => API_URL + `/landscaping-focal-area/select?landscaping_category=${landscapingCategory}`

//Policy Form
export const GET_FORM = API_URL + "/form"
export const GET_LANDSCAPING_FORM = API_URL + "/form/subcategory"
export const GET_RESPONSES_OF_FORM = API_URL + "/form/response"

// invite user

export const INVITE_USER = API_URL + "/user/invite"
//Partners
export const GET_PARTNERS = API_URL + "/partner/"
export const EXPORT_PARTNERS = API_URL + "/partner/export"
export const CREATE_NEW_PARTNER = API_URL + ""
export const EDIT_PARTNER = partner_id => `${API_URL}/partner/${partner_id}`
export const DELETE_PARTNER = partner_id => `${API_URL}/partner/${partner_id}`

//Policy Directory
export const GET_POLICIES = API_URL + "/policy/"
export const POST_POLICY_VIEW = policy_id => `${API_URL}/public-policy-view/${policy_id}`
// export const POST_POLICY_VIEW = (policyId) => `/public-policy-view/${policyId}`;
export const DOWNLOAD_POLICY = policy_id => `${API_URL}/policy/${policy_id}/policy-file`
export const GET_PUBLIC_POLICIES = API_URL + "/policy/public"
export const GET_PUBLIC_POLICY_URL = policy_id => `${API_URL}/policy/public/${policy_id}/policy-file/`
export const EXPORT_POLICIES = API_URL + "/policy/export"
export const EXPORT_POLICIES_PUBLIC = API_URL + "/policy/export/public"

//User Directory
export const EXPORT_USERS = API_URL + "/user/export"

// Tasks
export const EXPORT_ETRACKER_TASKS = API_URL + "/policy/tasks/export"
export const EXPORT_BENCHMARKING_TASKS = API_URL + "/country-benchmarking-milestone/tasks/export"
export const EXPORT_LANDSCAPING_TASKS = API_URL + "/country-landscaping-focal-area/tasks/export"
export const EXPORT_COUNTRY_LANDSCAPING_FOCAL_AREAS = API_URL + "/country-landscaping-focal-area/export"

//Country Updates
export const GET_COUNTRY_EVENTS = API_URL + "/country-event/"
export const GET_COUNTRY_EVENTS_PUBLIC = API_URL + "/country-event/public/"

export const CREATE_EVENT = API_URL + "/country-event/"
export const EDIT_COUNTRY_EVENT = API_URL + "/country-event/{country_event_id}"
export const EDIT_POLICY_UPDATE = policy_update_id => `${API_URL}/policy-update/${policy_update_id}`
export const DELETE_UPDATE = API_URL + "/policy-update/{policy_update_id}"
export const DELETE_COUNTRY_EVENT = API_URL + "/country-event/{country_event_id}"

//Policy Updates 
export const GET_POLICY_UPDATES = API_URL + "/policy-update/"
export const GET_POLICY_UPDATES_PUBLIC = API_URL + "/policy-update/public/"

export const CREATE_POLICY_UPDATE = API_URL + "/policy-update/"
export const GET_POLICY_UPDATE_PICTURE = (policy_update_id) =>
  `${API_URL}/policy-update/${policy_update_id}/picture`


//Countries
export const GET_COUNTRIES = API_URL + "/country/"

//E-Tracker
export const ETRACKER_OVERVIEW = API_URL + "/data-analytics/overview";
export const ETRACKER_OVERVIEW_PUBLIC = "/data-analytics/overview/public";
export const ETRACKER_ETHIOPIA_POLICY_REGULATORY_FRAMEWORK_ANALYSIS = API_URL + "/data-analytics/ethiopia-policy-regulatory-framework?country_id=2";
export const ETRACKER_STAGE = (stageID) => API_URL + "/data-analytics/" + stageID;
export const ETRACKER_STAGE_PUBLIC = (stageID) => API_URL + `/data-analytics/${stageID}/public`;
export const ETRACKER_POLICY_ANALYTICS = (country_id,category) => `${API_URL}/data-analytics/policy-tracker?${country_id?`country_id=${country_id}`:""}&${category?`category=${category}`:""}`
export const ETRACKER_POLICY_WINS = API_URL + "/data-analytics/policy-win"
export const ETRACKER_POLICY_WINS_PUBLIC = API_URL + "/data-analytics/policy-win/public"
export const GET_ETRACKER_MILESTONE_QUESTIONS = (policy_id, stage_id) => `${API_URL}/policy-e-tracker-milestone-question/${policy_id}/${stage_id}`

// export const GET_MILESTONE_QUESTIONS = (country_id, milestone_id) => `${API_URL}/country-benchmarking-milestone-question/${country_id}/${milestone_id}`;

//PLM
export const POLICY = API_URL + "/policy/"

//Change user Password 
export const CHANGE_ME_PASSWORD = API_URL + "/user/me/password"
export const CHANGE_ME_PROFILE = API_URL + "/user/me"

export const CHANGE_USER_NAME = API_URL + "/user/me"

export const BENCHMARKING_ANALYTICS = API_URL + "/data-analytics/benchmarking"
export const BENCHMARKING_ANALYTICS_PUBLIC = API_URL + "/data-analytics/benchmarking/public"


// Notifications
export const CREATE_NOTIFICATION = API_URL + "/notification/"
export const GET_NOTIFICATIONS = API_URL + "/notification/"
export const UPDATE_NOTIFICATION = notification_id => `${API_URL}/notification/${notification_id}`