import React, { useState } from 'react';
import PolicyUpdates from './PolicyUpdates';
import CountriesDropdown from 'components/Common/countries-dropdown';




export default function HomePolicyUpdates() {

    const [countryID, setCountryID] = useState(null);

   

    return (


        <div className='w-11/12 mx-auto my-4'>

            <div className='bg-[#036f36d9] p-4'>
                <CountriesDropdown countryID={countryID} setCountryID={setCountryID} hasRAC={true}/>
            </div>
            
            <PolicyUpdates externalCountryID={countryID} showAdd={false}/>
        </div>

       
    )
}