import React, { useState, useMemo } from "react";
import { Table } from "reactstrap";
import styled from "@emotion/styled";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

// Styled Components
const StyledTableContainer = styled.div`
  width: 100%;
  padding: 1em;
  overflow-x: auto;
`;

const FirstColumn = styled.td`
  display: flex;
  align-items: center;
  padding-left: ${(props) => (props.isCategory ? "0" : "1.5rem")};
  font-weight: ${(props) => (props.isCategory ? "500" : "normal")};
  color: ${(props) => (props.isCategory ? "#036F36" : "#049046")};
  border-left: 5px solid ${(props) =>
    props.isCategory ? "#036F36" : "transparent"};
  gap: 8px;
`;

const StyledTable = styled(Table)`
  width: max-content;
  min-width: 100%;
  border-radius: 16px 16px 0 0;
  border-collapse: separate;
  border-spacing: 0;

  & thead {
    background-color: #f8f9fa;
    position: sticky;
    top: 0;
    z-index: 1;
    border-radius: 16px 16px 0 0;
  }
  & th {
    padding: 1em;
    background-color: #f0f0f0;
    font-weight: 500;
    text-align: center;
    border-bottom: 2px solid #dee2e6;
    white-space: nowrap;
  }
  & td {
    padding: 0.8em;
    border-bottom: 1px solid #dee2e6;
    background-color: white;
    vertical-align: middle;
    text-align: center;
  }
  & td:first-of-type {
    text-align: left;
  }
`;

const LegendContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1em;
`;

const Dot = styled.span`
  height: 16px;
  width: 16px;
  background-color: ${(props) => props.color};
  border-radius: 50%;
  display: inline-block;
  margin-right: 8px;
`;

const LegendText = styled.span`
  margin-right: 24px;
`;

const getColorForScore = (score) => {
  return score ? "#00cc00" : "#ff3300";
};

const getScoreText = (score) => {
  return score ? "Present" : "Absent";
};

const TabularFormsTable = ({ forms = {}, countryID = 0 }) => {
  const [expandedCategories, setExpandedCategories] = useState({});

  const toggleCategory = (category) => {
    setExpandedCategories((prev) => ({
      ...prev,
      [category]: !prev[category],
    }));
  };

  // Prepare data for export
  const exportData = useMemo(() => {
    const data = [];
    
    // Add header row
    const headers = [
      "Category/Focal Area",
      "All Countries",
      ...(forms?.overall_score_data?.country_data?.map(country => country.long_name) || [])
    ];
    data.push(headers);

    // Add overall score row
    data.push([
      "Agricultural Transformation",
      Math.round(forms?.overall_score_data?.all_countries_average_score),
      ...(forms?.overall_score_data?.country_data?.map(country => Math.round(country.average_score)) || [])
    ]);

    // Add category and focal area rows
    (forms?.landscaping_area_data?.landscaping_data || []).forEach((landscaping, i) => {
      const { landscaping_category, average_score } = landscaping;
      
      // Add category row
      const categoryScores = forms?.landscaping_area_data?.country_data
        ?.filter(data => data.landscaping_category === landscaping_category)
        .map(country => Math.round(country.average_score));
      
      data.push([
        `${i + 1}) ${landscaping_category}`,
        Math.round(average_score),
        ...(categoryScores || [])
      ]);

      // Add focal area rows
      const focalAreas = forms?.focal_area_data?.focal_area_data?.filter(
        focal => focal.landscaping_category === landscaping_category
      ) || [];

      focalAreas.forEach((focal, focalIndex) => {
        const { focal_area, average_score: focalAvgScore } = focal;
        const focalScores = forms?.overall_score_data?.country_data?.map(country => {
          const countryFocal = forms?.focal_area_data?.country_data?.find( //code can be optimised for time complexity
            f => f.focal_area === focal_area &&
                f.landscaping_category === landscaping_category &&
                f.country_id === country.country_id
          );
          return countryFocal ? getScoreText(countryFocal.average_score) : "Absent";
        });

        data.push([
          `${i + 1}.${focalIndex + 1}) ${focal_area}`,
          getScoreText(focalAvgScore),
          ...(focalScores || [])
        ]);
      });
    });

    return data;
  }, [forms]);

  const renderFocalAreas = (category, categoryIndex) => {
    const focalAreas = forms?.focal_area_data?.focal_area_data?.filter(
      (focal) => focal.landscaping_category === category
    ) || [];

    return focalAreas.map((focal, focalIndex) => {
      const { focal_area, average_score } = focal;

      return (
        <tr key={`${categoryIndex}-${focalIndex}`}>
          <FirstColumn>{`${categoryIndex}.${focalIndex + 1}) ${focal_area}`}</FirstColumn>
          <td>
            <Dot color={getColorForScore(Math.round(average_score))} />
          </td>
          {forms?.overall_score_data?.country_data?.map((country, i) => {
            const countryFocal = forms?.focal_area_data?.country_data?.find(
              (f) =>
                f.focal_area === focal_area &&
                f.landscaping_category === category &&
                f.country_id === country.country_id
            );
            // const score = countryFocal ? Math.round(countryFocal.average_score) : 0;
            return (
              <td key={i}>
                <Dot color={getColorForScore(countryFocal.average_score)} />
              </td>
            );
          })}
        </tr>
      );
    });
  };

  // Expose exportData for parent component
  TabularFormsTable.exportData = exportData;

  return (
    <StyledTableContainer>
      <LegendContainer>
        <LegendText style={{ fontSize: '18px' }}>
          <b>Policy Status Indicator:</b>
        </LegendText>
        <LegendText style={{ fontSize: '16px' }}>
          <Dot color="#ff3300" /> Policy (or Policies) Presence
        </LegendText>
        <LegendText style={{ fontSize: '16px' }}>
          <Dot color="#00cc00" /> Policy (or Policies) Absence
        </LegendText>
      </LegendContainer>

      <StyledTable>
        <thead>
          <tr>
            <th>Category</th>
            <th>All Countries</th>
            {forms?.overall_score_data?.country_data?.map((country, i) => (
              <th key={i}>{country.long_name}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            <FirstColumn isCategory>Agricultural Transformation</FirstColumn>
            <td>{Math.round(forms?.overall_score_data?.all_countries_average_score)}</td>
            {forms?.overall_score_data?.country_data?.map((country, i) => (
              <td key={i}>{Math.round(country.average_score)}</td>
            ))}
          </tr>
          {(forms?.landscaping_area_data?.landscaping_data || []).map(
            (landscaping, i) => {
              const { landscaping_category, average_score } = landscaping;

              return (
                <React.Fragment key={i}>
                  <tr>
                    <FirstColumn isCategory>
                      <button
                        style={{
                          background: "none",
                          border: "none",
                          cursor: "pointer",
                          marginRight: "8px",
                        }}
                        onClick={() => toggleCategory(landscaping_category)}
                      >
                        {expandedCategories[landscaping_category] ? <FaChevronUp /> : <FaChevronDown />}
                      </button>
                      {`${i + 1}) ${landscaping_category}`}
                    </FirstColumn>
                    <td>{Math.round(average_score)}</td>
                    {forms?.landscaping_area_data?.country_data
                      ?.filter(
                        (data) =>
                          data.landscaping_category === landscaping_category
                      )
                      .map((country, j) => (
                        <td key={j}>{Math.round(country.average_score)}</td>
                      ))}
                  </tr>
                  {expandedCategories[landscaping_category] &&
                    renderFocalAreas(landscaping_category, i + 1)}
                </React.Fragment>
              );
            }
          )}
        </tbody>
      </StyledTable>
    </StyledTableContainer>
  );
};

export default TabularFormsTable;