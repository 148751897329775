import CountriesDropdown from 'components/Common/countries-dropdown';
import React, { useEffect, useState } from 'react';
import { benchmarkingAnalytics } from 'services/benchmarkingServices';
import SystemsDropdown from './SystemsDropdown';
import BenchmarkingBarChart from './BarChart';
import { Button } from 'reactstrap';
import downloadChart from 'components/Common/ImageToPdf';
import { Icon } from '@iconify/react';
import LoadingSpinner from 'components/Common/Loading-Spinner';

function Visualisation({ alert }) {

    const [tab, setTab] = useState('system');
    const [countryID, setCountryID] = useState();
    const [systemID, setSystemID] = useState();
    const [data, setData] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [isDownloading, setIsDownloading] = useState(false);

    const getData = async () => {
        try{
            setIsLoading(true);
            let query = {
                view_type: tab,
                ...(countryID && { country_id: countryID }),
                ...(systemID && { system_id: systemID })
            };

            setData(await benchmarkingAnalytics(query));

        } catch (err) {
            setData();
            console.error(err);
            alert('Fetching data', false);
        }finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getData();
    }, [tab, systemID, countryID])

    return (
        <div>
            <div className="d-flex justify-content-between">
                <div className="country-selection">
                    <span className="mr-2 text-nowrap">Showing data for </span>
                    {
                        tab === 'system'
                            ?
                            <SystemsDropdown systemID={systemID} setSystemID={setSystemID} />
                            :
                            <CountriesDropdown countryID={countryID} setCountryID={setCountryID} hasRAC={true} />
                    }
                </div>
                <Button className="common-button" disabled={isLoading || isDownloading} onClick={()=>downloadChart('benchmark-chart', setIsDownloading, `benchmark-chart-${tab}-${(data?.system_name || data?.long_name) ? (data?.system_name || data?.long_name): 'All'}-${new Date().toISOString().split('T')[0]}.pdf`)}>
                    {isDownloading ? <Icon icon="codex:loader"/> : <i className="bx bx-download"/>}
                </Button>
            </div>
            <div className='d-flex justify-center'>
                <div className={`sub-tab mr-1 ${tab === 'system' ? 'sub-tab-active' : ''}`} onClick={()=>setTab('system')}>System</div>
                <div className={`sub-tab ml-1 ${tab === 'country' ? 'sub-tab-active' : ''}`} onClick={()=> setTab('country')}>Country</div>
            </div>
            <div id="benchmark-chart">
                {isLoading ? (
                    <LoadingSpinner/>
                ) : data ? (
                    <BenchmarkingBarChart data={data?.data || data} tab={tab} filterSelected={data?.system_name || data?.long_name} dottedLineValue={data?.all_countries_system_average} region={data?.region}/>
                ) : <p>Unable to fetch data.</p>}
            </div>
        </div>
    );
}

export default Visualisation;