import React from "react";
import Organizations from "./Organizations";
import { Table } from "reactstrap";
import styled from "@emotion/styled";
import "./Organizations.css";
import DeleteModal from "../../components/Common/DeleteModal"

const StyledTable = styled(Table)`
width: 100%;
  table-layout: fixed;
  background-color: transparent;
  border-radius: 16px 16px 0px 0px;
  overflow: hidden;

  & thead {
    background-color: #f8f9fa;
  }

  // & th {
  //   padding-block: 1.5em;
  //   background-color: ##F0F0F0;
  //   text-align: left;
  //   font-size: 1rem;
  //   color: #495057;
  //   border-bottom: 2px solid #dee2e6;
  // }

  & td {
    padding: 1em;
    text-align: left;
    font-size: 14px;
    font-weight: 500;
    border: none; /* Remove all borders */
    border-bottom: 1px solid #dee2e6; /* Only keep the bottom border */
    vertical-align: middle;
    background-color: white; /* Ensure the background is white */
  }


  & tbody {
    & tr {
      background-color: white !important; /* Ensure all rows have a white background */
      cursor: default;

      &:hover {
        background-color: #f8f9fa; /* Add a hover effect for rows */
      }
    }

  }

  & tbody tr td:first-of-type {
    .name-cell {
      padding: 0.2em 0.4em;
      border-radius: 0.8em;
      background-color: rgba(80, 165, 241, 0.1);
      color: rgba(80, 165, 241);
    }
  }
`;

const StyledButtonGroup = styled.div`
  display: flex;
  gap: 1em;
  border-collapse: separate;

  & button {
    all: unset;
    cursor: pointer;
  }

  & .mdi-pencil {
    color: #4caf50; /* Green for edit */
    transition: transform 0.2s;
  }

  & .mdi-delete {
    color: #f44336; /* Red for delete */
    transition: transform 0.2s;
  }

  & button:hover .mdi-pencil,
  & button:hover .mdi-delete {
    transform: scale(1.1);
  }
`;

const StyledTableContainer = styled.div`
  width: 100%;
  padding: 1em;
  overflow-x: auto; /* Allows horizontal scroll if necessary */
`;

export const CustomTable = ({ data, caption, initiateEdit, onDeleteClick }) => {
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [deletionObjectId, setDeletionObjectId] = React.useState();

  return (
    <StyledTableContainer>
      <StyledTable className="custom-table">
        <colgroup>
          <col style={{ width: "20%" }} />
          <col style={{ width: "20%" }} />
          <col style={{ width: "30%" }} />
          <col style={{ width: "20%" }} />
          <col style={{ width: "20%" }} />
          <col style={{ width: "20%" }} />
          <col style={{ width: "20%" }} />
          <col style={{ width: "20%" }} />
          <col style={{ width: "15%" }} />
        </colgroup>
        <thead>
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Short Name</th>
            <th scope="col">Countries</th>
            <th scope="col">Status</th>
            <th scope="col">Created By</th>
            <th scope="col">Created At</th>
            <th scope="col">Updated By</th>
            <th scope="col">Updated At</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((org, i) => (
            <tr key={i}>
              <td style={{ color: "#495057" }}>{org.long_name}</td>
              <td>{org.short_name}</td>
              <td>
                <p>
                  {org.country?.map((country) => country.country.long_name).join(", ")}
                </p>
              </td>
              <td> {org?.is_archived ? "Archived" : "Active"} </td>
              <td style={{ fontWeight: "400" }}>{`${org.created_by?.first_name || ""} ${org.created_by?.last_name || ""}`.trim() || ""}</td>
              <td>{org.created_at}</td>
              <td>{`${org?.updated_by?.first_name || ""} ${org.updated_by?.last_name || ""}`.trim() || ""}</td>
              <td>{org.updated_at}</td>
              <td>
                <StyledButtonGroup>
                  <button onClick={() => initiateEdit(org.id)}>
                    <i className="mdi mdi-pencil font-size-18 hover-event text-success" />
                  </button>
                  <button
                    onClick={() => {
                      setDeleteModal(true);
                      setDeletionObjectId(org.id);
                    }}
                  >
                    <i className="mdi mdi-delete font-size-18 del-icon text-danger" />
                  </button>
                </StyledButtonGroup>
              </td>
            </tr>
          ))}
        </tbody>
        <DeleteModal
          show={deleteModal}
          onCloseClick={() => setDeleteModal(false)}
          onDeleteClick={() => onDeleteClick(deletionObjectId)}
          item={caption}
        />
      </StyledTable>
    </StyledTableContainer>

  );
};

export default function OrganizationManagement({ alert }) {
  document.title = "Organizations Management";

  return (
    <div className="page-content">
      <Organizations alert={alert} />
    </div>
  );
}