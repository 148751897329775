import React from 'react'
import styles from './styles/policy_landscaping.module.css'
// import Image from 'next/image';
import PolicyTableCell from './PolicyTableCell';
import MileStonePopUp from './MileStonePopUp';


const PolicyLandscapingTable = ({ label, stages,countries }) => {

    const getIndexCounter = (c_index, index) => {
        if(c_index === 0){
            return index+1
        }
        // calculate sum of all policies lenth in previous countries befor current index
        let countries_list = countries?.slice(0, c_index)
        let sum = countries_list?.reduce((total, country) => total + country.policies?.length, 0)
        return sum + index + 1
    }

    const getTotalTargets = () => {
        let total = 0
        countries?.forEach(country => {
            total += country.policies?.length
        })
        return total
    }

    const randomColor = () => {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
          color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
      }

    return (

        <div className={`${styles.policyLandscaping}`}>

            <div className={` ${styles.flexR} ${styles.flexRCcenterY}`}>
                <div className={`${styles.flexG} ${styles.flexC}`}>
                    <h1 className={`${styles.policyBold} ${styles.policyHeading}`}>
                        {label}
                    </h1>
                    <div className={`${styles.glexG} ${styles.dividerT}`}>
                    </div>
                </div>
                <div>
                <img
                    src="/static/media/agra_logo2_cropped.41762811ca376b496d62.png"
                    alt="Logo"
                    width={90}
                    height={45}
                    className={styles.logoImage} // Apply the custom styles
                />
                </div>
            
            </div>

            <div className={styles.tableScroll}>
                <table className={styles.policyTable}>
                    <thead>
                        <tr>
                            <th className={styles.countryCell}>

                            </th>
                            <th className={styles.targetCell}>
                                Total <br /> 
                            </th>
                            <th>
                                Policy
                            </th>
                            {stages?.map((stage, index) => (
                                <th key={stage.name}>
                                    <p className={styles.policyBold}>
                                    Stage {index + 1}: {stage.name }
                                    </p>
                                </th>
                            ))}

                        </tr>
                    </thead>
                

                <tbody>
                    {
                        countries?.map((country, c_index) => (

                            country.policies?.map((policy,p_index) => (

                                <tr key={policy.name}>
                                    {
                                        p_index === 0 && (
                                            <td rowSpan={country.policies?.length} className={styles.countryCell}>
                                                <p className={styles.policyBold}>
                                                    {country.name}
                                                </p>
                                            </td>
                                        )
                                    }

                                    <td className={styles.targetCell}>
                                        <p className={styles.policyBold}>
                                            {getIndexCounter(c_index, p_index)}
                                        </p>
                                    </td>

                                    <td className={styles.policyNameCell}>
                                        <p>
                                            {policy.name}
                                        </p>
                                    </td>

                                    {
                                        stages?.map((stage, s_index) => (

                                            <td key={stage.name}>
                                                <PolicyTableCell stage_index={s_index+1} policy={policy}/>
                                            </td>
                                        ))
                                    }
                                    
                                </tr>
                                    
                            ))

                            
                        ))
                                    
                    }
                </tbody>

                <tfoot>
                    <tr>
                        <td>
                            <p className={styles.policyBold}>Total</p>
                        </td>
                        <td className={styles.targetCell}>
                            <p className={styles.policyBold}>{getTotalTargets()}</p>
                        </td>
                        <td>
                            <MileStonePopUp></MileStonePopUp>
                        </td>
                        {stages?.map((stage, index) => (
                            <td key={stage.name}>
                                <div className={`${styles.flexR} ${styles.flexRCcenterY} ${styles.flexGapSm}`}>
                                    {stage.milestones?.map((milestone, m_index) => (
                                        <div key={milestone.name} className={`${styles.flexG} ${styles.milestoneIndicator}`} style={{backgroundColor: randomColor()}}>
                                            <MileStonePopUp hoverConent={``} mainContent={milestone}/>
                                        </div>
                                    ))}
                                </div>
                            </td>
                        ))}

                    </tr>
                </tfoot>
                
                </table>
            </div>


        </div>
    )
}   


export default PolicyLandscapingTable