import { get } from "./AXIOS"
import { HOME_PAGE_DASHBOARD, HOME_PAGE_DASHBOARD_PUBLIC, KEY_METRICS_HOME, } from "./CONSTANTS"
import { buildQueryParams } from "helpers/utlities";

export const getKeyMetrics = async () => {
  try {
    return await get(KEY_METRICS_HOME)
  } catch (e) {
    console.log("Error", e)
    return e
  }
}

export const getHomePageDashboardData = async (query) => {

  const params = buildQueryParams({
    ...(query?.countryID !== 0 && { country_id: query?.countryID }),
  });

  const isLoggedIn = localStorage.getItem('token');

  try {
    return await get(`${isLoggedIn ? HOME_PAGE_DASHBOARD : HOME_PAGE_DASHBOARD_PUBLIC}?${params.toString()}`);
  } catch (e) {
    console.log("Error", e);
    return e;
  }
}