import PropTypes from "prop-types"
import React from "react"

import { Switch, BrowserRouter as Router } from "react-router-dom"
import { connect } from "react-redux"

// Import Routes all
import { authProtectedRoutes, publicRoutes, reportRoutes } from "./routes"

// Import all middleware
import Authmiddleware from "./routes/route"

// layouts Format
import VerticalLayout from "./components/VerticalLayout/"
import HorizontalLayout from "./components/HorizontalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"
import { QueryClientProvider } from "@tanstack/react-query"
import { queryClient } from "hooks/queries/queryClient"
// Import scss
import "./assets/scss/theme.scss"
import "./assets/scss/agra.scss"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import HeaderLayout from "components/NonAuthHeaderLayout"

const App = props => {
  function getLayout() {
    let layoutCls = VerticalLayout
    switch (props.layout.layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout
        break
      default:
        layoutCls = VerticalLayout
        break
    }
    return layoutCls
  }

  const Layout = getLayout()

  // Function to determine which layout to use
  const getRouteLayout = (path) => {
    const headerPaths = ['/', '/home-tracker','/home-policies','/home-policy-updates', '/home-benchmarking', '/home-landscape', '/home-frequently-asked-questions']
    return headerPaths.includes(path) ? HeaderLayout : NonAuthLayout
  }

  return (
    <React.Fragment>
      <QueryClientProvider client={queryClient}>
        <Router>
          <Switch>
            {publicRoutes.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                layout={getRouteLayout(route.path)}
                component={route.component}
                key={idx}
                title={route.title || ""}
                iconClass={route.icon || ""}
                isAuthProtected={false}
                exact
              />
            ))}

            {authProtectedRoutes.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                layout={Layout}
                component={route.component}
                key={idx}
                title={route.title || route.path.replace(/[:/_-]/g, " ")}
                iconClass={route.icon || ""}
                isAuthProtected={true}
                exact
              />
            ))}

            {reportRoutes.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                layout={Layout}
                component={route.component}
                key={idx}
                title={route.title || route.path.replace(/[:/_-]/g, " ")}
                iconClass={route.icon || ""}
                isAuthProtected={true}
                exact
              />
            ))}
          </Switch>
        </Router>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </React.Fragment>
  )
}

App.propTypes = {
  layout: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  }
}

export default connect(mapStateToProps, null)(App)
