import React, { useState, useEffect } from "react"
import { Button, Card, CardBody, CardHeader, ModalHeader, Modal, ModalBody } from "reactstrap"
import SearchBox from "components/Common/SearchBox"
import Pagination from "components/Common/Pagination"
import LoadingSpinner from "components/Common/Loading-Spinner"
import useDebounce from "hooks/useDebounce"
import { StyledContainer } from "pages/Labels/Systems"
import ExportTable from "../../../components/Common/ExportTable"
import { exportTableData } from "services/common"
import { getBenchmarkingTasks } from "services/milestoneServices"
import BenchmarkingTable from "../BenchmarkingTasksTable"
import BenchmarkingTasksFilters from "./Filters"
import '../TasksManagement.css';

const BenchmarkingTasks = ({ alert }) => {
  // const title = formType === "ETracker" ? "E-Tracker Milestones" : "Benchmarking Milestones";
  // document.title = "Milestone Management | AGRA";

  const [searchField, setSearchField] = useState("")
  const debouncedSearchTerm = useDebounce(searchField);
  const [selectedLocations, setSelectedLocations] = useState([])
  const [complete, setComplete] = useState(null);

  const [currentPage, setCurrentPage] = useState(1)
  const [totalEntries, setTotalEntries] = useState(0)
  const [offset, setOffset] = useState(0)

  // Manage loading, error, and milestonesData states
  const [tasksData, setTasksData] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  const [modalFilter, setModalFilter] = useState(false)
  const toggleFilter = () => setModalFilter(prevState => !prevState)

  const searchChange = e => {
    setSearchField(e.target.value.toLowerCase())
    setOffset(0)
    setCurrentPage(1)
  }
  
  const fetchTasks = async () => {
    const filters = {
      locations: selectedLocations?.map(location=>location.value),
      complete: complete?.value,
      limit: 50,
      offset
    };
    setIsLoading(true);
    try {
      const {data, count} = await getBenchmarkingTasks(filters);
      setTasksData(data)
      setTotalEntries(count)
    } catch (error) {
      console.error(error)
      alert("Fetching data", false)
    } finally {
      setIsLoading(false)
    }
  }

  const applyFilters = (locations, complete) => {
    setSelectedLocations(locations);
    setComplete(complete);
    toggleFilter();
  }

  const goToPage = page => {
    if (page > 0) {
      const offsetParam = page * 50 - 50
      setOffset(offsetParam)
      setCurrentPage(page)
    } else {
      console.error("Page number should be greater than 0")
    }
  }

  const checkForFilters = () => selectedLocations.length || complete;

  const clearFilters = () => {
    setSelectedLocations([]);
    setComplete(null);
  }  

  useEffect(() => {
    fetchTasks();
  }, [
    selectedLocations, 
    complete, 
    debouncedSearchTerm, 
    currentPage
  ])

  const exportTable = async type => {
    try {
      const query = {
        export_format: type
        // ...(selectedUser && { user_id: selectedUser }),
      }

      exportTableData(query, "Benchmarking_Tasks")

    } catch (err) {
      console.error(err)
      alert("Data export", false)
    }
  }

  return (
    <div>
      <Card>
        <CardHeader className="bg-white">
          {/*<h4><i className="bx bx-file"></i> {title}</h4>*/}
          <div className="d-flex justify-content-between">
            <StyledContainer>
              <SearchBox searchChange={searchChange} />
              <Button
                className="common-button"
                onClick={toggleFilter}
              >
                <i className="mdi mdi-filter-variant me-1" /> Filters
              </Button>

              {checkForFilters() ? 
                <Button
                  className="common-button"
                  onClick={clearFilters}
                >
                  <i className="ph-x me-1"></i>
                  Clear All Filters
                </Button> : null
              }
            </StyledContainer>
            <ExportTable loadFilteredData={exportTable}></ExportTable>
          </div>
          <Modal isOpen={modalFilter} toggle={toggleFilter} className="modal-lg">
            <ModalHeader toggle={toggleFilter}>
              <i className="mdi mdi-filter-variant me-1"></i>
              Filters
            </ModalHeader>
            <ModalBody>
              <BenchmarkingTasksFilters
                filteredLocations={selectedLocations}
                filteredComplete={complete}
                applyFilters={applyFilters}
              />
            </ModalBody>
          </Modal>
        </CardHeader>
        {(isLoading) && <LoadingSpinner />}
        <CardBody>
          {tasksData?.length > 0 ? (
            <>
                <BenchmarkingTable
                  forms={tasksData}
                />
                <Pagination
                  currentPage={currentPage}
                  goToPage={goToPage}
                  totalEntries={totalEntries}
                />
            </>
          ) : null}
        </CardBody>
      </Card>
    </div>
  )
}

export default BenchmarkingTasks
