import React, { useState, useEffect, useCallback } from "react"
import { FiArrowLeft, FiArrowRight } from "react-icons/fi"
import BenchmarkingIcon from '../../assets/images/BenchmarkingIcon.svg';
import CustomisableIcon from '../../assets/images/CustomisableIcon.svg';
import PolicyNewsAndUpdatesIcon from '../../assets/images/PolicyNewsAndUpdatesIcon.svg';
import RealTimePolicyTrackingIcon from '../../assets/images/RealTimePolicyTrackingIcon.svg';

export const HomeResultSlider = ({ slides }) => {
  const [currentIndex, setCurrentIndex] = useState(0)

  const svgs = [RealTimePolicyTrackingIcon, CustomisableIcon, BenchmarkingIcon, PolicyNewsAndUpdatesIcon];

  // Function to move to the next slide
  const nextSlide = () => {
    setCurrentIndex(prevIndex => (prevIndex + 1) % slides.length)
  }
  const prevSlide = useCallback(() => {
    setCurrentIndex(prevIndex => (prevIndex > 0 ? prevIndex - 1 : slides.length - 1));
  }, [slides.length]);

  useEffect(()=>{
    const timer = setInterval(()=>{
      nextSlide();
    }, 10000)
    return () => clearInterval(timer);
  },[nextSlide]);

  return (
    <div className="flex flex-row items-center justify-evenly">
      <div
        className="rounded-full h-12 w-12 flex justify-center items-center border-2 border-green-800 cursor-pointer"
        onClick={prevSlide}
      >
        <FiArrowLeft color="#137A08" size="1.75rem" />
      </div>
      <div className="flex flex-col w-[24rem]">

        <div className="rounded-2xl shadow-md p-3 flex items-center h-48">
          <div>
            <p className="text-green-800 text-2xl mb-1">{slides[currentIndex]?.title}</p>
            <div className="text-base">
              {slides[currentIndex]?.message}
            </div>
          </div>
          <div className="ml-4">
            <img
              src={svgs[currentIndex]}
              alt='icon'
              className="w-64"
            />
          </div>
        </div>

        {/* dots */}
        <div className="flex flex-row mt-4 justify-center">
          {slides && slides.length > 0
            ? slides.map((_, index) => {
                return (
                  <div
                    key={index}
                    className={`w-[0.8125rem] h-[0.8125rem] rounded-full mr-2 ${
                      currentIndex === index ? "bg-green-800" : "bg-[#D9D9D9]"
                    }`}
                  ></div>
                )
              })
            : null}
        </div>

      </div>
      <div
        className="rounded-full h-12 w-12 flex justify-center items-center border-2 border-green-800 cursor-pointer"
        onClick={nextSlide}
      >
        <FiArrowRight color="#137A08" size="1.75rem" />
      </div>
    </div>
  )
}
