import React, { useEffect, useState, useMemo } from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Table,
} from "reactstrap"

import { Pdate, Name, Idno } from "./CryptoCol"

import TableContainer from "../../../components/Common/TableContainer"
import { formateOnlyDate } from "helpers/utlities"

import { getAccessCodeDetail } from "services"

const AccessCodeDetail = props => {
  document.title = "Access Code Information | AGRA"

  const {
    match: { params },
  } = props

  const [accessCodeDetail, setAccessCodeDetail] = useState()
  // eslint-disable-next-line no-unused-vars

  useEffect(() => {
    if (params && params.id) {
      getAccessCodeDetail(params.id).then(res => {
        setAccessCodeDetail(res.data)
      })
    }
  }, [])

  const columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: "id",
        disableFilters: true,
        filterable: true,
        Cell: cellProps => {
          return <Idno {...cellProps} />
        },
      },
      {
        Header: "Email",
        accessor: "email",
        disableFilters: true,
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />
        },
      },
      {
        Header: "Token",
        accessor: "token",
        disableFilters: true,
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />
        },
      },
      {
        Header: "Joined At",
        accessor: "joined_at",
        disableFilters: true,
        filterable: true,
        Cell: cellProps => {
          return <Pdate {...cellProps} />
        },
      },
    ],
    []
  )

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {accessCodeDetail && (
            <Row>
              <Col xl="12">
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">Detail</CardTitle>
                    <div className="table-responsive">
                      <Table className="table table-bordered border-success mb-0 table-striped">
                        <tbody>
                          <tr>
                            <th scope="row">Access Code</th>
                            <td>{accessCodeDetail.code}</td>
                          </tr>
                          <tr>
                            <th scope="row">Organization</th>
                            <td>{accessCodeDetail?.organization?.name}</td>
                          </tr>
                          <tr>
                            <th scope="row">Token</th>
                            <td>{accessCodeDetail.token}</td>
                          </tr>
                          <tr>
                            <th scope="row">Role</th>
                            <td>{accessCodeDetail.role}</td>
                          </tr>
                          <tr>
                            <th scope="row">Email</th>
                            <td>{accessCodeDetail.email}</td>
                          </tr>
                          <tr>
                            <th scope="row">Joined At</th>
                            <td>{accessCodeDetail.joined_at || "-"}</td>
                          </tr>
                          <tr>
                            <th scope="row">Created At</th>
                            <td>
                              {formateOnlyDate(accessCodeDetail.created_at)}
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">Updated At</th>
                            <td>
                              {formateOnlyDate(accessCodeDetail.updated_at)}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              {/* <Col xl="12">
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">Access Code Users</CardTitle>
                    <TableContainer
                      columns={columns}
                      data={accessCodeDetail.users || []}
                      isGlobalFilter={false}
                      customPageSize={20}
                      customPageSizeOptions={true}
                    />
                  </CardBody>
                </Card>
              </Col> */}

              <Col xl="12">
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">Access Locations</CardTitle>
                    <Table className="table table-bordered border-success mb-0 table-striped">
                      <thead>
                        <tr>
                          <th>Location Code</th>
                          <th>Location Name</th>
                        </tr>
                      </thead>
                      <tbody>
                        {accessCodeDetail.countries.length > 0 &&
                          accessCodeDetail.countries.map((country, key) => (
                            <tr key={key}>
                              <td>{country.title}</td>
                              <td>{country.label}</td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </CardBody>
                </Card>
              </Col>

              <Col xl="12">
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">Access Stages</CardTitle>
                    <Table className="table table-bordered border-success mb-0 table-striped">
                      <thead>
                        <tr>
                          <th>Stage</th>
                          <th>Type</th>
                        </tr>
                      </thead>
                      <tbody>
                        {(accessCodeDetail.role != "user" && (
                          <tr className="text-center">
                            <td colSpan="2">All Stages</td>
                          </tr>
                        )) ||
                          (accessCodeDetail.stages.length > 0 &&
                            accessCodeDetail.stages.map((stage, key) => (
                              <tr key={key}>
                                <td>
                                  Stage {stage.stage}: {stage.label}
                                </td>
                                <td>{stage.type}</td>
                              </tr>
                            )))}
                      </tbody>
                    </Table>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

AccessCodeDetail.propTypes = {
  accessCodeDetail: PropTypes.any,
  getAccessCodeDetail: PropTypes.func,
}

export default withRouter(AccessCodeDetail)
