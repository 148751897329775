import React, { useEffect, useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { listStages } from "services"; 

const MilestoneStageDropdown = ({ selectedStageID, setMilestoneStage }) => {
  const [milestoneStages, setMilestoneStages] = useState([]);
  const [menu, setMenu] = useState(false);
  const [selectedStage, setSelectedStage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchMilestoneStages = async () => {
      try {
        setIsLoading(true);
        const stages = await listStages(); // Fetch the list of milestone stages
        setMilestoneStages(stages);

        // Find and set the default stage using selectedStageID
        const defaultStage = stages.find((stage) => stage.id === selectedStageID);
        setSelectedStage(defaultStage || stages[0]); // Fallback to the first stage
        setMilestoneStage((defaultStage || stages[0])?.id); // Update parent
      } catch (error) {
        console.error("Failed to fetch milestone stages:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchMilestoneStages();
  }, [selectedStageID, setMilestoneStage]);

  const changeStageAction = (stage) => {
    setSelectedStage(stage);
    setMilestoneStage(stage.id); // Update the parent component
    toggle();
  };

  const toggle = () => setMenu(!menu);

  return (
    <Dropdown isOpen={menu} toggle={toggle} className="d-inline-block">
      <DropdownToggle className="d-flex align-items-center btn bg-white" tag="button">
        {isLoading ? "Loading..." : selectedStage?.name || "Select a Stage"}
        <i className="mdi mdi-chevron-down mx-2" />
      </DropdownToggle>
      <DropdownMenu>
        {milestoneStages.map((stage) => (
          <DropdownItem
            key={stage.id}
            onClick={() => changeStageAction(stage)}
            className={`d-flex align-items-center ${
              selectedStage?.id === stage.id ? "bg-slate-100 justify-content-between" : ""
            }`}
          >
            <span>{stage.name}</span>
            {selectedStage?.id === stage.id && <i className="mdi mdi-check ms-auto" />}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

export default MilestoneStageDropdown;
