import React from "react"
import loginLogo from "assets/images/new-logo.svg"
import Login from "pages/Authentication/Login"
import { OnboardSlider } from "components/Common/OnboardSlider"
import { welcomeMessages } from "helpers/utlities"
import Register from "pages/Authentication/Register"
import { useLocation } from 'react-router-dom';
import ForgetPassword from "pages/Authentication/ForgetPassword"
import { Link } from "react-router-dom/cjs/react-router-dom"
import Footer from "components/VerticalLayout/Footer"
import ResetPassword from "pages/Authentication/ResetPassword"

export const Welcome = () => {
  const location = useLocation();

  // Split the pathname by '/' and filter out empty strings (for cases like leading slashes)
  const firstRoute = location.pathname.split('/').filter(Boolean)[0] || 'root';
  
  // Conditionally render based on the firstRoute
  const renderComponent = () => {
    switch (firstRoute) {
      case 'login':
        return <Login />;
      case 'register':
        return <Register />;
      case 'forgot-password':
        return <ForgetPassword />;
      case 'reset-password':
        return <ResetPassword/>;
      default:
        return <Login/>;
    }
  };

  return (
    <div className="bg-auth overflow-auto">
      {/* <div className="fixed left-0 top-0 bottom-0">
            <img src={auth1Image} alt="" className="w-auto h-full" />
          </div>
          <div className="fixed right-0 top-0 bottom-0">
            <img src={auth2Image} alt="" className="w-auto h-full" />
          </div> */}
      <div className="mx-3 mb-12 lg:mb-0">
        <div className="h-screen flex flex-col justify-start items-start gap-6 lg:flex-row lg:mx-24 lg:justify-between lg:items-center">
          <div className="h-full flex flex-col justify-around">
            <Link to='/'>
              <div className="rounded-[2.25rem] px-3 py-2 w-[10rem] bg-white lg:w-[13.5rem]">
                <img src={loginLogo} alt="AGRA Logo"
                  // className="h-auto max-w-full"
                />
              </div>
            </Link>
            <div className="text-[#FEFE01]">
              <div className="font-bold text-2xl mb-2 lg:text-4xl lg:mb-4">
                Welcome to the <br /> APRITS Dashboard.
              </div>
              <div className="w-2/3">
                <OnboardSlider
                  slides={welcomeMessages}
                />
              </div>
            </div>
            <div></div>
          </div>
          <div>
            {renderComponent()}
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  )
}
