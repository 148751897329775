import React, { useState, useEffect } from "react";
import { Button, ModalHeader, Modal, ModalBody } from "reactstrap";
import SearchBox from "components/Common/SearchBox";
import Pagination from "components/Common/Pagination";
import FormsTable from "./BenchmarkingTable";
import LoadingSpinner from "components/Common/Loading-Spinner";
import useDebounce from "hooks/useDebounce";
import ExportTable from "../../components/Common/ExportTable";
import { exportTableData } from "services/common";
import { getCountryBenchmarkingMilestones } from "../../services/milestoneServices"
import styled from "styled-components"
import BenchmarkingTasksFilters from "pages/Tasks/Benchmarking/Filters";

const StyledCountryBenchmarkingPage = styled.div`
    & > .actions {
        & > .basic-single {
            flex-basis: 100px;
        }
    }
`

const CountryBenchmarking = ({ alert }) => {
  // document.title = "Benchmarking | AGRA";

  const [searchField, setSearchField] = useState("");
  const debouncedSearchTerm = useDebounce(searchField);
  const [selectedLocations, setSelectedLocations] = useState([])
  const [complete, setComplete] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalEntries, setTotalEntries] = useState(0);
  const [offset, setOffset] = useState(0);

  // Manage loading, error, and milestonesData states
  const [milestonesData, setMilestonesData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const [modalFilter, setModalFilter] = useState(false);
  const toggleFilter = () => setModalFilter(prevState => !prevState);

  const searchChange = e => {
    setSearchField(e.target.value.toLowerCase());
    setOffset(0);
    setCurrentPage(1);
  };

  const applyFilters = (locations, complete) => {
    setSelectedLocations(locations);
    setComplete(complete);
    toggleFilter();
  }

  const goToPage = page => {
    if (page > 0) {
      const offsetParam = page * 50 - 50;
      setOffset(offsetParam);
      setCurrentPage(page);
    } else {
      console.error("Page number should be greater than 0");
    }
  };

  const checkForFilters = () => selectedLocations.length || complete;

  const clearFilters = () => {
    setSelectedLocations([]);
    setComplete(null);
  };

  const fetchCountryBenchmarking = async () => {
    const filters = {
      locations: selectedLocations?.map(location=>location.value),
      complete: complete?.value,
      limit: 50,
      offset
    };
    setIsLoading(true);
    try {
      const { data, count } = await getCountryBenchmarkingMilestones(filters)
      setMilestonesData(data);
      setTotalEntries(count);
    } catch (error) {
      console.error(error);
      alert("Fetching data", false)
    } finally {
      setIsLoading(false);
    }
  };
  
  useEffect(() => {
    fetchCountryBenchmarking();
  }, [selectedLocations, complete, debouncedSearchTerm, currentPage]);

  const exportTable = async type => {
    try {
      const query = {
        export_format: type,
        // ...(selectedUser && { user_id: selectedUser }),
        // ...(selectedActivity && { activity: selectedActivity }),
      };

      exportTableData(query, 'Country_Benchmarking_Milestones');

    } catch (err) {
      console.error(err)
      alert("Data export", false)
    }
  }

  // const initiateEdit = (id) => {
  //   const selectedRowData = milestonesData?.data?.find((row) => row.id === id);
  //   if (selectedRowData) {
  //     localStorage.setItem("CountryMilestoneRecordData", JSON.stringify(selectedRowData));
  //   }
  // };

  return (
    <StyledCountryBenchmarkingPage>
      <div
        className="d-flex align-items-center gap-1 actions"
        style={{ width: "96%", marginInline: "auto" }}
      >
        <SearchBox searchChange={searchChange} />
        <div>
          <Button
            className="common-button"
            onClick={toggleFilter}
          >
            <i className="mdi mdi-filter-variant me-1"></i>
            Filter
          </Button>
          <Modal
            isOpen={modalFilter}
            toggle={toggleFilter}
            className="modal-lg"
          >
            <ModalHeader toggle={toggleFilter}>
              <i className="mdi mdi-filter-variant me-1"></i>
              Filters
            </ModalHeader>
            <ModalBody>
              <BenchmarkingTasksFilters
                filteredLocations={selectedLocations}
                filteredComplete={complete}
                applyFilters={applyFilters}
              />
            </ModalBody>
          </Modal>
        </div>
        <div>
          {checkForFilters() && (
            <Button
              className="common-button"
              onClick={clearFilters}
            >
              <i className="ph-x me-1"></i>
              Clear All Filters
            </Button>
          )}
        </div>

        <div className="ml-auto">
          <ExportTable loadFilteredData={exportTable} />
        </div>
      </div>
      {isLoading && <LoadingSpinner />}
      <div style={{ width: "96%", marginInline: "auto" }}>
        {milestonesData?.length > 0 ? (
          <>
            <FormsTable
              forms={milestonesData || []}
            />
            <Pagination
              currentPage={currentPage}
              goToPage={goToPage}
              totalEntries={totalEntries}
            />
          </>
        ) : null}
      </div>
    </StyledCountryBenchmarkingPage>
  )
}

export default CountryBenchmarking;