import React, { useState } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';

function LandscapingCategoryDropdown({selectedCategory, setSelectedCategory}) {

    const categories = ['None', 'Macro', 'Micro', 'Meso'];
    const [menu, setMenu] = useState(false);

    const toggle = () => {
        setMenu(!menu)
    }

    const selectCategory = category => {
        setSelectedCategory(category);
        toggle();
    }

    return (
        <div>
            <Dropdown isOpen={menu} toggle={toggle} className="d-inline-block">
                <DropdownToggle className="d-flex align-items-center btn bg-white" tag="button">
                    {selectedCategory}
                    <i className="mdi mdi-chevron-down ml-2" />
                </DropdownToggle>
                <DropdownMenu>
                    {categories.map((category, i) => (
                    <DropdownItem
                        key={i}
                        onClick={() => selectCategory(category)}
                        className={`d-flex align-items-center ${
                            selectedCategory === category ? "bg-slate-100 justify-content-between" : ""
                        }`}
                    >
                        <span>{category}</span>
                        { selectedCategory === category && <i className="mdi mdi-check ms-auto" />}
                    </DropdownItem>
                    ))}
                </DropdownMenu>
            </Dropdown>
        </div>
    );
}

export default LandscapingCategoryDropdown;