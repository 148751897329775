// DonutChart.js
import React, { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import './DonutChart.css'; // for custom styles

ChartJS.register(ArcElement, Tooltip, Legend);

const DonutChart = ({chartData, label,title}) => {

  const [labels, setLabels] = useState([]);

  useEffect(()=>{
    if(chartData){
      let labels = chartData?.name?.map(((name, i)=>`${i+1} - ${name}`));
      setLabels(labels);
    }
  },[chartData])

  const data = {
    labels: labels,
    datasets: [
      {
        data: chartData?.[label?.dataField],
        backgroundColor: [
          "#55A630",
          "#80B918",
          "#AACC00",
          "#BFD200",
          "#D4D700",
          "#DBF227",
        ],
        hoverBackgroundColor: [
          "rgba(85, 166, 48, 0.8)", 
          "rgba(128, 185, 24, 0.8)", 
          "rgba(170, 204, 0, 0.8)", 
          "rgba(191, 210, 0, 0.8)",  
          "rgba(212, 215, 0, 0.8)",  
          "rgba(219, 242, 39, 0.8)", 
        ],
        cutout: "70%", // Donut size
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        enabled: true,
      },
      legend: {
        display: false, // Disable the default legend to create a custom one
      },
    },
  };

  return (
    <div className="chart-container">
      <h3 className="section-title">{title? title : `Stages`}</h3>
      <div className="chart-legend-wrapper">
        <div className="donut-chart-wrapper chart-height">
          <Doughnut data={data} options={options} />
          {/* Custom Center Text */}
          <div className="centered-text">
            <span>{label?.dataField === 'number_of_policies' ? chartData?.total : chartData?.overall_average_score}</span>
            <p>{label?.textField}</p>
          </div>
        </div>
        {/* Custom Legend */}
        <div className="custom-legend">
          <div className="legend-item">
            <div></div>
            <div></div>
            <div>{label?.textField}</div>
          </div>
          {data?.labels?.map((label, index) => (
            <div key={index} className="legend-item">
              <div
                className="legend-color"
                style={{ backgroundColor: data?.datasets[0]?.backgroundColor?.[index] }}
              ></div>
              <span>{label}</span>
              <span className="legend-value">{data?.datasets[0]?.data?.[index]}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DonutChart;