import React, { useState } from 'react';
import Select from "react-select";

const roleOptions = [{label: "Admin", value: true}, {label: "User", value: false}];

function Filters({applyFilters, appliedFilters}) {
    const [filters, setFilters] = useState(appliedFilters);

    const handleChange = (options, field) => {
        console.log(options);
        setFilters((prev)=>({
          ...prev,
          [field]: options
        }))
    }

    return (
        <>
            <div className="d-flex my-2">
                <div className="d-flex align-items-center">
                <i className="mdi mdi-chart-bar me-1" />
                <h6 className="mb-0">Role:</h6>
                </div>
                <div className="ms-2" style={{ width: "100%" }}>
                <Select
                    isClearable
                    options={roleOptions}
                    defaultValue={filters.role}
                    className="basic-single"
                    classNamePrefix="select"
                    placeholder="Select"
                    onChange={options => handleChange(options, 'role')}
                />
                </div>
            </div>
            <div className="d-flex" style={{ justifyContent: "center" }}>
                <button
                    className="btn btn-success common-button"
                    style={{ width: "90%" }}
                    onClick={()=>applyFilters(filters)}
                >
                    Apply
                </button>
            </div>
        </>
    );
}

export default Filters;