import PropTypes from "prop-types"
import React, { useState, useEffect, useRef } from "react"

import { useSelector } from "react-redux"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"

// Import relevant icons from Iconify
import { Icon } from '@iconify/react'
import homeOutline from '@iconify/icons-mdi/home-outline'
import taskIcon from '@iconify/icons-mdi/clipboard-check-outline'
import dataIntelligenceIcon from '@iconify/icons-mdi/chart-line'
import policyLandscapingIcon from '@iconify/icons-mdi/file-document-outline'
import benchmarkingIcon from '@iconify/icons-mdi/chart-box-outline'
import policyDirectoryIcon from '@iconify/icons-mdi/file-search-outline'
import policyUpdatesIcon from '@iconify/icons-mdi/calendar-text-outline'
import systemsManagementIcon from '@iconify/icons-mdi/cog-outline'
import lockIcon from '@iconify/icons-mdi/lock'
import editIcon from '@iconify/icons-mdi/pencil-outline'
import groupIcon from '@iconify/icons-mdi/account-group-outline'
import questionMarkIcon from '@iconify/icons-mdi/help-circle-outline'
import auditIcon from '@iconify/icons-mdi/history'
import buildingIcon from '@iconify/icons-mdi/office-building-outline'
import trackingIcon from '@iconify/icons-mdi/clipboard-text-outline'
import userManagementIcon from '@iconify/icons-mdi/account-cog-outline'
import chartBarIcon from '@iconify/icons-mdi/chart-bar'  // For Statistics
import clipboardTextIcon from '@iconify/icons-mdi/clipboard-text'  // For Qualitative Analysis
import newspaperIcon from '@iconify/icons-mdi/newspaper'  // For News Insights
import trendingUpIcon from '@iconify/icons-mdi/trending-up'  // For Trends

const SidebarContent = props => {
  const ref = useRef()
  const [menu, setMenu] = useState(false)
  const { user } = useSelector(state => state.Profile)
  const { stages } = useSelector(state => state.list)
  const [hasBenchmarkingAccess, setHasBenchmarkingAccess] = useState(false)
  const [hasLandscapingAccess, setHasLandscapingAccess] = useState(false)

  useEffect(() => {
    stages.map(stage => {
      if (stage.type === "benchmarking") {
        setHasBenchmarkingAccess(true)
      }
      if (stage.type === "landscaping") {
        setHasLandscapingAccess(true)
      }
    })
  }, [stages])

  useEffect(() => {
    const pathName = props.location.pathname

    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])

  useEffect(() => {
    if (user && "id" in user) {
      setMenu(!menu)
    }
  }, [user])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{props.t("Work space")} </li>
            <li>
              <Link to="/dashboard">
                <div className="d-flex align-items-center">
                  <Icon icon={homeOutline} className="me-2" width="20" />
                  <span>{props.t("Home")}</span>
                </div>
              </Link>
            </li>
            <li>
              <Link to="/my-tasks">
                <div className="d-flex align-items-center">
                  <Icon icon={taskIcon} className="me-2" width="20" />
                  <span>{props.t("My Tasks")}</span>
                </div>
              </Link>
            </li>
            <li>
              <Link to="/#" className="has-arrow d-flex justify-between items-center">
                <div className="d-flex">
                  <Icon icon={dataIntelligenceIcon} className="me-2" width="20" />
                  <span>{props.t("Data Intelligence")}</span>
                </div>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/#" className="has-arrow d-flex justify-between items-center">
                    <div className="d-flex align-items-center">
                      <Icon icon={chartBarIcon} className="me-2" width="20" />
                      <span>{props.t("Statistics")}</span>
                    </div>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/statistics/e-tracker">
                        <div className="d-flex align-items-center">
                          <Icon icon={editIcon} className="me-2" width="20" />
                          {props.t("e-Tracker")}
                        </div>
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/#" className="has-arrow d-flex justify-between items-center">
                    <div className="d-flex align-items-center">
                      <Icon icon={clipboardTextIcon} className="me-2" width="20" />
                      <span>{props.t("Qualitative Analysis")}</span>
                    </div>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/">
                        <div className="d-flex align-items-center">
                          <Icon icon={newspaperIcon} className="me-2" width="20" />
                          <span>{props.t("News Insights")}</span>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link to="/#">
                        <div className="d-flex align-items-center">
                          <Icon icon={trendingUpIcon} className="me-2" width="20" />
                          <span>{props.t("Trends")}</span>
                        </div>
                      </Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/policy-landscape">
                <div className="d-flex align-items-center">
                  {hasLandscapingAccess ? (
                    <Icon icon={policyLandscapingIcon} className="me-2" width="20" />
                  ) : (
                    <Icon icon={policyLandscapingIcon} className="me-2" width="20" />
                  )}
                  <span>{props.t("Policy Landscaping")}</span>
                </div>
              </Link>
            </li>

            <li>
              <Link to="/benchmarking">
                <div className="d-flex align-items-center">
                  {hasBenchmarkingAccess ? (
                    <Icon icon={benchmarkingIcon} className="me-2" width="20" />
                  ) : (
                    <Icon icon={benchmarkingIcon} className="me-2" width="20" />
                  )}
                  <span>{props.t("Benchmarking")}</span>
                </div>
              </Link>
            </li>

            <li>
              <Link to="/#" className="has-arrow d-flex justify-between items-center">
                <div className="d-flex">
                  <Icon icon={editIcon} className="me-2" width="20" />
                  <span>{props.t("E-Tracker")}</span>
                </div>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/policy-progress">
                    <div className="d-flex align-items-center">
                      <Icon icon={trackingIcon} className="me-2" width="20" />
                      <span>{props.t("Policy Progress")}</span>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="/e-tracker">
                    <div className="d-flex align-items-center">
                      <Icon icon={chartBarIcon} className="me-2" width="20" />
                      <span>{props.t("Stage Insights")}</span>
                    </div>
                  </Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/policy-directory">
                <div className="d-flex align-items-center">
                  <Icon icon={policyDirectoryIcon} className="me-2" width="20" />
                  <span>{props.t("Policy Directory")}</span>
                </div>
              </Link>
            </li>

            <li>
              <Link to="/partner-management">
                <div className="d-flex align-items-center">
                  <Icon icon={groupIcon} className="me-2" width="20" />
                  <span>{props.t("Policy Partners")}</span>
                </div>
              </Link>
            </li>
            <li>
              <Link to="/policy-updates-and-events-management">
                <div className="d-flex align-items-center">
                  <Icon icon={policyUpdatesIcon} className="me-2" width="20" />
                  <span>{props.t("Policy Updates & Events")}</span>
                </div>
              </Link>
            </li>
            <li>
              <Link to="/frequently-asked-questions">
                <div className="d-flex align-items-center">
                  <Icon icon={questionMarkIcon} className="me-2" width="20" />
                  <span>{props.t("FAQs")}</span>
                </div>
              </Link>
            </li>

            {user && user.is_admin && (
              <>
                <hr style={{ color: "gray" }} />
                <li className="menu-title">{props.t("Admin Features")} </li>
                <li>
                  <Link to="/labels">
                    <div className="d-flex align-items-center">
                      <Icon icon={systemsManagementIcon} className="me-2" width="20" />
                      <span>{props.t("Systems Management")}</span>
                    </div>
                  </Link>
                </li>

                <li>
                  <Link to="/forms">
                    <div className="d-flex align-items-center">
                      <Icon icon={trackingIcon} className="me-2" width="20" />
                      <span>{props.t("Tracking & Assessment")}</span>
                    </div>
                  </Link>
                </li>

                <li>
                  <Link to="/user-management">
                    <div className="d-flex align-items-center">
                      <Icon icon={userManagementIcon} className="me-2" width="20" />
                      <span>{props.t("User Management")}</span>
                    </div>
                  </Link>
                </li>

                <li>
                  <Link to="/organization-management">
                    <div className="d-flex align-items-center">
                      <Icon icon={buildingIcon} className="me-2" width="20" />
                      <span>{props.t("User Organizations")}</span>
                    </div>
                  </Link>
                </li>

                <li>
                  <Link to="/audit">
                    <div className="d-flex align-items-center">
                      <Icon icon={auditIcon} className="me-2" width="20" />
                      <span>{props.t("Audit Trail")}</span>
                    </div>
                  </Link>
                </li>
              </>
            )}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
